import React from 'react';
import styles from './Error.module.scss';
import I18n from './I18n';

export default function Error({ text }) {
  return (
    <div className={styles.container}>
      <I18n>{text || 'Erro desconhecido'}</I18n>
    </div>
  );
}
