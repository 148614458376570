import withObservables from '@nozbe/with-observables';
import React, { useEffect } from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';
import UnimedCard from '../components/UnimedCard';
import HeaderLogoAndProfile from '../components/HeaderLogoAndProfile';
import Event from '../database/model/Event';
import DataService from '../libs/DataService';
import {
  ACADEMIA_UNIMED_EVENT,
  ARENA_UNIMED_EVENT,
  MUDE_1_HABITO_RIO_EVENT,
} from '../libs/Env';

const idList = [
  ARENA_UNIMED_EVENT,
  ACADEMIA_UNIMED_EVENT,
  MUDE_1_HABITO_RIO_EVENT,
];

type Props = {
  events: Event[];
};

function UnimedScreen({ events }: Props) {
  useEffect(() => {
    idList.forEach(id => DataService.syncEvent(id));
  }, []);

  return (
    <>
      <HeaderLogoAndProfile />
      <ScrollView
        contentContainerStyle={styles.container}
        showsVerticalScrollIndicator={false}
      >
        {events.map(event => (
          <View key={event.id} style={styles.card}>
            <UnimedCard id={event.id} />
          </View>
        ))}
      </ScrollView>
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 20,
    paddingBottom: 100,
  },
  card: {
    marginBottom: 20,
  },
});

const enhance: any = withObservables([], () => ({
  events: DataService.observeEventsByIds(idList),
}));

export default enhance(UnimedScreen);
