import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { connect } from 'react-redux';
import { resolveError } from '../libs/FormUtils';
import Theme from '../libs/Theme';
import Api from '../libs/Api';
import FormLayout from './FormLayout';
import I18n from './I18n';
import ActionButton from './ActionButton';
import CheckboxInput from './CheckboxInput';

const NO_DEFICIENCY = 'Sem deficiência';

const OPTIONS = [
  'Deficiência Visual',
  'Deficiência Auditiva',
  'Deficiência Intelectual',
  'Deficiência Física',
  'Deficiência Múltipla',
  NO_DEFICIENCY,
];

class DeficiencyForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: this.resolveData(this.props.user.comments),
      error: '',
    };
  }

  async componentDidMount() {
    try {
      await Api.getMe();
    } catch (error) {}
  }

  componentDidUpdate(prevProps) {
    if (prevProps.user.comments !== this.props.user.comments) {
      const data = this.resolveData(this.props.user.comments);
      this.setState({ data });
    }
  }

  resolveData = string => {
    const parsedData = string?.split(', ') || [];
    const data = {};
    OPTIONS.forEach(o => (data[o] = parsedData.includes(o)));
    return data;
  };

  onChangeInput = (value, name) => {
    if (name === NO_DEFICIENCY && value) {
      this.setState({ data: this.resolveData(NO_DEFICIENCY) });
    } else {
      this.setState({
        data: { ...this.state.data, [NO_DEFICIENCY]: false, [name]: value },
      });
    }
  };

  isValid = () => {
    return OPTIONS.filter(option => this.state.data[option]).length > 0;
  };

  submitHandler = async () => {
    if (!this.isValid()) {
      this.setState({ error: 'fieldRequired' });
      return false;
    }

    this.setState({ error: '' });

    try {
      const comments = OPTIONS.filter(o => this.state.data[o]).join(', ');
      await Api.updateMe({ comments });
      this.props.onSuccess?.();
    } catch (error) {
      this.setState({ error: resolveError(error) });
    }
  };

  getFields = () => {
    return [
      <View>
        {OPTIONS.map(key => (
          <View key={key} style={styles.checkboxRow}>
            <CheckboxInput
              label={key}
              name={key}
              value={this.state.data[key]}
              onChange={this.onChangeInput}
            />
          </View>
        ))}
      </View>,
    ];
  };

  getSubmitButton = () => (
    <ActionButton
      title={this.props.submitLabel || 'save'}
      theme="whiteWithBorder"
      onPress={this.submitHandler}
      useFeedbackIcon
    />
  );

  render() {
    const fields = this.getFields();
    const footer = this.getSubmitButton();
    return (
      <View>
        <View style={styles.disclaimerArea}>
          <Text style={styles.disclaimerText}>
            <I18n>deficiencyDescription</I18n>
          </Text>
        </View>
        <FormLayout fields={fields} footer={footer} error={this.state.error} />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  disclaimerArea: {
    padding: 20,
  },
  disclaimerText: {
    lineHeight: 25,
    color: Theme.color.darkGray,
    fontFamily: Theme.fonts.barlow,
    fontSize: 16,
  },
  checkboxRow: {
    marginBottom: 10,
  },
});

const mapStateToProps = ({ user }) => ({ user });

export default connect(mapStateToProps)(DeficiencyForm);
