import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import Theme from '../libs/Theme';
import LoadingIcon from '../icons/LoadingIcon';
import I18n from './I18n';

export default class LoadingView extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      ready: false,
    };
  }

  componentDidMount() {
    if (this.props.lazyMount) {
      setTimeout(() => this.setState({ ready: true }));
    }
  }

  render() {
    const ready = this.props.lazyMount ? this.state.ready : true;

    if (ready && this.props.hasData) {
      return this.props.children || null;
    }

    if (!ready || this.props.loading) {
      return (
        <View style={[styles.container, this.props.style]}>
          <LoadingIcon color={this.props.color || Theme.color.magenta} />
        </View>
      );
    }

    const empty = this.props.empty || 'dataNotAvailable';

    if (typeof empty === 'string') {
      return (
        <View style={[styles.container, this.props.style]}>
          <Text
            style={[
              styles.text,
              { color: this.props.color || Theme.color.veryDarkGray },
            ]}
          >
            <I18n>{empty}</I18n>
          </Text>
        </View>
      );
    }

    if (typeof empty === 'function') {
      return empty();
    }

    return this.props.empty;
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    minHeight: 200,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Theme.color.white,
  },
  text: {
    color: Theme.color.veryDarkGray,
    fontFamily: Theme.fonts.barlow,
    fontSize: 15,
    lineHeight: 24,
    textAlign: 'center',
    paddingHorizontal: 50,
  },
});
