import React from 'react';
import { StyleSheet, Text, View, TouchableWithoutFeedback } from 'react-native';
import Theme from '../libs/Theme';
import CouchIcon from '../icons/CouchIcon';
import I18n from './I18n';

export default function CouchMessage({ title, subtitle, cta, onPressCta }) {
  return (
    <View>
      <CouchIcon color={Theme.color.veryDarkGray} style={styles.icon} />
      <Text style={styles.title}>
        <I18n>{title}</I18n>
      </Text>
      <Text style={styles.subtitle}>
        <I18n>{subtitle}</I18n>
      </Text>
      <TouchableWithoutFeedback onPress={onPressCta}>
        <Text style={styles.cta}>
          <I18n>{cta}</I18n>
        </Text>
      </TouchableWithoutFeedback>
    </View>
  );
}

const styles = StyleSheet.create({
  icon: {
    alignSelf: 'center',
    marginTop: 80,
  },
  title: {
    color: Theme.color.veryDarkGray,
    fontFamily: Theme.fonts.barlowSemiCondensedMedium,
    fontSize: 14,
    textAlign: 'center',
    marginTop: 10,
  },
  subtitle: {
    color: Theme.color.gray,
    fontFamily: Theme.fonts.barlowSemiBold,
    fontSize: 11,
    textAlign: 'center',
    marginTop: 5,
  },
  cta: {
    color: Theme.color.magenta,
    fontFamily: Theme.fonts.barlowBold,
    fontSize: 12,
    textAlign: 'center',
    textTransform: 'uppercase',
    alignSelf: 'center',
    padding: 16,
  },
});
