import React from 'react';
import axios from 'axios';
import styles from './CollectionScreen.module.scss';
import { API_URL } from '../libs/Env';
import Theme from '../libs/Theme';
import { openApp } from '../libs/Utils';
import withNavParams from '../components/withNavParams';
import LayoutWithHeader from '../components/LayoutWithHeader';

class CollectionScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      error: '',
      workoutCollection: null,
      sponsor: null,
    };
  }

  async componentDidMount() {
    try {
      const result = await axios.get(
        `${API_URL}/workout_collection/${this.props.id}/`,
      );
      const workoutCollection = result.data;
      this.setState({ workoutCollection });

      if (workoutCollection.sponsor) {
        const resultSponsor = await axios.get(
          `${API_URL}/sponsor/${workoutCollection.sponsor}`,
        );
        const sponsor = resultSponsor.data;
        this.setState({ sponsor });
      }
    } catch (error) {
      this.setState({ error: error.message });
    }

    this.setState({ loading: false });
  }

  render() {
    const { loading, error, workoutCollection, sponsor } = this.state;

    if (!workoutCollection) {
      return <LayoutWithHeader loading={loading} error={error} />;
    }

    const backgroundColor = sponsor?.main_color || Theme.color.magenta;

    return (
      <LayoutWithHeader loading={loading} error={error}>
        <div className={styles.container}>
          <a
            className={styles.card}
            href="https://onelink.to/sharecolecao"
            onClick={openApp}
          >
            <div
              className={styles.header}
              style={{ backgroundImage: `url(${workoutCollection.image})` }}
            >
              <div className={styles.name}>{workoutCollection.name}</div>
              <div className={styles.details}>Coleção</div>
            </div>
            <div className={styles.footer} style={{ backgroundColor }}>
              <div>
                {!!sponsor?.transparency_logo && (
                  <img
                    src={sponsor.transparency_logo}
                    className={styles.logo}
                    alt=""
                  />
                )}
              </div>
              <div className={styles.button}>Baixar app</div>
            </div>
          </a>
        </div>
      </LayoutWithHeader>
    );
  }
}

export default withNavParams(CollectionScreen);
