import { createStore, applyMiddleware, combineReducers } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
// we can't use `storage from redux-persist` because it uses internal _reactNative.AsyncStorage
// and it's duplicating AsyncStorage instance so one instance erase data from another
import AsyncStorage from '@react-native-community/async-storage';
import thunkMiddleware from 'redux-thunk';
import { REDUX_PERSIST_KEY } from '../libs/Env';

import localSettings from './localSettings';
import cities from './cities';
import user from './user';
import address from './address';
import settings from './settings';
import emergencyContacts from './emergencyContacts';
import anamnesis from './anamnesis';
import featureFlag from './featureFlag';
import creditCards from './creditCards';
import transactions from './transactions';

const appReducer = combineReducers({
  localSettings,
  cities,
  user,
  address,
  settings,
  emergencyContacts,
  anamnesis,
  featureFlag,
  creditCards,
  transactions,
});

const persistConfig = {
  key: REDUX_PERSIST_KEY,
  storage: AsyncStorage,
};

const persistedReducer = persistReducer(persistConfig, appReducer);

export function setupStore() {
  const store = createStore(persistedReducer, applyMiddleware(thunkMiddleware));
  const persistor = persistStore(store);
  return { store, persistor };
}
