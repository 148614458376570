import React from 'react';
import Api from '../libs/Api';
import NavigationService from '../libs/NavigationService';
import AlertService from '../libs/AlertService';
import { validatePassword, resolveError } from '../libs/FormUtils';
import withNavParams from '../components/withNavParams';
import Header from '../components/Header';
import KeyboardScrollView from '../components/KeyboardScrollView';
import ActionButton from '../components/ActionButton';
import FormLayout from '../components/FormLayout';
import TextInput from '../components/TextInput';

class RecoverPasswordScreen extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      password: '',
      repassword: '',
      error: '',
    };
  }

  onChangeInput = (value, name) => {
    this.setState({ [name]: value });
  };

  onSubmit = async () => {
    const { token } = this.props;
    const { password, repassword } = this.state;

    const validateError = validatePassword(password, repassword);
    if (validateError) {
      this.setState({ error: validateError });
      return false;
    }

    try {
      await Api.confirmPassword(token, password);
    } catch (error) {
      this.setState({ error: resolveError(error, false, 'password') });
      return false;
    }

    AlertService.show({
      title: 'passwordRecovered',
      message: 'passwordRecoveredText',
      confirmText: 'loginAfterChange',
      onConfirm: () => NavigationService.navigate('Login'),
    });
  };

  render() {
    const fields = [
      <TextInput
        name="password"
        value={this.state.password}
        label="enterYourNewPassword"
        onChange={this.onChangeInput}
      />,
      <TextInput
        name="repassword"
        value={this.state.repassword}
        label="enterYourNewPasswordAgain"
        onChange={this.onChangeInput}
      />,
    ];

    const footer = (
      <ActionButton
        title="sendNewPassword"
        theme="magenta"
        onPress={this.onSubmit}
      />
    );

    return (
      <>
        <Header />
        <KeyboardScrollView>
          <FormLayout
            header="letsRecoverYourPassword"
            content="passwordInstructions"
            fields={fields}
            footer={footer}
            error={this.state.error}
          />
        </KeyboardScrollView>
      </>
    );
  }
}

export default withNavParams(RecoverPasswordScreen);
