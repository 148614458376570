export const ufList = {
  BR: [
    { label: '', value: '' },
    { label: 'Acre', value: 'AC' },
    { label: 'Alagoas', value: 'AL' },
    { label: 'Amapá', value: 'AP' },
    { label: 'Amazonas', value: 'AM' },
    { label: 'Bahia', value: 'BA' },
    { label: 'Ceará', value: 'CE' },
    { label: 'Distrito Federal', value: 'DF' },
    { label: 'Espírito Santo', value: 'ES' },
    { label: 'Goiás', value: 'GO' },
    { label: 'Maranhão', value: 'MA' },
    { label: 'Mato Grosso', value: 'MT' },
    { label: 'Mato Grosso do Sul', value: 'MS' },
    { label: 'Minas Gerais', value: 'MG' },
    { label: 'Pará', value: 'PA' },
    { label: 'Paraíba', value: 'PB' },
    { label: 'Paraná', value: 'PR' },
    { label: 'Pernambuco', value: 'PE' },
    { label: 'Piauí', value: 'PI' },
    { label: 'Rio de Janeiro', value: 'RJ' },
    { label: 'Rio Grande do Norte', value: 'RN' },
    { label: 'Rio Grande do Sul', value: 'RS,' },
    { label: 'Rondônia', value: 'RO' },
    { label: 'Roraima', value: 'RR' },
    { label: 'Santa Catarina', value: 'SC' },
    { label: 'São Paulo', value: 'SP' },
    { label: 'Sergipe', value: 'SE' },
    { label: 'Tocantins', value: 'TO' },
  ],
  US: [
    { label: '', value: '' },
    { label: 'Alabama', value: 'AL' },
    { label: 'Alaska', value: 'AK' },
    { label: 'Arizona', value: 'AZ' },
    { label: 'Arkansas', value: 'AR' },
    { label: 'California', value: 'CA' },
    { label: 'Colorado', value: 'CO' },
    { label: 'Connecticut', value: 'CT' },
    { label: 'Delaware', value: 'DE' },
    { label: 'Florida', value: 'FL' },
    { label: 'Georgia', value: 'GA' },
    { label: 'Hawaii', value: 'HI' },
    { label: 'Idaho', value: 'ID' },
    { label: 'Illinois', value: 'IL' },
    { label: 'Indiana', value: 'IN' },
    { label: 'Iowa', value: 'IA' },
    { label: 'Kansas', value: 'KS' },
    { label: 'Kentucky', value: 'KY' },
    { label: 'Louisiana', value: 'LA' },
    { label: 'Maine', value: 'ME' },
    { label: 'Maryland', value: 'MD' },
    { label: 'Massachusetts', value: 'MA' },
    { label: 'Michigan', value: 'MI' },
    { label: 'Minnesota', value: 'MN' },
    { label: 'Mississippi', value: 'MS' },
    { label: 'Missouri', value: 'MO' },
    { label: 'Montana', value: 'MT' },
    { label: 'Nebraska', value: 'NE' },
    { label: 'Nevada', value: 'NV' },
    { label: 'New Hampshire', value: 'NH' },
    { label: 'New Jersey', value: 'NJ' },
    { label: 'New Mexico', value: 'NM' },
    { label: 'New York', value: 'NY' },
    { label: 'North Carolina', value: 'NC' },
    { label: 'North Dakota', value: 'ND' },
    { label: 'Ohio', value: 'OH' },
    { label: 'Oklahoma', value: 'OK' },
    { label: 'Oregon', value: 'OR' },
    { label: 'Pennsylvania', value: 'PA' },
    { label: 'Rhode Island', value: 'RI' },
    { label: 'South Carolina', value: 'SC' },
    { label: 'South Dakota', value: 'SD' },
    { label: 'Tennessee', value: 'TN' },
    { label: 'Texas', value: 'TX' },
    { label: 'Utah', value: 'UT' },
    { label: 'Vermont', value: 'VT' },
    { label: 'Virginia', value: 'VA' },
    { label: 'Washington', value: 'WA' },
    { label: 'West Virginia', value: 'WV' },
    { label: 'Wisconsin', value: 'WI' },
    { label: 'Wyoming', value: 'WY' },
  ],
};

const countryList = [
  { label_pt: '', label_en: '', value: '' },
  { label_pt: 'Afeganistão', label_en: 'Afghanistan', value: 'AF' },
  { label_pt: 'África do Sul', label_en: 'South Africa', value: 'ZA' },
  { label_pt: 'Albânia', label_en: 'Albania', value: 'AL' },
  { label_pt: 'Alemanha', label_en: 'Germany', value: 'DE' },
  { label_pt: 'Algéria', label_en: 'Algeria', value: 'DZ' },
  { label_pt: 'Andorra', label_en: 'Andorra', value: 'AD' },
  { label_pt: 'Angola', label_en: 'Angola', value: 'AO' },
  { label_pt: 'Anguilla', label_en: 'Anguilla', value: 'AI' },
  { label_pt: 'Antártida', label_en: 'Antarctica', value: 'AQ' },
  {
    label_pt: 'Antigua e Barbuda',
    label_en: 'Antigua and Barbuda',
    value: 'AG',
  },
  {
    label_pt: 'Antilhas Holandesas',
    label_en: 'Netherlands Antilles',
    value: 'AN',
  },
  { label_pt: 'Arábia Saudita', label_en: 'Saudi Arabia', value: 'SA' },
  { label_pt: 'Argentina', label_en: 'Argentina', value: 'AR' },
  { label_pt: 'Armênia', label_en: 'Armenia', value: 'AM' },
  { label_pt: 'Aruba', label_en: 'Aruba', value: 'AW' },
  { label_pt: 'Austrália', label_en: 'Australia', value: 'AU' },
  { label_pt: 'Áustria', label_en: 'Austria', value: 'AT' },
  { label_pt: 'Azerbaijão', label_en: 'Azerbaijan', value: 'AZ' },
  { label_pt: 'Bahamas', label_en: 'Bahamas', value: 'BS' },
  { label_pt: 'Bahrein', label_en: 'Bahrain', value: 'BH' },
  { label_pt: 'Bangladesh', label_en: 'Bangladesh', value: 'BD' },
  { label_pt: 'Barbados', label_en: 'Barbados', value: 'BB' },
  { label_pt: 'Bélgica', label_en: 'Belgium', value: 'BE' },
  { label_pt: 'Belize', label_en: 'Belize', value: 'BZ' },
  { label_pt: 'Benin', label_en: 'Benin', value: 'BJ' },
  { label_pt: 'Bermuda', label_en: 'Bermuda', value: 'BM' },
  { label_pt: 'Bielorrússia', label_en: 'Belarus', value: 'BY' },
  { label_pt: 'Birmânia', label_en: 'Burma', value: 'MM' },
  { label_pt: 'Bolívia', label_en: 'Bolivia', value: 'BO' },
  {
    label_pt: 'Bósnia e Herzegovina',
    label_en: 'Bosnia and Herzegovina',
    value: 'BA',
  },
  { label_pt: 'Botswana', label_en: 'Botswana', value: 'BW' },
  { label_pt: 'Brasil', label_en: 'Brazil', value: 'BR' },
  { label_pt: 'Brunei', label_en: 'Brunei', value: 'BN' },
  { label_pt: 'Bulgária', label_en: 'Bulgaria', value: 'BG' },
  { label_pt: 'Burkina Faso', label_en: 'Burkina Faso', value: 'BF' },
  { label_pt: 'Burundi', label_en: 'Burundi', value: 'BI' },
  { label_pt: 'Butão', label_en: 'Bhutan', value: 'BT' },
  { label_pt: 'Cabo Verde', label_en: 'Cape Green', value: 'CV' },
  { label_pt: 'Camarões', label_en: 'Cameroon', value: 'CM' },
  { label_pt: 'Camboja', label_en: 'Cambodia', value: 'KH' },
  { label_pt: 'Canadá', label_en: 'Canada', value: 'CA' },
  { label_pt: 'Catar', label_en: 'Qatar', value: 'QA' },
  { label_pt: 'Cazaquistão', label_en: 'Kazakhstan', value: 'KZ' },
  { label_pt: 'Chade', label_en: 'Chad', value: 'TD' },
  { label_pt: 'Chile', label_en: 'Chile', value: 'CL' },
  { label_pt: 'China', label_en: 'China', value: 'CN' },
  { label_pt: 'Chipre', label_en: 'Cyprus', value: 'CY' },
  { label_pt: 'Colômbia', label_en: 'Colombia', value: 'CO' },
  { label_pt: 'Comores', label_en: 'Comoros', value: 'KM' },
  { label_pt: 'Congo', label_en: 'Congo', value: 'CG' },
  { label_pt: 'Congo (DR)', label_en: 'Congo (DR', value: 'CD' },
  { label_pt: 'Coreia do Norte', label_en: 'North Korea', value: 'KP' },
  { label_pt: 'Coreia do Sul', label_en: 'South Korea', value: 'KR' },
  { label_pt: 'Costa do Marfim', label_en: 'Costa do Marfim', value: 'CI' },
  { label_pt: 'Costa Rica', label_en: 'Costa Rica', value: 'CR' },
  { label_pt: 'Croácia', label_en: 'Croatia', value: 'HR' },
  { label_pt: 'Cuba', label_en: 'Cuba', value: 'CU' },
  { label_pt: 'Dinamarca', label_en: 'Denmark', value: 'DK' },
  { label_pt: 'Djibuti', label_en: 'Djibouti', value: 'DJ' },
  { label_pt: 'Dominica', label_en: 'Dominica', value: 'DM' },
  { label_pt: 'Egito', label_en: 'Egypt', value: 'EG' },
  { label_pt: 'El Salvador', label_en: 'El Salvador', value: 'SV' },
  {
    label_pt: 'Emirados Árabes',
    label_en: 'United Arab Emirates',
    value: 'AE',
  },
  { label_pt: 'Equador', label_en: 'Ecuador', value: 'EC' },
  { label_pt: 'Eritreia', label_en: 'Eritrea', value: 'ER' },
  { label_pt: 'Eslováquia', label_en: 'Slovakia', value: 'SK' },
  { label_pt: 'Eslovênia', label_en: 'Slovenia', value: 'SI' },
  { label_pt: 'Espanha', label_en: 'Spain', value: 'ES' },
  { label_pt: 'Estados Unidos', label_en: 'United States', value: 'US' },
  { label_pt: 'Estônia', label_en: 'Estonia', value: 'EE' },
  { label_pt: 'Esvalbarde', label_en: 'Esvalbarde', value: 'SJ' },
  { label_pt: 'Etiópia', label_en: 'Ethiopia', value: 'ET' },
  { label_pt: 'Fiji', label_en: 'Fiji', value: 'FJ' },
  { label_pt: 'Filipinas', label_en: 'Philippines', value: 'PH' },
  { label_pt: 'Finlândia', label_en: 'Finland', value: 'FI' },
  { label_pt: 'França', label_en: 'France', value: 'FR' },
  { label_pt: 'Gabão', label_en: 'Gabon', value: 'GA' },
  { label_pt: 'Gâmbia', label_en: 'Gambia', value: 'GM' },
  { label_pt: 'Gana', label_en: 'Ghana', value: 'GH' },
  { label_pt: 'Geórgia', label_en: 'Georgia', value: 'GE' },
  { label_pt: 'Gibraltar', label_en: 'Gibraltar', value: 'GI' },
  { label_pt: 'Granada', label_en: 'Grenade', value: 'GD' },
  { label_pt: 'Grécia', label_en: 'Greece', value: 'GR' },
  { label_pt: 'Groelândia', label_en: 'Greenland', value: 'GL' },
  { label_pt: 'Guadalupe', label_en: 'Guadeloupe', value: 'GP' },
  { label_pt: 'Guão', label_en: 'Guão', value: 'GU' },
  { label_pt: 'Guatemala', label_en: 'Guatemala', value: 'GT' },
  { label_pt: 'Guiana', label_en: 'Guyana', value: 'GY' },
  { label_pt: 'Guiana Francesa', label_en: 'French Guiana', value: 'GF' },
  { label_pt: 'Guiné', label_en: 'Guinea', value: 'GN' },
  { label_pt: 'Guiné Equatorial', label_en: 'Equatorial Guinea', value: 'GQ' },
  { label_pt: 'Guiné-Bissau', label_en: 'Guinea Bissau', value: 'GW' },
  { label_pt: 'Haiti', label_en: 'Haiti', value: 'HT' },
  { label_pt: 'Holanda', label_en: 'Netherlands', value: 'NL' },
  { label_pt: 'Honduras', label_en: 'Honduras', value: 'HN' },
  { label_pt: 'Hong Kong', label_en: 'Hong Kong', value: 'HK' },
  { label_pt: 'Hungria', label_en: 'Hungary', value: 'HU' },
  { label_pt: 'Iêmen', label_en: 'Yemen', value: 'YE' },
  { label_pt: 'Ilha Bouvet', label_en: 'Bouvet Island', value: 'BV' },
  { label_pt: 'Ilha Christmas', label_en: 'Christmas Island', value: 'CX' },
  { label_pt: 'Ilha Norfolk', label_en: 'Norfolk Island', value: 'NF' },
  { label_pt: 'Ilhas Cayman', label_en: 'Cayman Islands', value: 'KY' },
  {
    label_pt: 'Ilhas Cocos (Keeling)',
    label_en: 'Cocos (Keeling) Islands',
    value: 'CC',
  },
  { label_pt: 'Ilhas Cook', label_en: 'Cook Islands', value: 'CK' },
  { label_pt: 'Ilhas Faroe', label_en: 'Faroe Islands', value: 'FO' },
  {
    label_pt: 'Ilhas Geórgia do Sul e Sandwich do Sul',
    label_en: 'South Georgia and the South Sandwich Islands',
    value: 'GS',
  },
  {
    label_pt: 'Ilhas Heard e McDonald',
    label_en: 'Heard and McDonald Islands',
    value: 'HM',
  },
  { label_pt: 'Ilhas Malvinas', label_en: 'Falkland Islands', value: 'FK' },
  {
    label_pt: 'Ilhas Marianas do Norte',
    label_en: 'Northern Mariana Islands',
    value: 'MP',
  },
  { label_pt: 'Ilhas Marshall', label_en: 'Marshall Islands', value: 'MH' },
  {
    label_pt: 'Ilhas Menores Distantes dos Estados Unidos',
    label_en: 'United States Minor Outlying Islands',
    value: 'UM',
  },
  { label_pt: 'Ilhas Picárnia', label_en: 'Picarnia Islands', value: 'PN' },
  { label_pt: 'Ilhas Salomão', label_en: 'Solomon Islands', value: 'SB' },
  {
    label_pt: 'Ilhas Turks e Caicos',
    label_en: 'Turks and Caicos Islands',
    value: 'TC',
  },
  {
    label_pt: 'Ilhas Virgens (USA)',
    label_en: 'Virgin Islands (USA)',
    value: 'VI',
  },
  {
    label_pt: 'Ilhas Virgens Inglesas',
    label_en: 'English Virgin Islands',
    value: 'VG',
  },
  { label_pt: 'Índia', label_en: 'India', value: 'IN' },
  { label_pt: 'Indonésia', label_en: 'Indonesia', value: 'ID' },
  { label_pt: 'Iran', label_en: 'I ran', value: 'IR' },
  { label_pt: 'Iraque', label_en: 'Iraq', value: 'IQ' },
  { label_pt: 'Irlanda', label_en: 'Ireland', value: 'IE' },
  { label_pt: 'Islândia', label_en: 'Iceland', value: 'IS' },
  { label_pt: 'Israel', label_en: 'Israel', value: 'IL' },
  { label_pt: 'Italia', label_en: 'Italy', value: 'IT' },
  { label_pt: 'Jamaica', label_en: 'Jamaica', value: 'JM' },
  { label_pt: 'Japão', label_en: 'Japan', value: 'JP' },
  { label_pt: 'Jornânia', label_en: 'Jornania', value: 'JO' },
  { label_pt: 'Kiribati', label_en: 'Kiribati', value: 'KI' },
  { label_pt: 'Kuwait', label_en: 'Kuwait', value: 'KW' },
  { label_pt: 'Laos', label_en: 'Laos', value: 'LA' },
  { label_pt: 'Lesoto', label_en: 'Lesotho', value: 'LS' },
  { label_pt: 'Letônia', label_en: 'Latvia', value: 'LV' },
  { label_pt: 'Líbano', label_en: 'Lebanon', value: 'LB' },
  { label_pt: 'Libéria', label_en: 'Liberia', value: 'LR' },
  { label_pt: 'Líbia', label_en: 'Libya', value: 'LY' },
  { label_pt: 'Liechtenstein', label_en: 'Liechtenstein', value: 'LI' },
  { label_pt: 'Lituânia', label_en: 'Lithuania', value: 'LT' },
  { label_pt: 'Luxemburgo', label_en: 'Luxembourg', value: 'LU' },
  { label_pt: 'Macao', label_en: 'Macao', value: 'MO' },
  { label_pt: 'Macedônia', label_en: 'Macedonia', value: 'MK' },
  { label_pt: 'Madagascar', label_en: 'Madagascar', value: 'MG' },
  { label_pt: 'Malásia', label_en: 'Malaysia', value: 'MY' },
  { label_pt: 'Malawi', label_en: 'Malawi', value: 'MW' },
  { label_pt: 'Maldivas', label_en: 'Maldives', value: 'MV' },
  { label_pt: 'Mali', label_en: 'Mali', value: 'ML' },
  { label_pt: 'Malta', label_en: 'Malta', value: 'MT' },
  { label_pt: 'Marrocos', label_en: 'Morocco', value: 'MA' },
  { label_pt: 'Martinica', label_en: 'Martinique', value: 'MQ' },
  { label_pt: 'Maurício', label_en: 'Mauritius', value: 'MU' },
  { label_pt: 'Mauritânia', label_en: 'Mauritania', value: 'MR' },
  { label_pt: 'Mayotte', label_en: 'Mayotte', value: 'YT' },
  { label_pt: 'México', label_en: 'Mexico', value: 'MX' },
  { label_pt: 'Micronésia', label_en: 'Micronesia', value: 'FM' },
  { label_pt: 'Moçambique', label_en: 'Mozambique', value: 'MZ' },
  { label_pt: 'Moldova', label_en: 'Moldova', value: 'MD' },
  { label_pt: 'Mônaco', label_en: 'Monaco', value: 'MC' },
  { label_pt: 'Mongólia', label_en: 'Mongolia', value: 'MN' },
  { label_pt: 'Montserrat', label_en: 'Montserrat', value: 'MS' },
  { label_pt: 'Namíbia', label_en: 'Namibia', value: 'NA' },
  { label_pt: 'Nauru', label_en: 'Nauru', value: 'NR' },
  { label_pt: 'Nepal', label_en: 'Nepal', value: 'NP' },
  { label_pt: 'Nicarágua', label_en: 'Nicaragua', value: 'NI' },
  { label_pt: 'Niger', label_en: 'Niger', value: 'NE' },
  { label_pt: 'Nigéria', label_en: 'Nigeria', value: 'NG' },
  { label_pt: 'Niue', label_en: 'Niue', value: 'NU' },
  { label_pt: 'Noruega', label_en: 'Norway', value: 'NO' },
  { label_pt: 'Nova Caledônia', label_en: 'New Caledonia', value: 'NC' },
  { label_pt: 'Nova Zelândia', label_en: 'New Zealand', value: 'NZ' },
  { label_pt: 'Omã', label_en: 'Oman', value: 'OM' },
  { label_pt: 'Palau', label_en: 'Palau', value: 'PW' },
  { label_pt: 'Palestina', label_en: 'Palestine', value: 'PS' },
  { label_pt: 'Panamá', label_en: 'Panama', value: 'PA' },
  { label_pt: 'Papua-Nova Guiné', label_en: 'Papua New Guinea', value: 'PG' },
  { label_pt: 'Paquistão', label_en: 'Pakistan', value: 'PK' },
  { label_pt: 'Paraguai', label_en: 'Paraguay', value: 'PY' },
  { label_pt: 'Peru', label_en: 'Peru', value: 'PE' },
  {
    label_pt: 'Polinésia Francesa',
    label_en: 'French Polynesian',
    value: 'PF',
  },
  { label_pt: 'Polônia', label_en: 'Poland', value: 'PL' },
  { label_pt: 'Porto Rico', label_en: 'Puerto Rico', value: 'PR' },
  { label_pt: 'Portugal', label_en: 'Portugal', value: 'PT' },
  { label_pt: 'Quênia', label_en: 'Kenya', value: 'KE' },
  { label_pt: 'Quirguistão', label_en: 'Kyrgyzstan', value: 'KG' },
  { label_pt: 'Reino Unido', label_en: 'United Kingdom', value: 'GB' },
  {
    label_pt: 'República Centro-Africana',
    label_en: 'Central African Republic',
    value: 'CF',
  },
  {
    label_pt: 'República da Serra Leoa',
    label_en: 'Republic of Sierra Leone',
    value: 'SL',
  },
  {
    label_pt: 'República Dominicana',
    label_en: 'Dominican Republic',
    value: 'DO',
  },
  { label_pt: 'República Tcheca', label_en: 'Czech republic', value: 'CZ' },
  { label_pt: 'Reunião', label_en: 'Meeting', value: 'RE' },
  { label_pt: 'Romênia', label_en: 'Romania', value: 'RO' },
  { label_pt: 'Ruanda', label_en: 'Rwanda', value: 'RW' },
  { label_pt: 'Rússia', label_en: 'Russia', value: 'RU' },
  { label_pt: 'Saara Ocidental', label_en: 'Western Sahara', value: 'EH' },
  { label_pt: 'Samoa', label_en: 'Samoa', value: 'WS' },
  { label_pt: 'Samoa Americana', label_en: 'American Samoa', value: 'AS' },
  { label_pt: 'Santa Helena', label_en: 'Saint Helen', value: 'SH' },
  { label_pt: 'Santa Lúcia', label_en: 'Saint Lucia', value: 'LC' },
  { label_pt: 'São Cristóvão', label_en: 'Saint Cristopher', value: 'KN' },
  { label_pt: 'São Marino', label_en: 'San Marino', value: 'SM' },
  {
    label_pt: 'São Pedro e Miquelon',
    label_en: 'Saint Peter and Miquelon',
    value: 'PM',
  },
  {
    label_pt: 'Sao Tomé e Príncipe',
    label_en: 'Sao Tome and Principe',
    value: 'ST',
  },
  {
    label_pt: 'São Vicente e Granadinas',
    label_en: 'Saint Vincent and the Grenadines',
    value: 'VC',
  },
  { label_pt: 'Seicheles', label_en: 'Seychelles', value: 'SC' },
  { label_pt: 'Senegal', label_en: 'Senegal', value: 'SN' },
  {
    label_pt: 'Sérvia e Montenegro',
    label_en: 'Serbia and Montenegro',
    value: 'CS',
  },
  { label_pt: 'Singapura', label_en: 'Singapore', value: 'SG' },
  { label_pt: 'Síria', label_en: 'Syria', value: 'SY' },
  { label_pt: 'Somália', label_en: 'Somalia', value: 'SO' },
  { label_pt: 'Sri Lanka', label_en: 'Sri Lanka', value: 'LK' },
  { label_pt: 'Suazilândia', label_en: 'Swaziland', value: 'SZ' },
  { label_pt: 'Sudão', label_en: 'Sudan', value: 'SD' },
  { label_pt: 'Suécia', label_en: 'Sweden', value: 'SE' },
  { label_pt: 'Suiça', label_en: 'Switzerland', value: 'CH' },
  { label_pt: 'Suriname', label_en: 'Suriname', value: 'SR' },
  { label_pt: 'Tailândia', label_en: 'Thailand', value: 'TH' },
  { label_pt: 'Taiwan', label_en: 'Taiwan', value: 'TW' },
  { label_pt: 'Tajiquistão', label_en: 'Tajikistan', value: 'TJ' },
  { label_pt: 'Tanzânia', label_en: 'Tanzania', value: 'TZ' },
  {
    label_pt: 'Terras Austrais e Antárticas Francesas',
    label_en: 'French Southern and Antarctic Lands',
    value: 'TF',
  },
  {
    label_pt: 'Território Britânico do Oceano Índico',
    label_en: 'British Indian Ocean Territory',
    value: 'IO',
  },
  { label_pt: 'Timor-Leste', label_en: 'Timor-Leste', value: 'TL' },
  { label_pt: 'Togo', label_en: 'Go', value: 'TG' },
  { label_pt: 'Tonga', label_en: 'Tonga', value: 'TO' },
  { label_pt: 'Toquelau', label_en: 'Tokelau', value: 'TK' },
  {
    label_pt: 'Trinidad e Tobago',
    label_en: 'Trinidad and Tobago',
    value: 'TT',
  },
  { label_pt: 'Tunísia', label_en: 'Tunisia', value: 'TN' },
  { label_pt: 'Turcomenistão', label_en: 'Turkmenistan', value: 'TM' },
  { label_pt: 'Turquia', label_en: 'Turkey', value: 'TR' },
  { label_pt: 'Tuvalu', label_en: 'Tuvalu', value: 'TV' },
  { label_pt: 'Ucrânia', label_en: 'Ukraine', value: 'UA' },
  { label_pt: 'Uganda', label_en: 'Uganda', value: 'UG' },
  { label_pt: 'Uruguai', label_en: 'Uruguay', value: 'UY' },
  { label_pt: 'Uzbequistão', label_en: 'Uzbekistan', value: 'UZ' },
  { label_pt: 'Vanuatu', label_en: 'Vanuatu', value: 'VU' },
  { label_pt: 'Vaticano', label_en: 'Vatican', value: 'VA' },
  { label_pt: 'Venezuela', label_en: 'Venezuela', value: 'VE' },
  { label_pt: 'Vietnam', label_en: 'Vietnam', value: 'VN' },
  { label_pt: 'Wallis e Futuna', label_en: 'Wallis and Futuna', value: 'WF' },
  { label_pt: 'Zâmbia', label_en: 'Zambia', value: 'ZM' },
  { label_pt: 'Zimbábue', label_en: 'Zimbabwe', value: 'ZW' },
];

const countryListMap = {
  'pt-br': countryList.map(i => ({ label: i.label_pt, value: i.value })),
  en: countryList.map(i => ({ label: i.label_en, value: i.value })),
};

export function getCountryList(language) {
  return countryListMap[language] || countryListMap['pt-br'];
}
