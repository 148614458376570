import React from 'react';
import { TouchableWithoutFeedback, View, StyleSheet, Text } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import FastImage from 'react-native-fast-image';
import { formatDuration } from '../libs/DIYUtils';
import Theme, { opacity } from '../libs/Theme';

export const WIDTH = (Theme.dimensions.windowWidth - 50) / 2;

export default function ActivityCardView({
  image,
  imageOverlay,
  name,
  trainer,
  place,
  duration,
  modality,
  onPress,
}) {
  return (
    <TouchableWithoutFeedback onPress={onPress}>
      <View style={styles.container}>
        <View style={styles.image}>
          <FastImage
            style={StyleSheet.absoluteFill}
            source={{
              uri:
                image ||
                trainer?.images?.[0] ||
                modality?.image ||
                trainer?.picture ||
                '',
            }}
          />
          <LinearGradient
            style={StyleSheet.absoluteFill}
            locations={[0, 0.25, 0.75, 1]}
            colors={[
              opacity('black', 0.25),
              opacity('black', 0),
              opacity('black', 0),
              opacity('black', 0.5),
            ]}
          />
          {imageOverlay}
        </View>
        <Text style={styles.name}>{name}</Text>
        <Text style={styles.details}>
          {[trainer?.name, place?.name].filter(s => s).join(', ')}
        </Text>
        <Text style={styles.details}>
          {[formatDuration(duration), modality?.name]
            .filter(s => s)
            .join(' • ')}
        </Text>
      </View>
    </TouchableWithoutFeedback>
  );
}

const styles = StyleSheet.create({
  container: {
    width: WIDTH,
    backgroundColor: Theme.color.white,
    overflow: 'hidden',
    borderRadius: 5,
  },
  image: {
    width: WIDTH,
    height: WIDTH * (160 / 155),
    backgroundColor: Theme.color.veryLightGray,
  },
  name: {
    color: Theme.color.veryDarkGray,
    fontFamily: Theme.fonts.barlowSemiBold,
    fontSize: 15,
    lineHeight: 18,
    marginTop: 10,
    marginHorizontal: 10,
  },
  details: {
    color: Theme.color.gray,
    fontFamily: Theme.fonts.barlowSemiBold,
    fontSize: 12,
    lineHeight: 14,
    margin: 10,
    marginTop: 5,
  },
});
