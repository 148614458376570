import React, { useCallback, useRef, useState } from 'react';
import {
  Animated,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  View,
  TouchableWithoutFeedback,
} from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import FastImage from 'react-native-fast-image';
import AnalyticsService from '../libs/AnalyticsService';
import DataService, { withObservables } from '../libs/DataService';
import { resolveError } from '../libs/FormUtils';
import NavigationService from '../libs/NavigationService';
import SentryService from '../libs/SentryService';
import ShareService from '../libs/ShareService';
import Theme from '../libs/Theme';
import { genderContext } from '../libs/Utils';
import useSync from '../hooks/useSync';
import HeartIcon from '../icons/HeartIcon';
import HeartOutlineIcon from '../icons/HeartOutlineIcon';
import withNavParams from '../components/withNavParams';
import Loading from '../components/Loading';
import Empty from '../components/Empty';
import Header from '../components/Header';
import I18n from '../components/I18n';
import DonationEntry from '../components/DonationEntry';
import HorizontalMenu from '../components/HorizontalMenu';
import LessonsByDate from '../components/LessonsByDate';
import WorkoutCard from '../components/WorkoutCard';
import AsyncButton from '../components/AsyncButton';

const IMAGE_WIDTH = Theme.dimensions.windowWidth - 55 - 136;

const TABS = ['lives', 'onDemand'];

function TrainerScreen({ trainer, city, workouts, syncing, favoriteTrainers }) {
  const {
    id,
    picture,
    name,
    instagram,
    institution,
    description,
    images,
    product,
  } = trainer;

  const onPressShare = useCallback(() => {
    ShareService.share(
      'shareTrainer',
      {
        context: genderContext(trainer),
        trainer: trainer.name,
        url: trainer.slug
          ? `https://app.mude.fit/${trainer.slug}`
          : `https://app.mude.fit/professor/${trainer.id}`,
      },
      'trainer',
      trainer.id,
    );
  }, [trainer]);

  const [tab, setTab] = useState(0);

  const onChangeTab = useCallback(index => {
    setTab(index);
  }, []);

  const onPressDonation = useCallback(() => {
    NavigationService.navigate('TrainerDonation', { id });
  }, [id]);

  const onPressCalendar = useCallback(() => {
    NavigationService.navigate('Search', {
      trainer: id,
      type: 'lesson',
      is_virtual: true,
    });
  }, [id]);

  const isFavorite = favoriteTrainers.includes(trainer.id);

  const onPressFavorite = useCallback(async () => {
    try {
      if (isFavorite) {
        await DataService.updateFavoriteTrainer(id, false);
        AnalyticsService.logEvent('remove_favorite_trainer', {
          trainer_id: String(id),
        });
      } else {
        await DataService.updateFavoriteTrainer(id, true);
        AnalyticsService.logEvent('add_favorite_trainer', {
          trainer_id: String(id),
        });
      }
    } catch (error) {
      SentryService.log(error);
      resolveError(error, true);
    }
  }, [id, isFavorite]);

  const scrollRef = useRef(null);
  const scrollValue = useRef(new Animated.Value(0));
  const onScrollEvent = useRef(
    Animated.event(
      [{ nativeEvent: { contentOffset: { y: scrollValue.current } } }],
      { useNativeDriver: true },
    ),
  );

  const isNative = Platform.select({ native: true });

  return (
    <>
      {isNative && <Header onPressShare={onPressShare} />}
      <View style={styles.container}>
        <LinearGradient
          locations={[0, 0.5]}
          colors={[
            Theme.color.white,
            Theme.color.lowContrastGray_USE_WITH_WISDOM,
          ]}
          style={styles.background}
        />
        <Animated.ScrollView
          ref={scrollRef}
          onScroll={onScrollEvent.current}
          scrollEventThrottle={1}
          bounces={false}
          showsVerticalScrollIndicator={false}
          contentContainerStyle={styles.contentContainer}
        >
          <View style={styles.identification}>
            <FastImage style={styles.picture} source={{ uri: picture || '' }} />
            <Text style={styles.name}>{name}</Text>
            {!!instagram && (
              <Text style={styles.instagram} selectable>
                {instagram}
              </Text>
            )}
            {!!institution && (
              <Text style={styles.institution}>{institution}</Text>
            )}
            <Text style={styles.description}>{description}</Text>
          </View>
          {!!product && (
            <View style={styles.donation}>
              <DonationEntry
                text="supportTrainerToOfferActivities"
                textI18nParams={{ trainer: name }}
                onPress={onPressDonation}
              />
            </View>
          )}
          {images.length > 0 && (
            <ScrollView
              horizontal
              showsHorizontalScrollIndicator={false}
              contentContainerStyle={styles.images}
            >
              {images.map(image => (
                <FastImage
                  key={image}
                  style={styles.image}
                  source={{ uri: image || '' }}
                />
              ))}
            </ScrollView>
          )}
          {isNative && (
            <>
              <View style={styles.menu}>
                <HorizontalMenu
                  items={TABS}
                  selected={tab}
                  onChange={onChangeTab}
                />
              </View>
              {tab === 0 ? (
                <View style={styles.tabLessons}>
                  <LessonsByDate trainer={id} city={city} />
                  <View style={styles.calendar}>
                    <TouchableWithoutFeedback onPress={onPressCalendar}>
                      <Text style={styles.calendarText}>
                        <I18n>viewFullScheduleOfLives</I18n>
                      </Text>
                    </TouchableWithoutFeedback>
                  </View>
                </View>
              ) : tab === 1 ? (
                workouts.length > 0 ? (
                  <View style={styles.workouts}>
                    {workouts.map(workout => (
                      <View key={workout.id} style={styles.workout}>
                        <WorkoutCard id={workout.id} />
                      </View>
                    ))}
                  </View>
                ) : syncing ? (
                  <Loading />
                ) : (
                  <Empty text="emptyOnDemand" />
                )
              ) : null}
            </>
          )}
        </Animated.ScrollView>
      </View>

      {isNative && (
        <View style={styles.footer}>
          <View style={styles.footerDescription}>
            <Text
              style={[styles.footerText, isFavorite && styles.footerTextGray]}
            >
              <I18n
                i18nKey={
                  isFavorite ? 'trainerIsYourFavorite' : 'addTrainerAsFavorite'
                }
                params={{ trainer: name, context: genderContext(trainer) }}
              >
                <Text style={styles.footerBold} />
              </I18n>
            </Text>
          </View>
          <AsyncButton
            theme={isFavorite ? 'white' : 'magenta'}
            style={[styles.submit, isFavorite && styles.disabled]}
            onPress={onPressFavorite}
          >
            {isFavorite ? (
              <HeartIcon color={Theme.color.magenta} />
            ) : (
              <HeartOutlineIcon color={Theme.color.white} />
            )}
          </AsyncButton>
        </View>
      )}
    </>
  );
}

function TrainerSync(props) {
  const { id, trainer } = props;

  const { syncing, error } = useSync(
    useCallback(async () => {
      await DataService.syncTrainer(id);
      await DataService.syncWorkouts({ trainer: id });
    }, [id]),
  );

  if (!trainer) {
    return (
      <>
        <Header />
        {syncing ? <Loading /> : <Empty text={error || 'notFound'} />}
      </>
    );
  }

  return <TrainerScreen {...props} syncing={syncing} />;
}

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    flexShrink: 1,
    backgroundColor: Theme.color.lowContrastGray_USE_WITH_WISDOM,
  },
  contentContainer: {
    paddingBottom: 60,
  },
  background: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: Theme.dimensions.windowHeight / 2,
  },
  identification: {
    alignItems: 'center',
    padding: 20,
  },
  picture: {
    height: 144,
    width: 96,
    borderRadius: 96,
    backgroundColor: Theme.color.lowContrastGray_USE_WITH_WISDOM,
    overflow: 'hidden',
  },
  name: {
    color: Theme.color.veryDarkGray,
    fontFamily: Theme.fonts.barlowBold,
    fontSize: 26,
    marginTop: 20,
  },
  instagram: {
    color: Theme.color.darkGray,
    fontFamily: Theme.fonts.barlowMedium,
    fontSize: 15,
    marginTop: 3,
  },
  institution: {
    color: Theme.color.darkGray,
    fontFamily: Theme.fonts.barlowMedium,
    fontSize: 15,
    marginTop: 15,
  },
  description: {
    color: Theme.color.veryDarkGray,
    fontFamily: Theme.fonts.barlow,
    fontSize: 16,
    lineHeight: 22,
    marginTop: 40,
  },
  donation: {
    paddingHorizontal: 20,
    marginTop: 20,
  },
  images: {
    flexDirection: 'row',
    paddingLeft: 20,
    paddingRight: 10,
    marginTop: 40,
    marginBottom: 20,
  },
  image: {
    backgroundColor: Theme.color.veryLightGray,
    width: IMAGE_WIDTH,
    height: IMAGE_WIDTH * (230 / 170),
    borderRadius: 6,
    marginRight: 15,
  },
  menu: {
    marginTop: 40,
  },
  tabLessons: {
    paddingTop: 20,
  },
  calendar: {
    borderColor: Theme.color.veryLightGray,
    borderTopWidth: 1,
    marginHorizontal: 20,
  },
  calendarText: {
    color: Theme.color.magenta,
    fontFamily: Theme.fonts.barlowBold,
    fontSize: 12,
    lineHeight: 12,
    textTransform: 'uppercase',
    letterSpacing: 0.7,
    paddingVertical: 20,
  },
  workouts: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    paddingVertical: 20,
    paddingHorizontal: 15,
  },
  workout: {
    margin: 5,
  },
  footer: {
    height: 80 + Theme.dimensions.bottomSpace,
    flexDirection: 'row',
    borderColor: Theme.color.veryLightGray,
    borderTopWidth: 1,
  },
  footerDescription: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexGrow: 1,
    flexShrink: 1,
    paddingHorizontal: 20,
    paddingBottom: Theme.dimensions.bottomSpace,
  },
  footerText: {
    color: Theme.color.veryDarkGray,
    fontFamily: Theme.fonts.barlow,
    fontSize: 12,
  },
  footerTextGray: {
    color: Theme.color.darkGray,
  },
  footerBold: {
    fontFamily: Theme.fonts.barlowBold,
  },
  submit: {
    width: 80,
    paddingBottom: Theme.dimensions.bottomSpace,
    borderColor: Theme.color.veryLightGray,
    borderLeftWidth: 1,
  },
  disabled: {
    backgroundColor: null,
  },
});

const enhance2 = withObservables(['id'], ({ id }) => ({
  trainer: DataService.observeTrainer(id),
  workouts: DataService.observeWorkoutsByTrainer(id),
}));

const enhance = withObservables([], () => ({
  city: DataService.observeVirtualCityId(),
  favoriteTrainers: DataService.observeFavoriteTrainers(),
}));

export default withNavParams(enhance(enhance2(TrainerSync)));
