import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { connect } from 'react-redux';
import isEqual from 'react-fast-compare';
import moment from 'moment-timezone';
import Theme from '../libs/Theme';
import Api from '../libs/Api';
import AlertService from '../libs/AlertService';
import DataService from '../libs/DataService';
import { resolveError } from '../libs/FormUtils';
import NavigationService from '../libs/NavigationService';
import {
  anamnesisRequiresCertificate,
  medicalCertificateShowned,
} from '../libs/Rules';
import I18n from './I18n';
import TextLink from './TextLink';
import LoadingView from './LoadingView';
import ActionButton from './ActionButton';
import CheckboxInput from './CheckboxInput';

const COVID_IDS = [32, 33, 34, 35];

export const COVID_FORM_KEY = 'Anamnesis_covid';

class Anamnesis extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      error: null,
      anamnesis: this.props.anamnesis || [],
      terms: false,
    };
  }

  async componentDidMount() {
    try {
      await Api.getAnamnesis();
    } catch (error) {
      this.setState({ error: error.message });
    }
    this.setState({ loading: false });
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.anamnesis, this.props.anamnesis)) {
      this.setState({ anamnesis: this.props.anamnesis });
    }
  }

  componentWillUnmount() {
    clearTimeout(this.backTimeout);
  }

  onCheckAll = (_, data) => {
    const anamnesis = this.state.anamnesis.map(q => ({
      ...q,
      answer: q.options[data].id,
    }));
    this.setState({ anamnesis });
  };

  onCheckOption = (_, data) => {
    const { question, option } = data;
    const anamnesis = this.state.anamnesis.map(q => {
      if (q.id === question) {
        return { ...q, answer: option };
      }
      return q;
    });
    this.setState({ anamnesis });
  };

  onPressTerms = () => {
    AlertService.show({
      title: 'anamnesisTermsTitle',
      message: 'anamnesisTermsMessage',
    });
  };

  onCheckTerms = value => {
    this.setState({ terms: value });
  };

  onSubmit = async () => {
    const { onSuccess, isCovid } = this.props;
    const { anamnesis, terms } = this.state;

    if (anamnesis.filter(q => !q.answer).length > 0) {
      AlertService.show({
        title: 'unfilledAnamnesisTitle',
        message: 'unfilledAnamnesisDescription',
      });
      return false;
    }

    if (!terms) {
      AlertService.show({
        title: isCovid
          ? 'uncheckedTermsCovidTitle'
          : 'uncheckedTermsAnamnesisTitle',
        message: isCovid
          ? 'uncheckedTermsCovidDescription'
          : 'uncheckedTermsAnamnesisDescription',
      });
      return false;
    }

    try {
      const data = anamnesis.map(q => ({ question: q.id, option: q.answer }));
      await Api.updateAnamnesis(data);
      await Api.getAnamnesis(); // atualiza anamnesis no redux
      await Api.getMe(); // atualiza user.anamnesis_status antes de ir embora
      if (isCovid) {
        DataService.setLocal(COVID_FORM_KEY, moment().format());
      }
    } catch (error) {
      resolveError(error, true);
      return false;
    }

    // atenção, só verifica o user após o Api.getMe() para ter certeza q o user atualizou
    if (
      !isCovid &&
      anamnesisRequiresCertificate(this.props.user) &&
      !medicalCertificateShowned(this.props.user)
    ) {
      AlertService.show({
        title: 'done',
        children: (
          <Text style={styles.modalText}>
            <I18n i18nKey="modalMedicalCertificateContent">
              <Text style={styles.modalTextBold} />
            </I18n>
          </Text>
        ),
      });
    }

    this.backTimeout = setTimeout(() => {
      if (onSuccess) {
        onSuccess();
      } else {
        NavigationService.goBack();
      }
    }, 1500);
  };

  render() {
    const { submitLabel, isCovid } = this.props;
    const { loading, error, anamnesis } = this.state;

    return (
      <LoadingView
        hasData={anamnesis.length}
        loading={loading}
        empty={error || 'anamnesisNotAvailable'}
      >
        {!isCovid && (
          <Text style={styles.title}>
            <I18n>anamnesisIntro</I18n>
          </Text>
        )}
        <Text style={styles.description}>
          {isCovid ? (
            <I18n i18nKey="covidDescription">
              <TextLink
                highlight
                url="https://www.mude.fit/wp-content/uploads/2020/07/protocolo-de-seguranca-covid-19.pdf"
              />
            </I18n>
          ) : (
            <I18n>anamnesisDescription</I18n>
          )}
        </Text>
        <Text style={styles.instructions}>
          <I18n>anamnesisInstructions</I18n>
        </Text>
        {!isCovid && (
          <View style={styles.shortcut}>
            <Text style={styles.shortcutText}>
              <I18n>answerAll</I18n>
            </Text>
            {anamnesis[0]?.options?.map((option, index) => (
              <View key={option.id} style={styles.option}>
                <CheckboxInput
                  name={index}
                  value={
                    anamnesis.filter(q => q.answer === q.options[index].id)
                      .length === anamnesis.length
                  }
                  onChange={this.onCheckAll}
                  before={<Text style={styles.optionText}>{option.value}</Text>}
                />
              </View>
            ))}
          </View>
        )}
        {anamnesis.map((question, i) => (
          <View key={question.id} style={styles.question}>
            <Text style={styles.questionText}>
              <Text style={styles.questionNumber}>{i + 1}.</Text>{' '}
              {question.title}
            </Text>
            {question.options.map(option => (
              <View key={option.id} style={styles.option}>
                <CheckboxInput
                  name={{ question: question.id, option: option.id }}
                  value={question.answer === option.id}
                  onChange={this.onCheckOption}
                  before={<Text style={styles.optionText}>{option.value}</Text>}
                />
              </View>
            ))}
          </View>
        ))}
        <View style={styles.footer}>
          <View style={styles.terms}>
            <CheckboxInput
              value={this.state.terms}
              label={
                isCovid ? (
                  <I18n i18nKey="agreeWithCovidTerms">
                    <TextLink url="https://www.mude.fit/wp-content/uploads/2020/07/protocolo-de-seguranca-covid-19.pdf" />
                  </I18n>
                ) : (
                  <I18n i18nKey="agreeWithAnamnesisTerms">
                    <TextLink onPress={this.onPressTerms} />
                  </I18n>
                )
              }
              onChange={this.onCheckTerms}
            />
          </View>
          <ActionButton
            title={submitLabel || 'send'}
            theme="magenta"
            size="tall"
            onPress={this.onSubmit}
            style={styles.infoButton}
            useFeedbackIcon
          />
        </View>
      </LoadingView>
    );
  }
}

const styles = StyleSheet.create({
  title: {
    color: Theme.color.veryDarkGray,
    fontFamily: Theme.fonts.barlowBold,
    fontSize: 16,
    lineHeight: 22,
    marginHorizontal: 30,
    marginTop: 30,
  },
  description: {
    color: Theme.color.veryDarkGray,
    fontFamily: Theme.fonts.barlow,
    fontSize: 13,
    lineHeight: 18,
    marginHorizontal: 30,
    marginTop: 25,
  },
  instructions: {
    color: Theme.color.veryDarkGray,
    fontFamily: Theme.fonts.barlowMedium,
    fontSize: 13,
    lineHeight: 18,
    marginHorizontal: 30,
    marginTop: 25,
  },
  shortcut: {
    backgroundColor: Theme.color.lowContrastGray_USE_WITH_WISDOM,
    borderColor: Theme.color.veryLightGray,
    borderTopWidth: 1,
    borderBottomWidth: 1,
    marginHorizontal: 20,
    marginTop: 25,
    marginBottom: 10,
    paddingVertical: 20,
    paddingHorizontal: 10,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  shortcutText: {
    color: Theme.color.gray,
    fontFamily: Theme.fonts.barlowBold,
    fontSize: 11,
    lineHeight: 11,
    textTransform: 'uppercase',
    flex: 1,
  },
  option: {
    marginLeft: 12,
  },
  optionText: {
    color: Theme.color.veryDarkGray,
    fontFamily: Theme.fonts.neuePlakCompLight,
    fontSize: 18,
    lineHeight: 18,
    letterSpacing: 1,
    textTransform: 'uppercase',
    top: 1,
    marginRight: 2,
  },
  question: {
    marginVertical: 20,
    marginHorizontal: 30,
    flexDirection: 'row',
    alignItems: 'center',
  },
  questionText: {
    color: Theme.color.veryDarkGray,
    fontFamily: Theme.fonts.barlowSemiCondensed,
    flexGrow: 1,
    flexShrink: 1,
    marginRight: 10,
  },
  questionNumber: {
    fontFamily: Theme.fonts.barlowSemiCondensedBold,
  },
  footer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: 30,
    paddingBottom: 20,
  },
  terms: {
    flexShrink: 1,
    marginRight: 10,
  },
  modalText: {
    color: Theme.color.darkGray,
    fontFamily: Theme.fonts.barlow,
    fontSize: 16,
  },
  modalTextBold: {
    fontFamily: Theme.fonts.barlowBold,
  },
});

const mapStateToProps = ({ anamnesis, user, settings }, { isCovid }) => {
  return {
    anamnesis: isCovid
      ? anamnesis.filter(i => COVID_IDS.includes(i.id))
      : anamnesis.filter(i => !COVID_IDS.includes(i.id)),
    user,
    settings,
  };
};

export default connect(mapStateToProps)(Anamnesis);
