import React from 'react';
import styles from './LayoutWithHeader.module.scss';
import HtmlHeader from './HtmlHeader';
import Loading from './Loading';
import Error from './Error';

export default function LayoutWithHeader({ loading, error, children }) {
  return (
    <div className={styles.container}>
      <HtmlHeader />
      {loading ? <Loading /> : error ? <Error text={error} /> : children}
    </div>
  );
}
