import React from 'react';
import { Switch, StyleSheet, Text, View } from 'react-native';
import Theme from '../libs/Theme';
import I18n from '../components/I18n';

export default class SwitchInput extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      optimisticValue: null,
    };
  }

  componentWillUnmount() {
    this.unmounted = true;
  }

  onChange = async value => {
    if (!this.props.onChange) {
      return;
    }

    this.setState({ optimisticValue: value });

    try {
      await this.props.onChange(value, this.props.name);
    } finally {
      if (this.unmounted) {
        return;
      }
      this.setState({ optimisticValue: null });
    }
  };

  renderLabel = () => {
    const { label, required, error } = this.props;
    return (
      <Text style={[styles.label, error ? styles.labelError : null]}>
        <I18n>{label}</I18n>
        {required ? '*' : ''}
      </Text>
    );
  };

  renderSwitch = () => {
    const { value } = this.props;
    const { optimisticValue } = this.state;
    return (
      <Switch
        value={optimisticValue === null ? value : optimisticValue}
        onValueChange={this.onChange}
        style={styles.switch}
        trackColor={{
          false: Theme.color.veryLightGray,
          true: Theme.color.lightGreen,
        }}
      />
    );
  };

  renderCaption = () => {
    const { caption } = this.props;
    return (
      <Text style={styles.footerText}>
        <I18n>{caption}</I18n>
      </Text>
    );
  };

  render() {
    const { label, caption, style } = this.props;
    return (
      <View style={[styles.field, style]}>
        {label && this.renderLabel()}
        {this.renderSwitch()}
        {caption && this.renderCaption()}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  field: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  label: {
    color: Theme.color.darkGray,
    fontFamily: Theme.fonts.barlow,
    fontSize: 12,
    lineHeight: 24,
    textTransform: 'uppercase',
  },
  switch: {
    transform: [{ scaleX: 0.8 }, { scaleY: 0.8 }],
    marginLeft: 10,
  },
  footerText: {
    color: Theme.color.gray,
    fontFamily: Theme.fonts.barlow,
    fontSize: 13,
    marginLeft: 6,
  },
  labelError: {
    color: Theme.color.magenta,
  },
  disabled: {
    color: Theme.color.gray,
  },
});
