import { Model, Relation } from '@nozbe/watermelondb';
import { field, json, relation } from '@nozbe/watermelondb/decorators';
import { Associations } from '@nozbe/watermelondb/Model';
import Modality from './Modality';
import Product from './Product';
import Trainer from './Trainer';

export default class Workout extends Model {
  static table = 'workouts';

  static associations: Associations = {
    modalities: { type: 'belongs_to', key: 'modality' },
    trainers: { type: 'belongs_to', key: 'trainer' },
    products: { type: 'belongs_to', key: 'product' },
  };

  @relation('modalities', 'modality') modality_obj!: Relation<Modality>;
  @relation('trainers', 'trainer') trainer_obj!: Relation<Trainer>;
  @relation('products', 'product') product_obj!: Relation<Product>;

  @json('parts', _json => _json || []) parts!: WorkoutPart[];
  @json('modalities', _json => _json || []) modalities!: string[];
  @json('equipments', _json => _json || []) equipments!: string[];
  @field('name') name!: string;
  @field('image') image!: string;
  @field('description') description!: string;
  @field('description_image') description_image!: string;
  @field('good_for') good_for!: string;
  @field('duration') duration!: number;
  @field('level') level!: number;
  @field('cost_mode') cost_mode!: string;
  @field('is_recorded') is_recorded!: boolean;
  @field('start_audio') start_audio!: string;
  @field('end_audio') end_audio!: string;
  @field('modality') modality!: string;
  @field('trainer') trainer!: string;
  @field('product') product!: string;
  @field('sponsor') sponsor!: string;
  @field('show_in_history') show_in_history!: boolean;
}

export type WorkoutPart = {
  id: number;
  name: string;
  order: number;
  items: WorkoutPartItem[];
};

export type WorkoutPartItem = {
  id: number;
  exercise_id: number;
  text: string;
  details: string | null;
  order: number;
  duration: number;
  repetitions: number | null;
  audio: string | null;
  rest_duration: number | null;
  series: number;
  audio_timeline: {
    time: number;
    audio: string;
    length: number;
  }[];
  has_load: boolean;
  type: string;
  prepare_duration: number;
};
