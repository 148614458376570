import React, { useCallback } from 'react';
import { StyleSheet, Text, TouchableWithoutFeedback, View } from 'react-native';
import FastImage from '../packages/react-native-fast-image';
import LinearGradient from 'react-native-web-linear-gradient';
import Event from '../database/model/Event';
import Sponsor from '../database/model/Sponsor';
import PinIcon from '../icons/PinIcon';
import DataService, { withObservables } from '../libs/DataService';
import {
  ACADEMIA_UNIMED_EVENT,
  ARENA_UNIMED_EVENT,
  MUDE_1_HABITO_RIO_EVENT,
} from '../libs/Env';
import NavigationService from '../libs/NavigationService';
import Theme, { opacity } from '../libs/Theme';

type Props = {
  event?: Event;
  sponsor?: Sponsor;
};

const UnimedCard = ({ event, sponsor }: Props) => {
  const eventId = event?.id;
  const onPress = useCallback(() => {
    NavigationService.navigate('Event', { id: eventId });
  }, [eventId]);

  if (!event) {
    return null;
  }

  return (
    <TouchableWithoutFeedback onPress={onPress}>
      <View style={styles.container}>
        <View style={styles.image}>
          <FastImage
            style={StyleSheet.absoluteFill}
            source={{ uri: event.hero_image }}
          />
          <LinearGradient
            style={StyleSheet.absoluteFill}
            locations={[0, 0.15, 0.5, 1]}
            colors={[
              opacity('black', event.period_description ? 0.25 : 0),
              opacity('black', 0),
              opacity('black', 0),
              opacity('black', 0.85),
            ]}
          />
          <View style={styles.content}>
            <View style={styles.spacer} />
            <Text style={styles.name}>{event.name}</Text>
            <Text style={styles.details}>{event.description}</Text>
          </View>
        </View>
        <View
          style={[
            styles.footer,
            { backgroundColor: sponsor?.main_color || Theme.color.magenta },
          ]}
        >
          <PinIcon height={16} width={16} />
          <Text style={styles.place}>
            {
              {
                [ARENA_UNIMED_EVENT]: 'Florianópolis',
                [ACADEMIA_UNIMED_EVENT]: 'São Paulo',
                [MUDE_1_HABITO_RIO_EVENT]: 'Rio de Janeiro',
              }[event.id]
            }
          </Text>
        </View>
      </View>
    </TouchableWithoutFeedback>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: Theme.color.white,
    overflow: 'hidden',
    borderRadius: 5,
  },
  image: {
    backgroundColor: Theme.color.veryLightGray,
  },
  content: {
    height: 250,
    padding: 20,
  },
  date: {
    color: Theme.color.white,
    fontFamily: Theme.fonts.barlowCondensedBold,
    fontSize: 16,
    opacity: 0.8,
    textTransform: 'uppercase',
    marginLeft: 5,
  },
  spacer: {
    flex: 1,
  },
  name: {
    color: Theme.color.white,
    fontFamily: Theme.fonts.barlowBold,
    fontSize: 33,
    marginLeft: -2,
    marginTop: 10,
  },
  details: {
    color: Theme.color.white,
    fontFamily: Theme.fonts.barlowBold,
    fontSize: 13,
    marginTop: 8,
  },
  footer: {
    flexDirection: 'row',
    alignItems: 'center',
    height: 50,
    paddingHorizontal: 20,
  },
  place: {
    color: Theme.color.white,
    fontFamily: Theme.fonts.barlow,
    fontSize: 16,
    marginLeft: 5,
    marginBottom: 2,
  },
});

const enhance2: any = withObservables(
  ['event'],
  ({ event }: { event?: Event }) => ({
    sponsor: DataService.observeSponsor(event?.sponsor),
  }),
);

const enhance: any = withObservables(['id'], ({ id }: { id: string }) => ({
  event: DataService.observeEvent(id),
}));

export default enhance(enhance2(UnimedCard));
