import StoreService from '../libs/StoreService';

const SET_DEFAULT_FLAGS = 'FEATURE_FLAG/SET_DEFAULT_FLAGS';
const SET_USER_FLAGS = 'FEATURE_FLAG/SET_USER_FLAGS';
const DELETE_FLAGS = 'FEATURE_FLAG/DELETE_FLAGS';

export function setDefaultFlags(flags) {
  StoreService.dispatch({ type: SET_DEFAULT_FLAGS, flags });
}

export function setUserFlags(flags) {
  StoreService.dispatch({ type: SET_USER_FLAGS, flags });
}

export function deleteFlags() {
  StoreService.dispatch({ type: DELETE_FLAGS });
}

export default function reducer(state = {}, action) {
  switch (action.type) {
    case SET_DEFAULT_FLAGS: {
      // sobreescreve o valor padrão pela preferência do usuário
      const user_flags = {};
      Object.keys(action.flags).forEach(key => {
        // valida se o user definiu um valor previamente, assim evita apagar a flag com { [key]: undefined }
        const userValue = (state.user_flags || {})[key];
        if (typeof userValue !== 'undefined') {
          user_flags[key] = userValue;
        }
      });

      return {
        ...action.flags,
        ...user_flags,
        user_flags,
      };
    }

    case SET_USER_FLAGS: {
      return {
        ...state,
        ...action.flags,
        user_flags: {
          ...state.user_flags,
          ...action.flags,
        },
      };
    }

    case DELETE_FLAGS: {
      return {};
    }

    default:
      return state;
  }
}
