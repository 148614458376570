import React, { useCallback } from 'react';
import DataService, {
  withObservables,
  withMapProps,
} from '../libs/DataService';
import NavigationService from '../libs/NavigationService';
import ActivityEntryView from './ActivityEntryView';

function WorkoutActivityEntry({ id, workout, trainer, modality }) {
  const onPress = useCallback(() => {
    NavigationService.navigate('Workout', { id });
  }, [id]);

  if (!workout) {
    return null;
  }

  return (
    <ActivityEntryView
      image={workout.image}
      name={workout.name}
      trainer={trainer}
      modality={modality}
      duration={workout.duration * 60}
      onPress={onPress}
    />
  );
}

const enhance3 = withObservables(
  ['trainer', 'modality'],
  ({ trainer, modality }) => ({
    trainer: DataService.observeTrainer(trainer),
    modality: DataService.observeModality(modality),
  }),
);

const enhance2 = withMapProps(({ workout }) => ({
  trainer: workout?.trainer,
  modality: workout?.modalities?.[0],
}));

const enhance = withObservables(['id'], ({ id }) => ({
  workout: DataService.observeWorkout(id),
}));

export default enhance(enhance2(enhance3(WorkoutActivityEntry)));
