import { Model, Q, Query, Relation } from '@nozbe/watermelondb';
import {
  children,
  field,
  json,
  lazy,
  relation,
} from '@nozbe/watermelondb/decorators';
import { Associations } from '@nozbe/watermelondb/Model';
import Customer from './Customer';
import Enrollment from './Enrollment';
import LessonGroup from './LessonGroup';
import Modality from './Modality';
import Place from './Place';
import Sponsor from './Sponsor';
import Trainer from './Trainer';

export default class Lesson extends Model {
  static table = 'lessons';

  static associations: Associations = {
    enrollments: { type: 'has_many', foreignKey: 'lesson' },
    places: { type: 'belongs_to', key: 'place' },
    trainers: { type: 'belongs_to', key: 'trainer' },
    modalities: { type: 'belongs_to', key: 'modality' },
    lesson_groups: { type: 'belongs_to', key: 'lesson_group' },
    sponsors: { type: 'belongs_to', key: 'sponsor' },
  };

  @children('enrollments') enrollments!: Query<Enrollment>;

  @lazy enrollmentsRegistered = this.collections
    .get<Enrollment>('enrollments')
    .query(Q.where('registered', true), Q.where('lesson', this.id));

  @relation('places', 'place') place_obj!: Relation<Place>;
  @relation('trainers', 'trainer') trainer_obj!: Relation<Trainer>;
  @relation('modalities', 'modality') modality_obj!: Relation<Modality>;
  @relation('lesson_groups', 'lesson_group')
  lesson_group_obj!: Relation<LessonGroup>;
  @relation('sponsors', 'sponsor') sponsor_obj!: Relation<Sponsor>;

  @lazy customers = this.collections
    .get<Customer>('customers')
    .query(Q.on('enrollments', 'lesson', this.id));

  @json('events', _json => _json || []) events!: string[];
  @json('modalities', _json => _json || []) modalities!: string[];
  @field('name') name!: string;
  @field('start_datetime') start_datetime!: string;
  @field('end_datetime') end_datetime!: string;
  @field('total_vacancies') total_vacancies!: number;
  @field('available_vacancies') available_vacancies!: number;
  @field('public_visibility') public_visibility!: boolean;
  @field('public_subscription') public_subscription!: boolean;
  @field('highlight_image') highlight_image!: string;
  @field('status') status!: string;
  @field('cancellation_reason') cancellation_reason!: string;
  @field('cancellation_visibility') cancellation_visibility!: boolean;
  @field('requires_anamnesis') requires_anamnesis!: boolean;
  @field('description') description!: string;
  @field('city_hall_approved') city_hall_approved!: boolean;
  @field('allow_children') allow_children!: boolean;
  @field('subscription_time_limit') subscription_time_limit!: number;
  @field('created_by') created_by!: string;
  @field('trainer') trainer!: string;
  @field('substitute_trainer') substitute_trainer!: string;
  @field('sponsor') sponsor!: string;
  @field('place') place!: string;
  @field('modality') modality!: string;
  @field('lesson_group') lesson_group!: string;
  @field('cost_mode') cost_mode!: string;
  @field('is_virtual') is_virtual!: boolean;
  @field('live_id') live_id!: string;
  @field('stream_url') stream_url!: string;
  @field('has_chat') has_chat!: boolean;
  @field('has_reserved_spots') has_reserved_spots!: boolean;
  @json('spots', _json => _json || []) spots!: string[];
  @json('available_spots', _json => _json || []) available_spots!: string[];
}
