import React, { useState, useEffect, useRef } from 'react';
import { Animated } from 'react-native';

export default function TransitionView(props) {
  const {
    value = 1,
    attribute = 'opacity',
    duration = 250,
    delay,
    easing,
  } = props;

  const firstRender = useRef(true);
  const [animatedValue] = useState(new Animated.Value(value));

  useEffect(() => {
    // no primeiro effect (render/componentDidMount) não precisamos rodar a animação
    // pois ela já começa com seu valor inicial, assim otimiza processamento
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }

    const animation = Animated.timing(animatedValue, {
      toValue: value,
      duration,
      delay,
      easing,
      useNativeDriver: true,
    });
    animation.start();
    return () => animation.stop();
  }, [value]); // eslint-disable-line react-hooks/exhaustive-deps

  const animatedStyle =
    attribute === 'opacity'
      ? { opacity: animatedValue }
      : { transform: [{ [attribute]: animatedValue }] };

  return (
    <Animated.View
      {...props}
      style={[props.style, animatedStyle]}
      // força a view dentro do ambiente nativo e, por exemplo, impede que
      // uma view que inicie com { opacity: 1 } deixe de animar um fade out
      // https://facebook.github.io/react-native/docs/view#collapsable
      // https://github.com/facebook/react-native/issues/25318
      // https://github.com/facebook/react-native/pull/25361
      collapsable={false}
    />
  );
}
