import moment from 'moment-timezone';
import StoreService from './StoreService';
import { RECIFE, haversineFormula } from './Utils';

export function hasVacancies(lesson) {
  return lesson && lesson.available_vacancies > 0;
}

export function needAnamnesis(lesson) {
  return lesson && lesson.requires_anamnesis;
}

export function anamnesisEmpty(user) {
  return (
    user &&
    user.anamnesis_status &&
    user.anamnesis_status.details === 'anamnesis not complete'
  );
}

export function anamnesisValid(user) {
  return user && user.anamnesis_status && user.anamnesis_status.valid;
}

export function anamnesisRequiresCertificate(user) {
  return (
    user &&
    user.anamnesis_status &&
    user.anamnesis_status.details === 'medical certificate required'
  );
}

export function anamnesisExpired(user) {
  return (
    user && user.anamnesis_status && user.anamnesis_status.details === 'expired'
  );
}

export function medicalCertificateShowned(user) {
  return user && user.medical_certificate_check;
}

export function subscriptionTime(lesson, user, place) {
  const { cities } = StoreService.getState();

  const city = cities.find(c => String(c.id) === String(place?.city)) || {};

  const lessonTime = lesson?.subscription_time_limit || 0;
  const userTime = user?.subscriptions_time_limit || 0;
  const cityTime = city?.min_time_subscription || 0;

  return Math.max(lessonTime, userTime, cityTime);
}

export function isOpen(lesson, user, place) {
  if (!lesson) {
    return false;
  }

  const now = moment();
  const start = moment(lesson.start_datetime);
  const willStartAt = start.diff(now, 'minutes');

  const limit = subscriptionTime(lesson, user, place);

  return willStartAt < limit;
}

export function isCanceled(lesson) {
  return lesson && lesson.status === 'cancelled';
}

export function isPaid(lesson) {
  return lesson?.cost_mode === 'paid';
}

export function isDonation(lesson) {
  return lesson?.cost_mode === 'donation';
}

export function nearOfLesson(place, position) {
  if (!place || !place.latitude || !position || !position.latitude) {
    return false;
  }

  const distance = haversineFormula(
    place.latitude,
    place.longitude,
    position.latitude,
    position.longitude,
  );

  return distance <= 400;
}

export function isSubscribed(enrollment) {
  return enrollment && enrollment.registered;
}

export function isChecked(enrollment) {
  return enrollment && (enrollment.checked_in || enrollment.trainer_checked);
}

export function exclusive(sponsorGroups, lesson) {
  if (lesson.public_subscription) {
    return false;
  }
  if (!lesson.sponsor) {
    return false;
  }
  if (!sponsorGroups) {
    return true;
  }
  return !sponsorGroups?.filter(g => g.is_member).length;
}

export function willStartSoon(lesson) {
  if (!lesson) {
    return false;
  }

  const now = moment();
  const start = moment(lesson.start_datetime);
  const willStartAt = start.diff(now, 'minutes');

  if (isLive(lesson)) {
    return willStartAt < 5;
  }

  const { MIN_TIME_CHECKIN } = StoreService.getState().settings;
  const minTimeCheckin = MIN_TIME_CHECKIN || 15;

  return willStartAt < minTimeCheckin;
}

export function checkInEnd(lesson) {
  if (!lesson) {
    return false;
  }

  const now = moment();
  const end = moment(lesson.end_datetime);
  const willEndAt = end.diff(now, 'minutes');

  return isLive(lesson) ? willEndAt < 5 : willEndAt < 10;
}

export function lessonEnd(lesson) {
  if (!lesson) {
    return false;
  }

  const now = moment();
  const end = moment(lesson.end_datetime);
  const willEndAt = end.diff(now, 'minutes');

  return willEndAt < 0;
}

export function outOfLimit(user) {
  return user && user.available_subscriptions <= 0;
}

export function reviewed(enrollment) {
  return !!enrollment?.rating;
}

export function enqueued(enrollment) {
  return enrollment && enrollment.queued;
}

export function personalDataComplete(user) {
  if (!user.cpf && !user.passport) {
    return false;
  }
  if (user.settings.default_city === RECIFE) {
    if (!user.birth_date || !user.gender) {
      return false;
    }
  }
  return true;
}

export function emergencyComplete(emergency) {
  return emergency && Object.values(emergency).length > 0;
}

export function deficiencyComplete(user) {
  return user.settings.default_city !== RECIFE || user.comments;
}

export function isLive(lesson) {
  return !!lesson?.is_virtual;
}

export const lessonStatus = {
  CAN_PAY: 'canPay',
  CAN_SUBSCRIBE: 'canSubscribe',
  CAN_LIVE_SUBSCRIBE: 'canLiveSubscribe',
  SUBSCRIBED: 'subscribed',
  LIVE_SUBSCRIBED: 'liveSubscribed',
  CHECKIN: 'checkin',
  CHECKIN_DONE: 'checkinDone',
  WATCH: 'watch',
  TO_REVIEW: 'toReview',
  REVIEW_DONE: 'reviewDone',
  CANCELED: 'canceled',
  LESSON_MISSED: 'lessonMissed',
  EXCLUSIVE: 'exclusive',
  USER_LIMIT: 'userLimit',
  NOT_OPEN_YET: 'notOpenYet',
  ALREADY_HAPPENED: 'alreadyHappened',
  LESSON_FULL: 'lessonFull',
  WARN_ME: 'warnMe',
};

export const getLessonStatus = (
  lesson,
  enrollment,
  user,
  sponsorGroups,
  place,
) => {
  // aula cancelada
  if (isCanceled(lesson)) {
    return lessonStatus.CANCELED;
  }

  // aula já inscrito
  if (isSubscribed(enrollment)) {
    // aula já consumida
    if (reviewed(enrollment)) {
      return lessonStatus.REVIEW_DONE;
    }
    if (checkInEnd(lesson) && isChecked(enrollment)) {
      return lessonStatus.TO_REVIEW;
    }

    // aula ao vivo
    if (isLive(lesson)) {
      if (willStartSoon(lesson)) {
        return lessonStatus.WATCH;
      }
      return lessonStatus.LIVE_SUBSCRIBED;
    }

    // aula presencial
    if (isChecked(enrollment)) {
      return lessonStatus.CHECKIN_DONE;
    }
    if (checkInEnd(lesson)) {
      return lessonStatus.LESSON_MISSED;
    }
    if (willStartSoon(lesson)) {
      return lessonStatus.CHECKIN;
    }
    return lessonStatus.SUBSCRIBED;
  }

  // aula antiga
  if (lessonEnd(lesson)) {
    return lessonStatus.ALREADY_HAPPENED;
  }

  // aula lotada
  if (!hasVacancies(lesson)) {
    if (enqueued(enrollment)) {
      return lessonStatus.WARN_ME;
    }
    return lessonStatus.LESSON_FULL;
  }

  // aula paga
  if (isPaid(lesson)) {
    return lessonStatus.CAN_PAY;
  }

  // aulas trancadas
  if (exclusive(sponsorGroups, lesson)) {
    return lessonStatus.EXCLUSIVE;
  }
  if (!isOpen(lesson, user, place)) {
    return lessonStatus.NOT_OPEN_YET;
  }

  // aula ao vivo
  if (isLive(lesson)) {
    if (willStartSoon(lesson)) {
      return lessonStatus.WATCH;
    }
    return lessonStatus.CAN_LIVE_SUBSCRIBE;
  }

  // aula grátis
  if (outOfLimit(user)) {
    return lessonStatus.USER_LIMIT;
  }
  return lessonStatus.CAN_SUBSCRIBE;
};
