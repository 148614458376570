import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import SelectPicker from 'react-native-picker-select';
import i18n from 'i18next';
import Theme from '../libs/Theme';
import I18n from './I18n';

export default class SelectInput extends React.PureComponent {
  onChange = text => {
    if (this.props.onChange) {
      this.props.onChange(text, this.props.name);
    }
  };

  renderLabel = () => {
    const { label, required, error } = this.props;
    return (
      <Text style={[styles.label, error ? styles.labelError : null]}>
        <I18n>{label}</I18n>
        {required ? '*' : ''}
      </Text>
    );
  };

  renderInput = () => {
    return (
      <View style={styles.pickerContainer}>
        <SelectPicker
          placeholder={this.props.placeholder || {}}
          items={this.props.items ? this.props.items : []}
          value={this.props.value}
          style={{
            inputIOS: { ...styles.picker, ...styles.inputIOS },
            inputAndroid: { ...styles.picker, ...styles.inputAndroid },
          }}
          onValueChange={this.onChange}
          useNativeAndroidPickerStyle={false}
          Icon={() => <View style={styles.selectIcon} />}
          doneText={i18n.t('inputDone')}
        />
      </View>
    );
  };

  renderCaption = () => {
    const { caption } = this.props;
    return (
      <Text style={styles.footerText}>
        <I18n>{caption}</I18n>
      </Text>
    );
  };

  renderError = () => {
    const { error } = this.props;
    return (
      <Text style={[styles.footerText, styles.error]}>
        <I18n>{error}</I18n>
      </Text>
    );
  };

  render() {
    return (
      <>
        {!!this.props.label && this.renderLabel()}
        {this.renderInput()}
        {!!this.props.caption && this.renderCaption()}
        {!!this.props.error && this.renderError()}
      </>
    );
  }
}

const styles = StyleSheet.create({
  label: {
    color: Theme.color.darkGray,
    fontFamily: Theme.fonts.barlow,
    fontSize: 11,
    textTransform: 'uppercase',
  },
  labelError: {
    color: Theme.color.magenta,
  },
  pickerContainer: {
    borderBottomWidth: 2,
    borderColor: Theme.color.veryLightGray,
    paddingRight: 3,
  },
  picker: {
    color: Theme.color.veryDarkGray,
    fontFamily: Theme.fonts.barlow,
    fontSize: 18,
    paddingRight: 20,
  },
  inputIOS: {
    paddingVertical: 8,
  },
  inputAndroid: {
    paddingVertical: 6,
    marginLeft: -4,
  },
  selectIcon: {
    width: 9,
    height: 9,
    borderBottomWidth: 2,
    borderLeftWidth: 2,
    borderColor: Theme.color.black,
    transform: [{ rotate: '-45deg' }],
    marginTop: 15,
  },
  footerText: {
    color: Theme.color.gray,
    fontFamily: Theme.fonts.barlow,
    fontSize: 13,
    marginTop: 6,
  },
  error: {
    color: Theme.color.magenta,
  },
});
