import React from 'react';
import { StyleSheet, Text } from 'react-native';
import moment from 'moment-timezone';
import Theme from '../libs/Theme';

export default function TimeBox({ date, children, theme, size, style }) {
  if (!date && !children) {
    return null;
  }
  return (
    <Text style={[styles.time, styles[theme], styles[size], style]}>
      {children || moment(date).format('HH:mm')}
    </Text>
  );
}

const styles = StyleSheet.create({
  time: {
    color: Theme.color.white,
    fontFamily: Theme.fonts.neuePlakCompLight,
    fontSize: 42,
    lineHeight: 42,
    textTransform: 'uppercase',
    fontVariant: ['tabular-nums'],
    includeFontPadding: false,
    top: 4,
  },
  large: {
    fontSize: 60,
    lineHeight: 60,
    top: 6,
  },
  gray: {
    color: Theme.color.gray,
  },
  magenta: {
    color: Theme.color.magenta,
  },
});
