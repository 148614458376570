import React from 'react';
import { InteractionManager } from 'react-native';
import hoistNonReactStatic from 'hoist-non-react-statics';
import { Q } from '@nozbe/watermelondb';
import _withObservables from '@nozbe/with-observables';
import { of } from 'rxjs';
import { catchError, switchMap, map } from 'rxjs/operators';
import qs from 'qs';
import { sortBy } from 'lodash';
import moment from 'moment-timezone';
import Watermelon from '../database/Watermelon';
import Api from './Api';
import { isResponseTimedOut, isResponseCanceled } from './ApiUtils';
import AuthService from './AuthService';
import { WATERMELON_DB_NAME } from './Env';
import { resolveError } from './FormUtils';
import SentryService from './SentryService';
import StoreService from './StoreService';
import CustomerSettings from '../database/model/CustomerSettings';
import Customer from '../database/model/Customer';
import Trainer from '../database/model/Trainer';
import Request, { RequestId } from '../database/model/Request';
import LanguageService from '../languages/LanguageService';

export const withObservables = _withObservables;

export function withMapProps(mapProps) {
  return Component => {
    const WithMapPropsComponent = props => (
      <Component {...props} {...mapProps(props)} />
    );
    return hoistNonReactStatic(WithMapPropsComponent, Component);
  };
}

/*
 * Esse é o serviço que o app utiliza para comunicar com os dados.
 *
 * Queries
 * - sync: Sincroniza pela api os dados do banco remoto com o banco local. // TODO Se falhar o sync, dispara um erro no formato string já resolvido
 * - find: Retona uma entrada do banco local. Se não achar, faz um sync para retornar o dado. Se nem assim achar, retorna null.
 * - observe: Observa reativamente um dado, útil para usar em conjunto com o withObservables.
 *
 * Mutations
 * - create: Cria uma nova entrada no banco remoto e faz um sync com o banco local. // TODO Se falhar o sync, dispara um erro no formato string já resolvido
 * - update: Atualiza uma entrada existente no banco remoto e faz um sync com o banco local. // TODO Se falhar o sync, dispara um erro no formato string já resolvido
 * - clear: Apaga uma ou várias entradas apenas no banco local. // TODO Se falhar o sync, dispara um erro no formato string já resolvido
 */

class DataService {
  constructor() {
    Watermelon.setup(WATERMELON_DB_NAME);

    this.queue = [];
    this.log = [];
    this.isSyncing = false;

    this.lastSync = 0;
  }

  ///// Cities /////

  syncCities = () => _array('cities', () => Api.getCities());

  syncCity = id => _single('cities', () => Api.getCity(id));

  findCity = id =>
    _strongQuery(
      () => Watermelon.getCollection('cities').find(String(id)),
      () => this.syncCity(id),
    );

  observeCities = () => Watermelon.getCollection('cities').query().observe();

  observeCity = id => _findAndObserve('cities', id);

  observeVirtualCityId = () =>
    Watermelon.getCollection('cities')
      .query(Q.where('is_virtual', true))
      .observeWithColumns(['is_virtual'])
      .pipe(map(v => v[0]?.id || null));

  ///// Content Search /////

  syncContentSearch = async params => {
    const apiParams = { ...params };

    if (apiParams.modality === 'others') {
      apiParams['modality.searchable'] = false;
      delete apiParams.modality;
    }

    if (apiParams.date) {
      const today = moment().format('YYYY-MM-DD');
      const tomorrow = moment().add(1, 'day').format('YYYY-MM-DD');
      const week = moment().endOf('week').format('YYYY-MM-DD');
      const month = moment().endOf('month').format('YYYY-MM-DD');
      const weekend = moment().endOf('week').add(1, 'day').format('YYYY-MM-DD');
      switch (apiParams.date) {
        case 'today':
          apiParams.start_date = today;
          apiParams.end_date = today;
          break;
        case 'tomorrow':
          apiParams.start_date = tomorrow;
          apiParams.end_date = tomorrow;
          break;
        case 'thisWeek':
          apiParams.start_date = today;
          apiParams.end_date = week;
          break;
        case 'thisMonth':
          apiParams.start_date = today;
          apiParams.end_date = month;
          break;
        case 'thisWeekend':
          apiParams.start_date = week;
          apiParams.end_date = weekend;
          break;
        default:
          apiParams.start_date = apiParams.date;
          apiParams.end_date = apiParams.date;
      }
      delete apiParams.date;
    }

    const response = await Api.getContentSearch(apiParams);
    if (Array.isArray(response)) {
      await Watermelon.sync(
        'lessons',
        response.filter(item => item.type === 'lesson'),
      );
      await Watermelon.sync(
        'workouts',
        response.filter(item => item.type === 'workout'),
      );
      await Watermelon.sync('requests', {
        id: _url('/content_search', params),
        response,
      });
    } else {
      Api.logMalformattedResponse(response);
    }
    return response;
  };

  observeContentSearch = params =>
    _findAndObserve('requests', _url('/content_search', params)).pipe(
      map(v => v?.response),
    );

  ///// Credit Card /////

  syncCreditCards = async () => {
    const response = await Api.getCreditCard();
    if (Array.isArray(response)) {
      return await Watermelon.sync(Request.table, {
        id: '/creditcard',
        response,
      });
    } else {
      _throwFeedback(response);
    }
  };

  observeCreditCards = () =>
    _findAndObserve(Request.table, '/creditcard').pipe(
      map(v => v?.response || []),
    );

  addCreditCard = async creditCard => {
    const response = await Api.postCreditCard(creditCard);
    await this.syncCreditCards();
    return response;
  };

  deleteCreditCard = async id => {
    await Api.deleteCreditCard(id);
    return this.syncCreditCards();
  };

  clearCreditCards = () =>
    Watermelon.sync(Request.table, {
      id: '/creditcard',
      response: [],
    });

  ///// Customer /////

  findCustomer = id =>
    _strongFind(
      () => Watermelon.getCollection(Customer.table).find(String(id)),
      () => this.syncMe(),
    );

  updateCustomerMe = async data => {
    const response = await Api.updateCustomerMe(data);
    if (response?.settings?.id) {
      response.settings = response.settings.id;
    }
    if (response?.id) {
      Watermelon.sync(Customer.table, response); // sincroniza em paralelo
    } else {
      Api.logMalformattedResponse(response);
    }
    return response;
  };

  observeCustomer = id => _findAndObserve(Customer.table, id);

  clearCustomerMe = () => {
    Watermelon.clearCollection(Customer.table);
  };

  ///// Customer Legal Response /////

  createCustomerLegalResponse = async (document, acceptance, response) => {
    // const meta_info = await getMetaInfo();
    const legalResponse = await Api.postCustomerLegalResponse({
      document,
      acceptance,
      response,
      // meta_info,
    });
    if (!legalResponse?.document) {
      Api.logMalformattedResponse(response);
    }
    return legalResponse;
  };

  ///// Customer Settings /////

  findCustomerSettings = id =>
    _strongFind(
      () => Watermelon.getCollection(CustomerSettings.table).find(String(id)),
      () => this.syncMe(),
    );

  updateCustomerSettings = async data => {
    const response = await Api.updateCustomerSettings(data);
    if (response?.id) {
      Watermelon.sync(CustomerSettings.table, response); // sincroniza em paralelo
    } else {
      Api.logMalformattedResponse(response);
    }
    return response;
  };

  observeCustomerSettings = id => _findAndObserve(CustomerSettings.table, id);

  clearCustomerSettings = () => {
    Watermelon.clearCollection(CustomerSettings.table);
  };

  ///// DiyHome /////

  syncDiyHome = async () => {
    const response = await Api.getDiyHome();
    if (Array.isArray(response?.content)) {
      return await Watermelon.sync('requests', { id: '/diy_home', response });
    } else {
      Api.logMalformattedResponse(response);
    }
  };

  observeDiyHome = () =>
    _findAndObserve('requests', '/diy_home').pipe(map(v => v?.response));

  ///// Enrollments /////

  syncEnrollments = () => _array('enrollments', () => Api.getEnrollments());

  findEnrollmentByLessonAndUser = (lessonId, customerId) =>
    _strongQuery(
      () =>
        Watermelon.getCollection('enrollments')
          .query(
            Q.where('lesson', String(lessonId)),
            Q.where('customer', String(customerId)),
          )
          .fetch()
          .then(result => result[0]),
      () => this.syncEnrollments(),
    );

  findEnrollmentsByUser = customerId =>
    _strongQuery(
      () =>
        Watermelon.getCollection('enrollments')
          .query(Q.where('customer', String(customerId)))
          .fetch(),
      () => this.syncEnrollments(),
    );

  updateEnrollment = async data => {
    const response = await Api.updateEnrollment(data.id, data);
    // essa resposta não vem com `id` 🤷 então usamos o `lesson` para validar
    if (response?.lesson) {
      Watermelon.sync('enrollments', { id: data.id, ...response }); // sincroniza em paralelo
    } else {
      Api.logMalformattedResponse(response);
    }
    return response;
  };

  observeRegisteredEnrollments = () =>
    Watermelon.getCollection('enrollments')
      .query(Q.where('registered', true))
      .observeWithColumns(['registered']);

  observeVisibleAndRegisteredEnrollments = () =>
    Watermelon.getCollection('enrollments')
      .query(Q.where('show_in_history', true), Q.where('registered', true))
      .observeWithColumns(['show_in_history', 'registered']);

  observeEnrollment = id => _findAndObserve('enrollments', id);

  observeEnrollmentByLessonId = lessonId =>
    Watermelon.getCollection('enrollments')
      .query(Q.where('lesson', String(lessonId)))
      .observeWithColumns([
        'lesson',
        'queued',
        'registered',
        'checked_in',
        'rating',
      ])
      .pipe(map(v => v[0] || null));

  clearEnrollments = () => Watermelon.clearCollection('enrollments');

  ///// Events /////

  __formatEvent = event => {
    if (event?.main_sponsor?.id) {
      event.sponsor = event.main_sponsor.id;
    }
    return event;
  };

  syncEvents = params =>
    _list('events', _url('/event', params), () =>
      Api.getEvents(params).then(response =>
        response?.map?.(this.__formatEvent),
      ),
    );

  syncEvent = id =>
    _single('events', async () => {
      const event = await Api.getEvent(id);
      return this.__formatEvent(event);
    });

  observeEvents = params =>
    _findAndObserve('requests', _url('/event', params)).pipe(
      map(v => v?.response || []),
    );

  observeEventsByIds = idList => _queryByIdListAndObserve('events', idList);

  observeEvent = id => _findAndObserve('events', id);

  ///// Exercises /////

  syncExercises = () =>
    _pagedArray('exercises', Api.getExercises, { limit: 50 });

  syncExercise = id => _single('exercises', () => Api.getExercise(id));

  observeExercises = () =>
    Watermelon.getCollection('exercises').query().observe();

  observeExercisesByIds = idList =>
    _queryByIdListAndObserve('exercises', idList);

  observeExercise = id => _findAndObserve('exercises', id);

  ///// ExerciseModalities /////

  syncExerciseModalities = () =>
    _pagedArray('exercise_modalities', Api.getExerciseModalities, {
      limit: 1000,
    });

  observeExercisesModalities = () =>
    Watermelon.getCollection('exercise_modalities').query().observe();

  ///// ExerciseRequirements /////

  syncExerciseRequirements = () =>
    _pagedArray('exercise_requirements', Api.getExerciseRequirements, {
      limit: 1000,
    });

  observeExerciseRequirementsByIds = idList =>
    _queryByIdListAndObserve('exercise_requirements', idList);

  ///// FavoriteTrainers /////

  syncFavoriteTrainers = async () => {
    const response = await Api.getFavoriteTrainers();
    if (Array.isArray(response)) {
      return await Watermelon.sync('requests', {
        id: '/favorite_trainer',
        response: response.map(id => String(id)),
      });
    } else {
      _throwFeedback(response);
    }
  };

  observeFavoriteTrainers = () =>
    _findAndObserve('requests', '/favorite_trainer').pipe(
      map(v => v?.response || []),
    );

  updateFavoriteTrainer = async (trainer, value) => {
    if (value) {
      await Api.postFavoriteTrainer(trainer);
    } else {
      await Api.deleteFavoriteTrainer(trainer);
    }
    return this.syncFavoriteTrainers();
  };

  clearFavoriteTrainers = () => {
    Watermelon.sync('requests', {
      id: '/favorite_trainer',
      response: [],
    });
  };

  ///// FavoriteWorkout /////

  syncFavoriteWorkouts = async () => {
    const response = await Api.getFavoriteWorkouts();
    if (Array.isArray(response)) {
      return await Watermelon.sync('requests', {
        id: '/favorite_workout',
        response: response.map(id => String(id)),
      });
    } else {
      _throwFeedback(response);
    }
  };

  observeFavoriteWorkouts = () =>
    _findAndObserve('requests', '/favorite_workout').pipe(
      map(v => v?.response || []),
    );

  updateFavoriteWorkout = async (workout, value) => {
    if (value) {
      await Api.postFavoriteWorkout(workout);
    } else {
      await Api.deleteFavoriteWorkout(workout);
    }
    return this.syncFavoriteWorkouts();
  };

  clearFavoriteWorkouts = () =>
    Watermelon.sync('requests', {
      id: '/favorite_workout',
      response: [],
    });

  ///// Highlights /////

  syncHighlights = async params => {
    const response = await Api.getHighlights(params);
    if (Array.isArray(response)) {
      return await Watermelon.sync('requests', {
        id: _url('/highlight', params),
        response,
      });
    } else {
      _throwFeedback(response);
    }
  };

  observeHighlights = params =>
    _findAndObserve('requests', _url('/highlight', params)).pipe(
      map(v => v?.response || []),
    );

  ///// Highlight Banner /////

  syncHighlightBanners = async params => {
    const response = await Api.getHighlightBanners(params);
    if (Array.isArray(response)) {
      return await Watermelon.sync('requests', {
        id: _url('/highlight_banner', params),
        response,
      });
    } else {
      Api.logMalformattedResponse(response);
    }
  };

  observeHighlightBanners = params =>
    _findAndObserve('requests', _url('/highlight_banner', params)).pipe(
      map(v => v?.response || []),
    );

  ///// Lessons /////

  syncLessons = params =>
    _list('lessons', _url('/lesson', params), () =>
      Api.getLessons({ simple: 1, limit: 100, ...params }).then(
        response => response?.results,
      ),
    );

  syncLesson = id =>
    _single('lessons', async () => {
      const lesson = await Api.getLesson(id);
      if (typeof lesson === 'object') {
        const objectToId = [
          'modality',
          'place',
          'sponsor',
          'trainer',
          'substitute_trainer',
        ];
        objectToId.forEach(prop => {
          if (lesson[prop]?.id) {
            lesson[prop] = lesson[prop].id;
          }
        });
      }
      return lesson;
    });

  findLesson = id =>
    _strongQuery(
      () => Watermelon.getCollection('lessons').find(String(id)),
      () => this.syncLesson(id),
    );

  findLessonsByIds = idList =>
    _strongQuery(
      () =>
        Watermelon.getCollection('lessons')
          .query(Q.where('id', Q.oneOf(idList.map(id => String(id)))))
          .fetch(),
      () => this.syncLessons({ ids: idList }),
    );

  observeLessons = params =>
    _findAndObserve('requests', _url('/lesson', params)).pipe(
      switchMap(request =>
        _queryByIdListAndObserve('lessons', request?.response),
      ),
    );

  observeLessonsByIds = idList => _queryByIdListAndObserve('lessons', idList);

  observeLesson = id => _findAndObserve('lessons', id);

  ///// LessonGroups /////

  syncLessonGroup = id =>
    _single('lesson_groups', () => Api.getLessonGroup(id));

  observeLessonGroup = id => _findAndObserve('lesson_groups', id);

  ///// LessonSpots /////

  saveLessonSpot = (enrollment, spot) => Api.postLessonSpot(enrollment, spot);

  ///// Me /////

  syncMe = async () => {
    // TODO se não estiver logado, retorna 401, mas isso dispara um erro no syncMe, é isso q queremos?
    // pensando em fluxo, aonda fica a responsabilidade de uma condicional para saber se o usuário está logado
    // para evitar fazer esse request quando não for necessário? Isso se reflete diretamente no findMe,
    // se algum serviço, por exemplo ClockService, deseja verificar se há dados do usuário atual, é o
    // ClockService ou o DataService que adiciona a condicional para evitar disparar um 401?
    const me = await Api.getMe();
    if (me?.id) {
      if (me.default_city) {
        me.default_city = String(me.default_city);
      }
      if (me.customer?.id) {
        const customer = { ...me.customer };
        if (customer.settings?.id) {
          const customerSettings = { ...customer.settings };
          customer.settings = customer.settings.id;
          await Watermelon.sync(CustomerSettings.table, customerSettings);
        }
        await Watermelon.sync(Customer.table, customer);
        me.customer = String(me.customer.id);
      }
      if (me.trainer?.id) {
        const trainer = { ...me.trainer };
        await Watermelon.sync(Trainer.table, trainer);
        me.trainer = String(me.trainer.id);
      }
      return await Watermelon.sync(Request.table, {
        id: RequestId.ME,
        response: me,
      });
    } else {
      Api.logMalformattedResponse(me);
    }
  };

  findMe = () =>
    _strongFind(
      () =>
        Watermelon.getCollection(
          Request.table.find(RequestId.ME).then(v => v?.response),
        ),
      () => this.syncMe(),
    );

  observeMe = () => _findRequestAndObserve(RequestId.ME);

  updateMeDefaultCity = async city => {
    if (AuthService.isLogged) {
      await this.updateCustomerSettings({ default_city: city });
      await this.syncMe().catch(() => {});
    } else {
      // TODO por enquanto sobreescrevemos todo o usuário ao trocar a cidade
      // isso porque ainda não temos nenhum dado além do default_city quando
      // é um usuário anônimo. No futuro, se tivermos mais dados, vamos
      // ter q fazer um merge com o usuário atual, possivelmente uma tabela
      // de users para salvar o usuário e facilmente trocar o valor de uma coluna
      await Watermelon.sync(Request.table, {
        id: RequestId.ME,
        response: { default_city: String(city) },
      });
    }
  };

  updateMeLanguage = async language => {
    const response = await Api.updateCustomerSettings({ language });
    if (response?.id) {
      await this.syncMe().catch(() => {});
      this.syncAllData();
      LanguageService.setLanguage(language);
    } else {
      Api.logMalformattedResponse(response);
    }
    return response;
  };

  clearMe = () => {
    Watermelon.getCollection(
      Request.table
        .find(RequestId.ME)
        .then(social => social.destroyPermanently())
        .catch(() => {}),
    );
  };

  ///// Me Social /////

  syncMeSocial = async () => {
    const response = await Api.getMeSocial();
    if (Array.isArray(response)) {
      return await Watermelon.sync(Request.table, {
        id: '/me/social',
        response,
      });
    } else {
      Api.logMalformattedResponse(response);
    }
  };

  observeMeSocial = () =>
    _findAndObserve(Request.table, '/me/social').pipe(map(v => v?.response));

  clearMeSocial = () => {
    Watermelon.getCollection(
      Request.table
        .find('/me/social')
        .then(social => social.destroyPermanently())
        .catch(() => {}),
    );
  };

  ///// Modalities /////

  syncModalities = params =>
    _list('modalities', _url('/modality', params), () =>
      Api.getModalities(params),
    );

  syncModality = id => _single('modalities', () => Api.getModality(id));

  observeModalities = params =>
    _findAndObserve('requests', _url('/modality', params)).pipe(
      switchMap(request =>
        _queryByIdListAndObserve('modalities', request?.response),
      ),
    );

  observeSearchableModalities = () =>
    Watermelon.getCollection('modalities')
      .query(Q.where('searchable', true))
      .observeWithColumns(['searchable']);

  observeModality = id => _findAndObserve('modalities', id);

  ///// Neighborhoods /////

  syncNeighborhoods = () =>
    _array('neighborhoods', () => Api.getNeighborhoods());

  syncNeighborhood = id =>
    _single('neighborhoods', () => Api.getNeighborhood(id));

  findNeighborhood = id =>
    _strongQuery(
      () => Watermelon.getCollection('neighborhoods').find(String(id)),
      () => this.syncNeighborhood(id),
    );

  observeNeighborhoods = () =>
    Watermelon.getCollection('neighborhoods').query().observe();

  observeNeighborhood = id => _findAndObserve('neighborhoods', id);

  ///// Places /////

  __formatPlace = place => {
    if (place?.neighborhood?.id) {
      place.neighborhood = place.neighborhood.id;
    }
    return place;
  };

  syncPlaces = params =>
    _pagedArray(
      'places',
      async (...params2) => {
        const places = await Api.getPlaces(...params2);
        places?.results?.forEach(this.__formatPlace);
        return places;
      },
      { limit: 200, ...params },
    );

  syncPlace = id =>
    _single('places', async () => {
      const place = await Api.getPlace(id);
      return this.__formatPlace(place);
    });

  findPlace = id =>
    _strongQuery(
      () => Watermelon.getCollection('places').find(String(id)),
      () => this.syncPlace(id),
    );

  observePlacesByCity = city =>
    Watermelon.getCollection('places')
      .query(Q.where('city', String(city)))
      .observeWithColumns(['city']);

  observeSearchablePlaces = () =>
    Watermelon.getCollection('places')
      .query(Q.where('searchable', true), Q.where('is_station', false))
      .observeWithColumns(['searchable', 'is_station']);

  observePlace = id => _findAndObserve('places', id);

  ///// Products /////

  syncProduct = id => _single('products', () => Api.getProduct(id));

  observeProduct = id => _findAndObserve('products', id);

  ///// Sponsors /////

  syncSponsors = () => _array('sponsors', () => Api.getSponsors());

  syncSponsor = id => _single('sponsors', () => Api.getSponsor(id));

  observeSponsor = id => _findAndObserve('sponsors', id);

  observePublishedSponsors = () =>
    Watermelon.getCollection('sponsors')
      .query(Q.where('published', true))
      .observeWithColumns(['published']);

  ///// Sponsor Groups /////

  __formatSponsorGroups = sponsorGroups => {
    sponsorGroups.forEach(group => {
      group.sponsor = group.sponsor?.id || '';
    });
    return sponsorGroups;
  };

  syncSponsorGroups = () =>
    _array('sponsor_groups', async () => {
      const sponsorGroups = await Api.getSponsorGroups();
      if (Array.isArray(sponsorGroups)) {
        this.__formatSponsorGroups(sponsorGroups);
      }
      return sponsorGroups;
    });

  observeSponsorGroupsBySponsor = sponsor =>
    Watermelon.getCollection('sponsor_groups')
      .query(Q.where('sponsor', String(sponsor)))
      .observeWithColumns(['sponsor', 'is_member']);

  observeSponsorGroups = () =>
    Watermelon.getCollection('sponsor_groups')
      .query()
      .observeWithColumns(['is_member']);

  updateSponsorGroup = async (name, value) => {
    const response = await Api.updateSponsorGroup(name, value);
    if (Array.isArray(response)) {
      await Watermelon.sync(
        'sponsor_groups',
        this.__formatSponsorGroups(response),
      );
    } else {
      Api.logMalformattedResponse(response);
    }
    return response;
  };

  ///// Trainers /////

  syncTrainers = () => _pagedArray('trainers', Api.getTrainers, { limit: 200 });

  syncTrainer = id => _single('trainers', () => Api.getTrainer(id));

  findTrainer = id =>
    _strongQuery(
      () => Watermelon.getCollection('trainers').find(String(id)),
      () => this.syncTrainer(id),
    );

  findTrainerBySlug = slug =>
    _strongQuery(
      () =>
        Watermelon.getCollection('trainers')
          .query(Q.where('slug', slug))
          .fetch()
          .then(result => result[0]),
      () => this.syncTrainers(),
    );

  observeApprovedTrainers = () =>
    Watermelon.getCollection('trainers')
      .query(Q.where('approved', true))
      .observeWithColumns('approved');

  observeTrainer = id => _findAndObserve('trainers', id);

  ///// Workouts /////

  syncWorkouts = params =>
    _pagedArray('workouts', Api.getWorkouts, { limit: 200, ...params });

  syncWorkout = id => _single('workouts', () => Api.getWorkout(id));

  observeWorkouts = () =>
    Watermelon.getCollection('workouts').query().observe();

  observeWorkoutsByTrainer = trainer =>
    Watermelon.getCollection('workouts')
      .query(Q.where('trainer', String(trainer)))
      .observeWithColumns(['trainer']);

  observeWorkout = id => _findAndObserve('workouts', id);

  ///// WorkoutCollections /////

  syncWorkoutCollections = () =>
    _pagedArray('workout_collections', Api.getWorkoutCollections, {
      limit: 100,
    });

  syncWorkoutCollection = id =>
    _single('workout_collections', () => Api.getWorkoutCollection(id));

  observeWorkoutCollection = id => _findAndObserve('workout_collections', id);

  ///// WorkoutConsumptions /////

  syncWorkoutConsumptions = () =>
    _pagedArray('workout_consumptions', Api.getWorkoutConsumptions, {
      limit: 200,
    });

  createWorkoutConsumption = async data => {
    const response = await Api.createWorkoutConsumption(data);
    if (response?.id) {
      Watermelon.sync('workout_consumptions', response); // sincroniza em paralelo
    } else {
      Api.logMalformattedResponse(response);
    }
    return response;
  };

  observeVisibleWorkoutConsumptions = () =>
    Watermelon.getCollection('workout_consumptions')
      .query(Q.where('show_in_history', true))
      .observeWithColumns(['show_in_history']);

  observeWorkoutConsumption = id => _findAndObserve('workout_consumptions', id);

  updateWorkoutConsumption = async data => {
    const response = await Api.updateWorkoutConsumption(data.id, data);
    if (response?.id) {
      Watermelon.sync('workout_consumptions', response); // sincroniza em paralelo
    } else {
      Api.logMalformattedResponse(response);
    }
    return response;
  };

  clearWorkoutConsumptions = () =>
    Watermelon.clearCollection('workout_consumptions');

  ///// Local Storage /////

  setLocal = (key, value) => Watermelon.setLocal(key, value);

  getLocal = key => Watermelon.getLocal(key);

  removeLocal = key => Watermelon.removeLocal(key);

  ///// Generic Observable /////

  observe = value => of(value);

  ///// Other methods /////

  syncPolling = async () => {
    const { user } = StoreService.getState();
    if (user?.id) {
      const enrollments = await this.findEnrollmentsByUser(user.id);
      const lessons = await this.findLessonsByIds(
        enrollments.map(e => e.lesson),
      );
      const futureLessons = lessons.filter(
        l => moment(l.end_datetime).diff(moment(), 'minutes') > 0,
      );
      if (futureLessons.length) {
        this.syncLessons({ ids: futureLessons.map(l => l.id) }).catch(() => {});
      }
    }
  };

  syncAllData = isAppStateSync => {
    // TODO o cache deve ser feito por cada método da api
    if (isAppStateSync) {
      const limit = 10 * 60 * 1000; // wait at least 10 minutes to a new sync
      if (Date.now() - this.lastSync > limit) {
        this.lastSync = Date.now();
      } else {
        return;
      }
    }

    const list = [
      { method: Api.getMyCity, lowPriority: true }, // be sure we have a valid city before get all content
    ];

    if (AuthService.isLogged) {
      list.push(
        { method: this.syncMe, lowPriority: true },
        { method: Api.getUserSettings, lowPriority: true },
        { method: Api.getAnamnesis, lowPriority: true },
        { method: this.syncEnrollments, lowPriority: true },
        { method: this.syncFavoriteTrainers, lowPriority: true },
        { method: this.syncFavoriteWorkouts, lowPriority: true },
        { method: Api.getUserAddress, lowPriority: true },
        { method: Api.getEmergencyContacts, lowPriority: true },
        { method: this.syncWorkoutConsumptions, lowPriority: true },
      );
    }

    list.push(
      // GroupClass
      { method: Api.getGlobalSettings, lowPriority: true },
      { method: this.syncEvents, lowPriority: true },
      { method: this.syncModalities, lowPriority: true },
      { method: this.syncSponsorGroups, lowPriority: true },
      { method: this.syncSponsors, lowPriority: true },
      { method: this.syncCities, lowPriority: true },
      { method: this.syncTrainers, lowPriority: true },
      { method: this.syncPlaces, lowPriority: true },
      { method: this.syncNeighborhoods, lowPriority: true },

      // DIY
      { method: this.syncDiyHome, lowPriority: true },
      { method: this.syncWorkoutCollections, lowPriority: true },
      { method: this.syncWorkouts, lowPriority: true },
      { method: this.syncExercises, lowPriority: true },
      { method: this.syncExerciseModalities, lowPriority: true },
      { method: this.syncExerciseRequirements, lowPriority: true },

      // Extra
      { method: Api.getFeatureFlag, lowPriority: true },
    );

    return this.syncArray(list);
  };

  syncArray = array => {
    const syncList = array.map(config => this.sync(config));
    return syncList[syncList.length - 1]; // retorna o último item para saber q todos já foram executados
  };

  /**
   * config: { method, params, lowPriority }
   */
  sync = config => {
    return new Promise(resolve => {
      const request = {
        ...config,
        resolve,
      };
      this.queue.push(request);

      this.processNextRequest();
    });
  };

  sortQueueByPriority = () => {
    this.queue.sort((a, b) => (!a.lowPriority && b.lowPriority ? -1 : 0));
  };

  processNextRequest = async () => {
    if (this.isSyncing || !this.queue.length) {
      return;
    }
    this.isSyncing = true;

    this.sortQueueByPriority();

    const request = this.queue.shift();
    const { method, params, resolve } = request;

    // TODO verificar o cache para não precisar fazer mtos requests iguais
    // TODO seguir a paginacao do results
    try {
      await method(params);
    } catch (error) {
      resolveError(error, false, '', false);
    }

    resolve();

    InteractionManager.runAfterInteractions(() => {
      setTimeout(() => {
        this.isSyncing = false;
        this.processNextRequest();
      }, 100);
    });
  };
}

///// Helpers /////

async function _array(table, request) {
  const response = await request();
  if (Array.isArray(response)) {
    await Watermelon.sync(table, response);
  } else {
    Api.logMalformattedResponse(response);
  }
}

async function _pagedArray(table, request, params) {
  const key = `updatedFrom_${table}_${JSON.stringify(params)}`;
  const updated_from = await Watermelon.getLocal(key);

  const doRequest = async (offset = 0) => {
    const response = await request({ ...params, offset, updated_from });
    if (Array.isArray(response?.results)) {
      await Watermelon.sync(table, response.results);
      if (response.next) {
        await doRequest(offset + response.results.length);
      }
    } else {
      _throwFeedback(response);
    }
  };

  await doRequest();
  Watermelon.setLocal(key, new Date().toISOString());
}

async function _list(table, id, request) {
  const list = await request();
  if (Array.isArray(list)) {
    await Watermelon.sync(table, list);
    await Watermelon.sync('requests', {
      id,
      response: list.map(item => String(item.id)),
    });
  } else {
    _throwFeedback(list);
  }
}

async function _single(table, request) {
  const response = await request();
  if (response?.id) {
    await Watermelon.sync(table, response);
  } else {
    Api.logMalformattedResponse(response);
  }
}

function _strongFind(find, sync) {
  return find()
    .catch(() => null)
    .then(result => result || sync().then(() => find()))
    .catch(() => null);
}

function _strongQuery(query, sync) {
  return query()
    .catch(() => null)
    .then(result => result ?? sync().then(() => query()))
    .catch(() => null);
}

function _findAndObserve(table, id) {
  const collection = Watermelon.getCollection(table);
  return collection.findAndObserve(String(id)).pipe(
    catchError(() =>
      collection
        .query(Q.where('id', String(id)))
        .observe()
        .pipe(switchMap(array => (array[0] ? array[0].observe() : of(null)))),
    ),
  );
}

function _findRequestAndObserve(id, fallback) {
  return _findAndObserve(Request.table, id).pipe(
    map(v => v?.response || fallback),
  );
}

function _queryByIdListAndObserve(table, idList = []) {
  return Watermelon.getCollection(table)
    .query(Q.where('id', Q.oneOf(idList.map(id => String(id)))))
    .observe()
    .pipe(map(array => sortBy(array, i => idList.indexOf(i.id))));
}

function _throwFeedback(response) {
  if (!isResponseTimedOut(response) && !isResponseCanceled(response)) {
    const error = new Error('Malformatted Response');
    error.response = { data: response };
    SentryService.log(error);
  }

  const error = new Error(String(response));
  error.response = response;
  const feedback = resolveError(error, false, '', false);
  throw new Error(feedback);
}

export function _url(path, params) {
  return `${path}${qs.stringify(params, {
    addQueryPrefix: true,
    sort: (a, b) => a.localeCompare(b),
    skipNulls: true,
  })}`;
}

export default new DataService();
