import { Model, Relation } from '@nozbe/watermelondb';
import { field, json, relation } from '@nozbe/watermelondb/decorators';
import Sponsor from './Sponsor';

export default class WorkoutCollection extends Model {
  static table = 'workout_collections';

  @json('content', _json => _json || []) content!: WorkoutCollectionContent[];
  @json('abouts', _json => _json || []) abouts!: WorkoutCollectionAbout[];
  @field('name') name!: string;
  @field('image') image!: string;
  @field('library_image') library_image!: string;
  @field('thumb_about') thumb_about!: string;
  @field('video_about') video_about!: string;
  @field('text_about') text_about!: string;
  @field('is_sequence') is_sequence!: boolean;
  @field('sponsor') sponsor!: string;
  @field('sponsor_url') sponsor_url!: string;

  @relation('sponsors', 'sponsor') sponsor_obj!: Relation<Sponsor>;
}

export type WorkoutCollectionContent = {
  id: number;
  title: string;
  workouts: number[];
};

export type WorkoutCollectionAbout = {
  image: string;
  text: string;
};
