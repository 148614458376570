const Theme = {
  color: {
    white: '#ffffff',
    lowContrastGray_USE_WITH_WISDOM: '#f5f5f5', // este cinza não dá contraste em telas de baixa qualidade
    veryLightGray: '#e0e0e0',
    lightGray: '#bdbdbd',
    gray: '#9e9e9e',
    darkGray: '#757575',
    veryDarkGray: '#212121',
    black: '#000000',
    magenta: '#eb0045',
    darkMagenta: '#bf1741',
    lightBlue: '#83b0c9',
    lightGreen: '#46d8c4',
    yellow: '#ffa100',
    purple: '#9c86f8',
    transparent: 'rgba(0,0,0,0)',
  },
  fonts: {
    barlow: 'Barlow-Regular',
    barlowMedium: 'Barlow-Medium',
    barlowSemiBold: 'Barlow-SemiBold',
    barlowBold: 'Barlow-Bold',
    barlowSemiCondensed: 'BarlowSemiCondensed-Regular',
    barlowSemiCondensedMedium: 'BarlowSemiCondensed-Medium',
    barlowSemiCondensedSemiBold: 'BarlowSemiCondensed-SemiBold',
    barlowSemiCondensedBold: 'BarlowSemiCondensed-Bold',
    barlowCondensed: 'BarlowCondensed-Regular',
    barlowCondensedMedium: 'BarlowCondensed-Medium',
    barlowCondensedSemiBold: 'BarlowCondensed-SemiBold',
    barlowCondensedBold: 'BarlowCondensed-Bold',
    neuePlakCompLight: 'NeuePlak-CompLight',
    neuePlakCompBold: 'NeuePlak-CompBold',
  },
  dimensions: {
    windowWidth: Math.min(window.innerWidth, 400),
    windowHeight: window.innerHeight,
    headerHeight: 60,
    tabBarHeight: 60,
    topSpace: 0,
    bottomSpace: 0,
    landscapeBottomSpace: 0,
  },
};

export function opacity(colorName, alphaPercent) {
  let alphaHex = Math.round(255 * alphaPercent).toString(16);
  if (alphaHex.length < 2) {
    alphaHex = `0${alphaHex}`;
  }
  return `${Theme.color[colorName]}${alphaHex}`;
}

export default Theme;
