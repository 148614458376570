import React from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';
import ActivityCard from './ActivityCard';
import { WIDTH as CARD_WIDTH } from './ActivityCardView';

const INTERVAL_WIDTH = CARD_WIDTH + 10;

export default function ActivitiesSlider({ items }) {
  return (
    <ScrollView
      horizontal
      snapToInterval={INTERVAL_WIDTH}
      snapToAlignment="start"
      decelerationRate="fast"
      showsHorizontalScrollIndicator={false}
      contentContainerStyle={styles.sliderContent}
      scrollEnabled={items.length > 1}
    >
      {items.map(item => (
        <View
          key={`${item.type}_${item.id}_${item.start_datetime}`}
          style={styles.card}
        >
          <ActivityCard item={item} />
        </View>
      ))}
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  sliderContent: {
    flexDirection: 'row',
    paddingLeft: 20,
    paddingRight: 10,
  },
  card: {
    marginRight: 10,
    flexDirection: 'row',
    alignItems: 'stretch',
  },
});
