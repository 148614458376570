import React, { useCallback } from 'react';
import { StyleSheet } from 'react-native';
import Theme from '../libs/Theme';
import NavigationService from '../libs/NavigationService';
import ModalScrollView from '../components/ModalScrollView';
import LoginForm from '../components/LoginForm';

export default function LoginModalScreen() {
  const onSuccess = useCallback(() => {
    NavigationService.goBack();
  }, []);

  const onForgotPassword = useCallback(() => {
    NavigationService.navigate('ForgotPasswordModal');
  }, []);

  return (
    <ModalScrollView title="logIn" contentContainerStyle={styles.container}>
      <LoginForm
        content="completeLoginText"
        onSuccess={onSuccess}
        onForgotPassword={onForgotPassword}
      />
    </ModalScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    paddingTop: 20,
    paddingBottom: 20 + Theme.dimensions.bottomSpace,
  },
});
