import React, { useCallback } from 'react';
import { TouchableWithoutFeedback, View } from 'react-native';
import { connect } from 'react-redux';
import styles from './HtmlHeader.module.scss';
import ModalService from '../libs/ModalService';
import NavigationService from '../libs/NavigationService';
import Theme from '../libs/Theme';
import ProfileIcon from '../icons/ProfileIcon';
import LoginToContinueModal from './LoginToContinueModal';

function HtmlHeader({ user }) {
  const onPressProfile = useCallback(() => {
    if (user?.id) {
      NavigationService.navigate('Profile');
    } else {
      ModalService.show(<LoginToContinueModal />);
    }
  }, [user]);

  return (
    <div className={styles.header}>
      <a href="https://mude.fit">
        <svg className={styles.logo} width={45} height={19} viewBox="0 0 45 19">
          <path
            d="M30.1 14.5c-.2 0-.3-.1-.3-.3V8.8c0-.2.1-.4.3-.4.2 0 .3.1.3.3v5.4c0 .3-.1.4-.3.4zM34.8.3v13.9c0 2.6-2.1 4.6-4.7 4.6s-4.7-2.1-4.7-4.7V8.8c0-2.6 2.1-4.7 4.7-4.7h.3V.3h4.4zm5 8.5c0-.2.1-.3.3-.3.2 0 .3.2.3.3v1.5h-.6V8.8zm5 0v2.6h-5.1v2.8c0 .2.2.3.3.3.2 0 .4-.2.4-.4V12h4.4v2.2c0 2.6-2.1 4.7-4.7 4.7s-4.7-2.1-4.7-4.7V8.8c0-2.6 2.1-4.7 4.7-4.7s4.7 2.1 4.7 4.7zm-24.5 5.4V4.7h4.4v9.5c0 2.6-2.1 4.7-4.7 4.7s-4.7-2.1-4.7-4.7V8.8c0-.2-.2-.3-.4-.3s-.4.2-.4.3v5.4c0 1.9-1.1 3.5-2.7 4.2-.1 0-.1.1-.2.1-.1.1-.3.1-.4.2-.4.1-.9.2-1.4.2-2.6 0-4.7-2.1-4.7-4.7V8.8c0-.2-.2-.3-.4-.3s-.4.2-.4.3v9.6H0V8.8c0-2.6 2.1-4.7 4.7-4.7s4.7 2.1 4.7 4.7v5.4c0 .2.1.3.3.3h.1c.2 0 .4-.2.4-.3V8.8c0-2.6 2.1-4.7 4.7-4.7s4.7 2.1 4.7 4.7v5.4c0 .2.2.3.4.3.1 0 .3-.1.3-.3z"
            fillRule="evenodd"
            clipRule="evenodd"
            fill={Theme.color.magenta}
          />
        </svg>
      </a>
      <TouchableWithoutFeedback onPress={onPressProfile}>
        <View>
          {user?.picture ? (
            <img className={styles.picture} src={user.picture} alt="" />
          ) : (
            <ProfileIcon color={Theme.color.magenta} />
          )}
        </View>
      </TouchableWithoutFeedback>
    </div>
  );
}

const mapStateToProps = ({ user }) => ({ user });

export default connect(mapStateToProps)(HtmlHeader);
