import React from 'react';
import { connect } from 'react-redux';
import { withTranslation, Trans } from 'react-i18next';
import i18n from 'i18next';

function I18n({ i18nKey, children, params, count, t }) {
  const key = i18nKey || children;

  if (typeof key === 'string' && i18n.exists(key)) {
    if (i18nKey) {
      return (
        <Trans i18nKey={i18nKey} values={params} count={count || params?.count}>
          {children || ''}
        </Trans>
      );
    }

    return t(key, params) || '';
  }

  return key || '';
}

// connect to language to listen updates
const mapStateToProps = ({ settings }) => ({ language: settings.language });

export default withTranslation()(connect(mapStateToProps)(I18n));
