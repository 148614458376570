import StoreService from '../libs/StoreService';
import StorageService from '../libs/StorageService';

const SET_USER = 'USER/SET_USER';
const SET_SETTINGS = 'USER/SET_SETTINGS';
const DELETE_USER = 'USER/DELETE_USER';

export function getUser() {
  return StoreService.getState().user;
}

export function setUser(user) {
  StoreService.dispatch({ type: SET_USER, user });
}

export function setUserSettings(settings) {
  StoreService.dispatch({ type: SET_SETTINGS, settings });
}

export function deleteUser() {
  StoreService.dispatch({ type: DELETE_USER });
}

export default function reducer(state = { settings: {} }, action) {
  switch (action.type) {
    case SET_USER: {
      const user = { ...action.user };

      // copiamos os dados do user da api para o nosso redux user
      // isso existe porque para a api há uma diferenciação entre customer e user
      // no futuro, temos que escolher entre:
      //   a) `customer.user.first_name`
      //   b) `customer.first_name` (e assumer que customer === reduxUser)
      const userModelFromApi = action.user.user; // é `user.user` pois na real é `customer.user`
      user.first_name = userModelFromApi.first_name;
      user.last_name = userModelFromApi.last_name;
      user.email = userModelFromApi.email;

      // se disponível, copiamos as configurações (language) para o nosso redux user
      // no futuro, devemos pegar essa informações direto do `user.settings` para não ter dado duplicado
      if (action.user.settings && action.user.settings.language) {
        user.language = action.user.settings.language;
      }

      const mergedUser = { ...state, ...user };

      // removemos as possíveis configurações duplicadas para evitar inconsistencia de dados
      delete mergedUser.default_city;
      delete mergedUser.city;

      return mergedUser;
    }

    case SET_SETTINGS: {
      const settings = { ...state.settings, ...action.settings };
      if (
        settings.default_city &&
        settings.default_city !== StorageService.getDefaultCity()
      ) {
        StorageService.setDefaultCity(settings.default_city);
      }
      return { ...state, settings };
    }

    case DELETE_USER: {
      return { settings: {} };
    }

    default:
      return state;
  }
}
