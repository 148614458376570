import React from 'react';
import { View, StyleSheet } from 'react-native';
import Api from '../libs/Api';
import Theme from '../libs/Theme';
import {
  validateEmail,
  validatePassword,
  resolveError,
} from '../libs/FormUtils';
import NavigationService from '../libs/NavigationService';
import ModalScrollView from '../components/ModalScrollView';
import I18n from '../components/I18n';
import TextInput from '../components/TextInput';
import TextLink from '../components/TextLink';
import FormLayout from '../components/FormLayout';
import CheckboxInput from '../components/CheckboxInput';
import ActionButton from '../components/ActionButton';

// Esta screen é muito parecida com CreateAccountScreen porém,
// por não ser um cadastro contínuo foi necessário criar algo mais personalizado.
// No futuro podemos pensar em maneiras de unificar elas.

export default class CreateAccountModalScreen extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      currentSlide: 0,
      nickname: '',
      email: '',
      phone_country_code: '+55',
      phone: '',
      password: '',
      password_confirm: '',
      policy: false,
      errors: {},
    };
  }

  onChangeInput = (value, name) => {
    this.setState({ [name]: value });
  };

  onTogglePolicy = value => {
    this.setState({ policy: value });
  };

  onSubmit = async () => {
    if (
      !this.validate.nickname() ||
      !this.validate.email() ||
      !this.validate.phone() ||
      !this.validate.password() ||
      !this.validate.policy()
    ) {
      return;
    }

    this.setState({ errors: {} });

    const { nickname, email, phone, phone_country_code, password } = this.state;

    const payload = {
      nickname,
      email,
      phone,
      phone_country_code,
      password,
    };

    try {
      await Api.createAccount(payload);
    } catch (error) {
      this.setState({ errors: { general: resolveError(error) } });
      return false;
    }

    NavigationService.goBack();
  };

  validate = {
    nickname: () => {
      const nickname = this.state.nickname.trim();

      if (!nickname.length) {
        this.setState({ errors: { nickname: 'nicknameRequired' } });
        return false;
      }

      return true;
    },

    email: () => {
      const email = this.state.email.trim();

      if (!email) {
        this.setState({ errors: { email: 'emailRequired' } });
        return false;
      }

      if (!validateEmail(email)) {
        this.setState({ errors: { email: 'emailInvalid' } });
        return false;
      }

      return true;
    },

    phone: () => {
      const phone = this.state.phone.trim();

      if (!phone) {
        this.setState({ errors: { phone: 'phoneRequired' } });
        return false;
      }

      if (phone.length < 8) {
        this.setState({ errors: { phone: 'phoneInvalid' } });
        return false;
      }

      return true;
    },

    password: () => {
      const { password, password_confirm } = this.state;

      const error = validatePassword(password, password_confirm);
      if (error) {
        this.setState({ errors: { password: error } });
        return false;
      }

      return true;
    },

    policy: () => {
      if (!this.state.policy) {
        this.setState({ errors: { policy: 'uncheckedPolicy' } });
        return false;
      }

      return true;
    },
  };

  render() {
    const {
      nickname,
      email,
      phone_country_code,
      phone,
      password,
      password_confirm,
      policy,
      errors,
    } = this.state;

    const fields = [
      <TextInput
        name="nickname"
        value={nickname}
        label="nickname"
        onChange={this.onChangeInput}
        error={errors.nickname}
      />,
      <TextInput
        name="email"
        value={email}
        label="email"
        onChange={this.onChangeInput}
        error={errors.email}
      />,
      <View style={styles.row}>
        <View style={styles.countryCode}>
          <TextInput
            name="phone_country_code"
            value={phone_country_code}
            label="ddi"
            onChange={this.onChangeInput}
            error={errors.phone_country_code}
          />
        </View>
        <View style={styles.phone}>
          <TextInput
            name="phone"
            value={phone}
            label="phone"
            onChange={this.onChangeInput}
            error={errors.phone}
          />
        </View>
      </View>,
      <TextInput
        name="password"
        value={password}
        label="password"
        caption="passwordInstructions"
        onChange={this.onChangeInput}
        error={errors.password}
      />,
      <TextInput
        name="password_confirm"
        value={password_confirm}
        label="confirmPassword"
        onChange={this.onChangeInput}
        error={errors.password_confirm}
      />,
      <CheckboxInput
        name="policy"
        value={policy}
        label={
          <I18n i18nKey="agreeWithPolicy">
            <TextLink url="https://www.mude.fit/termos-e-privacidade/" />
          </I18n>
        }
        onChange={this.onTogglePolicy}
      />,
    ];

    const button = (
      <ActionButton
        title="createAccountSubmit"
        theme="magenta"
        onPress={this.onSubmit}
      />
    );

    return (
      <ModalScrollView
        title="createAccount"
        contentContainerStyle={styles.container}
      >
        <FormLayout
          fields={fields}
          footer={button}
          error={errors.policy || errors.general}
        />
      </ModalScrollView>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    paddingTop: 20,
    paddingBottom: 20 + Theme.dimensions.bottomSpace,
  },
  row: {
    flexDirection: 'row',
  },
  countryCode: {
    width: 50,
    marginRight: 10,
  },
  phone: {
    flexGrow: 1,
    flexShrink: 1,
  },
});
