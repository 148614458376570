import React from 'react';
import LessonActivityEntry from './LessonActivityEntry';
import WorkoutActivityEntry from './WorkoutActivityEntry';

export default function ActivityEntry({ item }) {
  const { type, is_virtual } = item;
  const _type =
    type || (typeof is_virtual !== 'undefined' ? 'lesson' : 'workout');

  switch (_type) {
    case 'lesson':
      return <LessonActivityEntry id={item.id} />;
    case 'workout':
      return <WorkoutActivityEntry id={item.id} />;
    default:
      return null;
  }
}
