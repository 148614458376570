export default {
  'Request is missing username parameter.': 'Your e-mail address is required',
  'Request is missing password parameter.': 'Your password is required',
  'Invalid credentials given.': 'Invalid username or password.',
  'Email not available': 'E-mail not available.',
  invalid_grant: 'Invalid e-mail or password',
  invalid_request: 'One or more fields are not correctly filled',
  'invalid token': 'Invalid or expired token.',

  confirmPasswordRequired: 'The field "Password confirm" is required',
  differentPasswords: 'The provided passwords are different',
  emailInvalid: 'The e-mail is invalid',
  emailRequired: 'E-mail field is required',
  fieldRequired: 'This field is required',
  invalidCpf: 'Invalid CPF',
  'cpf already exists': 'CPF already used by another account',
  invalidPassword: 'Incorrect password',
  nicknameRequired: 'Please, use a valid nickname',
  phoneInvalid: 'Please, use a valid phone number',
  phoneRequired: 'The phone number is required',
  uncheckedPolicy:
    'You need to accept our termos of use and privacy policy before continue',
  weakPassword:
    'The password must contain at least 8 digits and both numbers and letters.',
  emergencyContactError: 'Error registering an emergency contact',

  'Network Error': 'Ops. Appears to be offline.',
  networkErrorTitle: 'Ops. A network error occurred.',
  networkErrorContent: `Maybe you are without internet access or our servers are overloaded.

Please try again or get in contact with us, so we can fix it as soon as possible!.`,

  invalid_payment_hash: 'Invalid payment method. Please register a new one.',
  invalidCard: 'Invalid card',
  invalidDate: 'Invalid date',
  invalidCode: 'Invalid code',
  invalidName: 'Invalid name',
  requiredField: 'Required field',

  minimumPrice: 'For fee reasons, the minimum amount is R$ 2.00.',
};
