import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import {
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  TouchableWithoutFeedback,
  View,
} from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import FastImage from 'react-native-fast-image';
import Api from '../libs/Api';
import NavigationService from '../libs/NavigationService';
import Theme from '../libs/Theme';
import BackArrowIcon from '../icons/BackArrowIcon';
import MapIcon from '../icons/MapIcon';
import ProfileIcon from '../icons/ProfileIcon';
import SettingsIcon from '../icons/SettingsIcon';
import AuthGate from '../components/AuthGate';
import HorizontalMenu from '../components/HorizontalMenu';
import ActivitiesTab from '../components/ActivitiesTab';
import JourneyTab from '../components/JourneyTab';
import SponsorsTab from '../components/SponsorsTab';
import I18n from '../components/I18n';

const isNative = Platform.select({ native: true });
const TABS = isNative
  ? ['activities', 'journey', 'partners']
  : ['activities', 'journey'];

function ProfileScreen({ user, cities }) {
  const [currentTab, setCurrentTab] = useState(0);

  const onPressBack = useCallback(() => {
    NavigationService.goBack();
  }, []);

  const onPressMap = useCallback(() => {
    NavigationService.navigate('Map');
  }, []);

  const onPressSettings = useCallback(() => {
    NavigationService.navigate('Settings');
  }, []);

  const onPressLogout = useCallback(() => {
    Api.logout();
    NavigationService.reset('FrontDoor');
  }, []);

  const onChangeTab = useCallback(index => {
    setCurrentTab(index);
  }, []);

  if (!user) {
    return <AuthGate />;
  }

  const isNative = Platform.select({ native: true });

  return (
    <AuthGate>
      <LinearGradient
        locations={[0, 0.5]}
        colors={[
          Theme.color.white,
          Theme.color.lowContrastGray_USE_WITH_WISDOM,
        ]}
        style={StyleSheet.absoluteFill}
      />
      {isNative && (
        <View style={styles.header}>
          <TouchableWithoutFeedback onPress={onPressBack}>
            <View style={styles.headerButton}>
              <BackArrowIcon ccolor={Theme.color.magenta} />
            </View>
          </TouchableWithoutFeedback>
          <TouchableWithoutFeedback onPress={onPressMap}>
            <View style={styles.headerCity}>
              <Text style={styles.headerCityText} numberOfLines={1}>
                {cities.find(c => c.id === user.settings.default_city)?.name ||
                  ''}
              </Text>
              <MapIcon />
            </View>
          </TouchableWithoutFeedback>
          <TouchableWithoutFeedback onPress={onPressSettings}>
            <View style={styles.headerButton}>
              <SettingsIcon />
            </View>
          </TouchableWithoutFeedback>
        </View>
      )}
      <ScrollView
        bounces={false}
        showsVerticalScrollIndicator={false}
        contentContainerStyle={styles.contentContainer}
      >
        <View style={styles.cover}>
          <View style={styles.picture}>
            {user.picture ? (
              <FastImage
                style={styles.picture}
                source={{ uri: user.picture || '' }}
              />
            ) : (
              <ProfileIcon
                color={Theme.color.veryLightGray}
                width={76}
                height={76}
              />
            )}
          </View>
          <View style={styles.name}>
            {user.nickname ? (
              <>
                <Text style={styles.bigName}>{user.nickname}</Text>
                <Text style={styles.smallName}>
                  {[user.first_name, user.last_name].filter(s => s).join(' ')}
                </Text>
              </>
            ) : (
              <Text style={styles.bigName}>
                {[user.first_name, user.last_name].filter(s => s).join(' ')}
              </Text>
            )}
            {!isNative && (
              <TouchableWithoutFeedback onPress={onPressLogout}>
                <Text style={styles.exitButton}>
                  <I18n>exit</I18n>
                </Text>
              </TouchableWithoutFeedback>
            )}
          </View>
        </View>
        <HorizontalMenu
          items={TABS}
          selected={currentTab}
          onChange={onChangeTab}
        />
        {currentTab === 0 && <ActivitiesTab />}
        {currentTab === 1 && <JourneyTab />}
        {currentTab === 2 && <SponsorsTab />}
      </ScrollView>
    </AuthGate>
  );
}

const styles = StyleSheet.create({
  header: {
    paddingTop: Theme.dimensions.topSpace,
    flexDirection: 'row',
  },
  headerCity: {
    flexGrow: 1,
    flexShrink: 1,
    paddingHorizontal: 20,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  headerCityText: {
    color: Theme.color.veryDarkGray,
    fontFamily: Theme.fonts.barlowBold,
    fontSize: 16,
    marginRight: 8,
  },
  headerButton: {
    height: Theme.dimensions.headerHeight,
    width: 50,
    alignItems: 'center',
    justifyContent: 'center',
  },
  contentContainer: {
    paddingBottom: 60 + Theme.dimensions.bottomSpace,
  },
  cover: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 20,
  },
  picture: {
    width: 76,
    height: 76,
    borderRadius: 76,
    marginRight: 20,
  },
  name: {
    flexGrow: 1,
    flexShrink: 1,
  },
  bigName: {
    color: Theme.color.veryDarkGray,
    fontFamily: Theme.fonts.barlowBold,
    fontSize: 30,
    lineHeight: 30,
  },
  smallName: {
    color: Theme.color.darkGray,
    fontFamily: Theme.fonts.barlowSemiBold,
    fontSize: 14,
    marginTop: 5,
  },
  exitButton: {
    color: Theme.color.magenta,
    fontFamily: Theme.fonts.barlowBold,
    fontSize: 10,
    textTransform: 'uppercase',
    paddingVertical: 5,
  },
});

const mapStateToProps = ({ user, cities }) => ({ user, cities });

export default connect(mapStateToProps)(ProfileScreen);
