import React, { useCallback } from 'react';
import { StyleSheet, Text, TouchableWithoutFeedback, View } from 'react-native';
import { connect } from 'react-redux';
import FastImage from 'react-native-fast-image';
import ModalService from '../libs/ModalService';
import NavigationService from '../libs/NavigationService';
import MapIcon from '../icons/MapIcon';
import MudeLogoIcon from '../icons/MudeLogoIcon';
import ProfileIcon from '../icons/ProfileIcon';
import Theme from '../libs/Theme';
import I18n from './I18n';
import LoginToContinueModal from './LoginToContinueModal';

function HeaderLogoAndProfile({ user, title, cities, showCity }) {
  const onPressMap = useCallback(() => {
    NavigationService.navigate('Map');
  }, []);

  const onPressProfile = useCallback(() => {
    if (user?.id) {
      NavigationService.navigate('Profile');
    } else {
      ModalService.show(<LoginToContinueModal />);
    }
  }, [user]);

  const city = cities.find(c => c.id === user.settings.default_city);

  return (
    <View style={styles.container}>
      <View style={styles.title}>
        <MudeLogoIcon color={Theme.color.magenta} />
        <Text style={styles.titleText}>
          <I18n>{title}</I18n>
        </Text>
      </View>
      {showCity && (
        <TouchableWithoutFeedback onPress={onPressMap}>
          <View style={styles.city}>
            <Text style={styles.cityText}>{city ? city.name : ''}</Text>
            <MapIcon />
          </View>
        </TouchableWithoutFeedback>
      )}
      <TouchableWithoutFeedback onPress={onPressProfile}>
        <View style={styles.profile}>
          {user?.picture ? (
            <FastImage
              style={styles.picture}
              source={{ uri: user.picture || '' }}
            />
          ) : (
            <ProfileIcon color={Theme.color.magenta} />
          )}
        </View>
      </TouchableWithoutFeedback>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: Theme.color.white,
    height: Theme.dimensions.headerHeight + Theme.dimensions.topSpace,
    paddingTop: Theme.dimensions.topSpace,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  title: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 20,
    marginTop: -1,
  },
  titleText: {
    color: Theme.color.veryDarkGray,
    fontFamily: Theme.fonts.barlowCondensed,
    fontSize: 20,
    textTransform: 'lowercase',
    marginLeft: 8,
  },
  city: {
    flexGrow: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  cityText: {
    color: Theme.color.veryDarkGray,
    fontFamily: Theme.fonts.barlowBold,
    fontSize: 16,
    marginRight: 8,
  },
  profile: {
    justifyContent: 'center',
    padding: 20,
  },
  picture: {
    width: 28,
    height: 28,
    borderRadius: 28,
  },
});

const mapStateToProps = ({ user, cities }) => ({ user, cities });

export default connect(mapStateToProps)(HeaderLogoAndProfile);
