import { Model, Query, Relation } from '@nozbe/watermelondb';
import { children, field, relation } from '@nozbe/watermelondb/decorators';
import { Associations } from '@nozbe/watermelondb/Model';
import City from './City';
import Lesson from './Lesson';
import Neighborhood from './Neighborhood';

export default class Place extends Model {
  static table = 'places';

  static associations: Associations = {
    lessons: { type: 'has_many', foreignKey: 'place' },
    neighborhoods: { type: 'belongs_to', key: 'neighborhood' },
    cities: { type: 'belongs_to', key: 'city' },
  };

  @children('lessons') lessons!: Query<Lesson>;

  @relation('cities', 'city') city_obj!: Relation<City>;
  @relation('neighborhoods', 'neighborhood')
  neighborhood_obj!: Relation<Neighborhood>;

  @field('neighborhood') neighborhood!: string;
  @field('name') name!: string;
  @field('address') address!: string;
  @field('latitude') latitude!: string;
  @field('longitude') longitude!: string;
  @field('is_station') is_station!: boolean;
  @field('searchable') searchable!: boolean;
  @field('city') city!: string;
  @field('published') published!: boolean;
}
