import { appSchema, tableSchema } from '@nozbe/watermelondb';

// As propriedades criadas com o prefixo `__` (dois underlines)
// são usadas apenas para referências internas do nosso app e
// estão fora das implementações padrões do WatermelonDB

export default appSchema({
  version: 27,
  tables: [
    tableSchema({
      name: 'addresses',
      columns: [
        { name: 'name', type: 'string' },
        { name: 'country', type: 'string' },
        { name: 'postcode', type: 'string' },
        { name: 'street_name', type: 'string' },
        { name: 'street_number', type: 'string' },
        { name: 'street_complement', type: 'string' },
        { name: 'state', type: 'string' },
        { name: 'city', type: 'string' },
        { name: 'neighborhood', type: 'string' },
        { name: 'customer_id', type: 'string', isIndexed: true },
      ],
    }),
    tableSchema({
      name: 'cities',
      columns: [
        { name: 'name', type: 'string' },
        { name: 'state', type: 'string' },
        { name: 'latitude', type: 'string' },
        { name: 'longitude', type: 'string' },
        { name: 'image', type: 'string' },
        { name: 'min_time_subscription', type: 'number' },
        { name: 'timezone', type: 'string' },
        { name: 'lessons_enabled', type: 'boolean', isIndexed: true },
        { name: 'country', type: 'string' },
        { name: 'is_virtual', type: 'boolean', isIndexed: true },
      ],
    }),
    tableSchema({
      name: 'customers',
      columns: [
        { name: 'user', type: 'string', __isJson: true },
        { name: 'nickname', type: 'string' },
        { name: 'cpf', type: 'string' },
        { name: 'passport', type: 'string' },
        { name: 'is_foreigner', type: 'boolean' },
        { name: 'phone_country_code', type: 'string' },
        { name: 'phone', type: 'string' },
        { name: 'height', type: 'string' },
        { name: 'weight', type: 'string' },
        { name: 'anamnesis_status', type: 'string', __isJson: true },
        { name: 'available_subscriptions', type: 'number' },
        { name: 'subscriptions_limit', type: 'number' },
        { name: 'gender', type: 'string' },
        { name: 'subscriptions_time_limit', type: 'number' },
        { name: 'lessons_skipped', type: 'number' },
        { name: 'birth_date', type: 'string' },
        { name: 'picture', type: 'string' },
        { name: 'medical_certificate_check', type: 'string' },
        { name: 'medical_certificate_trainer', type: 'string' },
        { name: 'sponsors', type: 'string', __isForeignKeyArray: true },
      ],
    }),
    tableSchema({
      name: 'customer_settings',
      columns: [
        { name: 'language', type: 'string' },
        { name: 'default_city', type: 'string' },
        { name: 'new_highlight_lesson_push', type: 'boolean' },
        { name: 'new_highlight_lesson_email', type: 'boolean' },
        { name: 'new_event_push', type: 'boolean' },
        { name: 'new_event_email', type: 'boolean' },
        { name: 'canceled_lesson_push', type: 'boolean' },
        { name: 'canceled_lesson_email', type: 'boolean' },
        { name: 'checkin_available_push', type: 'boolean' },
        { name: 'checkin_available_email', type: 'boolean' },
        { name: 'review_available_push', type: 'boolean' },
        { name: 'review_available_email', type: 'boolean' },
        { name: 'open_vacancy_push', type: 'boolean' },
        { name: 'open_vacancy_email', type: 'boolean' },
        { name: 'payment_push', type: 'boolean' },
        { name: 'custom_push', type: 'boolean' },
      ],
    }),
    tableSchema({
      name: 'emergency_contacts',
      columns: [
        { name: 'name', type: 'string' },
        { name: 'phone_country_code', type: 'string' },
        { name: 'phone', type: 'string' },
        { name: 'email', type: 'string' },
        { name: 'relationship', type: 'string' },
        { name: 'customer_id', type: 'string', isIndexed: true },
      ],
    }),
    tableSchema({
      name: 'enrollments',
      columns: [
        { name: 'queued', type: 'boolean' },
        { name: 'queue_enter_time', type: 'string' },
        { name: 'queue_leave_time', type: 'string' },
        { name: 'last_queue_notification', type: 'string' },
        { name: 'registered', type: 'boolean', isIndexed: true },
        { name: 'registered_time', type: 'string' },
        { name: 'unregistered_time', type: 'string' },
        { name: 'checked_in', type: 'boolean' },
        { name: 'checkin_time', type: 'string' },
        { name: 'trainer_checked', type: 'boolean' },
        { name: 'trainer_checkin_time', type: 'string' },
        { name: 'rating', type: 'number' },
        { name: 'comment', type: 'string' },
        { name: 'rating_time', type: 'string' },
        { name: 'medical_certificate_check', type: 'boolean' },
        { name: 'customer', type: 'string', isIndexed: true },
        { name: 'lesson', type: 'string', isIndexed: true },
        { name: 'show_in_history', type: 'boolean' },
        { name: 'reserved_spot', type: 'string' },
        { name: 'spot_reserve_time', type: 'string' },
      ],
    }),
    tableSchema({
      name: 'events',
      columns: [
        { name: 'name', type: 'string' },
        { name: 'description', type: 'string' },
        { name: 'start_date', type: 'string' },
        { name: 'end_date', type: 'string' },
        { name: 'hero_image', type: 'string' },
        { name: 'public_visibility', type: 'boolean' },
        { name: 'period_description', type: 'string' },
        { name: 'sponsor', type: 'string' },
        { name: 'sponsor_page', type: 'string' },
        { name: 'about_images', type: 'string', __isJson: true },
      ],
    }),
    tableSchema({
      name: 'exercises',
      columns: [
        { name: 'name', type: 'string' },
        { name: 'image', type: 'string' },
        { name: 'video', type: 'string' },
        { name: 'video_sizes', type: 'string', __isJson: true },
        { name: 'video_cached', type: 'string' },
        { name: 'audio', type: 'string' },
        { name: 'level', type: 'number' },
        { name: 'modalities', type: 'string', __isForeignKeyArray: true },
        { name: 'requirements', type: 'string', __isForeignKeyArray: true },
        { name: 'instructions', type: 'string', __isJson: true },
        { name: 'stream_url', type: 'string' },
      ],
    }),
    tableSchema({
      name: 'exercise_modalities',
      columns: [{ name: 'name', type: 'string' }],
    }),
    tableSchema({
      name: 'exercise_requirements',
      columns: [
        { name: 'name', type: 'string' },
        { name: 'type', type: 'string' },
      ],
    }),
    tableSchema({
      name: 'lesson_groups',
      columns: [
        { name: 'images', type: 'string', __isJson: true },
        { name: 'name', type: 'string' },
        { name: 'description', type: 'string' },
        { name: 'requires_anamnesis', type: 'boolean' },
        { name: 'public_visibility', type: 'boolean' },
        { name: 'public_subscription', type: 'boolean' },
        { name: 'allow_children', type: 'boolean' },
        { name: 'start_time', type: 'string' },
        { name: 'end_time', type: 'string' },
        { name: 'start_date', type: 'string' },
        { name: 'end_date', type: 'string' },
        { name: 'total_vacancies', type: 'number' },
        { name: 'recurrence_text', type: 'string' },
        { name: 'recurrence_monday', type: 'boolean' },
        { name: 'recurrence_tuesday', type: 'boolean' },
        { name: 'recurrence_wednesday', type: 'boolean' },
        { name: 'recurrence_thursday', type: 'boolean' },
        { name: 'recurrence_friday', type: 'boolean' },
        { name: 'recurrence_saturday', type: 'boolean' },
        { name: 'recurrence_sunday', type: 'boolean' },
        { name: 'trainer', type: 'string' },
        { name: 'modality', type: 'string' },
        { name: 'place', type: 'string' },
        { name: 'sponsor', type: 'string' },
        { name: 'product', type: 'string' },
      ],
    }),
    tableSchema({
      name: 'lessons',
      columns: [
        { name: 'events', type: 'string', __isForeignKeyArray: true },
        { name: 'modalities', type: 'string', __isForeignKeyArray: true },
        { name: 'name', type: 'string' },
        { name: 'start_datetime', type: 'string' },
        { name: 'end_datetime', type: 'string' },
        { name: 'total_vacancies', type: 'number' },
        { name: 'available_vacancies', type: 'number' },
        { name: 'public_visibility', type: 'boolean' },
        { name: 'public_subscription', type: 'boolean' },
        { name: 'highlight_image', type: 'string' },
        { name: 'status', type: 'string' },
        { name: 'cancellation_reason', type: 'string' },
        { name: 'cancellation_visibility', type: 'boolean' },
        { name: 'requires_anamnesis', type: 'boolean' },
        { name: 'description', type: 'string' },
        { name: 'city_hall_approved', type: 'boolean' },
        { name: 'allow_children', type: 'boolean' },
        { name: 'subscription_time_limit', type: 'number' },
        { name: 'created_by', type: 'string' },
        { name: 'trainer', type: 'string', isIndexed: true },
        { name: 'substitute_trainer', type: 'string' },
        { name: 'sponsor', type: 'string' },
        { name: 'place', type: 'string', isIndexed: true },
        { name: 'modality', type: 'string', isIndexed: true },
        { name: 'lesson_group', type: 'string' },
        { name: 'cost_mode', type: 'string' },
        { name: 'is_virtual', type: 'boolean' },
        { name: 'live_id', type: 'string' },
        { name: 'has_reserved_spots', type: 'boolean' },
        { name: 'reservable_spots', type: 'string', __isJson: true },
        { name: 'available_spots', type: 'string', __isJson: true },
      ],
    }),
    tableSchema({
      name: 'modalities',
      columns: [
        { name: 'about_images', type: 'string', __isJson: true },
        { name: 'name', type: 'string' },
        { name: 'image', type: 'string' },
        { name: 'square_image', type: 'string' },
        { name: 'horizontal_image', type: 'string' },
        { name: 'lesson_image', type: 'string' },
        { name: 'about_title', type: 'string' },
        { name: 'about_text', type: 'string' },
        { name: 'searchable', type: 'boolean' },
      ],
    }),
    tableSchema({
      name: 'neighborhoods',
      columns: [
        { name: 'name', type: 'string' },
        { name: 'latitude', type: 'string' },
        { name: 'longitude', type: 'string' },
        { name: 'city', type: 'string' },
      ],
    }),
    tableSchema({
      name: 'places',
      columns: [
        { name: 'neighborhood', type: 'string' },
        { name: 'name', type: 'string' },
        { name: 'address', type: 'string' },
        { name: 'latitude', type: 'string' },
        { name: 'longitude', type: 'string' },
        { name: 'is_station', type: 'boolean' },
        { name: 'searchable', type: 'boolean' },
        { name: 'city', type: 'string' },
      ],
    }),
    tableSchema({
      name: 'products',
      columns: [
        { name: 'plans', type: 'string', __isJson: true },
        { name: 'name', type: 'string' },
        { name: 'cost_mode', type: 'string' },
        { name: 'provider', type: 'string', __isJson: true },
      ],
    }),
    tableSchema({
      name: 'requests',
      columns: [{ name: 'response', type: 'string', __isJson: true }],
    }),
    tableSchema({
      name: 'sponsors',
      columns: [
        { name: 'name', type: 'string' },
        { name: 'main_color', type: 'string' },
        { name: 'text_color', type: 'string' },
        { name: 'transparency_logo', type: 'string' },
        { name: 'ad_image', type: 'string' },
        { name: 'page_image', type: 'string' },
        { name: 'page_text', type: 'string' },
        { name: 'main_url', type: 'string' },
        { name: 'calltoaction_url', type: 'string' },
        { name: 'calltoaction_text', type: 'string' },
        { name: 'order', type: 'number' },
        { name: 'gender', type: 'string' },
        { name: 'published', type: 'boolean' },
      ],
    }),
    tableSchema({
      name: 'sponsor_groups',
      columns: [
        { name: 'name', type: 'string' },
        { name: 'sponsor', type: 'string' },
        { name: 'is_member', type: 'boolean' },
      ],
    }),
    tableSchema({
      name: 'trainers',
      columns: [
        { name: 'name', type: 'string' },
        { name: 'slug', type: 'string' },
        { name: 'description', type: 'string' },
        { name: 'cref', type: 'string' },
        { name: 'picture', type: 'string' },
        { name: 'instagram', type: 'string' },
        { name: 'institution', type: 'string' },
        { name: 'approved', type: 'boolean', isIndexed: true },
        { name: 'gender', type: 'string' },
        { name: 'expertise', type: 'string', __isForeignKeyArray: true },
        { name: 'images', type: 'string', __isJson: true },
        { name: 'product', type: 'string' },
      ],
    }),
    tableSchema({
      name: 'users',
      columns: [
        { name: 'customer_id', type: 'string' },
        { name: 'trainer_id', type: 'string' },
        { name: 'email', type: 'string' },
        { name: 'first_name', type: 'string' },
        { name: 'last_name', type: 'string' },
        { name: 'is_staff', type: 'boolean' },
        { name: 'default_city', type: 'string' },
        { name: 'language', type: 'string' },
      ],
    }),
    tableSchema({
      name: 'workouts',
      columns: [
        { name: 'parts', type: 'string', __isJson: true },
        { name: 'modalities', type: 'string', __isForeignKeyArray: true },
        { name: 'equipments', type: 'string', __isForeignKeyArray: true },
        { name: 'name', type: 'string' },
        { name: 'image', type: 'string' },
        { name: 'description', type: 'string' },
        { name: 'description_image', type: 'string' },
        { name: 'good_for', type: 'string' },
        { name: 'duration', type: 'number' },
        { name: 'level', type: 'number' },
        { name: 'cost_mode', type: 'string' },
        { name: 'is_recorded', type: 'boolean' },
        { name: 'start_audio', type: 'string' },
        { name: 'end_audio', type: 'string' },
        { name: 'trainer', type: 'string' },
        { name: 'product', type: 'string' },
        { name: 'sponsor', type: 'string' },
      ],
    }),
    tableSchema({
      name: 'workout_collections',
      columns: [
        { name: 'content', type: 'string', __isJson: true },
        { name: 'abouts', type: 'string', __isJson: true },
        { name: 'name', type: 'string' },
        { name: 'image', type: 'string' },
        { name: 'library_image', type: 'string' },
        { name: 'thumb_about', type: 'string' },
        { name: 'video_about', type: 'string' },
        { name: 'text_about', type: 'string' },
        { name: 'sponsor', type: 'string' },
        { name: 'sponsor_url', type: 'string' },
      ],
    }),
    tableSchema({
      name: 'workout_consumptions',
      columns: [
        { name: 'start_time', type: 'string' },
        { name: 'end_time', type: 'string' },
        { name: 'completed', type: 'boolean' },
        { name: 'workout', type: 'string' },
        { name: 'last_part', type: 'string' },
        { name: 'last_item', type: 'string' },
        { name: 'rating', type: 'number' },
        { name: 'show_in_history', type: 'boolean' },
      ],
    }),
  ],
});
