import React, { useCallback, useState } from 'react';
import {
  Platform,
  StyleSheet,
  Text,
  TouchableWithoutFeedback,
  View,
} from 'react-native';
import moment from 'moment-timezone';
import Theme from '../libs/Theme';
import AlertService from '../libs/AlertService';
import DataService, {
  withObservables,
  withMapProps,
} from '../libs/DataService';
import { resolveError } from '../libs/FormUtils';
import { getRatingEmoji, getRatingLabel } from '../libs/LessonUtils';
import NavigationService from '../libs/NavigationService';
import CheckIcon from '../icons/CheckIcon';
import MMudeIcon from '../icons/MMudeIcon';
import StarIcon from '../icons/StarIcon';
import withNavParams from '../components/withNavParams';
import CloseButton from '../components/CloseButton';
import LessonEntry from '../components/LessonEntry';
import I18n from '../components/I18n';
import AsyncButton from '../components/AsyncButton';

function ReviewScreen_StarButton({ value, selected, onPress }) {
  const _onPress = useCallback(() => {
    onPress(value);
  }, [value, onPress]);

  return (
    <TouchableWithoutFeedback onPress={_onPress}>
      <View>
        <StarIcon
          color={selected ? Theme.color.magenta : Theme.color.veryLightGray}
          width={34}
          height={34}
          filled
        />
      </View>
    </TouchableWithoutFeedback>
  );
}

function ReviewScreen(props) {
  const { name, lesson, onSubmit } = props;
  const [rating, setRating] = useState(props.intialRating);

  const onPressStar = useCallback(value => {
    setRating(value);
  }, []);

  const _onSubmit = useCallback(async () => {
    try {
      await onSubmit(rating);
      setTimeout(() => NavigationService.goBack(), 2000);

      const isNative = Platform.select({ native: true });
      if (isNative && rating === 5) {
        const appFiveStarSeen = await DataService.getLocal('appFiveStarSeen');
        if (!appFiveStarSeen) {
          DataService.setLocal('appFiveStarSeen', moment().format());
          AlertService.show({
            title: 'appFiveStarTitle',
            message: 'appFiveStarMessage',
            confirmText: 'appFiveStarConfirm',
            dismissText: 'appFiveStarDismiss',
            onConfirm: () => {
              NavigationService.openUrl(
                Platform.OS === 'ios'
                  ? 'https://apps.apple.com/br/app/mude-fit/id1444152499'
                  : 'https://play.google.com/store/apps/details?id=fit.mude.app',
              );
            },
          });
        }
      }
    } catch (error) {
      resolveError(error, true);
    }
  }, [rating, onSubmit]);

  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <View style={styles.logo}>
          <MMudeIcon />
        </View>
        {!!lesson && (
          <>
            <View style={styles.title}>
              <Text style={styles.titleText}>
                <I18n>classDone</I18n>
              </Text>
            </View>
            <LessonEntry lesson={lesson} withDate />
          </>
        )}
        <CloseButton
          color={Theme.color.veryDarkGray}
          style={styles.closeButton}
        />
      </View>
      <View style={styles.body}>
        <Text style={[styles.bodyText, !rating && styles.big]}>
          <I18n i18nKey="howWasTheClass" params={{ activity: name }}>
            <Text style={styles.bold} />
          </I18n>
        </Text>
        {!!rating && (
          <View>
            <Text style={styles.ratingText}>
              {getRatingEmoji(rating)}
              {'\n'}
              <I18n>{getRatingLabel(rating)}</I18n>
            </Text>
          </View>
        )}
      </View>
      <View style={styles.footer}>
        <View style={styles.stars}>
          {[1, 2, 3, 4, 5].map(value => (
            <ReviewScreen_StarButton
              key={value}
              value={value}
              selected={value <= rating}
              onPress={onPressStar}
            />
          ))}
        </View>
        <AsyncButton
          style={[styles.submit, !rating && styles.disabled]}
          onPress={!!rating && _onSubmit}
          showSuccessIcon
        >
          <CheckIcon
            color={!rating ? Theme.color.veryLightGray : Theme.color.white}
          />
        </AsyncButton>
      </View>
    </View>
  );
}

function ReviewScreen_Lesson(props) {
  const { lesson, enrollment } = props;

  const enrollmentId = enrollment?.id;
  const onSubmit = useCallback(
    rating =>
      DataService.updateEnrollment({
        id: enrollmentId,
        rating,
        comment: '',
      }),
    [enrollmentId],
  );

  return (
    <ReviewScreen
      {...props}
      intialRating={enrollment?.rating}
      name={lesson?.name}
      onSubmit={onSubmit}
    />
  );
}

const lesson_enhance3 = withObservables(['lesson'], ({ lesson }) => ({
  lesson: DataService.observeLesson(lesson),
}));

const lesson_enhance2 = withMapProps(({ enrollment }) => ({
  lesson: enrollment?.lesson,
}));

const lesson_enhance = withObservables(['enrollment'], ({ enrollment }) => ({
  enrollment: DataService.observeEnrollment(enrollment),
}));

const ReviewScreen_EnhancedLesson = lesson_enhance(
  lesson_enhance2(lesson_enhance3(ReviewScreen_Lesson)),
);

function ReviewScreen_Workout(props) {
  const { workout, workoutConsumption } = props;

  const workoutConsumptionId = workoutConsumption?.id;
  const onSubmit = useCallback(
    rating =>
      DataService.updateWorkoutConsumption({
        id: workoutConsumptionId,
        rating,
      }),
    [workoutConsumptionId],
  );

  return <ReviewScreen {...props} name={workout?.name} onSubmit={onSubmit} />;
}

const workout_enhance3 = withObservables(['workout'], ({ workout }) => ({
  workout: DataService.observeWorkout(workout),
}));

const workout_enhance2 = withMapProps(({ workoutConsumption }) => ({
  workout: workoutConsumption?.workout,
}));

const workout_enhance = withObservables(
  ['workoutConsumption'],
  ({ workoutConsumption }) => ({
    workoutConsumption: DataService.observeWorkoutConsumption(
      workoutConsumption,
    ),
  }),
);

const ReviewScreen_EnhancedWorkout = workout_enhance(
  workout_enhance2(workout_enhance3(ReviewScreen_Workout)),
);

function ReviewScreen_Switch({ enrollment, workoutConsumption }) {
  if (enrollment) {
    return <ReviewScreen_EnhancedLesson enrollment={enrollment} />;
  }
  if (workoutConsumption) {
    return (
      <ReviewScreen_EnhancedWorkout workoutConsumption={workoutConsumption} />
    );
  }
  return null;
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Theme.color.white,
  },
  header: {
    marginTop: Theme.dimensions.topSpace,
    paddingHorizontal: 20,
  },
  logo: {
    height: Theme.dimensions.headerHeight,
    justifyContent: 'center',
  },
  title: {
    paddingBottom: 20,
  },
  titleText: {
    color: Theme.color.veryDarkGray,
    fontFamily: Theme.fonts.barlowBold,
    fontSize: 20,
  },
  closeButton: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  body: {
    flexGrow: 1,
    flexShrink: 1,
    justifyContent: 'center',
    padding: 20,
    paddingBottom: 40,
  },
  bodyText: {
    color: Theme.color.veryDarkGray,
    fontFamily: Theme.fonts.barlow,
    fontSize: 18,
    maxWidth: 300,
  },
  big: {
    fontSize: 36,
  },
  bold: {
    fontFamily: Theme.fonts.barlowSemiBold,
  },
  ratingText: {
    color: Theme.color.veryDarkGray,
    fontFamily: Theme.fonts.barlowBold,
    fontSize: 36,
    marginTop: 30,
  },
  footer: {
    height: 80 + Theme.dimensions.bottomSpace,
    flexDirection: 'row',
  },
  stars: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexGrow: 1,
    flexShrink: 1,
    paddingHorizontal: 20,
    paddingBottom: Theme.dimensions.bottomSpace,
    borderColor: Theme.color.veryLightGray,
    borderTopWidth: 1,
  },
  submit: {
    width: 80,
    paddingBottom: Theme.dimensions.bottomSpace,
  },
  disabled: {
    backgroundColor: Theme.color.white,
    borderColor: Theme.color.veryLightGray,
    borderTopWidth: 1,
    borderLeftWidth: 1,
  },
});

export default withNavParams(ReviewScreen_Switch);
