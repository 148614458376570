import React, { useCallback } from 'react';
import { StyleSheet, Text, TouchableWithoutFeedback, View } from 'react-native';
import NavigationService from '../libs/NavigationService';
import Theme from '../libs/Theme';
import I18n from './I18n';
import ResizableImage from './ResizableImage';

export default function SponsorCard({ sponsor }) {
  const onPressCta = useCallback(() => {
    NavigationService.openUrl(sponsor?.calltoaction_url);
  }, [sponsor]);

  if (!sponsor) {
    return null;
  }

  return (
    <View style={styles.container}>
      <Text style={styles.title}>
        <I18n>{sponsor.name}</I18n>
      </Text>
      <Text style={styles.text}>
        <I18n params={{ sponsor: sponsor.name }}>lessonSponsoredBy</I18n>
      </Text>
      <TouchableWithoutFeedback onPress={onPressCta}>
        <View style={[styles.cta, { backgroundColor: sponsor.main_color }]}>
          <ResizableImage
            style={styles.logo}
            source={{ uri: sponsor.transparency_logo || '' }}
            fitVertical
          />
          <Text style={styles.ctaText}>{sponsor.calltoaction_text}</Text>
        </View>
      </TouchableWithoutFeedback>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: Theme.color.white,
    borderRadius: 10,
    paddingHorizontal: 20,
  },
  title: {
    color: Theme.color.veryDarkGray,
    fontFamily: Theme.fonts.barlowSemiCondensedSemiBold,
    fontSize: 18,
    marginVertical: 20,
  },
  text: {
    color: Theme.color.darkGray,
    fontFamily: Theme.fonts.barlow,
    fontSize: 16,
  },
  cta: {
    height: 50,
    borderRadius: 4,
    marginVertical: 20,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 15,
  },
  ctaText: {
    color: Theme.color.white,
    fontFamily: Theme.fonts.barlowBold,
    fontSize: 12,
    textTransform: 'uppercase',
  },
  logo: {
    height: 35,
    width: 0,
  },
});
