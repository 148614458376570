import { Model, Relation } from '@nozbe/watermelondb';
import { field, json, relation } from '@nozbe/watermelondb/decorators';
import { Associations } from '@nozbe/watermelondb/Model';
import Sponsor from './Sponsor';

export default class Event extends Model {
  static table = 'events';

  static associations: Associations = {
    sponsors: { type: 'belongs_to', key: 'sponsor' },
  };

  @relation('sponsors', 'sponsor') sponsor_obj!: Relation<Sponsor>;

  @field('name') name!: string;
  @field('description') description!: string;
  @field('start_date') start_date!: string;
  @field('end_date') end_date!: string;
  @field('hero_image') hero_image!: string;
  @field('public_visibility') public_visibility!: boolean;
  @field('period_description') period_description!: string;
  @field('sponsor') sponsor!: string;
  @field('sponsor_page') sponsor_page!: string;
  @json('about_images', _json => _json || []) about_images!: string[];
}
