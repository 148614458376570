import React, { useCallback } from 'react';
import { TouchableWithoutFeedback, View, StyleSheet, Text } from 'react-native';
import FastImage from 'react-native-fast-image';
import DataService, {
  withObservables,
  withMapProps,
} from '../libs/DataService';
import { formatDuration } from '../libs/DIYUtils';
import Theme from '../libs/Theme';
import NavigationService from '../libs/NavigationService';

export const WIDTH = (Theme.dimensions.windowWidth - 50) / 2;

function WorkoutCard({ id, workout, modality }) {
  const onPress = useCallback(() => {
    NavigationService.navigate('Workout', { id });
  }, [id]);

  if (!workout) {
    return null;
  }

  const duration = formatDuration(workout.duration * 60);
  const _modality = modality?.name;

  return (
    <TouchableWithoutFeedback onPress={onPress}>
      <View style={styles.container}>
        <FastImage style={styles.image} source={{ uri: workout.image || '' }} />
        <Text style={styles.title} numberOfLines={2}>
          {workout.name}
        </Text>
        <Text style={styles.details}>
          {[duration, _modality].filter(s => s).join(' • ')}
        </Text>
      </View>
    </TouchableWithoutFeedback>
  );
}

const styles = StyleSheet.create({
  container: {
    width: WIDTH,
    backgroundColor: Theme.color.white,
    overflow: 'hidden',
    borderRadius: 10,
  },
  image: {
    width: WIDTH,
    height: WIDTH * (160 / 155),
    backgroundColor: Theme.color.veryLightGray,
  },
  title: {
    color: Theme.color.veryDarkGray,
    fontFamily: Theme.fonts.barlowSemiBold,
    fontSize: 15,
    lineHeight: 18,
    height: 18 * 2,
    marginTop: 10,
    marginHorizontal: 10,
  },
  details: {
    color: Theme.color.gray,
    fontFamily: Theme.fonts.barlowSemiBold,
    fontSize: 12,
    lineHeight: 14,
    margin: 10,
  },
});

const enhance3 = withObservables(['modality'], ({ modality }) => ({
  modality: DataService.observeModality(modality),
}));

const enhance2 = withMapProps(({ workout }) => ({
  modality: workout?.modalities?.[0],
}));

const enhance = withObservables(['id'], ({ id }) => ({
  workout: DataService.observeWorkout(id),
}));

export default enhance(enhance2(enhance3(WorkoutCard)));
