export default {
  exerciseLibraryEmpty: 'Exercises not yet registered.',

  // Levels
  beginner: 'Beginner',
  intermediate: 'Intermediate',
  advanced: 'Advanced',

  // Library
  library: 'Library',

  // Exercise
  exerciseNotAvailable: 'Exercise not available.',
  videoNotAvailable: 'Video not available.',

  // ExerciseInstructionsScreen
  preparation: 'Preparação', // backend
  execution: 'Execução', // backend
  attention: 'Atenção', // backend

  // Collection
  collectionNotAvailable: 'Collection not available.',
  aboutTheCollection: 'About the collection',
  chooseAWorkout: 'Choose a workout',
  exercisesLibrary: 'Complete library',
  exercisesLibraryDetail: 'See everything we have prepared for you',
  exercisesLibraryOf: 'Library {{name}}',
  collectionsSponsor: "Collection's Sponsor",
  exercise: 'exercise',
  exercise_plural: 'exercises',
  workout: 'workout',
  workouts: 'workouts',
  workout_plural: 'workouts',
  totalWorkouts: '{{count}} workout',
  totalWorkouts_plural: '{{count}} workouts',
  collection: 'collection',
  allLevels: 'All levels',
  by: 'by',

  // Workout
  start: 'Start',
  start_practice: 'Start practice',
  start_workout: 'Start workout',
  equipments: 'Equipment',
  withoutEquipment: 'Not required',
  whereToDo: 'Where?',
  anyPlace: 'Anywhere',
  goodFor: 'Good for',
  level: 'Level',
  createdBy: 'Created by',
  createdBy_practice: 'Practice created by',
  createdBy_workout: 'Workout created by',
  seconds: 'seconds',
  repetitions: 'Repetition',
  mediaDownloading: 'Loading {{count}} of {{total}} exercises',
  mediaDownloadReady: 'Workout ready! Press the \narrow when you are ready.',
  getReady: 'Get ready!',
  go: 'Go!',
  serieCounter: 'Serie {{count}} of {{total}}',
  stepCounter: '{{count}} of {{total}}',
  crono: 'Crono',
  volume: 'Volume',
  loadWeight: 'Load / Weight',
  instructions: 'Instructions',
  workoutPaused: 'Workout paused.',
  continueWorkout: 'Continue workout',
  exitWorkout: 'Exit workout',
  resetWorkout: 'Reset workout',
  takeARest: 'Rest for',
  regainYourEnergies: 'Regain \nyour energies',
  restingIsPartOfTraining: 'Resting is\npart of training',
  workoutDone: 'Workout done!',
  playTime: 'Workout',
  pauseTime: 'Paused',
  totalCrono: 'Total Crono',
  saveWorkout: 'Save workout?',
  chooseYourLoad: 'Choose your load',
  loadTooltipTitle: 'Write down your load',
  loadTooltipText: 'This allows you to track your progress in training.',
  loadTooltipButtonText: 'Ok, I got it',
  myList: 'My list',

  // Profile
  continue: 'continue',
  workoutInProgress: 'In Progress Workout',
  youHaventFinishedAnyWorkoutYet: "You haven't finished any workout :(",

  // Settings
  videoQuality: 'Video quality',
};
