import React from 'react';
import axios from 'axios';
import styles from './WorkoutScreen.module.scss';
import { API_URL } from '../libs/Env';
import { openApp } from '../libs/Utils';
import { humanizeLevel, getWorkoutExercises } from '../libs/DIYUtils';
import withNavParams from '../components/withNavParams';
import LayoutWithHeader from '../components/LayoutWithHeader';

class WorkoutScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      error: '',
      workout: null,
    };
  }

  async componentDidMount() {
    try {
      const result = await axios.get(`${API_URL}/workout/${this.props.id}/`);
      this.setState({ workout: result.data });
    } catch (error) {
      this.setState({ error: error.message });
    }

    this.setState({ loading: false });
  }

  render() {
    const { loading, error, workout } = this.state;

    if (!workout) {
      return <LayoutWithHeader loading={loading} error={error} />;
    }

    const workoutExercises = getWorkoutExercises(workout);

    return (
      <LayoutWithHeader loading={loading} error={error}>
        <div className={styles.container}>
          <a
            className={styles.card}
            href="https://onelink.to/sharetreino"
            onClick={openApp}
          >
            <div
              className={styles.header}
              style={{ backgroundImage: `url(${workout.image})` }}
            >
              <div className={styles.name}>{workout.name}</div>
              <div className={styles.details}>
                {workoutExercises.length}{' '}
                {workoutExercises.length > 1 ? 'exercícios' : 'exercício'}
                {!!workout.level && <> • {humanizeLevel(workout.level)}</>}
              </div>
            </div>
            <div className={styles.footer}>
              <div className={styles.status}>Saiba mais</div>
              <div className={styles.button}>Baixar app</div>
            </div>
          </a>
        </div>
      </LayoutWithHeader>
    );
  }
}

export default withNavParams(WorkoutScreen);
