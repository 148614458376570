import React from 'react';
import { Image } from 'react-native';

const FastImage = React.forwardRef(function (props, ref) {
  return <Image ref={ref} {...props} />;
});

FastImage.resizeMode = {
  contain: 'contain',
  cover: 'cover',
  stretch: 'stretch',
  center: 'center',
};

export default FastImage;
