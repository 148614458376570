import React, { useCallback } from 'react';
import { View } from 'react-native';
import Theme from '../../libs/Theme';
import { defaultStyles } from './styles';

export default function RNPickerSelect({
  items,
  value,
  onValueChange,
  disabled,
  style,
}) {
  const onChange = useCallback(
    e => {
      onValueChange?.(e.target.value);
    },
    [onValueChange],
  );

  return (
    <View style={[defaultStyles.viewContainer, style.viewContainer]}>
      <select
        value={value || ''}
        onChange={onChange}
        style={{
          backgroundColor: 'transparent',
          borderWidth: 0,
          padding: 5,
          color: Theme.color.veryDarkGray,
          fontFamily: Theme.fonts.barlow,
          fontSize: 18,
        }}
        disabled={disabled}
      >
        {items?.map(item => (
          <option key={item.value} value={item.value}>
            {item.label}
          </option>
        ))}
      </select>
    </View>
  );
}

export { defaultStyles };
