import React from 'react';
import Svg, { Path } from 'react-native-svg';
import Theme from '../libs/Theme';

MMudeIcon.defaultProps = {
  color: Theme.color.magenta,
};

export default function MMudeIcon(props) {
  return (
    <Svg width={39} height={23} viewBox="0 0 39 23" {...props}>
      <Path
        fill={props.color}
        d="M31.5 15.6c0 .3-.2.5-.5.5s-.5-.2-.5-.5V7.4c0-3.9-3.2-7.1-7.1-7.1-3.9 0-7.1 3.2-7.1 7.1v8.1c0 .3-.2.5-.5.5h-.1c-.2 0-.4-.3-.4-.5V7.4c0-3.9-3.2-7.1-7.1-7.1S.9 3.5.9 7.4v14.4h6.6V7.4c0-.3.2-.5.5-.5s.5.2.5.5v8.1c0 3.9 3.2 7.1 7.1 7.1.7 0 1.5-.1 2.1-.3.2-.1.4-.1.7-.2.1 0 .2-.1.3-.1 2.4-1.1 4-3.6 4-6.4V7.4c0-.3.2-.5.5-.5s.5.2.5.5v8.1c0 3.9 3.2 7.1 7.1 7.1 3.9 0 7.1-3.2 7.1-7.1V1.2h-6.6v14.4z"
        fillRule="evenodd"
        clipRule="evenodd"
      />
    </Svg>
  );
}
