import React from 'react';
import Svg, { Path } from 'react-native-svg';

export default function LiveIcon(props) {
  return (
    <Svg width={22} height={22} viewBox="0 0 22 22" {...props}>
      <Path
        fill={props.color}
        d="M3.9 4L5 5.1C3.4 6.7 2.5 8.8 2.5 11s.9 4.4 2.5 6l-1.1 1.1c-1.8-1.8-2.9-4.4-2.9-7S2.1 5.9 3.9 4zm14.2 0C20 5.9 21 8.4 21 11.1s-1.1 5.2-2.9 7.1L17 17c1.6-1.6 2.5-3.7 2.5-6s-.9-4.4-2.5-6l1.1-1zM6 6l1 1.1c-1 1-1.6 2.5-1.6 3.9 0 1.5.6 3 1.6 4l-1 1.2c-1.4-1.3-2.1-3.2-2.1-5.1C3.8 9.2 4.6 7.3 6 6zm10 0c1.4 1.3 2.1 3.2 2.1 5.1 0 1.9-.7 3.7-2.1 5.1L15 15c1-1 1.6-2.5 1.6-3.9 0-1.5-.5-2.9-1.6-3.9L16 6zm-5 2.5c1.4 0 2.5 1.1 2.5 2.5s-1.1 2.5-2.5 2.5-2.5-1.1-2.5-2.5S9.6 8.5 11 8.5zm0 1.6c-.5 0-1 .4-1 1 0 .5.4 1 1 1 .5 0 1-.4 1-1s-.5-1-1-1z"
      />
    </Svg>
  );
}
