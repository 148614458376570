export default {
  about: 'Sobre',
  birthDate: 'Data de nascimento',
  cancel: 'Cancelar',
  change: 'Trocar',
  city: 'Cidade',
  close: 'Fechar',
  confirmPassword: 'Confirmar senha',
  country: 'País',
  cpf: 'CPF',
  createYourPassword: 'Pronto! Agora é só criar a sua senha!',
  currentPassword: 'Senha atual',
  ddi: 'DDI',
  document: 'Documento',
  dontKnowZipcode: 'Não sei meu CEP',
  email: 'E-mail',
  emailAddress: 'Endereço de E-mail',
  event: 'Evento',
  exit: 'Sair',
  genderYouIdentify: 'Qual gênero se identifica?',
  goToWebsite: 'Visite o site',
  height: 'Altura',
  heightM: 'Altura (m)',
  weight: 'Peso',
  weightKg: 'Peso (kg)',
  IAmOnTheWay: 'Estou a caminho!',
  language: 'Idioma',
  lesson: 'Aula',
  lessons: 'Aulas',
  letsGo: 'Vamos lá!',
  levelOfKinship: 'Grau de parentesco:',
  modalities: 'Modalidades',
  modality: 'Modalidade',
  name: 'Nome',
  neighborhood: 'Bairro',
  street: 'Rua',
  street_number: 'Número',
  complement: 'Complemento',
  newPassword: 'Nova senha',
  nextLives: 'Próximas Lives',
  nextLessons: 'Próximas Aulas',
  nextLessonsIn: 'Próximas Aulas em:',
  schedulesAndEvents: 'Programações e eventos',
  nextStep: 'Próximo passo',
  next: 'Próximo',
  nickname: 'Apelido',
  onEmail: 'No E-mail',
  onPhone: 'No Celular',
  passport: 'Passaporte',
  password: 'Senha',
  passwordInstructions:
    'A senha precisa ter pelo menos 8 caracteres, com pelo menos uma letra e um número',
  pastLessons: 'Aulas passadas',
  phone: 'Telefone',
  places: 'Locais',
  readMore: 'Saiba mais',
  recurrence: 'Recorrência',
  uniqueClass: 'Aula única',
  sundayLetter: 'D',
  mondayLetter: 'S',
  tuesdayLetter: 'T',
  wednesdayLetter: 'Q',
  thursdayLetter: 'Q',
  fridayLetter: 'S',
  saturdayLetter: 'S',
  save: 'Salvar',
  schedule: 'Agendar',
  select: 'Selecione',
  send: 'Enviar',
  state: 'Estado',
  state_consul: 'Estado',
  surname: 'Sobrenome',
  thankYou: 'Obrigado!',
  trainer: 'Professor',
  trainers: 'Professores',
  uf: 'UF',
  whatIsYourPhone: 'Qual o seu telefone?',
  yourNickname: 'Seu apelido',
  zipcode: 'CEP',

  enter: 'Entrar',
  createAccount: 'Cadastre-se',
  or: 'ou',
  alreadyRegistered: 'Já tem cadastro?',
  logIn: 'Faça login',

  logInWithoutRegistration: 'Entrar sem cadastro',

  notFound: 'Não encontrado',
  dataNotAvailable: 'Dados não disponíveis.',
  couldNotOpenAUrl: 'Não foi possível abrir a url {{url}}',
  tryAgain: 'Tentar novamente',

  recoveryPassword: 'Recuperar senha',
  forgotPasswordEmailSent:
    'Te enviamos um email com as orientações sobre como mudar sua senha.\n\nDepois é só logar aqui e começar as suas aulas junto com a gente!',

  share: 'Compartilhar',

  inputCancel: 'Cancelar',
  inputDone: 'OK',

  viewFullScheduleOfLives: 'Ver agenda completa de lives',
  viewFullScheduleOfClasses: 'Ver agenda completa de aulas',
  seeAllTrainers: 'Ver todos os professores',
  lessonsNotFound: 'Nenhuma aula encontrada',

  live: 'Live',
  lives: 'Lives',
  profile: 'Perfil',

  // Login Anônimo
  hello: 'Olá!',
  youAreNotRegisteredYet:
    'Para continuar, faça seu cadastro ou entre com sua conta. Vem!',
  completeLoginText:
    'Para ter acesso ao app da Mude, você precisa entrar em sua conta. Por favor, digite seu email e senha :)',
  completeRegistration: 'Complete o cadastro',
  forgotPasswordQuestion: 'Esqueceu sua senha?',

  whatIsYourEmail: 'Olá, {{name}}!\nQual o seu e-mail?',
  thisEmailWillBeUsedToLogYouIntoYourAccount:
    'Este email será usado para você entrar na sua conta, use um email que você tem acesso.',
  forgotPassword: 'Esqueci minha senha',
  didYouForgetYourPassword: 'Esqueceu sua senha?',
  typeYourEmail: 'Olá! Por favor, digite seu e-mail e senha :)',
  weWillRecoverYourPassword:
    'Não tem problema, escreva aqui seu e-mail, nós vamos recuperar para você.',
  howWouldYouWantToBeNamed: 'Vamos começar.\nComo gostaria de ser chamado?',
  thisNameCanBeSeenByOthers:
    'Este nome poderá ser visto por outras pessoas dentro do app, tá?',
  agreeWithPolicy:
    'Concordo com os <0>Termos de uso</0> e com a <0>Política de Privacidade</0> da Mude',
  createAccountSubmit: 'Criar conta',
  full: 'Lotada',

  male: 'Masculino',
  female: 'Feminino',
  others: 'Outros',
  notSpecified: 'Não especificado',

  changeCity: 'Trocar cidade',
  howToGetThere: 'Ver no mapa',
  activateMyLocation: 'Ativar minha localização',

  journey: 'Jornada',
  myLessons: 'Minhas aulas',
  partners: 'Parceiros',
  yourNextLesson: 'Sua próxima aula',
  yourNextLessons: 'Suas próximas aulas',
  emptyActivitiesTitle: 'Suas atividades futuras aparecem aqui',
  emptyActivitiesText: 'Que tal começar agendando uma aula?',
  emptyActivitiesCta: 'Agende uma aula',
  emptyMyListCta: 'Adicione um conteúdo',
  emptyTrainersCta: 'Procure um professor',
  emptyJourneyTitle: 'Ops, parece que sua jornada ainda não começou',
  emptyJourneySubtitle: 'Mas você não vai ficar aí no sofá, né?!',
  emptyJourneyCta: 'Agende uma live',
  loadMore: 'Carregar mais',
  activityDetails: 'Detalhes da atividade',
  removeFromJourney: 'Remover da jornada',
  partnersAdvantages: 'Vantagens de parceiros',
  partnersContent: `A Mude existe porque nossos parceiros acreditam e investem no nosso projeto!\n
Por isso, a gente fica super feliz em oferecer algumas vantagens para os nossos alunos que se relacionam com essas marcas.`,
  knowYourBenefits: 'Conheça os benefícios',
  moreAboutThisSponsor: 'Conheça o parceiro',

  weDontHaveLessonsInYourCity:
    'Infelizmente ainda não temos aulas na sua cidade',
  dontBeSad:
    'Mas não fique triste, enquanto não levamos as aulas da Mude até sua cidade você pode aproveitar para se exercitar em uma de nossas estações.',
  findOutStations: 'DESCUBRA ESTAÇÕES PERTO DE VOCÊ',

  allow: 'Permitir',
  dontAllow: 'Não Permitir',
  itsImportantToGiveUsYourLocation:
    'É muito importante que nos dê acesso à sua localização',
  locationUseDisclaimer:
    'Sua localização atual será usada para sugerir aulas e estações de treino próximas a você.',
  calendarAccessTitle: 'Acesso ao calendário',
  calendarAccessText:
    'Precisamos da sua permissão para salvar essa aula no seu calendário',

  // Navegação principal
  settings: 'Configurações',
  accountSettings: 'Configurações da Conta',
  personalData: 'Dados Pessoais',
  emergencyContact: 'Contato de Emergência',
  anamnesisTitle: 'Ficha de Saúde',
  changePassword: 'Alterar Senha',
  notifications: 'Notificações',
  workoutOptions: 'Opções dos treinos',
  payments: 'Pagamentos',
  featureFlag: 'Feature Flag',
  deleteAccount: 'Apagar Conta',
  getHelp: 'Obtenha ajuda',
  userTermsAndPolicy: 'Termos de uso e \npolítica de privacidade',
  dataUseDisclaimer:
    'Solicitamos os dados abaixo a fim de aprimorar a segurança do acesso, além de tornar nosso serviço exclusivo de acordo com o seu perfil',

  // Parceiro
  genericCallToAction: 'Saiba como virar cliente',

  // Contato de emergência
  emergencyContactDisclaimer:
    'Solicitamos os dados de um contato para nos comunicar com ele ou ela no caso de apresentar-se uma emergência',
  emergencyContactRegistered: 'Cadastrado com sucesso!',

  // Anamnese
  anamnesisNotAvailable: 'Ficha de saúde não disponível.',
  anamnesisIntro: 'Questionário de prontidão \npara atividade física',
  anamnesisDescription:
    'Este questionário tem por objetivo identificar a necessidade de avaliação por um médico antes do início ou do aumento de nível da atividade física.',
  anamnesisInstructions: 'Assinale “sim” ou “não” às seguintes perguntas:',
  answerAll: 'Responder todas',
  agreeWithAnamnesisTerms:
    'Declaro que concordo com o <0>Termo de Responsabilidade</0> para prática de atividade física.',
  anamnesisTermsTitle:
    'Termo de Responsabilidade para a prática de atividade física',
  anamnesisTermsMessage:
    'Declaro que estou ciente de que é recomendável conversar com um médico, antes de iniciar ou aumentar o nível de atividade física pretendido, assumindo plena responsabilidade pela realização de qualquer atividade física sem o atendimento desta recomendação.',
  unfilledAnamnesisTitle: 'Respostas incompletas',
  unfilledAnamnesisDescription:
    'É necessário responder a todas as perguntas para prosseguir.',
  uncheckedTermsAnamnesisTitle: 'Termo de Responsabilidade',
  uncheckedTermsAnamnesisDescription:
    'É necessário concordar com o Termo de Responsabilidade para prosseguir.',

  deficiencyTitle: 'PCD',
  deficiencyDescription:
    'Para conseguirmos oferecer a melhor experiência a você, é importante entendermos se você possui alguma deficiência.',

  covidTitle: 'COVID-19',
  covidDescription:
    'A Mude segue um <0>protocolo de segurança</0> para garantir uma boa prática e o bem-estar de todos. Como parte das medidas de segurança precisamos que você responda a algumas questões.',
  agreeWithCovidTerms:
    'Declaro que concordo com o <0>Protocolo Covid-19</0> da Mude para prática de atividade física',
  uncheckedTermsCovidTitle: 'Protocolo Covid-19',
  uncheckedTermsCovidDescription:
    'É necessário concordar com o Protocolo Covid-19 para prosseguir.',

  covidBannerTitle: 'Núcleos em {{city}} abrirão gradativamente',
  covidBannerDescription:
    'Vamos manter a segurança juntos? Fique por dentro de todas as nossas <0>medidas de segurança</0>',

  // Notificações
  channelNewVacanciesTitle: 'Vagas liberadas',
  channelNewVacanciesDescription:
    'Receba um aviso sempre que uma vaga for liberada. Seja rápido, a procura é muito grande!',
  channelCancelledLessonsTitle: 'Aulas canceladas',
  channelCancelledLessonsDescription:
    'Receba um aviso caso alguma aula que você reservou seja cancelada.',
  channelCheckInTitle: 'Aula começando',
  channelCheckInDescription:
    'Receba um aviso 3 minutos antes de iniciar a aula para fazer o check-in ou assistir ao vivo.',
  channelPaymentsTitle: 'Pagamentos',
  channelPaymentsDescription:
    'Notificações caso aconteça algum problema com seu pagamento.',
  channelNewsTitle: 'Novidades',
  channelNewsDescription:
    'Saiba em primeira mão dos aulões e eventos especiais que prepararemos para você.',
  channelDefaultTitle: 'Informações Gerais',
  channelDefaultDescription:
    'Todas as informações sobre o app e o que acontece no universo Mude.',

  // Apagar conta
  accountRemovalDisclaimer1:
    'Ao apagar sua conta, você perde o acesso ao aplicativo e a todas as atividades oferecidas pela Mude, inclusive seu histórico de aulas.',
  accountRemovalDisclaimer2:
    'Você também tem a opção de fazer logout e apagar o aplicativo do seu celular, assim, quando você quiser, teremos tudo pronto para te receber de volta!',
  accountRemovalLeave: 'Quero Sair do Aplicativo',
  accountRemovalConfirmPassword:
    'Ok, para cancelar sua conta precisamos que você confirme sua senha para ter certeza é você mesmo quem quer fazer isso.',
  theDoorCracksOpen: 'As portas estão sempre abertas!',
  youreAlwaysWelcome:
    'Quando quiser voltar, estaremos prontos para te receber! :)',

  // Recuperar senha
  letsRecoverYourPassword: 'Vamos recuperar sua senha!',
  enterYourNewPasswordBelow: 'Insira sua nova senha abaixo',
  enterYourNewPassword: 'Insira sua nova senha',
  enterYourNewPasswordAgain: 'Digite novamente sua senha',
  sendNewPassword: 'Enviar',

  // Senha Recuperada
  passwordRecovered: 'Senha Alterada!',
  passwordRecoveredText:
    'Pronto, sua senha nova foi cadastrada. Agora você já pode voltar ao nosso app, fazer login e escolher a próxima atividade que vai trazer você para a rua com a gente!',
  loginAfterChange: 'Fazer login',

  // Help
  contactUsIfAnyQuestion:
    'Olá! Possui alguma dúvida, sugestão, feedback ou reclamação? Por favor, não deixe de entrar em contato com a gente!',
  version: 'Versão',

  // Events
  aboutEvent: 'Sobre o evento',
  eventAboutNotAvailable: 'Detalhes do evento não disponível.',

  // Modalidades
  noInfosAboutModality: 'Não há informações disponíveis sobre esta modalidade',
  noLessonsInThisRegion:
    'Infelizmente ainda não possuímos aulas nesta categoria em sua região',
  modalityNotAvailable: 'Modalidade não disponível.',

  // Lesson:
  lessonDetails: 'Detalhes da aula',
  details: 'Detalhes',
  back: 'Voltar',
  createReminder: 'Criar\nlembrete',
  reminderCreated: 'Lembrete\ncriado',
  yourReminderWasCreated: 'Seu lembrete foi criado',
  youAreScheduled: 'Você está agendado',
  yourVacancyIsReserved:
    'Se precisar você pode cancelar seu agendamento até o início da aula. Não se esqueça de fazer o check-in!',
  cannotAttendToTheClass: 'Não poderá comparecer à aula?',
  releaseVacancy: 'Liberar vaga',
  cancelReminder: 'Cancelar lembrete',
  usefulInformation: 'Informações úteis',
  support: 'Apoio',
  sponsor: 'Patrocínio',
  help: 'Ajuda',
  lessonLocation: 'Local da aula',
  otherDates: 'Outras datas',
  seeAgenda: 'Ver agenda',
  schedulesAndAgenda: 'Horários e agenda',
  addToCalendar: 'Adicionar ao calendário',
  addToYourPersonalCalendar: 'Adicionar no seu calendário pessoal',
  contact: 'Contato',
  someProblemLetsHelpYou: 'Algum problema? Vamos te ajudar',
  classOfferedByDonations:
    'Aula oferecida por meio de contribuições e apoio de alunos',
  classOfferedBySponsor: 'Aula oferecida por\n<0>{{sponsor}}</0>',
  supportTrainerToOfferActivities:
    'Apoie e ajude {{trainer}} a oferecer ainda mais atividades!',
  inviteYourFriends: 'Convide \nseus amigos',
  lookForOtherDates: 'Ver outras datas',
  cancelled: 'Cancelada',
  lessonCancelled: 'Esta aula \nfoi cancelada :(',
  lessonCancelledWarning: 'Aula cancelada',
  weHadToCancel: '{{reason}}Tivemos que cancelar esta aula. Até a próxima!',
  watch: 'Assistir',
  watchLive: 'Assistir\nao vivo',
  watchNow: 'Assistir agora',
  watchAgain: 'Assistir novamente',
  dontMissAnything: 'Não perca nenhum minuto',
  classTime: 'Hora da aula',
  checkinTime: 'Hora do check-in',
  checkInNow: 'Fazer \ncheck-in!',
  checkInWarning: 'Faça seu check-in',
  rememberToCheckin: 'Lembre-se de fazer o check-in!',
  theLessonIsAboutToStart:
    'A aula já vai começar, não se atrase! Se você não estiver presente no horário, sua vaga será disponibilizada para outro aluno.',
  checkinDone: 'Check-in feito \nBoa aula!',
  enjoyTheClass:
    'Que bom que você vai participar! Veja os detalhes para mais informacões e não se esqueça de fazer o check-in!',
  didYouLikeTheLesson: 'Como foi \nsua aula?',
  youWentToThisClass: 'Você foi a essa aula!',
  thankYouForYourPresence:
    'Que bom! A aula não seria a mesma sem você. Fique de olho para as próximas datas!',
  weHaveMissedYou: 'Você não foi :( \nSentimos sua falta',
  lessonMissedContent:
    'Ops. A aula não foi a mesma sem você. Não esqueça de liberar sua vaga para que alguém possa ir em seu lugar!',
  classOnlyForSponsorCta: 'Exclusiva {{sponsor}}',
  classOnlyForSponsorTitle: 'Aula exclusiva',
  classOnlyForSponsorContent:
    'Essa aula é exclusiva para clientes {{sponsor}}.',
  classOnlyForSponsorDismiss: 'Sou cliente',
  scheduleLimitReachedTitle: 'Você atingiu o limite de aulas agendadas',
  scheduleLimitReachedContent:
    'Você só pode agendar uma aula por vez. Para reservar esta aula, você terá primeiro que comparecer à que você já agendou.\n\nSe preferir, pode cancelar outra aula para participar desta.',
  scheduleLimitReachedContent_plural:
    'Você só pode agendar {{count}} aulas por vez. Para reservar esta aula, você terá primeiro que comparecer às que você já agendou.\n\nSe preferir, pode cancelar outra aula para participar desta.',
  scheduleLimitReachedConfirm: 'Ok',
  scheduleLimitReachedDismiss: 'Liberar outra aula',
  ageLimitTitle: 'Você tem mais de 15 anos?',
  ageLimitContent: 'Verifique sua data de nascimento.',
  timeBeforeScheduling: '{{timeToGo}} para liberação',
  notOpenYetTitle: 'Aguardando liberação',
  notOpenYetContent:
    'Agendamentos são liberados {{subscriptionTime}} antes da aula.',
  letMeKnowAboutNewVacancies: 'Me avise \nse liberar!',
  lessonIsFull: 'Lotada!',
  lessonIsFullTitle: 'Você quase conseguiu uma vaga',
  lessonIsFullContent: 'Que pena! Ligue o alarme que te avisamos se liberar',
  lessonNotificationsOn: 'Alerta \nligado!',
  warnMeTitle: 'Notificações de vagas ligado',
  warnMeContent:
    'Sempre que uma vaga for liberada vamos te notificar.\n\nFique ligado, como a procura é grande, não podemos garantir seu agendamento!\n\nSe preferir, pode desativar o alerta.',
  warnMeConfirm: 'Manter alerta',
  warnMeDismiss: 'Desligar alerta',
  totalCapacity: '{{count}} pessoa',
  totalCapacity_plural: '{{count}} pessoas',
  capacity: 'Capacidade',
  duration: 'Duração',
  happenedClass: 'Aula já realizada',
  releasedVacancies: 'Reservas liberadas',
  vacancies: '{{count}} vaga!',
  vacancies_plural: '{{count}} vagas',
  lastVacancies: 'Última vaga!',
  lastVacancies_plural: 'Últimas {{count}} vagas',
  lessonsSubscribeContent:
    'Faça sua reserva! As aulas grátis costumam lotar rápido',
  sponsorIsPartOfTheChange: 'O {{sponsor}} também é parte da mudança',
  sponsorIsPartOfTheChange_female: 'A {{sponsor}} também é parte da mudança',
  lessonSponsoredBy:
    'Sua aula é oferecida pelo {{sponsor}}, que compartilha nosso desejo de melhorar sua qualidade de vida',
  lessonSponsoredBy_female:
    'Sua aula é oferecida pela {{sponsor}}, que compartilha nosso desejo de melhorar sua qualidade de vida',
  instructor: 'Instrutor',
  instructor_female: 'Instrutora',
  teacher: 'Professor',
  teacher_female: 'Professora',
  guide: 'Guia',
  writeYourMessage: 'Escreva sua mensagem',

  // Check-in
  checkInTitle: 'Faça o check-in',
  checkInScreenBody: 'Chegou a hora da aula <0>{{lesson}}</0> com {{trainer}}.',
  checkInSubmit: 'Fazer check-in',

  // Rating
  classDone: 'Aula feita!',
  howWasTheClass: 'O quanto você curtiu <0>{{activity}}</0>?',
  ratingVeryBad: 'Não curti nada',
  ratingBad: 'Não curti',
  ratingOk: 'Mais ou menos',
  ratingGood: 'Curti',
  ratingVeryGood: 'Curti muito!',

  // Modals
  doItLater: 'Fazer depois',
  someoneWasFasterThanYou: 'Alguém foi mais rápido que você!',
  vacanyWasFulfilled:
    'A vaga que você ia pegar foi preenchida um pouquinho antes, mas não desista, assim que alguém desistir continuaremos te avisando.',
  warnMeForNewVacancies: 'Me avise se liberar vaga',
  IDontWantToBeWarned: 'Não quero ser avisado',
  scheduleConflictWarningTitle:
    'Ops. Você já marcou uma aula para este horário!',
  scheduleConflictWarningContent:
    'Há uma aula já agendada para este mesmo dia e horário.',
  genericErrorContent:
    'Ocorreu um erro desconhecido. Por favor, entre em contato com nossa equipe relatando o ocorrido.',
  genericErrorTitle: 'Ops. Algo não está certo!',

  weApologizeForTheMistake:
    'Pedimos desculpas pelo erro. Já está tudo certo, registramos sua presença!',
  cancelSubscription: 'Cancelar seu agendamento?',
  cancelSubscriptionContent:
    'Cancelando o agendamento você libera sua vaga para outra pessoa.',
  yesReleaseMyVacancy: 'Sim, liberar vaga',
  keepMyDateScheduled: 'Não, continuar agendado',

  cancelLiveSubscription: 'Cancelar seu lembrete?',
  cancelLiveSubscriptionContent:
    'Cancelando o lembrete você não receberá uma notificação quando a aula começar.',
  yesRemoveMyReminder: 'Sim, cancelar lembrete',
  keepMyReminder: 'Não, manter lembrete',

  modalAnamnesisTitle:
    'Para agendar essa aula precisamos saber algumas informações sobre sua saúde',
  modalAnamnesisContent:
    'Para isso, precisamos que você responda algumas perguntinhas. Isso é uma exigência do CREF e é muito importante para podermos atender você da melhor forma possível. É rapidinho!',
  answerNow: 'Responder Agora',
  modalAnamnesisExpiredTitle:
    'Para agendar essa aula precisamos checar algumas informações sobre sua saúde',
  modalAnamnesisExpiredContent:
    'Para isso, precisamos que você confira novamente suas respostas. Isso é uma exigência do CREF e é preciso renovar todo ano. É muito importante para podermos atender você da melhor forma possível. É rapidinho!',
  done: 'Pronto!',
  modalMedicalCertificateContent: `Agora você já pode agendar suas aulas. Mas lembre-se: <0>para fazer a primeira aula você precisa apresentar um atestado médico!\n</0>
Assim, fica garantido que você está liberado para praticar atividades físicas. O professor já está ciente e você pode entregar o atestado diretamente para ele.`,

  completeRegistrationRequiredTitle:
    'Para agendar essa aula, complete seu cadastro!',
  completeNow: 'Completar Agora',
  completeRegistrationContent: `É rapidinho! Assim você terá acesso a todas as aulas :)\n
Essas informações são muito importantes para ajudar a preservar sua integridade física e proporcionar a melhor experiência para você.`,

  modalCheckinContent:
    'Para fazer o check-in você precisa estar mais perto do local da aula ;)\nVem que vai ser ótimo!',

  areYouCloseToYourClass: 'Você já esta perto da sua aula?',

  whyCreateAccount:
    'Para ter acesso ao app da Mude, você precisa criar sua conta.',
  whyWeNeedData: 'Porque precisamos dessas informações?',
  modalWhyDataContent: `A Mude transforma cidades em espaços de bem estar para as pessoas. Conectamos patrocinadores, treinadores, governos e nossa comunidade de usuários apaixonados por ar livre de um jeito onde todos ganham.\n
Acreditamos que todos merecem ser saudáveis, e por isso valorizamos diversidade e inclusão. Conhecer melhor quem faz parte de nossa comunidade aberta nos dá mais recursos para seguir transformando cidades e vidas de acordo com nossos valores.\n
<0>IDADE E GÊNERO\n</0>
Estas informações nos ajudam a entender quais aulas, e outros produtos e serviços de bem estar, são preferidas por diferentes grupos de pessoas. Assim, podemos estruturar nossa oferta para melhor atender as expectativas e interesses de nossa comunidade cada vez maior. Nos ajuda também a buscar patrocinadores que queiram promover atividades ao ar livre orientadas para públicos específicos. Ainda no espírito de promover um bem estar de inclusão, queremos buscar também descontos com parceiros comerciais para oferecer à nossa comunidade produtos e serviços com preços mais acessíveis. Suas informações nos ajudam neste propósito.\n
<0>Informações pessoais serão anonimizadas e têm fins estatísticos. Não as usaremos individualmente.\n</0>
Mais informações sobre nossa política de privacidade e termos de uso podem ser enviados para seu email clicando aqui.`,

  locationNotAvailable: 'Localização não disponível.',
  grantLocationUndefined:
    'Confirme que sua localização está ligada e a permissão foi concedida.',
  grantLocationPermission:
    'Conceda a permissão de localização para que possamos atualizar sua posição.',

  appFiveStarTitle: 'Curtindo o app?',
  appFiveStarMessage:
    'Sua opinião é muito importante para tornar sua experiência com a Mude cada vez melhor.',
  appFiveStarConfirm: 'Avaliar app',
  appFiveStarDismiss: 'Agora não',

  // Doações
  donationTitle: 'Você faz a diferença',
  lessonDonationDescription:
    'A aula é gratuita, então que tal dar uma força pro {{trainer}} criar outras aulas assim?',
  lessonDonationDescription_female:
    'A aula é gratuita, então que tal dar uma força pra {{trainer}} criar outras aulas assim?',
  trainerDonationDescription:
    'Você apoia o {{trainer}} e ele pode organizar mais aulas pra você',
  trainerDonationDescription_female:
    'Você apoia a {{trainer}} e ela pode organizar mais aulas pra você',
  donationPriceTitle: 'Escolha sua contribuição, é rapidinho!',
  donationOtherValue: 'Outro valor',
  chooseTheValue: 'Escolha o valor',
  donateCta: 'Quero doar',
  supportTrainer: 'Apoiar {{trainer}}',
  donationSubmit: 'Apoiar Aula',
  donationSkip: 'Não quero apoiar',

  // Pagamentos
  total: 'Total',
  payment: 'Pagamento',
  free: 'Grátis',
  plans: 'Planos',
  planBenefit1: 'Preços especiais',
  planBenefit2: 'Reservas automáticas',
  planBenefit3: 'Vagas garantidas',
  perMonth: '/mês',
  signPlan: 'Assinar plano',
  seePlans: 'Ver planos',
  bestValue: 'Melhor valor',
  startFrom: 'Início em ',
  addNewCreditCard: 'Adicionar cartão',
  changeCreditCard: 'Trocar',
  didYouSeeOurPlans: 'Já viu nossos planos?',
  addCreditCardDescription:
    'Este cartão poderá ser usado para futuras transações no app.',
  ccNumber: 'Número do cartão',
  ccExp: 'Vencimento',
  ccCsc: 'CVC',
  ccName: 'Nome no cartão',
  billingAddress: 'Endereço da fatura',
  pay: 'Pagar',
  cards: 'Cartões',
  noCardsRegistered: 'Nenhum cartão\ncadastrado',
  removeCardTitle: 'Remover cartão?',
  removeCardMessage:
    'Tem certeza que deseja remover o cartão com final {{lastDigits}}?\n\nEssa ação não poderá ser desfeita.',
  removeCardAction: 'Remover cartão',
  transactions: 'Transações',
  noTransactionsRegistered: 'Nenhuma transação\nregistrada',
  waiting: 'Aguardando',
  paid: 'Pago',
  refused: 'Recusado',
  refunded: 'Estornado',
  chargeback: 'Contestado',
  thanksTitle: 'Muito\nobrigada!',
  thanksDescription:
    'Sua retribuição é muito valiosa. Agora você também faz parte da mudança',

  // Agenda
  filter: 'Filtrar',
  filterAgendaTitle: 'Filtrar agenda',
  locality: 'Localidade',
  findYourLocation: 'Encontre sua localidade',
  coach: 'Coach',
  findYourTrainer: 'Encontre seu professor',
  seeResults: 'Ver resultados',

  // Trainer
  seeNextLessons: 'Ver próximas aulas',
  emptyActivitiesForNextDays:
    'Nenhuma atividade agendada para os próximos dias',
  emptyOnDemand: 'Nenhum conteúdo on demand disponível ainda',
  addTrainerAsFavorite:
    'Adicione o <0>{{trainer}}</0> aos seus \nprofessores e fique por dentro\nde todas as suas novidades',
  addTrainerAsFavorite_female:
    'Adicione a <0>{{trainer}}</0> aos seus \nprofessores e fique por dentro\nde todas as suas novidades',
  trainerIsYourFavorite:
    '<0>{{trainer}} é seu professor :)</0>\nVocê será avisado sempre que chegar\num novo conteúdo ou atividade',
  trainerIsYourFavorite_female:
    '<0>{{trainer}} é sua professora :)</0>\nVocê será avisado sempre que chegar\num novo conteúdo ou atividade',

  // Search
  search: 'Busca',
  searchPlaceholder: 'Busca por atividades, modalidades, professores...',
  all: 'Tudo',
  activities: 'Atividades',
  totalActivities: '{{count}} atividade',
  totalActivities_plural: '{{count}} atividades',
  onDemand: 'On demand',
  showingResultsFor: 'Mostrando resultados para',
  didntFindShowResultsFor: 'Não achou? Mostrar resultados para',
  searchFilterTitle: 'Filtros',
  whereTitle: 'Onde?',
  whereText: 'Ache localidades de atividades',
  whenTitle: 'Quando?',
  whenText: 'Encontre atividades para agendar',
  howLongTitle: 'Quanto Tempo?',
  howLongText: 'Atividades que cabem no seu tempo',
  whichTrainerTitle: 'Qual professor?',
  whichTrainerText: 'Siga os seus favoritos e favoritas',
  popularResults: 'Resultados populares',
  liveNow: 'Ao vivo',
  emptySearchTitle: 'Ops. Sua busca não teve resultados',
  emptySearchSubtitle: 'Mas isso não é desculpa pra ir pro sofá',
  emptySearchCta: 'Buscar próximas lives',
  remindersAndSchedules: 'Lembretes e Agendamentos',
  yourNextLivesAndClasses: 'As suas próximas Aulas',
  toWatchAnytime: 'Para assistir a qualquer hora',
  favoriteTrainers: 'Professores favoritos',
  getNotifiedOfAllTheNews: 'Me avise de todas as novidades',
  virtualCity: 'Online',
  inTheComfortOfYourHome: 'No conforto da sua casa',
  noDurationLimit: 'Sem limite',
  approximately: 'Aproximadamente {{duration}}',
  timeRange: 'Faixa horária',
  morning: 'Manhã',
  afternoon: 'Tarde',
  night: 'Noite',
  morning_afternoon: 'Manhã e tarde',
  afternoon_night: 'Tarde e noite',
  morning_afternoon_night: 'Manhã, tarde e noite',
  allDay: 'O dia todo',
  until: 'até',
  calendar: 'Calendário',
  today: 'hoje',
  tomorrow: 'amanhã',
  thisWeek: 'essa semana',
  thisMonth: 'esse mês',
  thisWeekend: 'esse fim de semana',
  chooseADate: 'escolher uma data',
  day: 'dia',
  other: 'Outras',
  otherModalities: 'Outras Modalidades',

  // Lesson Spot
  bookSpot: 'Reservar vaga {{spot}}',
  chooseYourSpot: 'Escolha sua vaga',
  yourSpotIs: 'Sua vaga é',
  spotConfirmationDescription:
    'Não se esqueça de levar uma identificação para garantir sua vaga',
  changeSpot: 'Trocar vaga',
  selected: 'selecionada',
  available: 'disponível',
  unavailable: 'indisponível',
};
