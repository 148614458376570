import React from 'react';
import { StyleSheet, View } from 'react-native';
import Svg, { Path } from 'react-native-svg';
import Theme from '../libs/Theme';

export default class ClipboardIcon extends React.PureComponent {
  static defaultProps = {
    color: Theme.color.magenta,
  };

  render() {
    return (
      <View style={this.props.withBorder && styles.withBorder}>
        <Svg width={18} height={18} viewBox="0 0 27 33" {...this.props}>
          <Path
            fill={this.props.color}
            d="M24 3h-6.3c-.6-1.8-2.2-3-4.2-3S9.9 1.2 9.3 3H3C1.4 3 0 4.3 0 6v24c0 1.7 1.4 3 3 3h21c1.6 0 3-1.3 3-3V6c0-1.7-1.4-3-3-3zM13.5 3c.9 0 1.5.6 1.5 1.5S14.4 6 13.5 6 12 5.4 12 4.5 12.6 3 13.5 3zM24 30H3V6h3v4.5h15V6h3v24z"
          />
        </Svg>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  withBorder: {
    borderRadius: 34,
    backgroundColor: Theme.color.white,
    width: 34,
    height: 34,
    shadowColor: Theme.color.black,
    shadowOffset: { width: 0, height: 3 },
    shadowOpacity: 0.1,
    shadowRadius: 2,
    elevation: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
});
