import React from 'react';
import hoistNonReactStatic from 'hoist-non-react-statics';

export default function withNavParams(WrappedComponent) {
  const WithNavParams = props => (
    <WrappedComponent {...props} {...props.route.params} />
  );

  hoistNonReactStatic(WithNavParams, WrappedComponent);

  return WithNavParams;
}
