import React from 'react';
import ModalService from './ModalService';
import AlertModal from '../components/AlertModal';

class AlertService {
  show = props => {
    ModalService.hide();

    // timeout para dar tempo de fechar qualquer modal ativo e depois abrir um novo
    setTimeout(() => {
      ModalService.show(<AlertModal {...props} />);
    });
  };

  hide = () => {
    ModalService.hide();
  };
}

export default new AlertService();
