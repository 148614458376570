import React from 'react';
import Svg, { Path } from 'react-native-svg';

export default function HeartOutlineIcon(props) {
  return (
    <Svg width={22} height={22} viewBox="0 0 22 22" {...props}>
      <Path
        fill={props.color}
        d="M5.9 3.1c-3.5 0-3.8 2.8-3.8 3.6 0 2.1 1.4 3.9 2.7 5.2.7.7 4.6 4.9 6.2 6.6 1.6-1.7 5.6-5.9 6.2-6.6 1.3-1.3 2.7-3.1 2.7-5.2 0-1.1-.4-3.6-3.8-3.6-1.1 0-2.8.7-4.3 2.7L11 6.9l-.8-1.1c-1.5-2-3.3-2.7-4.3-2.7zM11 21.4l-.7-.8s-6.1-6.4-7-7.3C1.8 11.7.1 9.5.1 6.7c0-2.6 1.5-5.6 5.8-5.6 1.7 0 3.6 1 5.1 2.6 1.5-1.6 3.4-2.6 5.1-2.6 4.3 0 5.8 3 5.8 5.6 0 2.8-1.7 5-3.2 6.6-.9.9-7 7.3-7 7.3l-.7.8z"
      />
    </Svg>
  );
}
