import global from './global';
import diy from './diy';
import share from './share';
import errors from './errors';

export default {
  translation: {
    ...global,
    ...diy,
    ...share,
    ...errors,
  },
};
