import { Model, Query, Relation } from '@nozbe/watermelondb';
import {
  children,
  field,
  json,
  relation,
} from '@nozbe/watermelondb/decorators';
import { Associations } from '@nozbe/watermelondb/Model';
import Lesson from './Lesson';
import Modality from './Modality';
import Place from './Place';
import Product from './Product';
import Sponsor from './Sponsor';
import Trainer from './Trainer';

export default class LessonGroup extends Model {
  static table = 'lesson_groups';

  static associations: Associations = {
    lessons: { type: 'has_many', foreignKey: 'lesson' },
    trainers: { type: 'belongs_to', key: 'trainer' },
    modalities: { type: 'belongs_to', key: 'modality' },
    places: { type: 'belongs_to', key: 'place' },
    sponsors: { type: 'belongs_to', key: 'sponsor' },
    products: { type: 'belongs_to', key: 'product' },
  };

  @children('lessons') lessons!: Query<Lesson>;

  @relation('trainers', 'trainer') trainer_obj!: Relation<Trainer>;
  @relation('modalities', 'modality') modality_obj!: Relation<Modality>;
  @relation('places', 'place') place_obj!: Relation<Place>;
  @relation('sponsors', 'sponsor') sponsor_obj!: Relation<Sponsor>;
  @relation('products', 'product') product_obj!: Relation<Product>;

  @json('images', _json => _json || []) images!: string[];
  @field('name') name!: string;
  @field('description') description!: string;
  @field('requires_anamnesis') requires_anamnesis!: boolean;
  @field('public_visibility') public_visibility!: boolean;
  @field('public_subscription') public_subscription!: boolean;
  @field('allow_children') allow_children!: boolean;
  @field('start_time') start_time!: string;
  @field('end_time') end_time!: string;
  @field('start_date') start_date!: string;
  @field('end_date') end_date!: string;
  @field('total_vacancies') total_vacancies!: number;
  @field('recurrence_text') recurrence_text!: string;
  @field('recurrence_monday') recurrence_monday!: boolean;
  @field('recurrence_tuesday') recurrence_tuesday!: boolean;
  @field('recurrence_wednesday') recurrence_wednesday!: boolean;
  @field('recurrence_thursday') recurrence_thursday!: boolean;
  @field('recurrence_friday') recurrence_friday!: boolean;
  @field('recurrence_saturday') recurrence_saturday!: boolean;
  @field('recurrence_sunday') recurrence_sunday!: boolean;
  @field('trainer') trainer!: string;
  @field('modality') modality!: string;
  @field('place') place!: string;
  @field('sponsor') sponsor!: string;
  @field('product') product!: string;
}
