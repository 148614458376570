import { Model, Relation } from '@nozbe/watermelondb';
import { field, json, relation, writer } from '@nozbe/watermelondb/decorators';
import { Associations } from '@nozbe/watermelondb/Model';
import Modality from './Modality';

export default class Exercise extends Model {
  static table = 'exercises';

  static associations: Associations = {
    modalities: { type: 'belongs_to', key: 'modality' },
  };

  @relation('modalities', 'modality') modality_obj!: Relation<Modality>;

  @field('name') name!: string;
  @field('image') image!: string;
  @field('video') video!: string;
  @field('video_cached') video_cached!: string;
  @json('video_sizes', _json => _json || {}) video_sizes!: {};
  @field('audio') audio!: string;
  @field('level') level!: number;
  @field('modality') modality!: string;
  @json('modalities', _json => _json || []) modalities!: string[];
  @json('requirements', _json => _json || []) requirements!: string[];
  @json('instructions', _json => _json || []) instructions!: string[];
  @field('stream_url') stream_url!: string;

  @writer async saveCachedVideo(video_cached: string) {
    await this.update(exercise => {
      exercise.video_cached = video_cached;
    });
  }
}
