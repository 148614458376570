import React from 'react';
import axios from 'axios';
import moment from 'moment-timezone';
import styles from './LivesScreen.module.scss';
import { API_URL, LIVES_CITY } from '../libs/Env';
import LayoutWithHeader from '../components/LayoutWithHeader';
import LessonEntry from '../components/LessonEntry';

export default class LivesScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      error: '',
      data: [],
    };
  }

  async componentDidMount() {
    try {
      const data = [];
      const result = await axios.get(`${API_URL}/lesson/?city=${LIVES_CITY}`);
      let currentDay;
      result.data.results.forEach(lesson => {
        const dayId = moment(lesson.start_datetime).format('YYYY-MM-DD');
        if (currentDay?.id !== dayId) {
          const mDay = moment(dayId);
          currentDay = {
            id: dayId,
            type: 'day',
            title: `${mDay.format('dddd')} ${mDay.format('DD/MM')}`,
          };
          data.push(currentDay);
        }
        data.push(lesson);
      });
      this.setState({ data });
    } catch (error) {
      this.setState({ error: error.message });
    }

    this.setState({ loading: false });
  }

  render() {
    const { loading, error, data } = this.state;

    if (!data.length) {
      return (
        <LayoutWithHeader
          loading={loading}
          error={error || 'Nenhuma live encontrada'}
        />
      );
    }

    return (
      <LayoutWithHeader loading={loading} error={error}>
        <div className={styles.container}>
          <h1 className={styles.title}>Próximas lives</h1>
          {data.map((item, i) => {
            if (item.type === 'day') {
              return (
                <div key={item.id} className={styles.day}>
                  {item.title}
                </div>
              );
            }
            return <LessonEntry key={item.id} lesson={item} />;
          })}
        </div>
      </LayoutWithHeader>
    );
  }
}
