import React from 'react';
import { StyleSheet, Text, TouchableHighlight } from 'react-native';
import Theme from '../libs/Theme';
import I18n from './I18n';

export default function LessonActionButton({ title, icon, onPress, style }) {
  const IconComponent = icon;
  return (
    <TouchableHighlight
      style={[styles.container, style]}
      underlayColor={Theme.color.lowContrastGray_USE_WITH_WISDOM}
      onPress={onPress}
    >
      <>
        {IconComponent && (
          <IconComponent color={Theme.color.magenta} height={16} />
        )}
        <Text style={styles.title}>
          <I18n>{title}</I18n>
        </Text>
      </>
    </TouchableHighlight>
  );
}

const styles = StyleSheet.create({
  container: {
    height: 80 + Theme.dimensions.bottomSpace,
    paddingBottom: Theme.dimensions.bottomSpace,
    alignItems: 'center',
    justifyContent: 'center',
    borderColor: Theme.color.veryLightGray,
    borderRightWidth: 1,
    borderTopWidth: 1,
  },
  title: {
    color: Theme.color.veryDarkGray,
    fontFamily: Theme.fonts.barlowCondensed,
    fontSize: 13,
    marginTop: 5,
    textAlign: 'center',
  },
});
