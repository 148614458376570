import React from 'react';
import { StyleSheet, Text, TextInput as NativeTextInput } from 'react-native';
import { TextInputMask, MaskService } from 'react-native-masked-text';
import Theme from '../libs/Theme';
import I18n from './I18n';

// TODO
// unificar os labels de confirmar password
// unificar postcode com zipcode

const mapSecureTextEntry = {
  password: true,
  newPassword: true,
  confirm: true,
  repassword: true,
  password_confirm: true,
};

const mapTextContentType = {
  email: 'emailAddress',
  first_name: 'name',
  last_name: 'familyName',
  nickname: 'nickname',
  phone_country_code: 'telephoneNumber',
  phone: 'telephoneNumber',
  postcode: 'postalCode',
  zipcode: 'postalCode',
  country: 'countryName',
  state: 'addressState',
  council: 'addressState',
  city: 'addressCity',
  neighborhood: 'location',
  street_name: 'fullStreetAddress',
  password: 'password',
  newPassword: 'newPassword',
  ccNumber: 'creditCardNumber',
};

const mapKeyboardType = {
  email: 'email-address',
  first_name: 'default',
  last_name: 'default',
  nickname: 'default',
  phone_country_code: 'phone-pad',
  phone: 'phone-pad',
  height: 'decimal-pad',
  weight: 'decimal-pad',
  passport: 'default',
  cpf: 'number-pad',
  postcode: 'number-pad',
  zipcode: 'number-pad',
  council: 'default',
  city: 'default',
  neighborhood: 'default',
  ccNumber: 'number-pad',
  ccExp: 'number-pad',
  ccCsc: 'number-pad',
};

const mapMaskedType = {
  phone_country_code: 'custom',
  phone: 'custom',
  cpf: 'cpf',
  height: 'custom',
  weight: 'custom',
  postcode: 'zip-code',
  zipcode: 'zip-code',
  ccNumber: 'custom',
};

const mapAutoCapitalize = {
  email: 'none',
  password: 'none',
  newPassword: 'none',
  confirm: 'none',
  repassword: 'none',
  password_confirm: 'none',
};

// https://facebook.github.io/react-native/docs/textinput#autocompletetype
// https://developers.google.com/web/fundamentals/design-and-ux/input/forms#valores_de_atributo_name_e_autocomplete_de_entrada_recomendados
const mapAutoCompleteType = {
  email: 'email',
  first_name: 'name',
  nickname: 'name',
  password: 'password',
  phone: 'tel',
  street: 'street-address',
  postcode: 'postal-code',
  zipcode: 'postal-code',
  ccNumber: 'cc-number',
  ccExp: 'cc-exp',
  ccCsc: 'cc-csc',
};

const mapMaxLength = {
  postcode: 9,
  zipcode: 9,
  height: 4,
  weight: 4,
  ccExp: 7,
  ccCsc: 4,
};

const mapPlaceholder = {
  ccNumber: '0000 0000 0000 0000',
  ccExp: 'MM/AA',
  ccCsc: '000',
};

export default class TextInput extends React.PureComponent {
  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
  }

  componentDidMount() {
    this.onChange(this.props.value);
  }

  // public method
  focus = () => {
    if (this.inputRef.current) {
      if (this.inputRef.current._inputElement) {
        this.inputRef.current._inputElement.focus();
      } else {
        this.inputRef.current.focus();
      }
    }
  };

  onChange = text => {
    // o Google Pay não funciona bem com o react-native-masked-text para o ccExp, então tratamos manualmente
    if (this.props.name === 'ccExp') {
      text = MaskService.toMask('custom', text, { mask: '99/9999' });
      // resolve a data de expiração no formato MMAAAA ou MM/AAAA se vier por autocomplete ou rápida digitação
      if (text.length > 5) {
        text = `${text.substr(0, 2)}/${text.substr(-2)}`;
      }
    }

    if (this.props.onChange) {
      this.props.onChange(text, this.props.name);
    }
  };

  onSubmit = () => {
    if (this.props.nextInput && this.props.nextInput.current) {
      this.props.nextInput.current.focus();
    }

    if (this.props.onSubmit) {
      this.props.onSubmit();
    }
  };

  getMask = (name, value) => {
    switch (name) {
      case 'phone_country_code':
        return '+9999';

      case 'phone':
        if (this.props.international) {
          return '999 9999 9999';
        }
        if (value && value.length === 15) {
          return '(99) 99999-9999';
        }
        return '(99) 9999-99999';

      case 'height':
        return '9.99';

      case 'weight':
        if (value && value.length === 5) {
          return '999.9';
        } else {
          return '99.99';
        }

      case 'ccNumber':
        return '9999 9999 9999 9999';

      default:
        return '';
    }
  };

  renderLabel = () => {
    const { label, required, error } = this.props;
    return (
      <Text style={[styles.label, error ? styles.labelError : null]}>
        <I18n>{label}</I18n>
        {required ? '*' : ''}
      </Text>
    );
  };

  renderInput = () => {
    const { name, value } = this.props;

    const inputProps = {
      autoCapitalize: mapAutoCapitalize[name],
      keyboardType: mapKeyboardType[name],
      placeholder: mapPlaceholder[name],
      secureTextEntry: mapSecureTextEntry[name],
      textContentType: mapTextContentType[name],
      editable: !this.props.disabled,
      style: [
        styles.input,
        this.props.disabled ? styles.disabled : null,
        { width: this.props.width ? this.props.width : '100%' },
      ],
      onChangeText: this.onChange,
      onSubmitEditing: this.onSubmit,
      blurOnSubmit: !this.props.nextInput,
      returnKeyType: this.props.nextInput
        ? 'next'
        : this.props.onSubmit
        ? 'done'
        : null,
      ...this.props,
      value: this.props.value || '',
    };

    // remove o evento onChange default para não duplicar o callback
    delete inputProps.onChange;

    if (mapMaskedType[name]) {
      return (
        <TextInputMask
          type={mapMaskedType[name]}
          options={
            mapMaskedType[name] === 'custom'
              ? { mask: this.getMask(name, value) }
              : false
          }
          refInput={ref => {
            // react-native-masked-text não tem suporte para React.createRef()
            this.inputRef.current = ref;
          }}
          {...inputProps}
        />
      );
    } else {
      return (
        <NativeTextInput
          autoCompleteType={mapAutoCompleteType[name]}
          maxLength={mapMaxLength[name]}
          ref={this.inputRef}
          {...inputProps}
        />
      );
    }
  };

  renderCaption = () => {
    const { caption } = this.props;
    return (
      <Text style={styles.footerText}>
        <I18n>{caption}</I18n>
      </Text>
    );
  };

  renderError = () => {
    const { error } = this.props;
    return (
      <Text style={[styles.footerText, styles.error]}>
        <I18n>{error}</I18n>
      </Text>
    );
  };

  render() {
    return (
      <>
        {!!this.props.label && this.renderLabel()}
        {this.renderInput()}
        {!!this.props.caption && this.renderCaption()}
        {!!this.props.error && this.renderError()}
      </>
    );
  }
}

const styles = StyleSheet.create({
  label: {
    color: Theme.color.darkGray,
    fontFamily: Theme.fonts.barlow,
    fontSize: 11,
    textTransform: 'uppercase',
  },
  labelError: {
    color: Theme.color.magenta,
  },
  input: {
    fontFamily: Theme.fonts.barlow,
    fontSize: 18,
    lineHeight: 18,
    color: Theme.color.veryDarkGray,
    height: 40,
    borderBottomWidth: 2,
    borderColor: Theme.color.veryLightGray,
  },
  disabled: {
    color: Theme.color.gray,
  },
  footerText: {
    color: Theme.color.darkGray,
    fontFamily: Theme.fonts.barlow,
    fontSize: 13,
    lineHeight: 16,
    marginTop: 6,
  },
  error: {
    color: Theme.color.magenta,
  },
});
