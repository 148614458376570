import React from 'react';
import Svg, { Path } from 'react-native-svg';
import Theme from '../libs/Theme';

export default class CloseIcon extends React.PureComponent {
  static defaultProps = {
    color: Theme.color.white,
  };

  render() {
    return (
      <Svg width={18} height={18} viewBox="0 0 22 22" {...this.props}>
        <Path
          fill={this.props.color}
          d="M18 2.4l1.4 1.4-7.3 7.2 7.3 7.2-1.4 1.4-7.3-7.2-7.3 7.2L2 18.2 9.3 11 2 3.8l1.4-1.4 7.3 7.2L18 2.4z"
        />
      </Svg>
    );
  }
}
