import React from 'react';
import { StyleSheet, View } from 'react-native';
import TrainerCard from './TrainerCard';

const HORIZONTAL_GUTTER = 10;
const VERTICAL_GUTTER = 20;

export default function TrainersGrid({ trainers, onPress }) {
  return (
    <View style={styles.container}>
      {trainers.map(trainer => (
        <View key={trainer} style={styles.trainer}>
          <TrainerCard id={trainer} onPress={onPress} />
        </View>
      ))}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    paddingHorizontal: 20,
    marginHorizontal: -HORIZONTAL_GUTTER / 2,
    marginVertical: -VERTICAL_GUTTER / 2,
  },
  trainer: {
    marginHorizontal: HORIZONTAL_GUTTER / 2,
    marginVertical: VERTICAL_GUTTER / 2,
  },
});
