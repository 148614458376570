import React, { useCallback } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import moment from 'moment-timezone';
import AnalyticsService from '../libs/AnalyticsService';
import { watch, addToCalendar } from '../libs/LessonActions';
import NavigationService from '../libs/NavigationService';
import { isDonation, isLive, isChecked, checkInEnd } from '../libs/Rules';
import Theme from '../libs/Theme';
import { trainerLabel, genderContext } from '../libs/Utils';
import BorderSmallCloseIcon from '../icons/BorderSmallCloseIcon';
import CalendarIcon from '../icons/CalendarIcon';
import InfoIcon from '../icons/InfoIcon';
import LiveIcon from '../icons/LiveIcon';
import NotificationOnIcon from '../icons/NotificationOnIcon';
import PersonIcon from '../icons/PersonIcon';
import PinIcon from '../icons/PinIcon';
import LessonInfoButton from './LessonInfoButton';
import I18n from './I18n';
import DonationCard from './DonationCard';
import SponsorCard from './SponsorCard';

export default function LessonDetailsSubscribed({
  lesson,
  place,
  trainer,
  modality,
  sponsor,
  enrollment,
  onPressCancel,
}) {
  const onPressWatchAgain = useCallback(() => {
    watch(lesson?.id);
  }, [lesson]);

  const onPressMap = useCallback(() => {
    NavigationService.openUrl(
      `https://maps.google.com?q=${place?.latitude},${place?.longitude}`,
    );
  }, [place]);

  const onPressTrainer = useCallback(() => {
    NavigationService.navigate('Trainer', { id: trainer?.id });
  }, [trainer]);

  const openPressCalendar = useCallback(() => {
    NavigationService.navigate('Search', {
      place: place?.id,
      modality: modality?.id,
      trainer: trainer?.id,
    });
  }, [place, modality, trainer]);

  const openPressAddToCalendar = useCallback(() => {
    if (lesson) {
      addToCalendar(lesson.id);
      AnalyticsService.logEvent('add_to_calendar', {
        lesson_id: String(lesson.id),
      });
    }
  }, [lesson]);

  const openPressContact = useCallback(() => {
    NavigationService.navigate('Help');
  }, []);

  return (
    <View style={styles.container}>
      {isLive(lesson) && isChecked(enrollment) && (
        <View style={styles.infoButton}>
          <LessonInfoButton
            icon={LiveIcon}
            title={checkInEnd(lesson) ? 'watchAgain' : 'watchNow'}
            description="dontMissAnything"
            onPress={onPressWatchAgain}
          />
        </View>
      )}
      {onPressCancel && (
        <View style={styles.infoButton}>
          <LessonInfoButton
            icon={isLive(lesson) ? NotificationOnIcon : BorderSmallCloseIcon}
            title={isLive(lesson) ? 'cancelReminder' : 'releaseVacancy'}
            description="cannotAttendToTheClass"
            onPress={onPressCancel}
          />
        </View>
      )}
      <Text style={styles.title}>
        <I18n>usefulInformation</I18n>
      </Text>
      {!isLive(lesson) && (
        <View style={styles.infoButton}>
          <LessonInfoButton
            icon={PinIcon}
            title="lessonLocation"
            description={place?.name}
            onPress={onPressMap}
          />
        </View>
      )}
      <View style={styles.infoButton}>
        <LessonInfoButton
          icon={PersonIcon}
          title={trainerLabel(modality)}
          titleContext={genderContext(trainer)}
          description={trainer?.name}
          onPress={onPressTrainer}
        />
      </View>
      <View style={styles.infoButton}>
        <LessonInfoButton
          icon={CalendarIcon}
          title="schedulesAndAgenda"
          description={moment(lesson?.start_datetime).format(
            'DD [de] MMMM [de] YYYY [às] HH:mm',
          )}
          onPress={openPressCalendar}
        />
      </View>
      <View style={styles.infoButton}>
        <LessonInfoButton
          icon={CalendarIcon}
          title="addToCalendar"
          description="addToYourPersonalCalendar"
          onPress={openPressAddToCalendar}
        />
      </View>
      {isDonation(lesson) && (
        <>
          <Text style={styles.title}>
            <I18n>support</I18n>
          </Text>
          <DonationCard trainer={trainer} lessonId={lesson.id} />
        </>
      )}
      {!!sponsor && (
        <>
          <Text style={styles.title}>
            <I18n>sponsor</I18n>
          </Text>
          <SponsorCard sponsor={sponsor} />
        </>
      )}
      <Text style={styles.title}>
        <I18n>help</I18n>
      </Text>
      <View style={styles.infoButton}>
        <LessonInfoButton
          icon={InfoIcon}
          title="contact"
          description="someProblemLetsHelpYou"
          onPress={openPressContact}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    paddingTop: 30,
    paddingBottom: 60,
    paddingHorizontal: 20,
    backgroundColor: Theme.color.lowContrastGray_USE_WITH_WISDOM,
  },
  title: {
    color: Theme.color.veryDarkGray,
    fontFamily: Theme.fonts.barlowSemiCondensedSemiBold,
    fontSize: 18,
    marginVertical: 20,
  },
  infoButton: {
    marginBottom: 10,
  },
});
