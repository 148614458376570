import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment-timezone';
import styles from './LiveChatScreen.module.scss';
import ChatApi from '../libs/ChatApi';
import DataService, { withObservables } from '../libs/DataService';
import useSync from '../hooks/useSync';
import withNavParams from '../components/withNavParams';
import LayoutWithHeader from '../components/LayoutWithHeader';

function LiveChatScreen({ lesson }) {
  const chat = `lesson_${lesson.id}`;

  const [messages, setMessages] = useState([]);
  useEffect(() => {
    let retryTimeoutId;
    let unsubscribe;

    async function setup() {
      await ChatApi.setup();

      try {
        unsubscribe = ChatApi.getMessages(chat).onSnapshot(snapshot => {
          setMessages(
            snapshot.docs
              .map(d => {
                const data = d.data();
                return {
                  id: d.id,
                  ...data,
                  time: data.time ? data.time.seconds * 1000 : 0,
                };
              })
              .sort((a, b) => b.time - a.time),
          );
        });
      } catch (error) {
        retryTimeoutId = setTimeout(() => setup(), 2 * 1000);
      }
    }
    setup();

    return () => {
      clearTimeout(retryTimeoutId);
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [chat]);

  return (
    <LayoutWithHeader>
      <div className={styles.container}>
        <div className={styles.title}>{lesson.name}</div>
        <div className={styles.lessonDate}>
          {moment(lesson.start_datetime).format('DD/MM/YY HH:mm')}
        </div>
        {messages.map(m => (
          <div key={m.id} className={styles.message}>
            <span className={styles.name}>{m.name}</span> {m.text}
            <span
              className={styles.time}
              title={moment(m.time).format('DD/MM/YY HH:mm')}
            >
              {moment(m.time).format('HH:mm')}
            </span>{' '}
          </div>
        ))}
      </div>
    </LayoutWithHeader>
  );
}
function LiveChatScreen_Sync(props) {
  const { id, lesson } = props;

  const { syncing, error } = useSync(
    useCallback(() => {
      return DataService.syncLesson(id);
    }, [id]),
  );

  if (!lesson) {
    return (
      <LayoutWithHeader loading={syncing} error={error || 'lessonsNotFound'} />
    );
  }

  return <LiveChatScreen {...props} />;
}

const enhance = withObservables(['id'], ({ id }) => ({
  lesson: DataService.observeLesson(id),
}));

export default withNavParams(enhance(LiveChatScreen_Sync));
