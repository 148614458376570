import React from 'react';
import { View, TouchableOpacity, StyleSheet, Text } from 'react-native';
import Theme from '../libs/Theme';
import CheckIcon from '../icons/CheckIcon';
import I18n from './I18n';

export default class CheckboxInput extends React.PureComponent {
  onPress = () => {
    if (this.props.onChange) {
      this.props.onChange(!this.props.value, this.props.name);
    }
  };

  render() {
    const { value, label, error, before } = this.props;

    return (
      <TouchableOpacity onPress={this.onPress} style={styles.container}>
        {before}
        <View style={[styles.box, value && styles.selected]}>
          {value && (
            <CheckIcon color={Theme.color.white} width={12} height={12} />
          )}
        </View>
        {!!label && (
          <View style={styles.labelContainer}>
            <Text style={styles.label}>
              <I18n>{label}</I18n>
            </Text>
            {!!error && (
              <Text style={styles.error}>
                <I18n>{error}</I18n>
              </Text>
            )}
          </View>
        )}
      </TouchableOpacity>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
  },
  box: {
    width: 18,
    height: 18,
    borderRadius: 4,
    backgroundColor: Theme.color.white,
    borderColor: Theme.color.veryLightGray,
    borderWidth: 2,
    justifyContent: 'center',
    alignItems: 'center',
  },
  selected: {
    backgroundColor: Theme.color.magenta,
    borderWidth: 0,
  },
  labelContainer: {
    flexGrow: 1,
    flexShrink: 1,
    marginLeft: 10,
  },
  label: {
    color: Theme.color.darkGray,
    fontFamily: Theme.fonts.barlow,
    fontSize: 13,
    lineHeight: 16,
  },
  error: {
    color: Theme.color.magenta,
    fontFamily: Theme.fonts.barlow,
    fontSize: 13,
    lineHeight: 16,
    marginTop: 5,
  },
});
