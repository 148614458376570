import React, { useState, useEffect } from 'react';
import TransitionView from './TransitionView';

export default function FadeView(props) {
  const { start = 0, end = 1 } = props;

  const [value, setValue] = useState(start);

  useEffect(() => {
    setValue(end);
  }, [end]);

  return <TransitionView {...props} value={value} />;
}
