import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import Theme from '../libs/Theme';
import I18n from './I18n';

export default function Empty({ text, color }) {
  return (
    <View style={styles.container}>
      <Text style={[styles.empty, { color }]}>
        <I18n>{text}</I18n>
      </Text>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    marginVertical: 100,
  },
  empty: {
    color: Theme.color.veryDarkGray,
    fontFamily: Theme.fonts.barlow,
    fontSize: 16,
    lineHeight: 24,
    textAlign: 'center',
    paddingHorizontal: 50,
  },
});
