import React from 'react';
import Svg, { Path } from 'react-native-svg';

export default function HeartIcon(props) {
  return (
    <Svg width={22} height={22} viewBox="0 0 22 22" {...props}>
      <Path
        fill={props.color}
        d="M11 21.4l-.7-.8s-6.1-6.4-7-7.3C1.8 11.7.1 9.5.1 6.7c0-2.6 1.5-5.6 5.8-5.6 1.7 0 3.6 1 5.1 2.6 1.5-1.6 3.4-2.6 5.1-2.6 4.3 0 5.8 3 5.8 5.6 0 2.8-1.7 5-3.2 6.6-.9.9-7 7.3-7 7.3l-.7.8z"
      />
    </Svg>
  );
}
