import { useRef, useEffect, useCallback } from 'react';

export default function useTimeout() {
  const timeout = useRef(null);

  useEffect(() => {
    return () => {
      clearTimeout(timeout.current);
    };
  }, []);

  return useCallback((...args) => {
    timeout.current = setTimeout(...args);
  }, []);
}
