import { Platform } from 'react-native';
import axios from 'axios';
import qs from 'qs';
import _ from 'lodash';
import {
  isNetworkError,
  isTimeoutError,
  isResponseTimedOut,
  isResponseCanceled,
} from './ApiUtils';
import AnalyticsService from './AnalyticsService';
import AuthService from './AuthService';
import StorageService from './StorageService';
import DataService from './DataService';
import { API_URL, API_ALTERNATIVE_URL } from './Env';
import NotificationService from './NotificationService';
import SentryService from './SentryService';
import { RIO_DE_JANEIRO } from './Utils';
import LanguageService from '../languages/LanguageService';
import { setCities } from '../store/cities';
import { getUser, setUser, setUserSettings, deleteUser } from '../store/user';
import { addAddress, deleteAddress } from '../store/address';
import { updateSettings, resetSettings } from '../store/settings';
import {
  addEmergencyContact,
  addEmergencyContacts,
  deleteEmergencyContacts,
} from '../store/emergencyContacts';
import { setAnamnesis, deleteAnamnesis } from '../store/anamnesis';
import { setDefaultFlags, deleteFlags } from '../store/featureFlag';
import {
  setCreditCards,
  addCreditCard,
  deleteCreditCard,
  deleteAllCreditCards,
} from '../store/creditCards';
import { setTransactions } from '../store/transactions';

class Api {
  constructor() {
    this.baseURL = API_URL;
    this.altenativeBaseURL = API_ALTERNATIVE_URL; // api alternativa caso a principal esteja demorando muito

    this.clientId = '6e2meC0BHufltzEJf2ojgciybDYYgJxirubrGsED';
    this.clientSecret =
      'b6IuiBl8PI2tZUQyTxw7YGBWnWCmvkLBCqM9YIKpRjkMJxvR4b8lBvqFIx2x1zovNE27VnWwzvAZMqWBdTu7Km6JIbWpGGZgPQO4e9kq7fzmAml9RUHsZUckcXXJawtd';

    this.isRefreshing = false;
  }

  request = async (method, path, data, headers, extraConfig) => {
    const config = {
      method,
      url: path,
      baseURL: this.baseURL,
      headers: {
        'Content-Type': 'application/json',
        ...headers,
      },
      data,
      ...extraConfig,
    };
    try {
      const response = await this.raceRequest(config);
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  raceRequest = async config => {
    let timeoutId;

    const request1 = this.strongRequest(config);

    const request2 = new Promise(async (resolve, reject) => {
      timeoutId = setTimeout(async () => {
        try {
          const response = await this.strongRequest({
            ...config,
            baseURL: this.altenativeBaseURL,
          });
          resolve(response);
        } catch (error) {
          reject(error);
        }
      }, 10 * 1000);
    });

    try {
      const response = await Promise.race([request1, request2]);
      clearTimeout(timeoutId);
      return response;
    } catch (error) {
      clearTimeout(timeoutId);
      throw error;
    }
  };

  strongRequest = async (config, attempts = 5, timeout = 2500) => {
    try {
      const response = await axios({
        timeout,
        ...config,
      });

      if (
        attempts &&
        (isResponseTimedOut(response) || isResponseCanceled(response))
      ) {
        return this.strongRequest(config, attempts - 1, timeout * 2);
      }

      return response;
    } catch (error) {
      if (attempts && (isNetworkError(error) || isTimeoutError(error))) {
        return this.strongRequest(config, attempts - 1, timeout * 2);
      }

      throw error;
    }
  };

  authRequest = async (method, path, data) => {
    const headers = {
      Authorization: `Bearer ${AuthService.accessToken}`,
    };
    try {
      return await this.request(method, path, data, headers);
    } catch (error) {
      const status = error.response ? error.response.status : null;
      if ((status === 401 || status === 403) && AuthService.refreshToken) {
        return await this.refreshAuth(method, path, data);
      }
      throw error;
    }
  };

  refreshAuth = async (_method, _url, _data) => {
    // se está atualizando o token, espera um pouco e tenta de novo o request com o novo token mais atual
    if (this.isRefreshing) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          this.authRequest(_method, _url, _data).then(resolve).catch(reject);
        }, 1000);
      });
    }

    // salva uma flag para saber que está tentando gerar um novo token
    // isso evita dois token serem gerados em paralelo e um invalidar o outro
    this.isRefreshing = true;

    const url = `${this.baseURL}/token/`;

    const data = qs.stringify({
      grant_type: 'refresh_token',
      refresh_token: AuthService.refreshToken,
    });

    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
    };

    const extraConfig = {
      auth: {
        username: this.clientId,
        password: this.clientSecret,
      },
      withCredentials: true,
    };

    try {
      const result = await this.request(
        'post',
        url,
        data,
        headers,
        extraConfig,
      );
      const { access_token, refresh_token } = result;
      await AuthService.setTokens(access_token, refresh_token);
    } catch (error) {
      SentryService.log(error);
      this.logout();
      this.isRefreshing = false;
      throw error;
    }

    this.isRefreshing = false;

    return this.authRequest(_method, _url, _data);
  };

  resolveGetUrl = (path, data) =>
    `${path}${qs.stringify(data, { addQueryPrefix: true, skipNulls: true })}`;

  get = (path, data) => this.request('get', this.resolveGetUrl(path, data));

  post = (path, data) => this.request('post', path, data);

  authGet = (path, data) =>
    this.authRequest('get', this.resolveGetUrl(path, data));

  authPost = (path, data) => this.authRequest('post', path, data);

  authPatch = (path, data) => this.authRequest('patch', path, data);

  authDelete = (path, data) => this.authRequest('delete', path, data);

  getMyCity = async () => {
    const { default_city } = getUser().settings;
    if (default_city) {
      return default_city;
    }

    const storageCity = StorageService.getDefaultCity();
    if (storageCity) {
      return storageCity;
    }

    await DataService.syncCities();
    setUserSettings({ default_city: RIO_DE_JANEIRO });

    return RIO_DE_JANEIRO;
  };

  getModalities = params => this.authGet('/modality/', params);

  getModality = id => this.authGet(`/modality/${id}/`);

  getEvents = params => this.authGet('/event/', params);

  getEvent = id => this.authGet(`/event/${id}/`);

  getHighlights = params => this.authGet('/highlight/', params);

  getHighlightBanners = params => this.authGet('/highlight_banner/', params);

  getLessonGroup = id => this.authGet(`/lesson_group/${id}/`);

  postLessonSpot = (enrollment, spot) =>
    this.authPost('/lesson_spot/', { enrollment, spot });

  getLessons = params => this.authGet('/lesson/', params);

  getLesson = id => this.authGet(`/lesson/${id}/`);

  getEnrollments = () => this.authGet('/enrollment/');

  updateEnrollment = (id, enrollment) =>
    this.authPatch(`/enrollment/${id}/`, enrollment);

  getTrainers = params => this.authGet('/trainer/', params);

  getTrainer = id => this.authGet(`/trainer/${id}/`);

  enqueue = id => this.lessonAction(id, 'enqueue');

  dequeue = id => this.lessonAction(id, 'dequeue');

  subscribe = id => this.lessonAction(id, 'subscribe');

  unsubscribe = id => this.lessonAction(id, 'unsubscribe');

  checkin = id => this.lessonAction(id, 'checkin');

  lessonAction = async (id, action) => {
    let lesson;
    try {
      lesson = await this.authPost(`/lesson/${id}/${action}/`);

      // some actions like enqueue/dequeue doesn't return lesson data, so we query it
      if (!lesson) {
        lesson = await DataService.findLesson(id);
      }
    } catch (error) {
      // something goes wrong, update data to try an autofix and proceed with error to show to user
      await DataService.syncLesson(id);
      await DataService.syncEnrollments();
      await this.getMe();
      throw error;
    }

    // update me and enrollment to keep data synced
    await DataService.syncEnrollments();
    await this.getMe();

    // update lesson to keep data synced
    DataService.syncLesson(id);

    return lesson;
  };

  getMe = async () => {
    const data = await this.authGet('/customer/me/');

    if (!data || !data.id) {
      return data;
    }

    const user = { ...data };

    if (!user.settings) {
      user.settings = {};
    }
    if (!user.settings.default_city) {
      user.settings.default_city = await this.getMyCity();
    }
    if (!user.default_city) {
      user.default_city = await this.getMyCity();
    }

    setUser(user);

    // TODO temos dados duplicados: reduxState.settings e reduxState.user.settings
    // precisamos separar o que é settings global, do que é settings do usuário
    // neste caso, remover a linha abaixo resolveria isso
    // mas pode dar propriedades indefinidas em outras partes do codigo
    // por isso vamos segurar por enquanto
    updateSettings(user.settings);

    return user;
  };

  updateMe = async user => {
    const newUser = await this.authPatch('/customer/me/', user);
    setUser(newUser);

    return newUser;
  };

  getUserSettings = async () => {
    const settings = await this.authGet('/customer/settings/');
    if (!settings) {
      return null;
    }
    if (!settings.default_city) {
      settings.default_city = await this.getMyCity();
      await this.updateUserSettings({ default_city: settings.default_city });
    }
    setUserSettings(settings);
    return settings;
  };

  getUserAddress = async () => {
    const address = await this.authGet('/customer/address/');
    addAddress(address);

    return address;
  };

  updateUserSettings = setting =>
    this.authPatch('/customer/settings/', setting);

  updateCustomerSettings = this.updateUserSettings;

  updateUserAddress = address => this.authPatch('/customer/address/', address);

  updatePassword = password => this.authPost('/password/change/', password);

  deleteAccount = password =>
    this.authDelete('/customer/cancel_account/', password);

  getCities = async () => {
    const response = await this.authGet('/city/');
    if (Array.isArray(response)) {
      setCities(_.cloneDeep(response));
    } else {
      this.logMalformattedResponse(response);
    }
    return response;
  };

  getCity = id => this.authGet(`/city/${id}/`);

  getNeighborhoods = () => this.authGet('/neighborhood/');

  getNeighborhood = id => this.authGet(`/neighborhood/${id}/`);

  getPlaces = params => this.authGet('/place/', params);

  getPlace = id => this.authGet(`/place/${id}/`);

  getProduct = id => this.authGet(`/product/${id}/`);

  getAddressByZip = async zipCode => {
    const response = await this.strongRequest({
      method: 'get',
      url: `https://viacep.com.br/ws/${zipCode}/json/`,
      headers: {
        'Cache-Control': 'no-cache',
      },
    });
    const data = response.data;
    if (typeof data === 'object') {
      return data;
    } else {
      this.logMalformattedResponse(data);
    }
    return {};
  };

  getContentSearch = params => this.authGet('/content_search/', params);

  getSponsors = () => this.authGet('/sponsor/');

  getSponsor = id => this.authGet(`/sponsor/${id}/`);

  getSponsorGroups = () => this.authGet('/sponsor_group/');

  updateSponsorGroup = (id, is_member) =>
    this.authPatch('/sponsor_group/', [{ id, is_member }]);

  getGlobalSettings = async () => {
    const settings = await this.authGet('/global_settings/');
    updateSettings(settings);
    return settings;
  };

  getAnamnesis = async () => {
    const anamnesis = await this.authGet('/anamnesis/');
    setAnamnesis(anamnesis);
    return anamnesis;
  };

  updateAnamnesis = async data => {
    return await this.authPatch('/anamnesis/', data);
  };

  getEmergencyContacts = () =>
    this.authGet('/emergency-contact/').then(addEmergencyContacts);

  updateEmergencyContacts = (id, contact) =>
    this.authPatch(`/emergency-contact/${id}/`, contact).then(
      addEmergencyContact,
    );

  createEmergencyContacts = contact =>
    this.authPost('/emergency-contact/', contact).then(
      this.getEmergencyContacts,
    );

  getExercises = params => this.authGet('/exercise/', params);

  getExercise = id => this.authGet(`/exercise/${id}/`);

  getExerciseModalities = params => this.authGet('/exercise_modality/', params);

  getExerciseRequirements = params =>
    this.authGet('/exercise_requirement/', params);

  getFavoriteTrainers = () => this.authGet('/favorite_trainer/');

  postFavoriteTrainer = trainer =>
    this.authPost('/favorite_trainer/', { trainer });

  deleteFavoriteTrainer = trainer =>
    this.authDelete('/favorite_trainer/', { trainer });

  getFavoriteWorkouts = () => this.authGet('/favorite_workout/');

  postFavoriteWorkout = workout =>
    this.authPost('/favorite_workout/', { workout });

  deleteFavoriteWorkout = workout =>
    this.authDelete('/favorite_workout/', { workout });

  getWorkoutCollections = params =>
    this.authGet('/workout_collection/', params);

  getWorkoutCollection = id => this.authGet(`/workout_collection/${id}/`);

  getWorkouts = params => this.authGet('/workout/', params);

  getWorkout = id => this.authGet(`/workout/${id}/`);

  getWorkoutConsumptions = params =>
    this.authGet('/workout_consumption/', params);

  createWorkoutConsumption = data =>
    this.authPost('/workout_consumption/', data);

  updateWorkoutConsumption = (id, data) =>
    this.authPatch(`/workout_consumption/${id}/`, data);

  getDiyHome = () => this.authGet('/diy_home/');

  getCreditCard = async () => {
    const response = await this.authGet('/creditcard/');
    if (Array.isArray(response)) {
      setCreditCards(response);
    } else {
      this.logMalformattedResponse(response);
    }
    return response;
  };

  postCreditCard = async creditCard => {
    const response = await this.authPost('/creditcard/', creditCard);
    if (response && response.id) {
      addCreditCard(response);
      return response;
    } else {
      this.logMalformattedResponse(response);
      throw new Error(response);
    }
  };

  deleteCreditCard = async cardId => {
    await this.authDelete(`/creditcard/${cardId}/`);
    deleteCreditCard(cardId);
  };

  postPayment = async (plan, price, lesson, workout, trainer) => {
    const response = await this.authPost(`/payment/${plan}/`, {
      price,
      lesson,
      workout,
      trainer,
    });
    if (response && response.session) {
      return response;
    } else {
      this.logMalformattedResponse(response);
      throw new Error(response);
    }
  };

  postPaymentCheckout = async (session, card_id) => {
    return await this.authPost(`/payment_checkout/${session}/`, {
      card_id,
    });
  };

  getTransactions = async () => {
    const response = await this.authGet('/payment_transaction/');
    if (Array.isArray(response)) {
      setTransactions(response);
    } else {
      this.logMalformattedResponse(response);
    }
    return response;
  };

  savePushNotificationSubscription = token =>
    this.authPost('/push_subscription/', {
      endpoint: token,
      device: Platform.OS,
      language: LanguageService.getLanguage(),
    });

  setPushNotificationStatus = (id, status) =>
    this.authPatch(`/push_notification/${id}/`, { status });

  recoverPassword = email => this.post('/password/reset/', { email });

  confirmPassword = (token, password) =>
    this.post('/password/confirm/', { token, password });

  getFeatureFlag = async () => {
    const response = await this.strongRequest({
      method: 'get',
      url: 'https://app.mude.fit/feature-flag.json',
      headers: {
        'Cache-Control': 'no-cache',
      },
    });
    const data = response.data;
    if (typeof data === 'object') {
      setDefaultFlags(data);
    } else {
      this.logMalformattedResponse(data);
    }
    return data;
  };

  createAccount = async user => {
    SentryService.setUser(user);
    AnalyticsService.setUser(user);

    const result = await this.post('/signup/', user);
    await this.completeLogin(result);

    AnalyticsService.logEvent('sign_up', { method: 'email' });

    return result;
  };

  login = async user => {
    SentryService.setUser(user);
    AnalyticsService.setUser(user);

    const url = `${this.baseURL}/token/`;

    const data = qs.stringify({
      grant_type: 'password',
      username: user.email,
      password: user.password,
    });

    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
    };

    const extraConfig = {
      auth: {
        username: this.clientId,
        password: this.clientSecret,
      },
      withCredentials: true,
    };

    const result = await this.request('post', url, data, headers, extraConfig);
    await this.completeLogin(result);

    AnalyticsService.logEvent('login', { method: 'email' });

    return result;
  };

  facebookLogin = async access_token => {
    const result = await this.post('/signup/', {
      provider: 'facebook',
      access_token,
    });
    await this.completeLogin(result);

    AnalyticsService.logEvent('login', { method: 'facebook' });

    return result;
  };

  googleLogin = async access_token => {
    const result = await this.post('/signup/', {
      provider: 'google-oauth2',
      access_token,
    });
    await this.completeLogin(result);

    AnalyticsService.logEvent('login', { method: 'google' });

    return result;
  };

  appleLogin = async access_token => {
    const result = await this.post('/signup/', {
      provider: 'apple-id',
      access_token,
    });
    await this.completeLogin(result);

    AnalyticsService.logEvent('login', { method: 'apple' });

    return result;
  };

  completeLogin = async ({ access_token, refresh_token }) => {
    await AuthService.setTokens(access_token, refresh_token);

    // atualiza os dados do usuário, mas não impede o login caso haja alguma falha
    try {
      await this.getMe();
      await this.getUserSettings();
    } catch (error) {}

    // save user with user.id after login
    const user = getUser();
    SentryService.setUser(user);
    AnalyticsService.setUser(user);

    // save notification token related to logged user
    NotificationService.saveUserToken();

    DataService.syncAllData();
  };

  logout = async () => {
    const data = new FormData();
    data.append('client_id', this.clientId);
    data.append('client_secret', this.clientSecret);
    data.append('token', AuthService.accessToken);
    try {
      this.post('/revoke_token/', data);
    } catch (error) {}

    await AuthService.clear();
    await StorageService.clear();

    DataService.clearEnrollments();
    DataService.clearFavoriteTrainers();
    DataService.clearFavoriteWorkouts();
    DataService.clearWorkoutConsumptions();

    deleteUser();
    deleteAnamnesis();
    deleteEmergencyContacts();
    deleteAddress();
    deleteFlags();
    deleteAllCreditCards();
    resetSettings();
  };

  logMalformattedResponse = response => {
    if (isResponseTimedOut(response) || isResponseCanceled(response)) {
      return;
    }

    const error = new Error('Malformatted Response');
    error.response = { data: response };
    SentryService.log(error);
  };
}

export default new Api();
