import { resolveError } from './FormUtils';

// TODO [web migration]
// import NetInfo from '@react-native-community/netinfo';
const NetInfo = {
  addEventListener: () => {},
  fetch: async () => ({ type: 'wifi' }),
};

export const Bandwidth = {
  ZERO: 'zero',
  LOW: 'low',
  MEDIUM: 'medium',
  DEFAULT: 'medium',
  HIGH: 'high',
  ULTRA: 'ultra',
};

class NetInfoService {
  constructor() {
    this.bandwidth = Bandwidth.DEFAULT;
  }

  setup = async () => {
    this.unsubscribe = NetInfo.addEventListener(this.resolveState);

    try {
      const state = await NetInfo.fetch();
      this.resolveState(state);
    } catch (error) {
      resolveError(error, false, '', false);
    }
  };

  destroy = () => {
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  };

  resolveState = state => {
    const { type, details } = state;

    // se não estiver conectado, nem precisa processar os dados
    // atenção pq o state.isConnected pode dar um falso negativo quanto type === 'unknown'
    if (type === 'none') {
      this.bandwidth = Bandwidth.ZERO;
      return;
    }

    const bandwidthMap = {
      '2g': Bandwidth.LOW,
      '3g': Bandwidth.MEDIUM,
      '4g': Bandwidth.ULTRA, // ultra pq 4g muitas vezes é mais rápido q wifi, tb fazemos um filtro abaixo caso tenha cobrança financeira
      wifi: Bandwidth.ULTRA,
    };

    // escolhe a banda de acordo com o tipo de conexão
    const id = type === 'cellular' ? (details || {}).cellularGeneration : type;
    this.bandwidth = bandwidthMap[id] || Bandwidth.DEFAULT;

    // se a conexão tiver cobrança, não utiliza a maior banda, alivia um pouco para não abusar 💸
    if (
      this.bandwidth === Bandwidth.ULTRA &&
      (details || {}).isConnectionExpensive
    ) {
      this.bandwidth = Bandwidth.HIGH;
    }
  };

  getBandwidth = () => {
    return this.bandwidth;
  };
}

export default new NetInfoService();
