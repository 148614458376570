import React from 'react';
import Header from '../components/Header';
import KeyboardScrollView from '../components/KeyboardScrollView';
import ForgotPasswordForm from '../components/ForgotPasswordForm';

export default function ForgotPasswordScreen() {
  return (
    <>
      <Header />
      <KeyboardScrollView>
        <ForgotPasswordForm header="didYouForgetYourPassword" />
      </KeyboardScrollView>
    </>
  );
}
