import React from 'react';
import { View, StyleSheet } from 'react-native';
import Theme from '../libs/Theme';

export default function DotCounter({ total, current, theme }) {
  if (total < 2) {
    return null;
  }

  const themeStyle = customThemes[theme] || {};

  return (
    <View style={styles.container}>
      {new Array(total).fill(null).map((_, i) => (
        <View
          key={i}
          style={[
            styles.dot,
            themeStyle.dot,
            current === i ? styles.selected : null,
            current === i ? themeStyle.selected : null,
          ]}
        />
      ))}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  dot: {
    backgroundColor: Theme.color.veryLightGray,
    width: 6,
    height: 6,
    borderRadius: 6,
    marginHorizontal: 5,
  },
  selected: {
    backgroundColor: Theme.color.magenta,
    width: 8,
    height: 8,
    borderRadius: 8,
    marginHorizontal: 4,
  },
});

const customThemes = {
  white: StyleSheet.create({
    dot: {
      backgroundColor: Theme.color.white,
      opacity: 0.5,
    },
    selected: {
      backgroundColor: Theme.color.white,
      opacity: 1,
    },
  }),
};
