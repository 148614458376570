import Cookies from 'js-cookie';
import SentryService from './SentryService';
import { AUTH_STORAGE_KEY } from './Env';

const ACCESS_TOKEN_KEY = `${AUTH_STORAGE_KEY}_at`;
const REFRESH_TOKEN_KEY = `${AUTH_STORAGE_KEY}_rt`;

class AuthService {
  constructor() {
    this.accessToken = '';
    this.refreshToken = '';
  }

  async setup() {
    try {
      this.accessToken = Cookies.get(ACCESS_TOKEN_KEY);
      this.refreshToken = Cookies.get(REFRESH_TOKEN_KEY);
    } catch (error) {
      SentryService.log(error);
    }
  }

  async setTokens(accessToken, refreshToken) {
    this.accessToken = accessToken;
    this.refreshToken = refreshToken;

    try {
      const options = {
        expires: 365,
        secure: true,
      };
      Cookies.set(ACCESS_TOKEN_KEY, this.accessToken, options);
      Cookies.set(REFRESH_TOKEN_KEY, this.refreshToken, options);
    } catch (error) {
      SentryService.log(error);
    }
  }

  async clear() {
    this.accessToken = '';
    this.refreshToken = '';

    try {
      Cookies.remove(ACCESS_TOKEN_KEY);
      Cookies.remove(REFRESH_TOKEN_KEY);
    } catch (error) {
      SentryService.log(error);
    }
  }

  get isLogged() {
    return !!this.accessToken;
  }
}

export default new AuthService();
