import React, { useCallback } from 'react';
import { StyleSheet, View } from 'react-native';
import NavigationService from '../libs/NavigationService';
import Theme from '../libs/Theme';
import Header from '../components/Header';
import KeyboardScrollView from '../components/KeyboardScrollView';
import LoginForm from '../components/LoginForm';
import ActionButton from '../components/ActionButton';

export default function LoginScreen() {
  const onSuccess = useCallback(() => {
    NavigationService.reset('Tab');
  }, []);

  const onForgotPassword = useCallback(() => {
    NavigationService.navigate('ForgotPassword');
  }, []);

  const onPressAnonymous = useCallback(() => {
    NavigationService.reset('Tab');
  }, []);

  return (
    <>
      <Header />
      <KeyboardScrollView>
        <LoginForm
          header="typeYourEmail"
          onSuccess={onSuccess}
          onForgotPassword={onForgotPassword}
        />
        <View style={styles.anonymousLogin}>
          <ActionButton
            title="logInWithoutRegistration"
            theme="white"
            onPress={onPressAnonymous}
          />
        </View>
      </KeyboardScrollView>
    </>
  );
}

const styles = StyleSheet.create({
  anonymousLogin: {
    flexGrow: 1,
    flexShrink: 1,
    alignItems: 'center',
    justifyContent: 'flex-end',
    paddingTop: 60,
    paddingBottom: 10 + Theme.dimensions.bottomSpace,
  },
});
