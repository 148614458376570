import React, { useCallback } from 'react';
import { View, StyleSheet, Text } from 'react-native';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import DataService, {
  withObservables,
  withMapProps,
} from '../libs/DataService';
import { getLessonStatus, lessonStatus, isSubscribed } from '../libs/Rules';
import NavigationService from '../libs/NavigationService';
import Theme from '../libs/Theme';
import BookedIcon from '../icons/BookedIcon';
import NotificationOnIcon from '../icons/NotificationOnIcon';
import I18n from './I18n';
import ActivityCardView from './ActivityCardView';

function LessonActivityCard_Icon({ enrollment, status }) {
  if (
    status === lessonStatus.SUBSCRIBED ||
    status === lessonStatus.CHECKIN ||
    status === lessonStatus.CHECKIN_DONE
  ) {
    return <BookedIcon color={Theme.color.white} style={styles.icon} />;
  }

  if (
    status === lessonStatus.LIVE_SUBSCRIBED ||
    (status === lessonStatus.WATCH && isSubscribed(enrollment))
  ) {
    return <NotificationOnIcon color={Theme.color.white} style={styles.icon} />;
  }

  return null;
}

function LessonActivityCard({
  id,
  lesson,
  lessonGroup,
  place,
  trainer,
  modality,
  enrollment,
  user,
  sponsorGroups,
}) {
  const onPress = useCallback(() => {
    NavigationService.navigate('Lesson', { id });
  }, [id]);

  if (!lesson) {
    return null;
  }

  const status = getLessonStatus(
    lesson,
    enrollment,
    user,
    sponsorGroups,
    place,
  );

  const start = moment(lesson.start_datetime);
  const end = moment(lesson.end_datetime);

  const now = moment();
  const isLiveNow =
    start.diff(now, 'minutes') < 0 && end.diff(now, 'minutes') > 0;

  return (
    <ActivityCardView
      image={lessonGroup?.images?.[0]}
      imageOverlay={
        <>
          {lesson.is_virtual && isLiveNow ? (
            <View style={styles.liveNow}>
              <Text style={styles.liveNowText}>
                <I18n>liveNow</I18n>
              </Text>
            </View>
          ) : (
            <Text style={styles.date}>{start.format('DD MMM')}</Text>
          )}
          <Text style={styles.time}>{start.format('HH:mm')}</Text>
          <LessonActivityCard_Icon enrollment={enrollment} status={status} />
        </>
      }
      name={lesson.name}
      trainer={trainer}
      place={place}
      duration={end.diff(start, 'seconds')}
      modality={modality}
      onPress={onPress}
    />
  );
}

const styles = StyleSheet.create({
  liveNow: {
    position: 'absolute',
    top: 8,
    left: 8,
    paddingVertical: 2,
    paddingHorizontal: 5,
    backgroundColor: Theme.color.magenta,
    borderRadius: 2,
  },
  liveNowText: {
    color: Theme.color.white,
    fontFamily: Theme.fonts.barlowCondensedMedium,
    fontSize: 12,
    includeFontPadding: false,
    textTransform: 'uppercase',
  },
  date: {
    position: 'absolute',
    top: 10,
    left: 10,
    color: Theme.color.white,
    fontFamily: Theme.fonts.barlowCondensedSemiBold,
    fontSize: 12,
    textTransform: 'uppercase',
  },
  time: {
    position: 'absolute',
    top: 10,
    right: 10,
    color: Theme.color.white,
    fontFamily: Theme.fonts.barlowCondensedSemiBold,
    fontSize: 12,
  },
  icon: {
    position: 'absolute',
    bottom: 10,
    left: 10,
  },
});

const mapStateToProps = ({ user }) => ({ user });

const enhance3 = withObservables(
  ['place', 'trainer', 'modality', 'lessonGroup', 'sponsor', 'enrollment'],
  ({ place, trainer, modality, lessonGroup, sponsor, enrollment }) => ({
    place: DataService.observePlace(place),
    trainer: DataService.observeTrainer(trainer),
    modality: DataService.observeModality(modality),
    lessonGroup: DataService.observeLessonGroup(lessonGroup),
    sponsorGroups: DataService.observeSponsorGroupsBySponsor(sponsor),
    enrollment: DataService.observeEnrollmentByLessonId(enrollment),
  }),
);

const enhance2 = withMapProps(({ lesson }) => ({
  place: lesson?.place,
  trainer: lesson?.trainer,
  modality: lesson?.modality,
  lessonGroup: lesson?.lesson_group,
  sponsor: lesson?.sponsor,
  enrollment: lesson?.id,
}));

const enhance = withObservables(['id'], ({ id }) => ({
  lesson: DataService.observeLesson(id),
}));

export default connect(mapStateToProps)(
  enhance(enhance2(enhance3(LessonActivityCard))),
);
