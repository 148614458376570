import { Platform } from 'react-native';
import qs from 'qs';

export const GOOGLE_MAPS_STYLE = [
  {
    elementType: 'geometry',
    stylers: [
      {
        color: '#f5f5f5',
      },
    ],
  },
  {
    elementType: 'labels',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#616161',
      },
    ],
  },
  {
    elementType: 'labels.text.stroke',
    stylers: [
      {
        color: '#f5f5f5',
      },
    ],
  },
  {
    featureType: 'administrative.land_parcel',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'administrative.land_parcel',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#bdbdbd',
      },
    ],
  },
  {
    featureType: 'administrative.neighborhood',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'geometry',
    stylers: [
      {
        color: '#eeeeee',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'labels.text',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#757575',
      },
    ],
  },
  {
    featureType: 'poi.park',
    elementType: 'geometry',
    stylers: [
      {
        color: '#e5e5e5',
      },
    ],
  },
  {
    featureType: 'poi.park',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#9e9e9e',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'geometry',
    stylers: [
      {
        color: '#ffffff',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'labels',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'road.arterial',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#757575',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry',
    stylers: [
      {
        color: '#dadada',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#616161',
      },
    ],
  },
  {
    featureType: 'road.local',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#9e9e9e',
      },
    ],
  },
  {
    featureType: 'transit.line',
    elementType: 'geometry',
    stylers: [
      {
        color: '#e5e5e5',
      },
    ],
  },
  {
    featureType: 'transit.station',
    elementType: 'geometry',
    stylers: [
      {
        color: '#eeeeee',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [
      {
        color: '#c9c9c9',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'labels.text',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#9e9e9e',
      },
    ],
  },
];

function itemToStyleParam(item) {
  const props = [];
  if (item.featureType) {
    props.push(`feature:${item.featureType}`);
  }
  if (item.elementType) {
    props.push(`element:${item.elementType}`);
  }
  item.stylers.forEach(style => {
    Object.entries(style).forEach(([key, value]) => {
      if (key === 'color') {
        value = value.replace('#', '0x');
      }
      props.push(`${key}:${value}`);
    });
  });
  return `&style=${props.join('|')}`;
}

const qsMapStyle = GOOGLE_MAPS_STYLE.map(itemToStyleParam).join('');

export function getStaticUrl(latitude, longitude, width, height) {
  const params = qs.stringify(
    {
      center: `${latitude},${longitude}`,
      zoom: 12,
      size: `${Math.ceil(width)}x${Math.ceil(height)}`, // somente número inteiro
      key: Platform.select({
        android: 'AIzaSyD5aMJOcIKXCSIRa-of6VNJ4-IyUldJSWk',
        ios: 'AIzaSyBhGWCwZZuWSCTRWgW8CSViAXBSmq4emN4',
        web: 'AIzaSyCCyWIHxWbj5Zc-VqZVuKlxVPBtYBtX2zU',
      }),
    },
    { encode: false }, // encode converte a vírgula `,` para `%2C` e quebra no iOS
  );

  return `https://maps.googleapis.com/maps/api/staticmap?${params}${qsMapStyle}`;
}
