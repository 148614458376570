import { Model, Query } from '@nozbe/watermelondb';
import { children, field } from '@nozbe/watermelondb/decorators';
import { Associations } from '@nozbe/watermelondb/Model';
import Neighborhood from './Neighborhood';
import Place from './Place';

export default class City extends Model {
  static table = 'cities';

  static associations: Associations = {
    neighborhoods: { type: 'has_many', foreignKey: 'city' },
    places: { type: 'has_many', foreignKey: 'city' },
  };

  @children('neighborhoods') neighborhoods!: Query<Neighborhood>;
  @children('places') places!: Query<Place>;

  @field('name') name!: string;
  @field('state') state!: string;
  @field('latitude') latitude!: string;
  @field('longitude') longitude!: string;
  @field('image') image!: string;
  @field('min_time_subscription') min_time_subscription!: number;
  @field('timezone') timezone!: string;
  @field('lessons_enabled') lessons_enabled!: boolean;
  @field('country') country!: string;
  @field('is_virtual') is_virtual!: boolean;
}
