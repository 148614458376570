import React, { useCallback } from 'react';
import {
  Platform,
  StyleSheet,
  Text,
  TouchableWithoutFeedback,
  View,
} from 'react-native';
import moment from 'moment-timezone';
import DataService, {
  withObservables,
  withMapProps,
} from '../libs/DataService';
import NavigationService from '../libs/NavigationService';
import Theme from '../libs/Theme';
import useSync from '../hooks/useSync';
import I18n from './I18n';
import CouchMessage from './CouchMessage';
import ActivitiesSlider from './ActivitiesSlider';
import TrainersGrid from './TrainersGrid';

function ActivitiesTab_Section({ title, subtitle, children, cta, onPressCta }) {
  return (
    <View style={styles.section}>
      <Text style={styles.title}>
        <I18n>{title}</I18n>
      </Text>
      <Text style={styles.subtitle}>
        <I18n>{subtitle}</I18n>
      </Text>
      {children ? (
        children
      ) : (
        <TouchableWithoutFeedback onPress={onPressCta}>
          <Text style={styles.cta}>
            <I18n>{cta}</I18n>
          </Text>
        </TouchableWithoutFeedback>
      )}
    </View>
  );
}

function ActivitiesTab({ lessons, favoriteTrainers, favoriteWorkouts }) {
  const isNative = Platform.select({ native: true });

  const onPressLives = useCallback(() => {
    NavigationService.navigate('Lives');
  }, []);

  const onPressDiy = useCallback(() => {
    NavigationService.navigate('DIY');
  }, []);

  const onPressTrainers = useCallback(() => {
    NavigationService.navigate('Trainers');
  }, []);

  const futureLessons = lessons
    .filter(lesson => moment(lesson.end_datetime).diff(moment(), 'minutes') > 0)
    .sort((a, b) => new Date(a.start_datetime) - new Date(b.start_datetime));

  const workouts = favoriteWorkouts.map(id => ({ type: 'workout', id }));

  if (!futureLessons.length && !workouts.length && !favoriteTrainers.length) {
    return (
      <CouchMessage
        title="emptyActivitiesTitle"
        subtitle="emptyActivitiesText"
        cta="emptyActivitiesCta"
        onPressCta={onPressLives}
      />
    );
  }

  return (
    <>
      <ActivitiesTab_Section
        title="remindersAndSchedules"
        subtitle="yourNextLivesAndClasses"
        cta="emptyActivitiesCta"
        onPressCta={onPressLives}
      >
        {futureLessons.length > 0 && <ActivitiesSlider items={futureLessons} />}
      </ActivitiesTab_Section>
      {isNative && (
        <>
          <ActivitiesTab_Section
            title="myList"
            subtitle="toWatchAnytime"
            cta="emptyMyListCta"
            onPressCta={onPressDiy}
          >
            {workouts.length > 0 && <ActivitiesSlider items={workouts} />}
          </ActivitiesTab_Section>
          <ActivitiesTab_Section
            title="favoriteTrainers"
            subtitle="getNotifiedOfAllTheNews"
            cta="emptyTrainersCta"
            onPressCta={onPressTrainers}
          >
            {favoriteTrainers.length > 0 && (
              <TrainersGrid trainers={favoriteTrainers} />
            )}
          </ActivitiesTab_Section>
        </>
      )}
    </>
  );
}

function ActivitiesTab_Sync(props) {
  const { lessonIds } = props;

  const { syncing, error } = useSync(
    useCallback(async () => {
      await DataService.syncEnrollments();
      await DataService.syncFavoriteTrainers();
      await DataService.syncFavoriteWorkouts();
      if (lessonIds.length) {
        await DataService.syncLessons({ ids: lessonIds.join(',') });
      }
    }, [lessonIds]),
  );

  return <ActivitiesTab {...props} loading={syncing} error={error} />;
}

const styles = StyleSheet.create({
  section: {
    marginVertical: 20,
  },
  title: {
    color: Theme.color.veryDarkGray,
    fontFamily: Theme.fonts.barlowSemiCondensedBold,
    fontSize: 16,
    marginHorizontal: 20,
  },
  subtitle: {
    color: Theme.color.darkGray,
    fontFamily: Theme.fonts.barlow,
    fontSize: 14,
    marginHorizontal: 20,
    marginTop: 5,
    marginBottom: 20,
  },
  cta: {
    color: Theme.color.magenta,
    fontFamily: Theme.fonts.barlowBold,
    fontSize: 12,
    textTransform: 'uppercase',
    padding: 20,
    marginTop: -20,
  },
});

const enhance3 = withObservables(['lessonIds'], ({ lessonIds }) => ({
  lessons: DataService.observeLessonsByIds(lessonIds),
}));

const enhance2 = withMapProps(({ enrollments }) => ({
  lessonIds: enrollments.map(e => e.lesson),
}));

const enhance = withObservables([], () => ({
  enrollments: DataService.observeRegisteredEnrollments(),
  favoriteTrainers: DataService.observeFavoriteTrainers(),
  favoriteWorkouts: DataService.observeFavoriteWorkouts(),
}));

export default enhance(enhance2(enhance3(ActivitiesTab_Sync)));
