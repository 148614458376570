import StoreService from '../libs/StoreService';

const SET_LOCATION_PERMISSION_DENIED =
  'LOCAL_SETTINGS/SET_LOCATION_PERMISSION_DENIED';
const SET_MUTED = 'LOCAL_SETTINGS/SET_MUTED';
const SET_MAIN_CREDIT_CARD = 'LOCAL_SETTINGS/SET_MAIN_CREDIT_CARD';
const SET_VIDEO_SIZE = 'LOCAL_SETTINGS/SET_VIDEO_SIZE';
const SET_TOOLTIP_WEIGHT_SEEN = 'LOCAL_SETTINGS/SET_TOOLTIP_WEIGHT_SEEN';

export function setLocationPermissionDenied(value) {
  StoreService.dispatch({ type: SET_LOCATION_PERMISSION_DENIED, value });
}

export function setMuted(value) {
  StoreService.dispatch({ type: SET_MUTED, value });
}

export function setMainCreditCard(value) {
  StoreService.dispatch({ type: SET_MAIN_CREDIT_CARD, value });
}

export function setVideoSize(value) {
  StoreService.dispatch({ type: SET_VIDEO_SIZE, value });
}

export function setTooltipWeightSeen(value) {
  StoreService.dispatch({ type: SET_TOOLTIP_WEIGHT_SEEN, value });
}

export default function reducer(state = {}, action) {
  switch (action.type) {
    case SET_LOCATION_PERMISSION_DENIED: {
      return { ...state, locationPermissionDenied: action.value };
    }

    case SET_MUTED: {
      return { ...state, muted: action.value };
    }

    case SET_MAIN_CREDIT_CARD: {
      return { ...state, mainCreditCard: action.value };
    }

    case SET_VIDEO_SIZE: {
      return { ...state, videoSize: action.value };
    }

    case SET_TOOLTIP_WEIGHT_SEEN: {
      return { ...state, tooltipWeightSeen: action.value };
    }

    default:
      return state;
  }
}
