import StoreService from '../libs/StoreService';
import LanguageService from '../languages/LanguageService';

const UPDATE_SETTINGS = 'SETTINGS/UPDATE';
const RESET_SETTINGS = 'SETTINGS/RESET';

export const updateSettings = settings => {
  StoreService.dispatch({
    type: UPDATE_SETTINGS,
    payload: settings,
  });
};

export const resetSettings = () => {
  StoreService.dispatch({
    type: RESET_SETTINGS,
  });
};

const updateLanguage = settings => {
  const language = settings && settings.language;

  if (!language) {
    return;
  }

  if (language.indexOf('pt') > -1) {
    LanguageService.setLanguage('pt-br');
  } else {
    LanguageService.setLanguage('en');
  }

  return language;
};

export default function reducer(state = {}, action) {
  switch (action.type) {
    case UPDATE_SETTINGS: {
      const output = {
        ...state,
        ...action.payload,
      };

      output.language = updateLanguage(output);

      // removemos as possíveis configurações duplicadas para evitar inconsistencia de dados
      delete output.default_city;
      delete output.city;

      return output;
    }

    case RESET_SETTINGS: {
      // TODO
      // possivelmente queremos resetar o localSettings, e o settings ser apenas da mude
      // que não precisará resetar por causa disso, mas é importante validar e pensar certinho
      // const newSettings = {
      //   language: i18n && i18n.services.languageDetector.detect(['navigator']),
      // };
      // updateLanguage(newSettings);
      // return newSettings;
      return state;
    }

    default:
      return state;
  }
}
