import React, { useCallback } from 'react';
import { StyleSheet, Text, TouchableHighlight, View } from 'react-native';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import Theme from '../libs/Theme';

function DateBox({ date, theme, size, onPress }) {
  const _onPress = useCallback(() => {
    onPress?.(date);
  }, [date, onPress]);

  if (!date) {
    return null;
  }

  const momentDate = moment(date);

  const themeStyle = customThemes[theme] || {};
  const sizeStyle = customSizes[size] || {};

  return (
    <TouchableHighlight
      onPress={_onPress}
      disabled={!onPress}
      underlayColor={Theme.color.transparent}
      activeOpacity={0.5}
    >
      <View style={[styles.box, themeStyle.box, sizeStyle.box]}>
        <Text style={[styles.text, themeStyle.text, sizeStyle.text]}>
          {momentDate.format('ddd')}
          {'\n'}
          {momentDate.format('DD')}
        </Text>
      </View>
    </TouchableHighlight>
  );
}

const styles = StyleSheet.create({
  box: {
    height: 40,
    width: 32,
    borderRadius: 4,
    paddingTop: 7,
    paddingLeft: 7,
  },
  text: {
    color: Theme.color.darkGray,
    fontFamily: Theme.fonts.barlowCondensed,
    fontSize: 13,
    lineHeight: 13,
    textTransform: 'uppercase',
  },
});

const customThemes = {
  magenta: StyleSheet.create({
    box: {
      backgroundColor: Theme.color.magenta,
    },
    text: {
      color: Theme.color.white,
    },
  }),
  gray: StyleSheet.create({
    box: {
      backgroundColor: Theme.color.gray,
    },
    text: {
      color: Theme.color.white,
    },
  }),
  white: StyleSheet.create({
    box: {
      backgroundColor: Theme.color.transparent,
    },
    text: {
      color: Theme.color.white,
    },
  }),
};

const customSizes = {
  large: StyleSheet.create({
    box: {
      height: 48,
      width: 34,
      paddingTop: 10,
    },
    text: {
      fontSize: 14,
      lineHeight: 14,
    },
  }),
};

// connect to language to listen updates
const mapStateToProps = ({ settings: { language } }) => ({ language });

export default connect(mapStateToProps)(DateBox);
