import React, { useCallback } from 'react';
import { Platform, StyleSheet, Text, View } from 'react-native';
import { resolveError } from '../libs/FormUtils';
import Theme from '../libs/Theme';
import { formatPrice } from '../libs/Utils';
import StarIcon from '../icons/StarIcon';
import I18n from './I18n';
import AsyncButton from './AsyncButton';

LessonActionHighlightButton.defaultProps = {
  theme: 'white',
};

export default function LessonActionHighlightButton({
  theme,
  title,
  icon,
  iconProps,
  price,
  rating,
  onPress,
}) {
  const IconComponent = icon;

  const _onPress = useCallback(async () => {
    try {
      await onPress?.();
    } catch (error) {
      resolveError(error, true);
    }
  }, [onPress]);

  return (
    <AsyncButton
      theme={theme}
      onPress={_onPress}
      style={[styles.container, theme !== 'magenta' && styles.withBorder]}
    >
      <View style={styles.contentContainer}>
        <View style={styles.title}>
          <Text
            style={[
              styles.titleText,
              theme === 'magenta' && { color: Theme.color.white },
            ]}
          >
            <I18n>{title}</I18n>
          </Text>
          {typeof price === 'number' && (
            <Text style={styles.priceText}>
              {price > 0 ? formatPrice(price) : <I18n>free</I18n>}
            </Text>
          )}
          {!!rating && (
            <View style={styles.ratingStars}>
              {[1, 2, 3, 4, 5].map(value => (
                <StarIcon
                  key={value}
                  color={
                    value <= rating
                      ? Theme.color.magenta
                      : Theme.color.veryLightGray
                  }
                  filled
                  width={12}
                  height={12}
                  style={styles.ratingStar}
                />
              ))}
            </View>
          )}
        </View>
        {!!IconComponent && (
          <View style={styles.icon}>
            <IconComponent
              color={Theme.color.magenta}
              height={16}
              width={16}
              {...iconProps}
            />
          </View>
        )}
      </View>
    </AsyncButton>
  );
}

const styles = StyleSheet.create({
  container: {
    height: 80 + Theme.dimensions.bottomSpace,
    paddingBottom: Theme.dimensions.bottomSpace,
    paddingRight: 20,
    paddingLeft: Platform.select({
      native: Theme.dimensions.windowWidth / 2 - 136 - 20,
      web: 20,
    }),
  },
  withBorder: {
    borderColor: Theme.color.veryLightGray,
    borderRightWidth: 1,
    borderTopWidth: 1,
  },
  contentContainer: {
    width: '100%',
    flexDirection: 'row',
    flexGrow: 1,
    flexShrink: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    flexGrow: 1,
    flexShrink: 1,
    marginRight: 15,
  },
  titleText: {
    color: Theme.color.gray,
    fontFamily: Theme.fonts.barlowBold,
    fontSize: 12,
    lineHeight: 18,
    textTransform: 'uppercase',
    textAlign: Platform.select({
      web: 'center',
    }),
  },
  priceText: {
    color: Theme.color.white,
    fontFamily: Theme.fonts.neuePlakCompBold,
    fontSize: 20,
    lineHeight: 23,
    letterSpacing: 1,
    textTransform: 'uppercase',
  },
  ratingStars: {
    flexDirection: 'row',
    marginTop: 5,
    justifyContent: Platform.select({
      web: 'center',
    }),
  },
  ratingStar: {
    marginRight: 5,
  },
  icon: {
    backgroundColor: Theme.color.white,
    borderColor: Theme.color.veryLightGray,
    borderWidth: 2,
    width: 40,
    height: 40,
    borderRadius: 40,
    alignItems: 'center',
    justifyContent: 'center',
  },
});
