// TODO [web migration]

class GeolocationService {
  hasPermission = () => {};

  requestPermission = () => {};

  getCurrentPosition = () => {};
}

export default new GeolocationService();
