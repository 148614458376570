import React from 'react';
import LessonActivityCard from './LessonActivityCard';
import WorkoutActivityCard from './WorkoutActivityCard';

export default function ActivityCard({ item }) {
  const { type, is_virtual } = item;
  const _type =
    type || (typeof is_virtual !== 'undefined' ? 'lesson' : 'workout');

  switch (_type) {
    case 'lesson':
      return <LessonActivityCard id={item.id} />;
    case 'workout':
      return <WorkoutActivityCard id={item.id} />;
    default:
      return null;
  }
}
