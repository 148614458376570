import React from 'react';
import Svg, { Path } from 'react-native-svg';

export default function DiyIcon(props) {
  return (
    <Svg width={22} height={22} viewBox="0 0 22 22" {...props}>
      <Path
        fill={props.color}
        d="M3.8 2h14.5c1.8-.1 3.4 1.1 3.7 2.9V17.2c-.2 1.8-1.8 3.2-3.6 3.1H3.8C2 20.4.4 19.2.1 17.4V5.1C.2 3.4 1.7 2 3.4 2h.4zM1.9 5v12.2c.1.9.9 1.6 1.7 1.6h14.6c.9.1 1.7-.5 1.9-1.4V5.1c-.1-.9-.9-1.6-1.7-1.6H3.8c-.9 0-1.7.6-1.9 1.5zm6.2 1.3h.1l7.5 3.9c.3.2.5.5.5.8 0 .3-.1.6-.4.8l-.1.2-7.5 4c-.3.1-.6.1-.9-.1-.1-.1-.3-.4-.3-.6V7.2c0-.3.1-.6.4-.8.2-.1.5-.1.7-.1zm1 2.1c-.3-.2-.6 0-.6.3v4.8c0 .3.3.5.6.3l4.8-2.5c.3-.2.3-.4 0-.6L9.1 8.4z"
      />
    </Svg>
  );
}
