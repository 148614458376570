import StoreService from '../libs/StoreService';

const SET_CITIES = 'CITIES/SET_CITIES';

export function setCities(cities) {
  StoreService.dispatch({ type: SET_CITIES, cities });
}

export default function reducer(state = [], action) {
  switch (action.type) {
    case SET_CITIES: {
      // TODO
      // para simplificar e padronizar com o restante das stores
      // podemos transformar o state de array para object.
      // para isso precisamos alterar todos os connect que utilizam o `cities`
      return [...action.cities];
    }

    default:
      return state;
  }
}
