export default {
  shareLesson:
    "Let's go to {{lesson}}! {{weekDay}} day {{day}} at {{time}} with {{trainer}} {{url}}",
  shareLessonWithVacancies:
    "Let's go to {{lesson}}! {{weekDay}} day {{day}} at {{time}} with {{trainer}}, only {{count}} vacancy! {{url}}",
  shareLessonWithVacancies_plural:
    "Let's go to {{lesson}}! {{weekDay}} day {{day}} at {{time}} with {{trainer}}, only {{count}} vacancies! {{url}}",

  shareCollection: '{{collection}} {{url}}',

  shareWorkout: "Let's practice {{workout}}! {{url}}",

  shareEvent: '{{event}} {{url}}',

  shareTrainer: 'Meet {{trainer}} in Mude! {{url}}',
};
