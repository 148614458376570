export default {
  about: 'About',
  birthDate: 'Date of birth',
  cancel: 'Cancel',
  change: 'Change',
  city: 'City',
  close: 'Close',
  confirmPassword: 'Confirm password',
  country: 'Country',
  cpf: 'CPF',
  createYourPassword: 'Ready! Now just create your password!',
  currentPassword: 'Current password',
  ddi: 'CC',
  document: 'Document',
  dontKnowZipcode: "I don't know my zipcode",
  email: 'E-mail',
  emailAddress: 'E-mail Address',
  event: 'Event',
  exit: 'Exit',
  genderYouIdentify: 'Which gender do you identify yourself with?',
  goToWebsite: 'Go to Website',
  height: 'Height',
  IAmOnTheWay: "I'm on the way!",
  language: 'Language',
  lesson: 'Lesson',
  lessons: 'Lessons',
  letsGo: "Let' Go!",
  levelOfKinship: 'Relationship',
  modalities: 'Modalities',
  modality: 'Modality',
  name: 'Name',
  neighborhood: 'Neighborhood',
  street: 'Street',
  street_number: 'Apt/number',
  complement: 'Additional info',
  newPassword: 'New password',
  nextLives: 'Upcoming Lives',
  nextLessons: 'Upcoming Classes',
  nextLessonsIn: 'Upcoming Classes in:',
  schedulesAndEvents: 'Schedules and Events',
  nextStep: 'Next step',
  next: 'Next',
  nickname: 'Nickname',
  onEmail: 'On E-mail',
  onPhone: 'On Mobile',
  passport: 'Passport',
  password: 'Password',
  passwordInstructions:
    'The password must have at least 8 characters and must contain both letters and numbers',
  pastLessons: 'Past Lessons',
  phone: 'Phone',
  places: 'Places',
  readMore: 'Read more',
  recurrence: 'Recurrence',
  uniqueClass: 'Unique class',
  sundayLetter: 'S',
  mondayLetter: 'M',
  tuesdayLetter: 'T',
  wednesdayLetter: 'W',
  thursdayLetter: 'T',
  fridayLetter: 'F',
  saturdayLetter: 'S',
  save: 'Save',
  schedule: 'Schedule',
  select: 'Select',
  send: 'Send',
  state: 'State',
  state_consul: 'State/Province',
  surname: 'Last name',
  thankYou: 'Thank you!',
  trainer: 'Teacher',
  trainers: 'Teachers',
  uf: 'State',
  whatIsYourPhone: "What's your phone?",
  yourNickname: 'Your nickname',
  zipcode: 'Zipcode',

  enter: 'Enter',
  createAccount: 'Sign-up',
  or: 'or',
  alreadyRegistered: 'Already registered?',
  logIn: 'Log-in',

  logInWithoutRegistration: 'Log-in without registration',

  notFound: 'Not found',
  dataNotAvailable: 'Data not available.',
  couldNotOpenAUrl: 'Could not open the url {{url}}',
  tryAgain: 'Try again',

  recoveryPassword: 'Recovery password',
  forgotPasswordEmailSent:
    'We sent you an email with instructions on how to change your password.\n\nThen just log in here and start your classes with us!',

  share: 'Share',

  inputCancel: 'Cancel',
  inputDone: 'Done',

  viewFullScheduleOfLives: 'View full schedule of lives',
  viewFullScheduleOfClasses: 'View full schedule of classes',
  seeAllTrainers: 'See all teachers',
  lessonsNotFound: 'No lesson has been found',

  live: 'live',
  lives: 'Lives',
  profile: 'Profile',

  // Login Anônimo
  hello: 'Hello!',
  youAreNotRegisteredYet:
    "To continue, register or log in with your account. Let's go!",
  completeLoginText:
    'In order to access Mude app, you need to sign in to your account. Please, type your e-mail and password :)',
  completeRegistration: 'Complete your registration',
  forgotPasswordQuestion: 'Did you forget your password?',

  whatIsYourEmail: "Hi, {{name}}!\nWhat's your e-mail?",
  thisEmailWillBeUsedToLogYouIntoYourAccount:
    'This email will be used to log you into your account, use an email you have access to.',
  forgotPassword: 'Forgot your password?',
  didYouForgetYourPassword: 'Password recovery',
  typeYourEmail: 'Hi! Please type your e-mail and password :)',
  weWillRecoverYourPassword:
    "No problem! Write down your e-mail and we'll recover it for you!",
  howWouldYouWantToBeNamed: "Let's start.\nWhat's your nickname?",
  thisNameCanBeSeenByOthers:
    'This name can be seen by others people inside the app, okay?',
  agreeWithPolicy:
    "I agree with the Mude's <0>Terms of Use</0> and with the <0>Privacy Policy</0>",
  createAccountSubmit: 'Create account',
  full: 'Full',

  male: 'Male',
  female: 'Female',
  others: 'Others',
  notSpecified: 'Not defined',

  changeCity: 'Change city',
  howToGetThere: 'See on map',
  activateMyLocation: 'Allow to use my location',

  journey: 'Journey',
  myLessons: 'My Lessons',
  partners: 'Partners',
  yourNextLesson: 'Your next class',
  yourNextLessons: 'Your upcoming classes',
  emptyActivitiesTitle: 'Your future activities appear here',
  emptyActivitiesText: 'How about starting by scheduling a live class?',
  emptyActivitiesCta: 'Schedule a live',
  emptyMyListCta: 'Add a content',
  emptyTrainersCta: 'Search for a teacher',
  emptyJourneyTitle: "Oops, it looks like your journey hasn't started yet",
  emptyJourneySubtitle: "But you won't be there on the couch, right ?!",
  emptyJourneyCta: 'Agende uma live',
  activityDetails: 'Activity details',
  removeFromJourney: 'Remove from journey',
  loadMore: 'Load more',
  partnersAdvantages: 'Benefits',
  partnersContent:
    'Mude exists because our partners believe and invest in our project!\n\nThat’s why we’re happy to offer additional benefits to the students who have a relationship with these brands,',
  knowYourBenefits: 'Know the benefits',

  weDontHaveLessonsInYourCity:
    "Unfortunately we don't have classes in your city yet",
  dontBeSad:
    "But don't be sad, while we're working to take Mude's classes to your city you can enjoy to work out in one of our stations.",
  findOutStations: 'DISCOVER STATIONS NEAR YOU',

  allow: 'Allow',
  dontAllow: 'Deny',
  itsImportantToGiveUsYourLocation:
    "It's very import to give us access to your location",
  locationUseDisclaimer:
    'Your current location will be used to suggest stations and classes next to you.',
  calendarAccessTitle: 'Calendar Access',
  calendarAccessText:
    'We need your permission to save this class to your calendar',

  // Navegação principal
  settings: 'Settings',
  accountSettings: 'Account Settings',
  personalData: 'Personal Data',
  emergencyContact: 'Emergency Contact',
  anamnesisTitle: 'Health Overview',
  changePassword: 'Change Password',
  notifications: 'Notifications',
  workoutOptions: 'Workout options',
  payments: 'Payments',
  featureFlag: 'Feature Flag',
  deleteAccount: 'Delete Account',
  getHelp: 'Help',
  userTermsAndPolicy: 'Terms of use and \nprivacy policy',
  dataUseDisclaimer:
    'We ask for the information below to improve your security and to provide an exclusive access according to your profile',

  // Parceiro
  genericCallToAction: 'How to become a customer',

  // Contato de emergência
  emergencyContactDisclaimer:
    'In the section below, please provide the personal information needed in case of an emergency.',
  emergencyContactRegistered: 'Successfully registered!',

  // Anamnese
  anamnesisNotAvailable: 'Health overview not available.',
  anamnesisIntro: 'Physical Activity \nReadiness Questionnaire',
  anamnesisDescription:
    'The aim of the questionnaire is to help a physician with their evaluation before starting or increasing the level of your physical activity.',
  anamnesisInstructions: 'Check “yes” or “no” to the following questions:',
  answerAll: 'Answer all',
  agreeWithAnamnesisTerms:
    'I declare that I agree to the <0>Statement of Responsibility</0> for physical activity practice.',
  anamnesisTermsTitle:
    'Statement of Responsibility for physical activity practice',
  anamnesisTermsMessage:
    'I declare that I am aware that it is advisable to talk to a doctor before starting or increasing the level of physical activity intended, taking full responsibility for performing any physical activity without following this recommendation.',
  unfilledAnamnesisTitle: 'Incomplete answers',
  unfilledAnamnesisDescription: 'You must answer all questions to proceed.',
  uncheckedTermsAnamnesisTitle: 'Statement of Responsibility',
  uncheckedTermsAnamnesisDescription:
    'You must agree to the Statement of Responsibility to proceed.',

  deficiencyTitle: 'Disabled person',
  deficiencyDescription:
    'To be able to offer the best experience for you, it is important to understand if you have a disability.',

  covidTitle: 'COVID-19',
  covidDescription:
    'Mude follows a <0>security protocol</0> to ensure good practice and well-being for all. As part of security measures, you need answers to some questions.',
  agreeWithCovidTerms:
    "I declare that I agree to the Mude's <0>Protocol Covid-19</0> for physical activity practice.",
  uncheckedTermsCovidTitle: 'Protocol Covid-19',
  uncheckedTermsCovidDescription:
    'You must agree to the Protocol Covid-19 to proceed.',

  covidBannerTitle: 'Places in {{city}} will gradually open ',
  covidBannerDescription:
    'Shall we keep security together? Stay on top of all our <0>security measures</0>',

  // Notificações
  channelNewVacanciesTitle: 'Available vacancies',
  channelNewVacanciesDescription:
    'Receive a notification as soon as a new vacancy is available. Be quick, the demand for vacancies is high!',
  channelCancelledLessonsTitle: 'Cancelled lessons',
  channelCancelledLessonsDescription:
    'Receive a notification if lessons you reserved get cancelled.',
  channelCheckInTitle: 'Class starting',
  channelCheckInDescription:
    'Receive a notification 3 minutes before starting class to check in or watch live.',
  channelPaymentsTitle: 'Payments',
  channelPaymentsDescription:
    'Notifications if there is a problem with your payment.',
  channelNewsTitle: 'News',
  channelNewsDescription:
    'Be the first to know about special classes and events that we will prepare for you.',
  channelDefaultTitle: 'General informations',
  channelDefaultDescription:
    'All information about the app and what happens in the Mude.',

  // Apagar conta
  accountRemovalDisclaimer1:
    'If you wish to proceed with your account removal, you’ll lose all the data associated with your account and all the activities offered by Mude, including your lesson history.',
  accountRemovalDisclaimer2:
    'You also have the option to logout and delete the app from your phone. We’ll have all your information for you when you are ready to come back!',
  accountRemovalLeave: 'I want to leave the App',
  accountRemovalConfirmPassword:
    'Ok, to proceed with your account removal, we’ll need you to confirm your password to make sure that it is you who is deleting this account.',
  theDoorCracksOpen: 'Our doors are open to you!',
  youreAlwaysWelcome: "If you want to return, you'll be always welcome! :)",

  // Recuperar senha
  letsRecoverYourPassword: "Let's recover your password!",
  enterYourNewPasswordBelow: 'Enter your new password below',
  enterYourNewPassword: 'Enter your new password',
  enterYourNewPasswordAgain: 'Enter your new password again',
  sendNewPassword: 'Send',

  // Senha Recuperada
  passwordRecovered: 'Password Changed!',
  passwordRecoveredText:
    'Your new password has been setted up! Now, go back to our app, log in and choose the next outside activity that will bring us together.',
  loginAfterChange: 'login',

  // Help
  contactUsIfAnyQuestion:
    'Hello! Do you have any questions, suggestions, feedback or complaints? If so, feel free to get in contact with us!',
  version: 'Version',

  // Events
  aboutEvent: 'About the event',
  eventAboutNotAvailable: 'Event details not available.',

  // Modalidades
  noInfosAboutModality: 'There are no information available for this modality',
  noLessonsInThisRegion:
    "Unfortunately we don't have lessons for this category in your area",
  modalityNotAvailable: 'Modality not available.',

  // Lesson:
  lessonDetails: 'Class details',
  details: 'Details',
  back: 'Back',
  createReminder: 'Create\nreminder',
  reminderCreated: 'Reminder\ncreated',
  yourReminderWasCreated: 'Your reminder was created',
  youAreScheduled: 'You are booked',
  yourVacancyIsReserved:
    "If you need to, you can cancel your appointment until the beginning of the class. Don't forget to check in!",
  cannotAttendToTheClass: 'Cannot attend to the class?',
  releaseVacancy: 'Release vacancy',
  cancelReminder: 'Cancel reminder',
  usefulInformation: 'Useful information',
  support: 'Support',
  sponsor: 'Sponsor',
  help: 'Help',
  lessonLocation: 'Lesson location',
  otherDates: 'Outras datas',
  seeAgenda: 'See agenda',
  schedulesAndAgenda: 'Schedules and agenda',
  addToCalendar: 'Add to calendar',
  addToYourPersonalCalendar: 'Add to your personal calendar',
  contact: 'Contact',
  someProblemLetsHelpYou: "Some problem? Let's help you",
  classOfferedByDonations:
    'Class offered through contributions and student support',
  classOfferedBySponsor: 'Class offered by\n<0>{{sponsor}}</0>',
  supportTrainerToOfferActivities:
    'Support and help {{trainer}} to offer even more activities!',
  inviteYourFriends: 'Invite \nyour friends',
  lookForOtherDates: 'Look for other dates',
  cancelled: 'Cancelled',
  lessonCancelled: 'This class \nwas cancelled :(',
  lessonCancelledWarning: 'Lesson cancelled',
  weHadToCancel: '{{reason}}We had to cancel this class. See you next time!',
  watch: 'Watch',
  watchLive: 'Watch\nlive',
  watchNow: 'Watch now',
  watchAgain: 'Watch again',
  dontMissAnything: "Don't miss anything",
  classTime: 'Class time',
  checkinTime: 'Check-in time',
  checkInNow: 'Check in!',
  checkInWarning: 'Check in',
  rememberToCheckin: "Don't forget to check in!",
  theLessonIsAboutToStart:
    "The lesson will start soon, don't be late! If you cannot make to get to the lesson, your vacancy will be released to another student.",
  checkinDone: 'Check-in done \nGood class!',
  enjoyTheClass:
    "Glad you will participate! See details for more information and don't forget to check in!",
  didYouLikeTheLesson: 'How was \nyour class?',
  youWentToThisClass: 'You went to this class!',
  thankYouForYourPresence:
    "How nice! The class wouldn't be the same without you. Keep an eye out for the next information!",
  weHaveMissedYou: 'You did not go :( \nWe missed you',
  lessonMissedContent:
    'Oops. The class was not the same without you. Do not forget to release your seat so that someone can go in your place!',
  classOnlyForSponsorCta: 'Exclusive {{sponsor}}',
  classOnlyForSponsorTitle: 'Class is exclusive',
  classOnlyForSponsorContent:
    'This class is exclusive for {{sponsor}} clients.',
  classOnlyForSponsorDismiss: "I'm client",
  moreAboutSponsor: 'More about the sponsor',
  moreAboutThisSponsor: 'More about the sponsor',
  scheduleLimitReachedTitle: 'You have reached the limit of scheduled classes',
  scheduleLimitReachedContent:
    "You can schedule only one class at a time. To reserve this lesson, you first need to go to the other one you've already scheduled.\n\nIf you prefer, you can cancel another class to participate in this.",
  scheduleLimitReachedContent_plural:
    "You can schedule only {{count}} classes at a time. To reserve this lesson, you first need to go to the other ones you've already scheduled.\n\nIf you prefer, you can cancel another class to participate in this.",
  scheduleLimitReachedConfirm: 'Ok',
  scheduleLimitReachedDismiss: 'Release another class',
  ageLimitTitle: 'Are you over 15 years old?',
  ageLimitContent: 'Check your date of birth.',
  timeBeforeScheduling: '{{timeToGo}} to go',
  notOpenYetTitle: 'Waiting for release',
  notOpenYetContent:
    'Schedules are released {{subscriptionTime}} before class.',
  letMeKnowAboutNewVacancies: 'Warn me \nif available',
  lessonIsFull: 'Full!',
  lessonIsFullTitle: 'You almost got a vacancy',
  lessonIsFullContent:
    'What a shame! Turn on the alarm that we warn you when new vacancies open',
  lessonNotificationsOn: 'Notifications on!',
  warnMeTitle: 'Notifications of vacancies on',
  warnMeContent:
    "We'll let you know if any new vacancy get released.\n\nStay tuned, the demand is very high, so we can't guarantee your reservation!\n\nIf you prefer, you can remove the alert.",
  warnMeConfirm: 'Keep alert',
  warnMeDismiss: 'Turn off alert',

  turnOffNotifications: 'Turn off',
  totalCapacity: '{{count}} person',
  totalCapacity_plural: '{{count}} people',
  capacity: 'Capacity',
  duration: 'Duration',
  happenedClass: 'Class already happened',
  releasedVacancies: 'Vacancies released',
  vacancies: '{{count}} vacancy!',
  vacancies_plural: '{{count}} vacancies',
  lastVacancies: 'Last vacancy!',
  lastVacancies_plural: 'Last {{count}} vacancies',
  lessonsSubscribeContent:
    'Make your reservation! Free classes usually fill up fast',
  sponsorIsPartOfTheChange: 'The {{sponsor}} is also part of the change',
  lessonSponsoredBy:
    'Your class is offered by {{sponsor}} which shares our desire to improve your quality of life',
  instructor: 'Instructor',
  instructor_female: 'Instructor',
  teacher: 'Teacher',
  teacher_female: 'Teacher',
  guide: 'Guide',
  writeYourMessage: 'Write your message',

  // Check-in
  checkInTitle: 'Check in',
  checkInScreenBody: 'Class time <0>{{lesson}}</0> with {{trainer}}.',
  checkInSubmit: 'Check in',

  // Rating
  classDone: 'Class done!',
  howWasTheClass: 'How much did you like <0>{{activity}}</0>?',
  ratingVeryBad: 'Very bad',
  ratingBad: 'Bad',
  ratingOk: 'Ok',
  ratingGood: 'Good',
  ratingVeryGood: 'Very good!',

  // Modals
  doItLater: 'Do it later',
  someoneWasFasterThanYou: 'Someone was quicker than you!',
  vacanyWasFulfilled:
    "The vacancy you're about to take was fulfilled just a while ago :(. But don't give up! We'll keep warning you if a new vacancy is released!",
  warnMeForNewVacancies: 'Warn me about new vacancies',
  IDontWantToBeWarned: "I don't want to be warned",
  scheduleConflictWarningTitle:
    "Ops. You've already scheduled another lesson at the same time!",
  scheduleConflictWarningContent:
    "There's a class schedule for the same date and time.",
  genericErrorContent:
    'An unknown error occurred. Please get in contact with us, so we can fix it as soon as possible!',
  genericErrorTitle: 'Ops. Something is not quite right!',

  weApologizeForTheMistake:
    "We're sorry about the mistake. It's everything right now and we've registered your attendance!",
  cancelSubscription: 'Cancel your reservation?',
  cancelSubscriptionContent:
    'Cancelling your reservation releases the vacancy to another person.',
  yesReleaseMyVacancy: 'Yes, release vacancy',
  keepMyDateScheduled: 'No, keep vacancy',

  cancelLiveSubscription: 'Cancel your reminder?',
  cancelLiveSubscriptionContent:
    'Cancelling your reminder you will not receive a notification when the class starts.',
  yesRemoveMyReminder: 'Yes, cancel reminder',
  keepMyReminder: 'No, keep reminder',

  modalAnamnesisTitle:
    'Before proceeding to this reservation we need some informations about your health',
  modalAnamnesisContent:
    "We need you to answer some quick questions. This is a requirement from CREF and it's very important for us to make you experience the best as possible. It's really quickly!",
  answerNow: 'Answer now',
  modalAnamnesisExpiredTitle:
    "To schedule this lesson we'll to get some information about your health",
  modalAnamnesisExpiredContent:
    "In order to do this, we need you to review your answers. This is a requirement from CREF and it's important to review it every year. It's really important to make your experience the best as possible. It's really quickly!",
  done: 'Done!',
  modalMedicalCertificateContent: `Now you can schedule your classes. But remember: <0>a medical statement is required in your first class!\n</0>
So we make sure that you're able to practice physical exercises. The teacher is aware and you can give your medical statement directly to him.`,

  completeRegistrationRequiredTitle:
    'Please complete your registration before scheduling this lesson!',
  completeNow: 'Complete now',
  completeRegistrationContent: `It's really quickly! So you'll have access to all the classes :)\n
These details are very important to help to preserve your physical integrity and to provide the best experience for you.`,

  modalCheckinContent:
    "You need to be closer to your lesson's location in order to check in ;)\nIt will be great!",

  areYouCloseToYourClass: "Are you close to the lesson's location?",

  whyCreateAccount:
    'To get access to the Mude app, you need to create your account.',
  whyWeNeedData: 'Why do we need this information?',
  modalWhyDataContent: `Mude transforms cities in wellness spaces for people. We connect sponsors, coaches, governments and our community of open air lovers in a way everybody wins.\n
We believe everyone deserves to be healthy, and that's why we foster diversity and inclusiveness. Better knowing who is taking part of our open community gives us more resources to keep transforming cities and lives according to our values.\n
<0>AGE AND GENDER\n</0>
These information helps us to understand which classes, and other wellness products and services, are preferred by different customer segments. That way we can structure our offerings to better meet the expectations and interests of our growing community. It also helps us to find sponsors willing to promote open air activities oriented to specific customer segments. Maintaining our spirit and will to promote inclusiveness, we also want to negotiate discounts with commercial partners to offer more accessible products and services to our community. Your information helps us in this purpose.\n
<0>Personal Information will be anonymized and used for statistical purposes. We'll not use it individually.\n</0>
More information about our privacy policy and terms of use can be sent to your email by clicking here.`,

  locationNotAvailable: 'Location not available.',
  grantLocationUndefined:
    'Confirm that your location is turned on and permission has been granted.',
  grantLocationPermission: 'Grant location permission to update your position.',

  appFiveStarTitle: 'Enjoying the app?',
  appFiveStarMessage:
    'Your feedback is very important to make your experience with Mude better and better.',
  appFiveStarConfirm: 'Rate app',
  appFiveStarDismiss: 'Not now',

  // Doações
  donationTitle: 'You make the difference',
  lessonDonationDescription:
    'The class is free, so how about giving {{trainer}} a chance to create other classes like this?',
  trainerDonationDescription:
    'You support {{trainer}} and he can organize more classes for you',
  trainerDonationDescription_female:
    'You support {{trainer}} and she can organize more classes for you',
  donationPriceTitle: "Choose your contribution, it's quick!",
  donationOtherValue: 'Other value',
  chooseTheValue: 'Choose the value',
  donateCta: 'Donate',
  supportTrainer: 'Support {{trainer}}',
  donationSubmit: 'Support class',
  donationSkip: "I don't want to support",

  // Pagamentos
  total: 'Total',
  payment: 'Payment',
  free: 'Free',
  plans: 'Plans',
  planBenefit1: 'Special prices',
  planBenefit2: 'Automatic reservations',
  planBenefit3: 'Guaranteed vacancies',
  perMonth: '/month',
  signPlan: 'Sign plan',
  seePlans: 'See plans',
  bestValue: 'Best value',
  startFrom: 'Start from ',
  addNewCreditCard: 'Add credit card',
  changeCreditCard: 'Change',
  didYouSeeOurPlans: 'Did you see our plans?',
  addCreditCardDescription:
    'This card can be used for future in-app transactions.',
  ccNumber: 'Card number',
  ccExp: 'Expiration',
  ccCsc: 'CVV',
  ccName: 'Card holder',
  billingAddress: 'Billing address',
  pay: 'Pay',
  cards: 'Cards',
  noCardsRegistered: 'No cards\nregistered',
  removeCardTitle: 'Remove card?',
  removeCardMessage:
    'Are you sure you want to remove card with last digits {{lastDigits}}?\n\nThis action cannot be undone.',
  removeCardAction: 'Remove card',
  transactions: 'Transactions',
  noTransactionsRegistered: 'No transactions\nregistered',
  waiting: 'Waiting',
  paid: 'Paid',
  refused: 'Refused',
  refunded: 'Refunded',
  chargeback: 'Chargeback',
  thanksTitle: 'Thank you!',
  thanksDescription:
    'Your retribution is very valuable. Now you are also part of the change',

  // Agenda
  filter: 'Filter',
  filterAgendaTitle: 'Filter agenda',
  locality: 'Locality',
  findYourLocation: 'Find your location',
  coach: 'Coach',
  findYourTrainer: 'Find your trainer',
  seeResults: 'See results',

  // Trainer
  seeNextLessons: 'See next lessons',
  emptyActivitiesForNextDays: 'No activities scheduled for the next few days',
  emptyOnDemand: 'No on-demand content available yet',
  addTrainerAsFavorite:
    'Add <0>{{trainer}}</0> to your teachers and stay on top of all their news',
  trainerIsYourFavorite:
    '<0>{{trainer}} is your teacher :)</0>\nYou will be notified whenever new content or activity arrives',

  // Search
  search: 'Search',
  searchPlaceholder: 'Search for activities, modalities, trainers...',
  all: 'All',
  activities: 'Activities',
  totalActivities: '{{count}} activity',
  totalActivities_plural: '{{count}} activities',
  onDemand: 'On demand',
  showingResultsFor: 'Showing results for',
  didntFindShowResultsFor: "Didn't find? Show results for",
  searchFilterTitle: 'Filters',
  whereTitle: 'Where?',
  whereText: 'Find activity locations',
  whenTitle: 'When?',
  whenText: 'Find activities to schedule',
  howLongTitle: 'How long?',
  howLongText: 'Activities that fit your time',
  whichTrainerTitle: 'Which teacher?',
  whichTrainerText: 'Follow your favorites',
  popularResults: 'Popular results',
  liveNow: 'Live now',
  emptySearchTitle: 'Oops. Your search returned no results',
  emptySearchSubtitle: "But that's no excuse to go to the couch",
  emptySearchCta: 'Search for next lives',
  remindersAndSchedules: 'Reminders and Schedules',
  yourNextLivesAndClasses: 'Your next Lives and Classes',
  toWatchAnytime: 'To watch anytime',
  favoriteTrainers: 'Favorite trainers',
  getNotifiedOfAllTheNews: 'Get notified of all the news',
  virtualCity: 'Online',
  inTheComfortOfYourHome: 'In the comfort of your home',
  noDurationLimit: 'No limit',
  approximately: 'Approximately {{duration}}',
  timeRange: 'Time range',
  morning: 'Morning',
  afternoon: 'Afternoon',
  night: 'Night',
  morning_afternoon: 'Morning and afternoon',
  afternoon_night: 'Afternoon and night',
  morning_afternoon_night: 'Morning, afternoon and night',
  allDay: 'All day',
  until: 'until',
  calendar: 'Calendar',
  today: 'today',
  tomorrow: 'tomorrow',
  thisWeek: 'this week',
  thisMonth: 'this month',
  thisWeekend: 'this weekend',
  chooseADate: 'choose a date',
  day: 'day',
  other: 'Others',
  otherModalities: 'Others Modalities',

  // Lesson Spot
  bookSpot: 'Book spot {{spot}}',
  chooseYourSpot: 'Choose your spot',
  yourSpotIs: 'Your spot is',
  spotConfirmationDescription:
    "Don't forget to bring an ID to guarantee your place",
  changeSpot: 'Change spot',
  selected: 'selected',
  available: 'available',
  unavailable: 'unavailable',
};
