import { Model, Relation } from '@nozbe/watermelondb';
import { field, relation } from '@nozbe/watermelondb/decorators';
import Workout from './Workout';

export default class WorkoutConsumption extends Model {
  static table = 'workout_consumptions';

  @field('start_time') start_time!: string;
  @field('end_time') end_time!: string;
  @field('completed') completed!: boolean;
  @field('workout') workout!: string;
  @field('last_part') last_part!: string;
  @field('last_item') last_item!: string;
  @field('rating') rating!: number;
  @field('show_in_history') show_in_history!: boolean;

  @relation('workouts', 'workout') workout_obj!: Relation<Workout>;
}
