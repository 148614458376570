import React from 'react';
import {
  TouchableWithoutFeedback,
  View,
  StatusBar,
  StyleSheet,
} from 'react-native';
import ModalService from '../libs/ModalService';
import { opacity } from '../libs/Theme';
import FadeView from './FadeView';

export default class ModalLayer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      children: null,
    };

    // not beautiful, but very funcional 🤘
    ModalService.setRef(this);
  }

  onClose = () => {
    this.setState({ children: null });
  };

  render() {
    if (!this.state.children) {
      return null;
    }

    return (
      <FadeView style={styles.container}>
        <StatusBar barStyle="light-content" animated />
        <TouchableWithoutFeedback onPress={this.onClose}>
          <View style={StyleSheet.absoluteFill} />
        </TouchableWithoutFeedback>
        {this.state.children}
      </FadeView>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    ...StyleSheet.absoluteFillObject,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: opacity('black', 0.5),
  },
});
