export default {
  shareLesson:
    'Vamos na aula {{lesson}}! {{weekDay}} dia {{day}} às {{time}} com {{trainer}} {{url}}',
  shareLessonWithVacancies:
    'Vamos na aula {{lesson}}! {{weekDay}} dia {{day}} às {{time}} com {{trainer}}, só {{count}} vaga! {{url}}',
  shareLessonWithVacancies_plural:
    'Vamos na aula {{lesson}}! {{weekDay}} dia {{day}} às {{time}} com {{trainer}}, só {{count}} vagas! {{url}}',

  shareCollection: '{{collection}} {{url}}',

  shareWorkout: 'Vamos praticar {{workout}}! {{url}}',

  shareEvent: '{{event}} {{url}}',

  shareTrainer: 'Conheça o {{trainer}} na Mude! {{url}}',
  shareTrainer_female: 'Conheça a {{trainer}} na Mude! {{url}}',
};
