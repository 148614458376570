import React from 'react';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { connect } from 'react-redux';
import DataService, { withObservables } from '../libs/DataService';
import NavigationService from '../libs/NavigationService';
import { getLessonStatus, lessonStatus } from '../libs/Rules';
import Theme from '../libs/Theme';
import BookedIcon from '../icons/BookedIcon';
import CloseIcon from '../icons/CloseIcon';
import DateBox from './DateBox';
import TimeBox from './TimeBox';
import I18n from './I18n';

class LessonEntry extends React.PureComponent {
  onPress = () => {
    const { id } = this.props.lesson;
    const { name, params } = NavigationService.getActiveRoute();

    if (name === 'Lesson') {
      if (params?.id !== id) {
        NavigationService.push('Lesson', { id });
      }
    } else {
      NavigationService.navigate('Lesson', { id });
    }
  };

  renderStatus = (lesson, status) => {
    switch (status) {
      case lessonStatus.CANCELED:
        return (
          <Text style={[styles.status, styles.blue]}>
            <I18n>cancelled</I18n>{' '}
            <CloseIcon width={8} height={8} color={Theme.color.magenta} />
          </Text>
        );

      case lessonStatus.SUBSCRIBED:
      case lessonStatus.CHECKIN:
      case lessonStatus.CHECKIN_DONE:
        return (
          <BookedIcon width={16} height={16} color={Theme.color.magenta} />
        );

      case lessonStatus.LESSON_FULL:
        return (
          <Text style={[styles.status, styles.magenta]}>
            <I18n>lessonIsFull</I18n>
          </Text>
        );

      case lessonStatus.CAN_SUBSCRIBE:
        return (
          lesson.available_vacancies < 10 && (
            <Text style={[styles.status, styles.yellow]}>
              <I18n params={{ count: lesson.available_vacancies }}>
                vacancies
              </I18n>
            </Text>
          )
        );

      default:
        return null;
    }
  };

  render() {
    const {
      lesson,
      enrollment,
      user,
      sponsorGroups,
      place,
      trainer,
      withDate,
    } = this.props;

    if (!lesson) {
      return null;
    }

    const status = getLessonStatus(
      lesson,
      enrollment,
      user,
      sponsorGroups,
      place,
    );

    const isTitleGray = [
      lessonStatus.CANCELED,
      lessonStatus.LESSON_MISSED,
      lessonStatus.ALREADY_HAPPENED,
    ].includes(status);

    const isTimeGray = [
      lessonStatus.CANCELED,
      lessonStatus.LESSON_MISSED,
      lessonStatus.EXCLUSIVE,
      lessonStatus.USER_LIMIT,
      lessonStatus.NOT_OPEN_YET,
      lessonStatus.ALREADY_HAPPENED,
      lessonStatus.LESSON_FULL,
      lessonStatus.WARN_ME,
    ].includes(status);

    const theme = isTimeGray ? 'gray' : 'magenta';

    return (
      <TouchableOpacity
        onPress={this.onPress}
        underlayColor={Theme.color.transparent}
        activeOpacity={0.5}
      >
        <View style={styles.lesson}>
          {withDate && (
            <View style={styles.date}>
              <DateBox date={lesson.start_datetime} theme={theme} />
            </View>
          )}
          <View style={styles.textContainer}>
            <Text style={[styles.title, isTitleGray && styles.gray]}>
              {lesson.name}
              {'  '}
              {this.renderStatus(lesson, status)}
            </Text>
            <Text style={styles.info} numberOfLines={2}>
              {trainer?.name}, {place?.name}
            </Text>
          </View>
          <TimeBox date={lesson.start_datetime} theme={theme} />
        </View>
      </TouchableOpacity>
    );
  }
}

const styles = StyleSheet.create({
  lesson: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 80,
    borderTopWidth: 1,
    borderColor: Theme.color.veryLightGray,
  },
  date: {
    marginRight: 10,
  },
  textContainer: {
    flexGrow: 1,
    flexShrink: 1,
    marginRight: 10,
  },
  title: {
    color: Theme.color.veryDarkGray,
    fontFamily: Theme.fonts.barlowMedium,
    fontSize: 14,
    lineHeight: 14,
  },
  info: {
    color: Theme.color.lightGray,
    fontFamily: Theme.fonts.barlowMedium,
    fontSize: 13,
    lineHeight: 13,
    marginTop: 6,
  },
  status: {
    color: Theme.color.veryDarkGray,
    fontFamily: Theme.fonts.barlowCondensedSemiBold,
    fontSize: 12,
    textTransform: 'uppercase',
  },
  yellow: {
    color: Theme.color.yellow,
  },
  magenta: {
    color: Theme.color.magenta,
  },
  blue: {
    color: Theme.color.lightBlue,
  },
  gray: {
    color: Theme.color.gray,
  },
});

const mapStateToProps = ({ user }) => ({ user });

const enhance = withObservables(['lesson'], ({ lesson }) => ({
  trainer: DataService.observeTrainer(lesson?.trainer),
  place: DataService.observePlace(lesson?.place),
  sponsorGroups: DataService.observeSponsorGroupsBySponsor(lesson?.sponsor),
  enrollment: DataService.observeEnrollmentByLessonId(lesson?.id),
}));

export default connect(mapStateToProps)(enhance(LessonEntry));
