import React from 'react';
import {
  KeyboardAvoidingView,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  TouchableWithoutFeedback,
  View,
} from 'react-native';
import NavigationService from '../libs/NavigationService';
import Theme from '../libs/Theme';
import I18n from './I18n';
import CloseButton from './CloseButton';

class ModalScrollView extends React.PureComponent {
  onPressBackground = () => {
    NavigationService.goBack();
  };

  render() {
    const { icon, title, children, footer, contentContainerStyle } = this.props;
    return (
      <View style={styles.container}>
        <TouchableWithoutFeedback onPress={this.onPressBackground}>
          <View style={styles.background} />
        </TouchableWithoutFeedback>
        <View style={styles.header}>
          <View style={styles.headerLeft}>
            {icon && <View style={styles.icon}>{icon}</View>}
            <Text style={styles.title} numberOfLines={2}>
              <I18n>{title}</I18n>
            </Text>
          </View>
          <View style={styles.headerRight}>
            <CloseButton color={Theme.color.magenta} />
          </View>
        </View>
        <KeyboardAvoidingView
          style={styles.body}
          behavior={Platform.OS === 'ios' ? 'padding' : null}
        >
          <ScrollView
            contentContainerStyle={contentContainerStyle}
            bounces={false}
            keyboardShouldPersistTaps="always"
          >
            {children}
          </ScrollView>
          {footer}
        </KeyboardAvoidingView>
        {!!footer && <View style={styles.footerPadding} />}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    height: '100%',
  },
  background: {
    height: 35 + Theme.dimensions.topSpace,
  },
  header: {
    marginTop: -15,
    borderTopLeftRadius: 15,
    borderTopRightRadius: 15,
    paddingHorizontal: 20,
    height: 70,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: Theme.color.white,
    borderBottomWidth: 1,
    borderColor: Theme.color.veryLightGray,
  },
  headerLeft: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  headerRight: {
    marginRight: -15,
  },
  icon: {
    marginRight: 10,
  },
  title: {
    flex: 1,
    color: Theme.color.veryDarkGray,
    fontFamily: Theme.fonts.barlowBold,
    fontSize: 16,
  },
  body: {
    flex: 1,
    backgroundColor: Theme.color.white,
  },
  footerPadding: {
    height: Theme.dimensions.bottomSpace,
    backgroundColor: Theme.color.white,
  },
});

export default ModalScrollView;
