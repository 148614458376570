import { Model, Query } from '@nozbe/watermelondb';
import { children, field, json } from '@nozbe/watermelondb/decorators';
import { Associations } from '@nozbe/watermelondb/Model';
import LessonGroup from './LessonGroup';

export default class Product extends Model {
  static table = 'products';

  static associations: Associations = {
    lesson_groups: { type: 'has_many', foreignKey: 'product' },
  };

  @children('lesson_groups') lesson_groups!: Query<LessonGroup>;

  @json('plans', _json => _json || []) plans!: ProductPlan[];
  @field('name') name!: string;
  @field('cost_mode') cost_mode!: string;
  @json('provider', _json => _json || []) provider!: string[];
}

type ProductPlan = {
  active: boolean;
  recurrence_months: number;
  price: number;
};
