import React, { useCallback } from 'react';
import { View, StyleSheet, Text } from 'react-native';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import DataService, {
  withObservables,
  withMapProps,
} from '../libs/DataService';
import NavigationService from '../libs/NavigationService';
import { getLessonStatus, lessonStatus, isSubscribed } from '../libs/Rules';
import Theme from '../libs/Theme';
import BookedIcon from '../icons/BookedIcon';
import CloseIcon from '../icons/CloseIcon';
import NotificationOnIcon from '../icons/NotificationOnIcon';
import I18n from './I18n';
import ActivityEntryView from './ActivityEntryView';

function LessonActivityEntry_Status({ lesson, enrollment, status }) {
  if (status === lessonStatus.CANCELED) {
    return (
      <Text style={[styles.status, styles.blue]}>
        <I18n>cancelled</I18n>{' '}
        <CloseIcon width={8} height={8} color={Theme.color.magenta} />
      </Text>
    );
  }

  if (
    status === lessonStatus.SUBSCRIBED ||
    status === lessonStatus.CHECKIN ||
    status === lessonStatus.CHECKIN_DONE
  ) {
    return (
      <BookedIcon
        width={14}
        height={14}
        color={Theme.color.magenta}
        style={styles.iconBooked}
      />
    );
  }

  if (
    status === lessonStatus.LIVE_SUBSCRIBED ||
    (status === lessonStatus.WATCH && isSubscribed(enrollment))
  ) {
    return (
      <NotificationOnIcon
        width={14}
        height={14}
        color={Theme.color.magenta}
        style={styles.iconNotification}
      />
    );
  }

  if (status === lessonStatus.LESSON_FULL) {
    return (
      <Text style={[styles.status, styles.magenta]}>
        <I18n>lessonIsFull</I18n>
      </Text>
    );
  }

  const start = moment(lesson.start_datetime);
  const willStartAt = start.diff(moment(), 'minutes');
  if (lesson.is_virtual && willStartAt > 0 && willStartAt < 4 * 60) {
    return (
      <Text style={[styles.status, styles.yellow]}>
        <I18n params={{ count: lesson.available_vacancies }}>
          {start.fromNow()}
        </I18n>
      </Text>
    );
  }

  if (status === lessonStatus.CAN_SUBSCRIBE) {
    return (
      lesson.available_vacancies < 10 && (
        <Text style={[styles.status, styles.yellow]}>
          <I18n params={{ count: lesson.available_vacancies }}>vacancies</I18n>
        </Text>
      )
    );
  }

  return null;
}

function LessonActivityEntry({
  id,
  lesson,
  lessonGroup,
  place,
  trainer,
  modality,
  enrollment,
  user,
  sponsorGroups,
}) {
  const onPress = useCallback(() => {
    NavigationService.navigate('Lesson', { id });
  }, [id]);

  if (!lesson) {
    return null;
  }

  const status = getLessonStatus(
    lesson,
    enrollment,
    user,
    sponsorGroups,
    place,
  );

  const isTitleGray = [
    lessonStatus.CANCELED,
    lessonStatus.LESSON_MISSED,
    lessonStatus.ALREADY_HAPPENED,
  ].includes(status);

  const start = moment(lesson.start_datetime);
  const end = moment(lesson.end_datetime);

  const now = moment();
  const isLiveNow =
    start.diff(now, 'minutes') < 0 && end.diff(now, 'minutes') > 0;

  return (
    <ActivityEntryView
      image={lessonGroup?.images?.[0]}
      highlight={
        lesson.is_virtual && isLiveNow ? (
          <View style={styles.liveNow}>
            <Text style={styles.liveNowText}>
              <I18n>liveNow</I18n>
            </Text>
          </View>
        ) : (
          <Text style={styles.date}>
            {lesson.is_virtual ? 'Live • ' : ''}
            {start.format('DD MMM, HH:mm')}
          </Text>
        )
      }
      name={
        <Text style={isTitleGray && styles.gray}>
          {lesson.name}
          {'  '}
          <LessonActivityEntry_Status
            lesson={lesson}
            enrollment={enrollment}
            status={status}
          />
        </Text>
      }
      trainer={trainer}
      place={place}
      duration={end.diff(start, 'seconds')}
      modality={modality}
      onPress={onPress}
    />
  );
}

const styles = StyleSheet.create({
  liveNow: {
    paddingVertical: 2,
    paddingHorizontal: 5,
    backgroundColor: Theme.color.magenta,
    borderRadius: 2,
    alignSelf: 'flex-start',
    marginBottom: 6,
  },
  liveNowText: {
    color: Theme.color.white,
    fontFamily: Theme.fonts.barlowCondensedMedium,
    fontSize: 10,
    includeFontPadding: false,
    textTransform: 'uppercase',
  },
  date: {
    color: Theme.color.magenta,
    fontFamily: Theme.fonts.barlowCondensedSemiBold,
    fontSize: 12,
    includeFontPadding: false,
    textTransform: 'uppercase',
    marginBottom: 6,
  },
  status: {
    color: Theme.color.veryDarkGray,
    fontFamily: Theme.fonts.barlowCondensedSemiBold,
    fontSize: 12,
    includeFontPadding: false,
    textTransform: 'uppercase',
  },
  yellow: {
    color: Theme.color.yellow,
  },
  magenta: {
    color: Theme.color.magenta,
  },
  blue: {
    color: Theme.color.lightBlue,
  },
  gray: {
    color: Theme.color.gray,
  },
  iconBooked: {
    marginBottom: -1,
  },
  iconNotification: {
    marginBottom: -2,
  },
});

const mapStateToProps = ({ user }) => ({ user });

const enhance3 = withObservables(
  ['place', 'trainer', 'modality', 'lessonGroup', 'sponsor', 'enrollment'],
  ({ place, trainer, modality, lessonGroup, sponsor, enrollment }) => ({
    place: DataService.observePlace(place),
    trainer: DataService.observeTrainer(trainer),
    modality: DataService.observeModality(modality),
    lessonGroup: DataService.observeLessonGroup(lessonGroup),
    sponsorGroups: DataService.observeSponsorGroupsBySponsor(sponsor),
    enrollment: DataService.observeEnrollmentByLessonId(enrollment),
  }),
);

const enhance2 = withMapProps(({ lesson }) => ({
  place: lesson?.place,
  trainer: lesson?.trainer,
  modality: lesson?.modality,
  lessonGroup: lesson?.lesson_group,
  sponsor: lesson?.sponsor,
  enrollment: lesson?.id,
}));

const enhance = withObservables(['id'], ({ id }) => ({
  lesson: DataService.observeLesson(id),
}));

export default connect(mapStateToProps)(
  enhance(enhance2(enhance3(LessonActivityEntry))),
);
