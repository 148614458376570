import React from 'react';
import { StyleSheet, Text } from 'react-native';
import Theme from '../libs/Theme';
import I18n from './I18n';

export default function TabBarLabel({ title, focused }) {
  return (
    <Text
      style={[
        styles.text,
        { color: focused ? Theme.color.magenta : Theme.color.lightGray },
      ]}
    >
      <I18n>{title}</I18n>
    </Text>
  );
}

const styles = StyleSheet.create({
  text: {
    fontSize: 10,
    fontFamily: Theme.fonts.barlowBold,
    textAlign: 'center',
    textTransform: 'uppercase',
  },
});
