import React, { useCallback } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import DataService, { withObservables } from '../libs/DataService';
import { resolveError } from '../libs/FormUtils';
import NavigationService from '../libs/NavigationService';
import Theme from '../libs/Theme';
import useSync from '../hooks/useSync';
import I18n from './I18n';
import SwitchInput from './SwitchInput';
import TouchableWithData from './TouchableWithData';
import ResizableImage from './ResizableImage';

function SponsorsTab({ sponsors, sponsorGroups }) {
  const onToggle = useCallback(async (value, name) => {
    try {
      await DataService.updateSponsorGroup(name, value);
    } catch (error) {
      resolveError(error, true);
      return false;
    }
  }, []);

  const onPress = useCallback(e => {
    NavigationService.navigate('Sponsor', { id: e.data });
  }, []);

  return (
    <>
      <View style={styles.header}>
        <Text style={styles.headerTitle}>
          <I18n>partnersAdvantages</I18n>
        </Text>
        <Text style={styles.headerText}>
          <I18n>partnersContent</I18n>
        </Text>
      </View>
      {sponsors
        .sort((a, b) => a.order - b.order)
        .map(sponsor => (
          <View key={sponsor.id} style={styles.sponsor}>
            <View style={styles.groupList}>
              {sponsorGroups
                .filter(group => group.sponsor === sponsor.id)
                .map(group => (
                  <View key={group.id} style={styles.group}>
                    <Text style={styles.groupTitle}>{group.name}</Text>
                    <SwitchInput
                      name={group.id}
                      value={group.is_member}
                      onChange={onToggle}
                    />
                  </View>
                ))}
            </View>
            <TouchableWithData
              style={[
                styles.sponsorLink,
                { backgroundColor: sponsor.main_color },
              ]}
              onPress={onPress}
              data={sponsor.id}
            >
              <Text style={styles.sponsorCallToAction}>
                <I18n>moreAboutThisSponsor</I18n>
              </Text>
              <ResizableImage
                style={styles.logo}
                source={{ uri: sponsor.transparency_logo || '' }}
                fitVertical
              />
            </TouchableWithData>
          </View>
        ))}
    </>
  );
}

function SponsorsTab_Sync(props) {
  const { syncing, error } = useSync(
    useCallback(async () => {
      await DataService.syncSponsorGroups();
      await DataService.syncSponsors();
    }, []),
  );

  return <SponsorsTab {...props} loading={syncing} error={error} />;
}

const styles = StyleSheet.create({
  header: {
    padding: 20,
  },
  headerTitle: {
    color: Theme.color.veryDarkGray,
    fontFamily: Theme.fonts.barlowBold,
    fontSize: 18,
  },
  headerText: {
    color: Theme.color.darkGray,
    fontFamily: Theme.fonts.barlow,
    fontSize: 15,
    maxWidth: 300,
    marginTop: 10,
  },
  sponsor: {
    padding: 20,
    flexDirection: 'column',
    borderColor: Theme.color.veryLightGray,
    borderTopWidth: 1,
  },
  group: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 20,
  },
  groupTitle: {
    color: Theme.color.veryDarkGray,
    fontFamily: Theme.fonts.barlowBold,
    fontSize: 14,
    textTransform: 'uppercase',
  },
  sponsorLink: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingVertical: 5,
    paddingHorizontal: 10,
    borderRadius: 4,
  },
  sponsorCallToAction: {
    color: Theme.color.white,
    fontFamily: Theme.fonts.barlowBold,
    fontSize: 13,
    textTransform: 'uppercase',
  },
  logo: {
    height: 40,
    width: 0,
  },
});

const enhance = withObservables([], () => ({
  sponsors: DataService.observePublishedSponsors(),
  sponsorGroups: DataService.observeSponsorGroups(),
}));

export default enhance(SponsorsTab_Sync);
