import React from 'react';
import Svg, { Path } from 'react-native-svg';

export default function BorderSmallCloseIcon(props) {
  return (
    <Svg width={22} height={22} viewBox="0 0 22 22" {...props}>
      <Path
        fill={props.color}
        d="M11 0c6.1 0 11 4.9 11 11s-4.9 11-11 11S0 17.1 0 11 4.9 0 11 0zm2.8 6.8L11 9.6 8.2 6.8 6.8 8.2 9.6 11l-2.8 2.8 1.4 1.4 2.8-2.8 2.8 2.8 1.4-1.4-2.8-2.8 2.8-2.8-1.4-1.4zM11 20c5 0 9-4 9-9s-4-9-9-9-9 4-9 9 4 9 9 9z"
      />
    </Svg>
  );
}
