// TODO [web migration]
// import * as Sentry from "@sentry/react-native";
const Sentry = {
  setUser: () => {},
  addBreadcrumb: () => {},
  captureMessage: () => {},
  captureException: () => {},
};

class SentryService {
  setUser = (user = {}) => {
    Sentry.setUser({
      id: String(user.id), // https://github.com/getsentry/sentry-react-native/issues/436
      email: user.email,
    });
  };

  log = error => {
    console.error(error);

    Sentry.addBreadcrumb({
      data: {
        responseData: error?.response?.data || '',
        date: new Date(),
        // TODO [web migration]
        // bandwidth: NetInfoService.getBandwidth(),
      },
    });

    if (typeof error === 'string') {
      Sentry.captureMessage(error);
    } else {
      Sentry.captureException(error);
    }
  };
}

export default new SentryService();
