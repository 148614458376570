import React from 'react';
import Svg, { Path } from 'react-native-svg';

export default function CalendarIcon(props) {
  return (
    <Svg width={21} height={22} viewBox="0 0 21 22" {...props}>
      <Path
        fill={props.color}
        d="M12.5 12H14v2.8l2.4 1.4-.8 1.3-3.2-1.8V12m4.1-5h-14v11h4.7c-.4-.9-.7-1.9-.7-3 0-3.9 3.1-7 7-7 1.1 0 2.1.2 3 .7V7m-14 13c-1.1 0-2-.9-2-2V4c0-1.1.9-2 2-2h1V0h2v2h8V0h2v2h1c1.1 0 2 .9 2 2v6.1c1.2 1.3 2 3 2 4.9 0 3.9-3.1 7-7 7-1.9 0-3.6-.8-4.9-2H2.5m11-9.9c-2.7 0-4.9 2.2-4.9 4.9s2.2 4.8 4.9 4.8c1.3 0 2.5-.5 3.4-1.4s1.4-2.1 1.4-3.4c.1-2.7-2.1-4.9-4.8-4.9z"
      />
    </Svg>
  );
}
