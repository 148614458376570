import React from 'react';
import Svg, { Path } from 'react-native-svg';
import Theme from '../libs/Theme';

export default class BackArrowIcon extends React.PureComponent {
  static defaultProps = {
    color: Theme.color.magenta,
  };

  render() {
    return (
      <Svg width={30} height={30} viewBox="0 0 30 30" {...this.props}>
        <Path
          fill="none"
          stroke={this.props.color}
          strokeWidth={2}
          d="M19.9 5.1L10.1 15l9.8 9.9"
        />
      </Svg>
    );
  }
}
