class ModalService {
  setRef(ref) {
    this.ref = ref;
  }

  show = children => {
    if (this.ref) {
      this.ref.setState({ children });
    }
  };

  hide = () => {
    if (this.ref) {
      this.ref.setState({ children: null });
    }
  };
}

export default new ModalService();
