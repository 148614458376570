import React from 'react';
import { TouchableWithoutFeedback, View, StyleSheet, Text } from 'react-native';
import FastImage from 'react-native-fast-image';
import { formatDuration } from '../libs/DIYUtils';
import Theme from '../libs/Theme';

export default function ActivityEntryView({
  image,
  highlight,
  name,
  trainer,
  place,
  duration,
  modality,
  onPress,
}) {
  return (
    <TouchableWithoutFeedback onPress={onPress}>
      <View style={styles.container}>
        <FastImage
          style={styles.image}
          source={{
            uri:
              image ||
              trainer?.images?.[0] ||
              modality?.image ||
              trainer?.picture ||
              '',
          }}
        />
        <View style={styles.textContainer}>
          {highlight}
          <Text style={styles.name}>{name}</Text>
          <Text style={styles.details}>
            {[trainer?.name, place?.name].filter(s => s).join(', ')}
          </Text>
          <Text style={styles.durationAndModality}>
            {[formatDuration(duration), modality?.name]
              .filter(s => s)
              .join(' • ')}
          </Text>
        </View>
      </View>
    </TouchableWithoutFeedback>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
  },
  image: {
    width: 56,
    height: 73,
    borderRadius: 2,
    backgroundColor: Theme.color.veryLightGray,
    marginRight: 20,
  },
  textContainer: {
    flexGrow: 1,
    flexShrink: 1,
    justifyContent: 'center',
  },
  name: {
    color: Theme.color.veryDarkGray,
    fontFamily: Theme.fonts.barlowSemiCondensedMedium,
    fontSize: 14,
  },
  details: {
    color: Theme.color.gray,
    fontFamily: Theme.fonts.barlowSemiBold,
    fontSize: 11,
    marginTop: 2,
  },
  durationAndModality: {
    color: Theme.color.gray,
    fontFamily: Theme.fonts.barlowSemiBold,
    fontSize: 11,
    marginTop: 6,
  },
});
