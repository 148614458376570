import React from 'react';
import Svg, { Path } from 'react-native-svg';
import Theme from '../libs/Theme';

export default class PinIcon extends React.PureComponent {
  static defaultProps = {
    color: Theme.color.white,
  };

  render() {
    return (
      <Svg width={17} height={22} viewBox="0 0 17 22" {...this.props}>
        <Path
          fill={this.props.color}
          d="M8.5 21.7l-.6-.4c-.3-.2-7.6-5.2-7.6-13C.3 3.8 3.9.2 8.4.2s8.1 3.6 8.1 8.1c0 7.7-7.3 12.8-7.6 13l-.4.4zM5.6 8.2c0 1.6 1.3 2.9 2.9 2.9 1.6 0 2.9-1.3 2.9-2.9s-1.3-3-2.9-3c-1.6.1-2.9 1.4-2.9 3z"
        />
      </Svg>
    );
  }
}
