import StoreService from '../libs/StoreService';

const ADD_ADDRESS = 'ADDRESS/ADD_ADDRESS';
const DELETE_ADDRESS = 'ADDRESS/DELETE_ADDRESS';

export function addAddress(address) {
  StoreService.dispatch({ type: ADD_ADDRESS, address });
}

export function deleteAddress() {
  StoreService.dispatch({ type: DELETE_ADDRESS });
}

export default function reducer(state = {}, action) {
  switch (action.type) {
    case ADD_ADDRESS: {
      return Object.assign({}, state, action.address);
    }

    case DELETE_ADDRESS: {
      return {};
    }

    default:
      return state;
  }
}
