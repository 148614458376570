import React from 'react';
import { StyleSheet, View } from 'react-native';
import Svg, { Path } from 'react-native-svg';
import Theme from '../libs/Theme';

export default class InfoIcon extends React.PureComponent {
  static defaultProps = {
    color: Theme.color.magenta,
  };

  render() {
    return (
      <View style={this.props.withBorder && styles.withBorder}>
        <Svg width={18} height={18} viewBox="0 0 20 20" {...this.props}>
          <Path
            fill={this.props.color}
            d="M9 15h2V9H9v6zm1-15C4.5 0 0 4.5 0 10s4.5 10 10 10 10-4.5 10-10S15.5 0 10 0zm0 18c-4.4 0-8-3.6-8-8s3.6-8 8-8 8 3.6 8 8-3.6 8-8 8zM9 7h2V5H9v2z"
          />
        </Svg>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  withBorder: {
    borderRadius: 34,
    backgroundColor: Theme.color.white,
    width: 34,
    height: 34,
    shadowColor: Theme.color.black,
    shadowOffset: { width: 0, height: 3 },
    shadowOpacity: 0.1,
    shadowRadius: 2,
    elevation: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
});
