import React from 'react';
import { ScrollView, StyleSheet, Text, View } from 'react-native';
import AlertService from '../libs/AlertService';
import { resolveError } from '../libs/FormUtils';
import Theme from '../libs/Theme';
import I18n from './I18n';
import ActionButton from './ActionButton';

export default class AlertModal extends React.PureComponent {
  onConfirm = () => {
    return this.onPressButton(this.props.onConfirm);
  };

  onDismiss = () => {
    return this.onPressButton(this.props.onDismiss);
  };

  onPressButton = async callback => {
    if (!callback) {
      AlertService.hide();
      return;
    }

    try {
      await callback();
      AlertService.hide();
    } catch (error) {
      this.props.onError
        ? this.props.onError(error)
        : resolveError(error, true);
    }
  };

  render() {
    const {
      title,
      message,
      messageI18nParams,
      children,
      confirmText,
      dismissText,
    } = this.props;

    return (
      <View style={styles.container}>
        <ScrollView
          contentContainerStyle={styles.contentContainer}
          bounces={false}
        >
          {title && (
            <View style={styles.section}>
              <Text style={styles.title}>
                <I18n>{title}</I18n>
              </Text>
            </View>
          )}
          {message && (
            <View style={styles.section}>
              <Text style={styles.message}>
                <I18n params={messageI18nParams}>{message}</I18n>
              </Text>
            </View>
          )}
          {children}
          <View style={styles.section}>
            <ActionButton
              title={confirmText || 'Ok'}
              theme="magenta"
              onPress={this.onConfirm}
              style={styles.button}
            />
            {dismissText && (
              <ActionButton
                title={dismissText}
                theme="whiteWithBorder"
                onPress={this.onDismiss}
                style={styles.button}
              />
            )}
          </View>
        </ScrollView>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    maxHeight: Theme.dimensions.windowHeight - 40,
    width: 320,
    marginTop: Theme.dimensions.topSpace + 20,
    marginBottom: Theme.dimensions.bottomSpace + 20,
    backgroundColor: Theme.color.white,
    borderRadius: 10,
  },
  contentContainer: {
    paddingHorizontal: 30,
    paddingVertical: 20,
  },
  section: {
    marginVertical: 10,
  },
  title: {
    color: Theme.color.veryDarkGray,
    fontFamily: Theme.fonts.barlowCondensedBold,
    fontSize: 22,
  },
  message: {
    color: Theme.color.darkGray,
    fontFamily: Theme.fonts.barlow,
    fontSize: 16,
  },
  button: {
    marginTop: 10,
  },
});
