import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import moment from 'moment';
import momentTZ from 'moment-timezone';
import 'moment/locale/pt-br';
import en from './en/';
import pt from './pt/';
import SentryService from '../libs/SentryService';

class LanguageService {
  constructor() {
    // const deviceLanguage = window.navigator.userLanguage || window.navigator.language;
    const deviceLanguage = 'pt-br';

    // extrai apenas as duas primeiras letras para definir o idioma
    // assim garantimos o idioma certo em casos como en_BR, pt_PT etc
    const baseLanguage = (deviceLanguage || '').substr(0, 2);
    const availableLanguages = [
      { baseCode: 'pt', mudeCode: 'pt-br' },
      { baseCode: 'en', mudeCode: 'en' },
    ];

    const foundLanguage = availableLanguages.find(
      i => i.baseCode === baseLanguage,
    );

    this.bootLanguage = foundLanguage ? foundLanguage.mudeCode : 'pt-br';
  }

  setup = async () => {
    try {
      await i18n.use(initReactI18next).init({
        resources: {
          en,
          pt,
        },
        lng: this.bootLanguage,
        fallbackLng: this.bootLanguage,
        interpolation: {
          escapeValue: false,
        },
      });
    } catch (error) {
      SentryService.log(error);
    }

    this.setLanguage(this.bootLanguage);
  };

  getLanguage = () => {
    return i18n.language || this.bootLanguage;
  };

  setLanguage = language => {
    if (!language) {
      return;
    }
    i18n.changeLanguage(language);
    moment.locale(language);

    // https://github.com/moment/moment-timezone/issues/647#issuecomment-439600573
    momentTZ.defineLocale(language, moment.localeData()._config);
    momentTZ.locale(language);
  };
}

export default new LanguageService();
