import React, { useCallback } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { genderContext } from '../libs/Utils';
import NavigationService from '../libs/NavigationService';
import Theme from '../libs/Theme';
import I18n from './I18n';
import ActionButton from './ActionButton';

export default function DonationCard({ trainer, lessonId, workoutId }) {
  const onPressDonation = useCallback(() => {
    if (lessonId) {
      NavigationService.navigate('LessonDonation', { id: lessonId });
    } else if (workoutId) {
      NavigationService.navigate('WorkoutDonation', { id: workoutId });
    }
  }, [lessonId, workoutId]);

  return (
    <View style={styles.container}>
      <Text style={styles.title}>
        <I18n>donationTitle</I18n>
      </Text>
      <Text style={styles.text}>
        <I18n
          params={{
            context: genderContext(trainer),
            trainer: trainer?.name,
          }}
        >
          lessonDonationDescription
        </I18n>
      </Text>
      <ActionButton
        style={styles.donation}
        title="donateCta"
        theme="magenta"
        onPress={onPressDonation}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: Theme.color.white,
    borderRadius: 10,
    paddingHorizontal: 20,
  },
  title: {
    color: Theme.color.veryDarkGray,
    fontFamily: Theme.fonts.barlowSemiCondensedSemiBold,
    fontSize: 18,
    marginVertical: 20,
  },
  text: {
    color: Theme.color.darkGray,
    fontFamily: Theme.fonts.barlow,
    fontSize: 16,
  },
  donation: {
    marginVertical: 20,
  },
});
