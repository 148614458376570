import React from 'react';
import Svg, { Path } from 'react-native-svg';
import Theme from '../libs/Theme';

export default class MapIcon extends React.PureComponent {
  static defaultProps = {
    color: Theme.color.magenta,
  };

  render() {
    return (
      <Svg width={20} height={22} {...this.props}>
        <Path
          fill={this.props.color}
          d="M18 16.5l-4-1.7V2.5l4 1.7v12.3zM8 6.9l4-3.7V15l-4 3.7V6.9zM2 5.5l4 1.7v12.3l-4-1.7V5.5zm17.4-2.9l-6-2.5c-.1-.1-.2-.1-.3-.1H12.8h-.1c-.1 0-.1 0-.2.1h-.1-.1l-.1.1h-.1L6.8 5.3 1.4 3.1c-.3-.2-.7-.1-1 .1-.2.1-.4.5-.4.8v14.5c0 .4.2.8.6.9l6 2.5c.1.1.3.1.4.1.1 0 .2 0 .3-.1h.1s.1 0 .1-.1c0 0 .1 0 .1-.1l5.5-5.1 5.4 2.3c.2.1.4.1.5.1.2 0 .4-.1.6-.2.3-.2.4-.5.4-.8V3.5c0-.4-.2-.8-.6-.9z"
        />
      </Svg>
    );
  }
}
