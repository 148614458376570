import StoreService from '../libs/StoreService';

const ADD_EMERGENCY_CONTACTS = 'EMERGENCY/ADD_EMERGENCY_CONTACTS';
const ADD_EMERGENCY_CONTACT = 'EMERGENCY/ADD_EMERGENCY_CONTACT';
const DELETE_EMERGENCY_CONTACTS = 'EMERGENCY/DELETE_EMERGENCY_CONTACTS';

export const addEmergencyContacts = contacts => {
  StoreService.dispatch({ type: ADD_EMERGENCY_CONTACTS, contacts });
};

export const addEmergencyContact = contact => {
  StoreService.dispatch({ type: ADD_EMERGENCY_CONTACT, contact });
};

export function deleteEmergencyContacts() {
  StoreService.dispatch({ type: DELETE_EMERGENCY_CONTACTS });
}

export default function reducer(state = {}, action) {
  switch (action.type) {
    case ADD_EMERGENCY_CONTACTS: {
      const newState = { ...state };
      (action.contacts || []).forEach(item => (newState[item.id] = item));
      return newState;
    }

    case ADD_EMERGENCY_CONTACT: {
      return { ...state, [action.contact.id]: action.contact };
    }

    case DELETE_EMERGENCY_CONTACTS: {
      return {};
    }

    default:
      return state;
  }
}
