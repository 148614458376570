// import { InteractionManager } from "react-native";
import { Database, Q } from '@nozbe/watermelondb';
import LokiJSAdapter from '@nozbe/watermelondb/adapters/lokijs';
import isEqual from 'react-fast-compare';
import schema from './schema';
import migrations from './migrations';
import AuthService from '../libs/AuthService';
import { __DEV__ } from '../libs/Env';

import Address from './model/Address';
import City from './model/City';
import Customer from './model/Customer';
import CustomerSettings from './model/CustomerSettings';
import EmergencyContact from './model/EmergencyContact';
import Enrollment from './model/Enrollment';
import Event from './model/Event';
import Exercise from './model/Exercise';
import ExerciseModality from './model/ExerciseModality';
import ExerciseRequirement from './model/ExerciseRequirement';
import Lesson from './model/Lesson';
import LessonGroup from './model/LessonGroup';
import Modality from './model/Modality';
import Neighborhood from './model/Neighborhood';
import Place from './model/Place';
import Product from './model/Product';
import Request from './model/Request';
import Sponsor from './model/Sponsor';
import SponsorGroup from './model/SponsorGroup';
import Trainer from './model/Trainer';
import User from './model/User';
import Workout from './model/Workout';
import WorkoutCollection from './model/WorkoutCollection';
import WorkoutConsumption from './model/WorkoutConsumption';

const PRINT_LOGS = __DEV__;

class Watermelon {
  setup = dbName => {
    const adapter = new LokiJSAdapter({
      dbName,
      schema,
      migrations,
      useWebWorker: false,
      useIncrementalIndexedDB: true,
      extraIncrementalIDBOptions: {
        onversionchange: () => {
          // database was deleted in another browser tab (user logged out), so we must make sure we delete
          // it in this tab as well
          if (AuthService.isLogged) {
            window.location.reload();
          }
        },
      },
    });

    this._database = new Database({
      adapter,
      modelClasses: [
        Address,
        City,
        Customer,
        CustomerSettings,
        EmergencyContact,
        Enrollment,
        Event,
        Exercise,
        ExerciseModality,
        ExerciseRequirement,
        Lesson,
        LessonGroup,
        Modality,
        Neighborhood,
        Place,
        Product,
        Request,
        Sponsor,
        SponsorGroup,
        Trainer,
        User,
        Workout,
        WorkoutCollection,
        WorkoutConsumption,
      ],
    });
  };

  // throttling para não floodar nem concorrer com a gravação no banco
  sync = async (table, raw, attempts = 100) => {
    if (this.syncing && attempts > 0) {
      setTimeout(() => this.sync(table, raw, attempts - 1), 100);
      return;
    }
    this.syncing = true;
    return new Promise(async (resolve, reject) => {
      try {
        const result = await this.strongSync(table, raw);
        this.syncing = false;
        resolve(result);
      } catch (error) {
        this.syncing = false;
        reject(error);
      }
    });
  };

  // é sugerido q tente mais uma vez antes de disparar o erro
  // https://nozbe.github.io/WatermelonDB/Advanced/Sync.html#implementation-tips
  strongSync = async (table, raw, attempts = 1) => {
    try {
      return await this.executeSync(table, raw);
    } catch (error) {
      if (attempts > 0) {
        return await this.strongSync(table, raw, attempts - 1);
      } else {
        throw error;
      }
    }
  };

  executeSync = async (table, raw) => {
    const collection = this._database.collections.get(table);

    // limpa os dados que vieram da api para estar no formato a ser normalizado e salvo
    // ref: https://github.com/Nozbe/WatermelonDB/blob/master/src/RawRecord/index.js#L70
    const tableSchema = schema.tables[table];
    const columns = tableSchema.columnArray;
    const rawList = Array.isArray(raw) ? [...raw] : [raw];
    rawList.forEach(r => this.sanitizeRaw(columns, r));

    // resgata todas as entradas no banco q já existem o mesmo id
    const existingList = await collection
      .query(Q.where('id', Q.oneOf(rawList.map(i => i.id))))
      .fetch();

    // com todos os dados prontos, começa o sync, atenção q está tudo
    // dentro da mesma action para evitar conflito de dados
    await this._database.action(async () => {
      const statments = [];

      rawList.forEach(_raw => {
        const existing = existingList.find(record => record.id === _raw.id);
        if (existing) {
          // se a entrada existir, verifica se precisa de update, isso impede
          // que os componentes re-renderizem se não houver mudança de dados
          const updated = this.isUpdated(existing, _raw);
          if (updated) {
            // TODO vale mudar o _status e o _changed default do Watermelon?
            this.log('[updating]', table, _raw.id);
            statments.push(existing.prepareUpdate(this.prepareRecord(_raw)));
          }
        } else {
          this.log('[creating]', table, _raw.id);
          statments.push(collection.prepareCreate(this.prepareRecord(_raw)));
        }
      });

      await this._database.batch(...statments);
    });

    return raw;
  };

  sanitizeRaw = (columns, raw) => {
    raw.id = String(raw.id);
    for (let i = 0, len = columns.length; i < len; i++) {
      const column = columns[i];
      const key = column.name;

      if (typeof raw[key] === 'undefined') {
        continue;
      }

      if (!raw[key] && column.isOptional) {
        raw[key] = null;
        continue;
      }

      switch (column.type) {
        case 'string':
          if (column.__isForeignKeyArray) {
            raw[key] = raw[key] ? raw[key]?.map(id => String(id)) : [];
          } else if (column.__isJson) {
            // TODO precisamos fazer um deepSearch atrás de `item.id` para `String(item.id)`?
            // se sim, será q o __isForeignKeyArray não poderia ser um simples __isJson? ou até msm um __isArray?
            raw[key] = raw[key] ? raw[key] : {};
          } else {
            raw[key] = raw[key] ? String(raw[key]) : '';
          }
          break;
        case 'number':
          raw[key] = raw[key] ? Number(raw[key]) : 0;
          break;
        case 'boolean':
          raw[key] = raw[key] ? Boolean(raw[key]) : false;
          break;
        default:
      }
    }
  };

  isUpdated = (record, raw) => {
    const tableSchema = schema.tables[record.table];
    const columns = tableSchema.columnArray;
    for (let i = 0, len = columns.length; i < len; i++) {
      const key = columns[i].name;
      // usamos o isEqual pois pode ter dados complexos como json, array etc
      if (typeof raw[key] !== 'undefined' && !isEqual(record[key], raw[key])) {
        this.log(`${key}: ${record[key]} => ${raw[key]}`);
        return true;
      }
    }
    return false;
  };

  prepareRecord = raw => {
    return record => {
      const tableSchema = schema.tables[record.table];
      const columns = tableSchema.columnArray;
      for (let i = 0, len = columns.length; i < len; i++) {
        const key = columns[i].name;
        if (typeof raw[key] !== 'undefined') {
          record[key] = raw[key];
        }
      }
      if (raw.id) {
        record._raw.id = String(raw.id);
      }
    };
  };

  log = (...args) => {
    if (PRINT_LOGS) {
      console.log('[SYNC]', ...args);
    }
  };

  getCollection = table => {
    return this._database.collections.get(table);
  };

  setLocal = (key, value) => {
    return this._database.adapter.setLocal(key, value);
  };

  getLocal = key => {
    return this._database.adapter.getLocal(key);
  };

  removeLocal = key => {
    return this._database.adapter.removeLocal(key);
  };

  clearCollection = table =>
    this._database.action(() =>
      this._database.collections.get(table).query().destroyAllPermanently(),
    );
}

const instance = new Watermelon();

global.Watermelon = instance;

export default instance;
