import AsyncStorage from '@react-native-community/async-storage';
import SentryService from './SentryService';
import { STORAGE_KEY } from './Env';

class StorageService {
  constructor() {
    this.default_city = '';
  }

  async setup() {
    try {
      const dataString = await AsyncStorage.getItem(STORAGE_KEY);
      const dataJson = JSON.parse(dataString) || {};
      this.default_city = dataJson.default_city;
    } catch (error) {
      SentryService.log(error);
    }
  }

  async setDefaultCity(default_city) {
    this.default_city = default_city;

    try {
      await AsyncStorage.setItem(
        STORAGE_KEY,
        JSON.stringify({
          default_city: this.default_city,
        }),
      );
    } catch (error) {
      SentryService.log(error);
    }
  }

  getDefaultCity() {
    return this.default_city ? Number(this.default_city) : undefined;
  }

  async clear() {
    this.default_city = '';

    try {
      await AsyncStorage.removeItem(STORAGE_KEY);
    } catch (error) {
      SentryService.log(error);
    }
  }
}

export default new StorageService();
