import React from 'react';
import { StyleSheet } from 'react-native';
import FastImage from 'react-native-fast-image';
import LanguageService from '../languages/LanguageService';
import Theme from '../libs/Theme';

export default function SplashImage() {
  const image =
    LanguageService.getLanguage() === 'en'
      ? require('../images/splash-en.jpg')
      : require('../images/splash-pt.jpg');

  return <FastImage style={styles.image} source={image} />;
}

const styles = StyleSheet.create({
  image: {
    flex: 1,
    backgroundColor: Theme.color.white,
    ...StyleSheet.absoluteFillObject,
  },
});
