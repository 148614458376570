import React, { useEffect } from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';
import LinearGradient from 'react-native-web-linear-gradient';
import ActivityEntry from '../components/ActivityEntry';
import HeaderLogoAndProfile from '../components/HeaderLogoAndProfile';
import Loading from '../components/Loading';
import SelectInput from '../components/SelectInput';
import City from '../database/model/City';
import Lesson from '../database/model/Lesson';
import { Me } from '../database/model/Request';
import DataService, { withObservables } from '../libs/DataService';
import Theme from '../libs/Theme';
import { RIO_DE_JANEIRO } from '../libs/Utils';

type Props = {
  city: string;
  cities: City[];
  lessons: Lesson[];
};

const GroupClassScreen = ({ city, cities, lessons }: Props) => {
  useEffect(() => {
    DataService.syncMe();
    DataService.syncLessons({ city });
  }, [city]);

  const onChangeCity = (value: string) => {
    DataService.updateMeDefaultCity(value).then(() => {
      document.location.reload();
    });
  };

  return (
    <>
      <LinearGradient
        locations={[0.15, 0.5]}
        colors={[
          Theme.color.white,
          Theme.color.lowContrastGray_USE_WITH_WISDOM,
        ]}
        style={StyleSheet.absoluteFill}
      />
      <HeaderLogoAndProfile />
      <ScrollView
        bounces={false}
        showsVerticalScrollIndicator={false}
        contentContainerStyle={styles.contentContainer}
      >
        <View style={styles.city}>
          <SelectInput
            onChange={onChangeCity}
            value={city}
            items={cities
              .sort((a, b) => a.name.localeCompare(b.name))
              .map(c => ({ label: c.name, value: c.id }))}
          />
        </View>
        {lessons.length ? (
          lessons.map(lesson => (
            <View key={lesson.id} style={styles.entry}>
              <ActivityEntry item={{ type: 'lesson', id: lesson.id }} />
            </View>
          ))
        ) : (
          <Loading />
        )}
      </ScrollView>
    </>
  );
};

const styles = StyleSheet.create({
  contentContainer: {
    paddingHorizontal: 20,
    paddingBottom: 100,
  },
  city: {
    paddingBottom: 20,
  },
  entry: {
    marginVertical: 10,
  },
});

const enhance2: any = withObservables(['me'], ({ me }: { me?: Me }) => {
  const city = me?.default_city || RIO_DE_JANEIRO;
  return {
    city: DataService.observe(city),
    cities: DataService.observeCities(),
    lessons: DataService.observeLessons({ city }),
  };
});

const enhance: any = withObservables([], () => ({
  me: DataService.observeMe(),
}));

export default enhance(enhance2(GroupClassScreen));
