import React from 'react';
import Svg, { Path } from 'react-native-svg';

export default function ProfileIcon(props) {
  return (
    <Svg width={22} height={22} viewBox="0 0 22 22" {...props}>
      <Path
        fill={props.color}
        d="M11 1c5.5 0 10 4.5 10 10s-4.5 10-10 10S1 16.5 1 11 5.5 1 11 1zm0 13.3c-1.8 0-3.4 1.2-3.4 2.6v1.7c2.2 1 4.6 1 6.8 0v-1.7c0-1.5-1.6-2.6-3.4-2.6zm.1-11.6C7.5 2.7 4.4 5 3.2 8.3s0 7.1 2.7 9.2v-.7c0-2.4 2.3-4.3 5.1-4.3s5.1 1.9 5.1 4.3v.7c2.8-2.2 3.9-5.9 2.7-9.2s-4.2-5.6-7.7-5.6zm1.3 3c1.2.5 2 1.7 2 2.9 0 1.8-1.4 3.2-3.2 3.2-1.3 0-2.4-.8-2.9-2s-.3-2.5.6-3.4c.9-.9 2.3-1.2 3.5-.7zm-1.2 1.5c-.6 0-1.2.4-1.4.9s-.1 1.2.3 1.7c.4.4 1.1.6 1.7.3s.9-.8.9-1.4c0-.8-.7-1.5-1.5-1.5z"
      />
    </Svg>
  );
}
