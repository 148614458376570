import { Model, Relation } from '@nozbe/watermelondb';
import { field, relation } from '@nozbe/watermelondb/decorators';
import { Associations } from '@nozbe/watermelondb/Model';
import Customer from './Customer';

export default class EmergencyContact extends Model {
  static table = 'emergency_contacts';

  static associations: Associations = {
    customers: { type: 'belongs_to', key: 'customer' },
  };

  @relation('customers', 'customer') customer_obj!: Relation<Customer>;

  @field('name') name!: string;
  @field('phone_country_code') phone_country_code!: string;
  @field('phone') phone!: string;
  @field('email') email!: string;
  @field('relationship') relationship!: string;
  @field('customer') customer!: string;
}
