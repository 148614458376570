import axios from 'axios';
import {
  isNetworkError,
  isTimeoutError,
  isResponseTimedOut,
  isResponseCanceled,
} from './ApiUtils';
import AuthService from './AuthService';

const API_URL =
  'https://31v7r3o6a4.execute-api.us-east-1.amazonaws.com/chat-service';

function addScript(src) {
  if (window.document.getElementById(src)) {
    return;
  }
  const js = window.document.createElement('script');
  js.id = src;
  js.src = src;
  window.document.body.appendChild(js);
}

class ChatApi {
  setup = async () => {
    if (this.db) {
      return;
    }

    return new Promise((resolve, reject) => {
      addScript('https://www.gstatic.com/firebasejs/7.19.0/firebase-app.js');
      addScript(
        'https://www.gstatic.com/firebasejs/7.19.0/firebase-firestore.js',
      );

      let retryTime = 8;
      let giveUpTime = 30 * 1000;

      const onLoad = () => {
        if (!window.firebase || !window.firebase.firestore) {
          if (retryTime > giveUpTime) {
            reject();
          } else {
            retryTime *= 2;
            setTimeout(onLoad, retryTime);
          }
          return;
        }

        if (!this.db) {
          const app = window.firebase.initializeApp({
            apiKey: 'AIzaSyCCyWIHxWbj5Zc-VqZVuKlxVPBtYBtX2zU',
            authDomain: 'app-mude.firebaseapp.com',
            projectId: 'app-mude',
          });
          this.db = window.firebase.firestore(app);
        }

        resolve();
      };

      onLoad();
    });
  };

  sendMessage = async (chat, text) => {
    const config = {
      method: 'post',
      url: API_URL,
      headers: { 'Content-Type': 'application/json' },
      data: {
        t: AuthService.accessToken,
        chat,
        text,
      },
    };
    const response = await this.strongRequest(config);
    return response.data;
  };

  strongRequest = async (config, attempts = 5, timeout = 2500) => {
    try {
      const response = await axios({
        timeout,
        ...config,
      });

      if (
        attempts &&
        (isResponseTimedOut(response) || isResponseCanceled(response))
      ) {
        return this.strongRequest(config, attempts - 1, timeout * 2);
      }

      return response;
    } catch (error) {
      if (attempts && (isNetworkError(error) || isTimeoutError(error))) {
        return this.strongRequest(config, attempts - 1, timeout * 2);
      }
      throw error;
    }
  };

  getMessages = chat => {
    return this.db.collection('chats').doc(String(chat)).collection('messages');
  };
}

export default new ChatApi();
