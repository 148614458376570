import i18n from 'i18next';
import { isNetworkError, isTimeoutError } from './ApiUtils';
import AlertService from './AlertService';
import SentryService from './SentryService';

function hasLetters(string = '') {
  const letters = 'abcdefghyjklmnopqrstuvwxyz';
  string = string.toLowerCase();
  for (let i = 0; i < string.length; i++) {
    if (letters.indexOf(string.charAt(i), 0) !== -1) {
      return true;
    }
  }
  return false;
}

function hasNumbers(string = '') {
  const numbers = '1234567890';
  for (let i = 0; i < string.length; i++) {
    if (numbers.indexOf(string.charAt(i), 0) !== -1) {
      return true;
    }
  }
  return false;
}

export function validatePassword(password, passwordConfirmation) {
  if (password.length < 8 || !hasLetters(password) || !hasNumbers(password)) {
    return 'weakPassword';
  } else if (!passwordConfirmation.length) {
    return 'confirmPasswordRequired';
  } else if (password !== passwordConfirmation) {
    return 'differentPasswords';
  }
  return '';
}

export function validateCpf(cpf) {
  cpf = cpf.replace(/[^\d]+/g, '');

  if (cpf === '') {
    return false;
  }

  // Elimina CPFs invalidos conhecidos
  if (
    cpf.length !== 11 ||
    cpf === '00000000000' ||
    cpf === '11111111111' ||
    cpf === '22222222222' ||
    cpf === '33333333333' ||
    cpf === '44444444444' ||
    cpf === '55555555555' ||
    cpf === '66666666666' ||
    cpf === '77777777777' ||
    cpf === '88888888888' ||
    cpf === '99999999999'
  ) {
    return false;
  }

  // Valida 1o digito
  let add = 0;
  for (let i = 0; i < 9; i++) {
    add += parseInt(cpf.charAt(i), 10) * (10 - i);
  }

  let rev = 11 - (add % 11);

  if (rev === 10 || rev === 11) {
    rev = 0;
  }

  if (rev !== parseInt(cpf.charAt(9), 10)) {
    return false;
  }

  // Valida 2o digito
  add = 0;

  for (let i = 0; i < 10; i++) {
    add += parseInt(cpf.charAt(i), 10) * (11 - i);
  }

  rev = 11 - (add % 11);

  if (rev === 10 || rev === 11) {
    rev = 0;
  }

  if (rev !== parseInt(cpf.charAt(10), 10)) {
    return false;
  }

  return true;
}

export function validateEmail(email) {
  return /^\w+([.+-]?\w+)*@\w+([.-]?\w+)+(\.\w{2,3})+$/.test(email);
}

export function formatErrorFromApi(error, expectedKey = '') {
  const data = ((error || {}).response || {}).data || {};
  const feedback =
    data[expectedKey] ||
    data.error_description ||
    data.errors ||
    data.error ||
    data.detail ||
    data;

  function translate(string) {
    // atualiza o `keySeparator` e `nsSeparator` para permitir `.` (ponto) e `:` (dois pontos) no texto
    // https://stackoverflow.com/a/34037706/1288541
    if (typeof string === 'string') {
      return i18n.t(String(string) || '', {
        keySeparator: '|',
        nsSeparator: '||',
      });
    } else if (Array.isArray(string)) {
      return string.map(s => translate(s)).join('. ');
    } else {
      return '';
    }
  }

  if (Array.isArray(feedback)) {
    return feedback.map(s => translate(s)).join('\n');
  }

  if (typeof feedback === 'object') {
    return Object.keys(feedback)
      .map(key => {
        const translatedKey = translate(key);
        const translatedMessage = translate(feedback[key]);
        if (translatedMessage) {
          return `${translatedKey}: ${translatedMessage}`;
        } else {
          return '';
        }
      })
      .join('\n');
  }

  return translate(feedback);
}

export function resolveError(
  error,
  autoAlert = false,
  expectedKey = '',
  // TODO devemos remover esse parametro e combinar com o `autoAlert`.
  // em form que trate visualmente erros deve exibir da mesma forma o erro de rede
  // não precisando do AlertService.show()
  // como isso é um refactoring grande e que exige atenção, melhor fazer com calma
  autoNetworkErrorAlert = true,
) {
  // se for um erro de rede genérico e conhecido, exibe o modal e não imprime nada na tela
  if (isNetworkError(error) || isTimeoutError(error)) {
    if (autoNetworkErrorAlert) {
      AlertService.show({
        title: 'networkErrorTitle',
        message: 'networkErrorContent',
      });
      return '';
    } else {
      return 'Network Error';
    }
  }

  // trata o erro para tentar resgatar uma string amigável para o usuário
  let feedback = formatErrorFromApi(error, expectedKey);

  // se não conseguir um erro amigável
  if (!feedback) {
    // salva no log para tratarmos no futuro
    SentryService.log(error);

    // tenta resgatar qualquer string usável do objeto de erro
    feedback = (error || {}).message || '';
  }

  // se ainda assim não houver nenhum feedback, exibe o erro genérico
  if (!feedback) {
    if (autoNetworkErrorAlert) {
      AlertService.show({
        title: 'genericErrorTitle',
        message: 'genericErrorContent',
      });
    }
    return '';
  }

  // se o componente não renderizar o erro, exibe no formato de alerta pela praticidade
  if (autoAlert) {
    AlertService.show({
      title: 'genericErrorTitle',
      message: feedback,
    });
  }

  // retorna a string para o componente renderizar na tela contextualmente
  return feedback;
}
