import React, { useEffect, useState } from 'react';
import axios from 'axios';
import styles from './EventScreen.module.scss';
import { API_URL } from '../libs/Env';
import Theme from '../libs/Theme';
import { formatApiError } from '../libs/Utils';
import LayoutWithHeader from '../components/LayoutWithHeader';
import LessonsByDate from '../components/LessonsByDate';
import withNavParams from '../components/withNavParams';

function EventScreen({ id }) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [event, setEvent] = useState(null);

  useEffect(() => {
    async function load() {
      try {
        const result = await axios.get(`${API_URL}/event/${id}/`);
        setEvent(result.data);
      } catch (error) {
        setError(formatApiError(error));
      }
      setLoading(false);
    }
    load();
  }, [id]);

  if (!event) {
    return <LayoutWithHeader loading={loading} error={error} />;
  }

  const sponsor = event.main_sponsor;
  const backgroundColor = sponsor?.main_color || Theme.color.magenta;

  return (
    <LayoutWithHeader loading={loading} error={error}>
      <div
        className={styles.header}
        style={{ backgroundImage: `url(${event.hero_image})` }}
      >
        <div className={styles.intro}>{event.period_description}</div>
        <div className={styles.name}>{event.name}</div>
        <div className={styles.details}>{event.description}</div>
      </div>
      <div className={styles.sponsorBar} style={{ backgroundColor }}>
        {!!sponsor?.transparency_logo && (
          <img src={sponsor.transparency_logo} className={styles.logo} alt="" />
        )}
      </div>
      <div className={styles.lessons}>
        <LessonsByDate event={id} />
      </div>
    </LayoutWithHeader>
  );
}

export default withNavParams(EventScreen);
