import React from 'react';
import {
  ScrollView,
  StyleSheet,
  TouchableWithoutFeedback,
  View,
} from 'react-native';
import NavigationService from '../libs/NavigationService';
import DotCounter from '../components/DotCounter';
import ActionButton from '../components/ActionButton';
import Api from '../libs/Api';
import {
  validateEmail,
  validatePassword,
  resolveError,
} from '../libs/FormUtils';
import Theme from '../libs/Theme';
import BackArrowIcon from '../icons/BackArrowIcon';
import I18n from '../components/I18n';
import TextInput from '../components/TextInput';
import TextLink from '../components/TextLink';
import AndroidBackHandler from '../components/AndroidBackHandler';
import KeyboardScrollView from '../components/KeyboardScrollView';
import FormLayout from '../components/FormLayout';
import CheckboxInput from '../components/CheckboxInput';

export default class CreateAccountScreen extends React.PureComponent {
  constructor(props) {
    super(props);

    this.scrollView = React.createRef();

    this.state = {
      currentSlide: 0,
      nickname: '',
      email: '',
      phone_country_code: '+55',
      phone: '',
      password: '',
      password_confirm: '',
      policy: false,
      error: '',
    };
  }

  onPressBack = () => {
    if (this.state.currentSlide === 0) {
      NavigationService.goBack();
    } else {
      this.prevSlide();
      return true;
    }
  };

  prevSlide = () => {
    this.changeSlide(this.state.currentSlide - 1);
  };

  nextSlide = () => {
    this.changeSlide(this.state.currentSlide + 1);
  };

  changeSlide = currentSlide => {
    this.setState({ currentSlide, error: '' });

    if (this.scrollView.current) {
      setTimeout(() => {
        this.scrollView.current.scrollTo({
          x: currentSlide * Theme.dimensions.windowWidth,
          y: 0,
        });
      });
    }
  };

  onPressAnonymous = () => {
    NavigationService.reset('Tab');
  };

  onChangeInput = (value, name) => {
    this.setState({ [name]: value });
  };

  onTogglePolicy = value => {
    this.setState({ policy: value });
  };

  onSubmit = async () => {
    this.setState({ error: '' });

    const { nickname, email, phone, phone_country_code, password } = this.state;

    const payload = {
      nickname,
      email,
      phone,
      phone_country_code,
      password,
    };

    try {
      await Api.createAccount(payload);
    } catch (error) {
      this.setState({ error: resolveError(error) });
      return false;
    }

    NavigationService.reset('Tab');
  };

  validate = {
    nickname: () => {
      const nickname = this.state.nickname.trim();

      if (!nickname.length) {
        this.setState({ error: 'nicknameRequired' });
        return false;
      }

      return true;
    },

    email: () => {
      const email = this.state.email.trim();

      if (!email) {
        this.setState({ error: 'emailRequired' });
        return false;
      }

      if (!validateEmail(email)) {
        this.setState({ error: 'emailInvalid' });
        return false;
      }

      return true;
    },

    phone: () => {
      const phone = this.state.phone.trim();

      if (!phone) {
        this.setState({ error: 'phoneRequired' });
        return false;
      }

      if (phone.length < 8) {
        this.setState({ error: 'phoneInvalid' });
        return false;
      }

      return true;
    },

    password: () => {
      const { password, password_confirm } = this.state;

      const error = validatePassword(password, password_confirm);
      if (error) {
        this.setState({ error });
        return false;
      }

      if (!this.state.policy) {
        this.setState({ error: 'uncheckedPolicy' });
        return false;
      }

      return true;
    },
  };

  renderNickname = () => {
    const { nickname, error } = this.state;

    const fields = [
      <TextInput
        name="nickname"
        value={nickname}
        label="nickname"
        onChange={this.onChangeInput}
      />,
    ];

    const button = (
      <ActionButton
        title="nextStep"
        theme="magenta"
        onPress={() => this.validate.nickname() && this.nextSlide()}
      />
    );

    return (
      <>
        <FormLayout
          header="howWouldYouWantToBeNamed"
          content="thisNameCanBeSeenByOthers"
          fields={fields}
          footer={button}
          error={error}
        />
        <View style={styles.anonymousLogin}>
          <ActionButton
            title="logInWithoutRegistration"
            theme="white"
            onPress={this.onPressAnonymous}
          />
        </View>
      </>
    );
  };

  renderEmail = () => {
    const { nickname, email, error } = this.state;

    const fields = [
      <TextInput
        name="email"
        value={email}
        label="email"
        onChange={this.onChangeInput}
      />,
    ];

    const button = (
      <ActionButton
        title="nextStep"
        theme="magenta"
        onPress={() => this.validate.email() && this.nextSlide()}
      />
    );

    return (
      <FormLayout
        header={<I18n params={{ name: nickname }}>whatIsYourEmail</I18n>}
        content="thisEmailWillBeUsedToLogYouIntoYourAccount"
        fields={fields}
        footer={button}
        error={error}
      />
    );
  };

  renderPhone = () => {
    const { phone_country_code, phone, error } = this.state;

    const fields = [
      <View style={styles.row}>
        <View style={styles.countryCode}>
          <TextInput
            name="phone_country_code"
            value={phone_country_code}
            label="ddi"
            onChange={this.onChangeInput}
          />
        </View>
        <View style={styles.phone}>
          <TextInput
            name="phone"
            value={phone}
            label="phone"
            onChange={this.onChangeInput}
          />
        </View>
      </View>,
    ];

    const button = (
      <ActionButton
        title="nextStep"
        theme="magenta"
        onPress={() => this.validate.phone() && this.nextSlide()}
      />
    );

    return (
      <FormLayout
        header="whatIsYourPhone"
        fields={fields}
        footer={button}
        error={error}
      />
    );
  };

  renderPassword = () => {
    const { password, password_confirm, policy, error } = this.state;

    const fields = [
      <TextInput
        name="password"
        value={password}
        label="password"
        onChange={this.onChangeInput}
      />,
      <TextInput
        name="password_confirm"
        value={password_confirm}
        label="confirmPassword"
        onChange={this.onChangeInput}
      />,
      <CheckboxInput
        name="policy"
        value={policy}
        label={
          <I18n i18nKey="agreeWithPolicy">
            <TextLink url="https://www.mude.fit/termos-e-privacidade/" />
          </I18n>
        }
        onChange={this.onTogglePolicy}
      />,
    ];

    const button = (
      <ActionButton
        title="createAccountSubmit"
        theme="magenta"
        onPress={() => this.validate.password() && this.onSubmit()}
      />
    );

    return (
      <FormLayout
        header="createYourPassword"
        content="passwordInstructions"
        footer={button}
        fields={fields}
        error={error}
      />
    );
  };

  render() {
    const { currentSlide } = this.state;
    return (
      <AndroidBackHandler onBackPress={this.onPressBack}>
        <View style={styles.header}>
          <TouchableWithoutFeedback onPress={this.onPressBack}>
            <View style={styles.back}>
              <BackArrowIcon ccolor={Theme.color.magenta} />
            </View>
          </TouchableWithoutFeedback>
          <View style={styles.dots}>
            <DotCounter total={4} current={currentSlide || 0} />
          </View>
        </View>
        <KeyboardScrollView>
          <ScrollView
            horizontal
            pagingEnabled
            scrollEnabled={false}
            showsHorizontalScrollIndicator={false}
            bounces={false}
            ref={this.scrollView}
          >
            <View style={styles.slide}>{this.renderNickname()}</View>
            <View style={styles.slide}>{this.renderEmail()}</View>
            <View style={styles.slide}>{this.renderPhone()}</View>
            <View style={styles.slide}>{this.renderPassword()}</View>
          </ScrollView>
        </KeyboardScrollView>
      </AndroidBackHandler>
    );
  }
}

const styles = StyleSheet.create({
  header: {
    paddingTop: Theme.dimensions.topSpace,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  back: {
    height: Theme.dimensions.headerHeight,
    width: 50,
    alignItems: 'center',
    justifyContent: 'center',
  },
  dots: {
    marginRight: 20,
  },
  slide: {
    flexGrow: 1,
    flexShrink: 1,
    width: Theme.dimensions.windowWidth,
  },
  row: {
    flexDirection: 'row',
  },
  countryCode: {
    width: 50,
    marginRight: 10,
  },
  phone: {
    flexGrow: 1,
    flexShrink: 1,
  },
  anonymousLogin: {
    flexGrow: 1,
    flexShrink: 1,
    alignItems: 'center',
    justifyContent: 'flex-end',
    paddingTop: 60,
    paddingBottom: 10 + Theme.dimensions.bottomSpace,
  },
});
