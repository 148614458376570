import React from 'react';
import Svg, { Path } from 'react-native-svg';

export default function FacebookIcon(props) {
  return (
    <Svg width={22} height={22} viewBox="0 0 22 22" {...props}>
      <Path
        fill="#1877f2"
        d="M21.9 11.1C21.9 5 17 .2 11 .2S.1 5 .1 11.1c0 5.4 4 10 9.2 10.8v-7.6H6.5v-3.1h2.8V8.8c0-2.7 1.6-4.2 4.1-4.2 1.2 0 2.4.2 2.4.2v2.7h-1.4c-1.4 0-1.8.8-1.8 1.7v2h3l-.5 3.1h-2.5v7.6c5.3-.9 9.3-5.4 9.3-10.8"
      />
    </Svg>
  );
}
