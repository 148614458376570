import React from 'react';
import { Linking } from 'react-native';
import { CommonActions, StackActions } from '@react-navigation/native';
import urlParse from 'url-parse';
import qs from 'qs';
import DataService from './DataService';
import AlertService from './AlertService';

const ROUTES = [
  { screen: 'Anamnesis', url: 'anamnese' },
  // { screen: "Checkin", url: "checkin" }, // TODO temporariamente desabilitado pois são páginas diferentes entre o app e web
  { screen: 'CompleteDataModal', url: 'completar-cadastro' },
  { screen: 'CreateAccount', url: 'criar-conta' },
  { screen: 'CreateAccountModal', url: 'criar-conta-modal' },
  { screen: 'Event', url: 'evento' },
  { screen: 'Exercise', url: 'exercicio' },
  { screen: 'ForgotPassword', url: 'esqueci-minha-senha' },
  { screen: 'ForgotPasswordModal', url: 'esqueci-minha-senha-modal' },
  { screen: 'FronDoor', url: '' },
  { screen: 'Lesson', url: 'aula' },
  { screen: 'LessonSpot', url: 'vaga' },
  { screen: 'LivePlayer', url: 'live/play' },
  { screen: 'Lives', url: 'lives' },
  { screen: 'Login', url: 'login' },
  { screen: 'LoginModal', url: 'login-modal' },
  { screen: 'Payments', url: 'pagamentos' },
  { screen: 'Profile', url: 'perfil' },
  { screen: 'RecoverPassword', url: 'recuperar-minha-senha' },
  { screen: 'Tab', url: 'aulas' },
  { screen: 'Trainer', url: 'professor' },
  { screen: 'Workout', url: 'treino' },
  { screen: 'Collection', url: 'colecao' },
  { screen: 'QrWorkoutScreen', url: 'treino-qr' },
];

export function createHref(name, params) {
  const routerPath = ROUTES.find(r => r.screen === name)?.url || '';
  const id = params?.id || null;
  const slug = params?.slug || null;
  delete params?.id;
  delete params?.id;

  const pathname = [routerPath, id, slug].filter(s => s).join('/');
  const search = qs.stringify(params, {
    addQueryPrefix: true,
    skipNulls: true,
  });

  return `/${pathname}${search}`;
}

class NavigationService {
  constructor() {
    this.ref = React.createRef();
  }

  navigate = (name, params) => {
    this.ref.current?.navigate(name, params);
  };

  push = (name, params) => {
    this.ref.current?.dispatch(StackActions.push(name, params));
  };

  pop = n => {
    this.ref.current?.dispatch(StackActions.pop(n));
  };

  reset = name => {
    this.ref.current?.dispatch(
      CommonActions.reset({ index: 0, routes: [{ name }] }),
    );
  };

  goBack = () => {
    this.ref.current?.goBack();
  };

  getActiveRoute = state => {
    if (!state) {
      state = this.ref.current?.getRootState();
    }

    const route = state?.routes[state.index];
    if (route?.state) {
      return this.getActiveRoute(route.state);
    }

    return route || {};
  };

  openUrl = async url => {
    const urlObj = urlParse(url);

    const handledHosts = [
      //
      '',
      'app.mude.fit',
      document.location.host,
    ];

    if (!handledHosts.includes(urlObj.host) || urlObj.protocol === 'mailto:') {
      this.openExternalUrl(url);
      return;
    }

    const paths = urlObj.pathname.split('/').filter(s => s);

    // url amigável possui apenas um nível
    if (paths.length === 1) {
      const trainer = await DataService.findTrainerBySlug(paths[0]);
      if (trainer) {
        this.navigate('Trainer', { id: trainer.id });
        return;
      }
    }

    // não conseguimos usar o path padrão do react-navigation pois temos
    // uma árvore complexa de navigators e teria q navegar entre elas
    const pathMap = {
      'recuperar-minha-senha': 'RecoverPassword',
      aula: 'Lesson',
      checkin: 'CheckIn',
      evento: 'Event',
      exercicio: 'Exercise',
      lives: 'Lives',
      treino: 'Workout',
      colecao: 'Collection',
      pagamentos: 'Payments',
      anamnese: 'Anamnesis',
      professor: 'Trainer',
    };

    const screen = pathMap[paths[0]] || paths[0];

    if (screen) {
      const query = paths[1]
        ? { id: Number(paths[1]) }
        : qs.parse(urlObj.query, { ignoreQueryPrefix: true });

      this.navigate(screen, query);
    }
  };

  openExternalUrl = url => {
    Linking.openURL(url).catch(() => {
      AlertService.show({
        message: 'couldNotOpenAUrl',
        messageI18nParams: { url },
        confirmText: 'tryAgain',
        dismissText: 'ok',
        onConfirm: () => {
          this.openExternalUrl(url);
        },
      });
    });
  };
}

export default new NavigationService();
