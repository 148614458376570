import NavigationService from '../libs/NavigationService';
import AuthService from '../libs/AuthService';

export default function AuthGate({ children }) {
  if (!AuthService.isLogged) {
    NavigationService.navigate('FrontDoor');
    return null;
  }

  return children || null;
}
