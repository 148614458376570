import React, { useCallback } from 'react';
import { TouchableWithoutFeedback, StyleSheet, Text, View } from 'react-native';
import FastImage from 'react-native-fast-image';
import DataService, {
  withObservables,
  withMapProps,
} from '../libs/DataService';
import NavigationService from '../libs/NavigationService';
import Theme from '../libs/Theme';
import I18n from './I18n';

const WIDTH = (Theme.dimensions.windowWidth - 60) / 3;

function TrainerCard({ trainer, modality, onPress }) {
  const _onPress = useCallback(() => {
    if (onPress) {
      onPress(trainer);
    } else {
      NavigationService.navigate('Trainer', { id: trainer.id });
    }
  }, [trainer, onPress]);

  if (!trainer) {
    return null;
  }

  return (
    <TouchableWithoutFeedback onPress={_onPress}>
      <View style={styles.container}>
        <FastImage style={styles.picture} source={{ uri: trainer.picture }} />
        <Text style={styles.name}>
          <I18n>{trainer.name}</I18n>
        </Text>
        <Text style={styles.modality}>
          <I18n>{modality?.name}</I18n>
        </Text>
      </View>
    </TouchableWithoutFeedback>
  );
}

const styles = StyleSheet.create({
  container: {
    width: WIDTH,
  },
  picture: {
    height: WIDTH * (538 / 360),
    width: WIDTH,
    borderRadius: WIDTH,
    backgroundColor: Theme.color.lowContrastGray_USE_WITH_WISDOM,
    overflow: 'hidden',
  },
  name: {
    color: Theme.color.veryDarkGray,
    fontFamily: Theme.fonts.barlowSemiBold,
    fontSize: 15,
    lineHeight: 15,
    textAlign: 'center',
    marginTop: 10,
  },
  modality: {
    color: Theme.color.gray,
    fontFamily: Theme.fonts.barlowSemiBold,
    fontSize: 12,
    textAlign: 'center',
    marginTop: 3,
  },
});

const enhance3 = withObservables(['modality'], ({ modality }) => ({
  modality: DataService.observeModality(modality),
}));

const enhance2 = withMapProps(({ trainer }) => ({
  modality: trainer?.expertise[0],
}));

const enhance = withObservables(['id'], ({ id }) => ({
  trainer: DataService.observeTrainer(id),
}));

export default enhance(enhance2(enhance3(TrainerCard)));
