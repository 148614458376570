import React, { useCallback, useState } from 'react';
import { StyleSheet, Text, View, TouchableOpacity } from 'react-native';
import NavigationService from '../libs/NavigationService';
import Theme from '../libs/Theme';
import { formatPrice } from '../libs/Utils';
import CheckIcon from '../icons/CheckIcon';
import ForwardArrowIcon from '../icons/ForwardArrowIcon';
import I18n from './I18n';
import TouchableWithData from './TouchableWithData';

export default function PlansSection({ product, lessonId, title }) {
  const [current, setCurrent] = useState(0);

  const plans = product?.plans.filter(p => p.active).slice(1);
  const selectedPlan = plans?.[current];

  const productId = product?.id;
  const planId = selectedPlan?.id;

  const onPressBuy = useCallback(() => {
    NavigationService.navigate('Checkout', { productId, planId, lessonId });
  }, [productId, planId, lessonId]);

  const onPressSeePlans = useCallback(() => {
    NavigationService.navigate('Plans', { id: lessonId });
  }, [lessonId]);

  const onPressPlan = useCallback(e => {
    setCurrent(e.data);
  }, []);

  if (!plans?.length || !selectedPlan) {
    return null;
  }

  return (
    <View style={styles.container}>
      <View style={styles.leftSide}>
        <Text style={styles.title}>
          <I18n>{title || 'plans'}</I18n>
        </Text>
        <View>
          {['planBenefit1', 'planBenefit2', 'planBenefit3'].map(text => (
            <View key={text} style={styles.benefit}>
              <CheckIcon width={10} height={10} color={Theme.color.magenta} />
              <Text style={styles.benefitText}>
                <I18n>{text}</I18n>
              </Text>
            </View>
          ))}
        </View>
        <TouchableOpacity style={styles.link} onPress={onPressSeePlans}>
          <Text style={styles.linkText}>
            <I18n>seePlans</I18n>
          </Text>
          <ForwardArrowIcon
            width={12}
            height={12}
            color={Theme.color.magenta}
          />
        </TouchableOpacity>
      </View>
      <View style={styles.rightSide}>
        <View style={styles.planList}>
          {plans.map((p, index) => (
            <TouchableWithData
              key={p.id}
              data={index}
              onPress={onPressPlan}
              style={[styles.plan, current === index && styles.selectedPlan]}
            >
              <Text
                style={[
                  styles.planText,
                  current === index && styles.selectedPlanText,
                ]}
              >
                {p.name}
              </Text>
            </TouchableWithData>
          ))}
        </View>
        <Text style={styles.price}>
          {formatPrice(
            selectedPlan.price / (selectedPlan.recurrence_months || 1),
          )}
          {selectedPlan.recurrence_months > 1 && (
            <Text style={styles.recurrence}>
              {' '}
              <I18n>perMonth</I18n>
            </Text>
          )}
        </Text>
        <TouchableOpacity style={styles.link} onPress={onPressBuy}>
          <Text style={styles.linkText}>
            <I18n>signPlan</I18n>
          </Text>
          <ForwardArrowIcon
            width={12}
            height={12}
            color={Theme.color.magenta}
          />
        </TouchableOpacity>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    borderColor: Theme.color.veryLightGray,
    borderBottomWidth: 1,
    paddingVertical: 20,
    height: 140,
  },
  leftSide: {
    borderColor: Theme.color.magenta,
    borderLeftWidth: 5,
    paddingLeft: 10,
    flexGrow: 1,
    flexShrink: 1,
    justifyContent: 'space-between',
  },
  title: {
    color: Theme.color.darkGray,
    fontFamily: Theme.fonts.barlowSemiCondensedBold,
    fontSize: 14,
    lineHeight: 14,
    textTransform: 'uppercase',
  },
  benefit: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  benefitText: {
    color: Theme.color.gray,
    fontFamily: Theme.fonts.barlowCondensed,
    fontSize: 14,
    marginLeft: 5,
  },
  rightSide: {
    width: 136,
    justifyContent: 'space-between',
  },
  planList: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: -5,
    marginBottom: 5,
  },
  plan: {
    paddingVertical: 5,
  },
  selectedPlan: {
    borderColor: Theme.color.magenta,
    borderBottomWidth: 2,
  },
  planText: {
    color: Theme.color.magenta,
    fontFamily: Theme.fonts.barlowCondensedBold,
    fontSize: 12,
    textTransform: 'uppercase',
  },
  selectedPlanText: {
    color: Theme.color.lightGray,
  },
  price: {
    color: Theme.color.darkGray,
    fontFamily: Theme.fonts.neuePlakCompLight,
    fontSize: 33,
    lineHeight: 33,
  },
  recurrence: {
    color: Theme.color.gray,
    fontFamily: Theme.fonts.barlowCondensed,
    fontSize: 16,
  },
  link: {
    flexDirection: 'row',
    alignSelf: 'flex-start',
  },
  linkText: {
    color: Theme.color.magenta,
    fontFamily: Theme.fonts.barlowBold,
    fontSize: 10,
    textTransform: 'uppercase',
    marginRight: 3,
  },
});
