import React, { useCallback, useEffect, useState } from 'react';
import {
  ScrollView,
  StyleSheet,
  Text,
  TouchableWithoutFeedback,
  View,
} from 'react-native';
import moment from 'moment-timezone';
import { groupBy } from 'lodash';
import DataService, { withObservables } from '../libs/DataService';
import Theme from '../libs/Theme';
import useSync from '../hooks/useSync';
import Loading from './Loading';
import Empty from './Empty';
import LessonEntry from './LessonEntry';

function LessonsByDate_Day({ day, selected, onPress }) {
  const _onPress = useCallback(() => {
    onPress(day);
  }, [day, onPress]);

  const momentDay = moment(day);
  return (
    <TouchableWithoutFeedback onPress={_onPress}>
      <View style={styles.day}>
        <View style={selected && styles.selected}>
          <Text style={[styles.dayText, selected && styles.selectedText]}>
            {momentDay.format('ddd')}
            {'\n'}
            {momentDay.format('DD')}
          </Text>
        </View>
      </View>
    </TouchableWithoutFeedback>
  );
}

function LessonsByDate({ lessons }) {
  const lessonsByDay = groupBy(lessons, l =>
    moment(l.start_datetime).format('YYYY-MM-DD'),
  );

  const days = Object.keys(lessonsByDay).sort().slice(0, 14);

  const [currentDay, setCurrentDay] = useState(days[0]);

  useEffect(() => {
    // muda para o primeiro dia caso atualize os dias e o dia selecionado deixe de existir no calendário disponível
    if (!days.includes(currentDay)) {
      setCurrentDay(days[0]);
    }
  }, [days, currentDay]);

  const onPressDay = useCallback(day => {
    setCurrentDay(day);
  }, []);

  return (
    <View>
      <ScrollView
        horizontal
        bounces={false}
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={styles.contentContainer}
        style={styles.scrollViewDays}
      >
        {days.map(day => (
          <LessonsByDate_Day
            key={day}
            day={day}
            selected={day === currentDay}
            onPress={onPressDay}
          />
        ))}
      </ScrollView>
      <View style={styles.scrollViewLessons}>
        <View style={styles.lessons}>
          {lessonsByDay[currentDay]?.map(lesson => (
            <View key={lesson.id} style={styles.lesson}>
              <LessonEntry lesson={lesson} withVacancies />
            </View>
          ))}
        </View>
      </View>
    </View>
  );
}

function LessonsByDate_Sync(props) {
  const { lessons, city, event, trainer } = props;

  const { syncing, error } = useSync(
    useCallback(async () => {
      await DataService.syncLessons({ city, event, trainer });
    }, [city, event, trainer]),
  );

  if (!lessons?.length) {
    return syncing ? (
      <Loading />
    ) : (
      <Empty text={error || 'emptyActivitiesForNextDays'} />
    );
  }

  return <LessonsByDate {...props} />;
}

const styles = StyleSheet.create({
  scrollViewDays: {
    borderBottomColor: Theme.color.veryLightGray,
    borderBottomWidth: 1,
  },
  contentContainer: {
    paddingLeft: 5,
    paddingRight: 20,
  },
  day: {
    flexGrow: 1,
    paddingLeft: 15,
    width: 55,
  },
  dayText: {
    color: Theme.color.gray,
    fontFamily: Theme.fonts.barlowCondensedSemiBold,
    fontSize: 14,
    textTransform: 'uppercase',
    paddingBottom: 20,
  },
  selected: {
    borderBottomColor: Theme.color.magenta,
    borderBottomWidth: 2,
  },
  selectedText: {
    color: Theme.color.magenta,
  },
  scrollViewLessons: {
    marginTop: -1,
  },
  lessons: {
    paddingHorizontal: 20,
  },
  lesson: {
    height: 80,
  },
});

const enhance = withObservables(
  ['city', 'event ', 'trainer'],
  ({ city, event, trainer }) => ({
    lessons: DataService.observeLessons({ city, event, trainer }),
  }),
);

export default enhance(LessonsByDate_Sync);
