import React, { useCallback } from 'react';
import {
  Platform,
  StyleSheet,
  Text,
  TouchableWithoutFeedback,
  View,
} from 'react-native';
import NavigationService from '../libs/NavigationService';
import Theme from '../libs/Theme';
import I18n from './I18n';
import ActionButton from './ActionButton';
// import AppleButton from './AppleButton';
// import GoogleButton from './GoogleButton';
import FacebookButton from './FacebookButton';

const AppleButton = () => null;
const GoogleButton = () => null;

export default function LoginButtons({
  onSuccess,
  onPressLogin,
  onPressCreateAccount,
}) {
  const isApple = Platform.OS === 'ios';

  const _onPressLogin = useCallback(() => {
    NavigationService.navigate('Login');
  }, []);

  const _onPressCreateAccount = useCallback(() => {
    NavigationService.navigate('CreateAccount');
  }, []);

  return (
    <View style={styles.container}>
      <View style={styles.row}>
        {isApple ? (
          <AppleButton onSuccess={onSuccess} />
        ) : (
          <GoogleButton onSuccess={onSuccess} />
        )}
      </View>
      <View style={styles.row}>
        <FacebookButton onSuccess={onSuccess} />
        {isApple ? (
          <GoogleButton onSuccess={onSuccess} />
        ) : (
          <ActionButton
            title="createAccount"
            theme="whiteWithBorder"
            onPress={onPressCreateAccount || _onPressCreateAccount}
            style={styles.button}
          />
        )}
      </View>
      <View style={styles.row}>
        {isApple ? (
          <>
            <TouchableWithoutFeedback
              onPress={onPressCreateAccount || _onPressCreateAccount}
            >
              <View style={styles.link}>
                <Text style={styles.linkText}>
                  <I18n>createAccount</I18n>
                </Text>
              </View>
            </TouchableWithoutFeedback>
            <Text style={styles.footerText}>
              <I18n>or</I18n>
            </Text>
          </>
        ) : (
          <Text style={styles.footerText}>
            <I18n>alreadyRegistered</I18n>
          </Text>
        )}
        <TouchableWithoutFeedback onPress={onPressLogin || _onPressLogin}>
          <View style={styles.link}>
            <Text style={styles.linkText}>
              <I18n>logIn</I18n>
            </Text>
          </View>
        </TouchableWithoutFeedback>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    padding: 14,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  button: {
    margin: 5,
    flexShrink: 1,
    flexGrow: 1,
  },
  footerText: {
    color: Theme.color.darkGray,
    fontFamily: Theme.fonts.barlow,
    fontSize: 12,
  },
  link: {
    paddingVertical: 16,
    paddingHorizontal: 5,
  },
  linkText: {
    color: Theme.color.magenta,
    fontFamily: Theme.fonts.barlowBold,
    fontSize: 12,
    textTransform: 'uppercase',
    letterSpacing: 0.5,
  },
});
