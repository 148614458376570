import React from 'react';
import { TouchableOpacity, StyleSheet } from 'react-native';
import Theme from '../libs/Theme';
import NavigationService from '../libs/NavigationService';
import CloseIcon from '../icons/CloseIcon';

export default class CloseButton extends React.PureComponent {
  onPress = () => {
    if (this.props.onPress) {
      this.props.onPress();
    } else {
      NavigationService.goBack();
    }
  };

  render() {
    return (
      <TouchableOpacity
        style={[styles.container, this.props.style]}
        onPress={this.onPress}
      >
        <CloseIcon width={22} height={22} color={this.props.color} />
      </TouchableOpacity>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    height: Theme.dimensions.headerHeight,
    width: Theme.dimensions.headerHeight,
    alignItems: 'center',
    justifyContent: 'center',
  },
});
