import React from 'react';
import {
  Modal,
  Platform,
  StyleSheet,
  Text,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
} from 'react-native';
import DateTimePicker from '@react-native-community/datetimepicker';
import { defaultStyles } from 'react-native-picker-select';
import moment from 'moment-timezone';
import Theme from '../libs/Theme';
import TextInput from './TextInput';
import I18n from './I18n';

export default class DateInput extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      pickerDate: this.getPickerDate(),
      modalVisible: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.setState({
        pickerDate: this.getPickerDate(),
      });
    }
  }

  getPickerDate = () => {
    const momentValue = moment(this.props.value, 'YYYY-MM-DD');
    return momentValue.isValid() ? momentValue.toDate() : new Date();
  };

  onPressInput = () => {
    this.setState({ modalVisible: true, pickerDate: this.getPickerDate() });
  };

  onPressCancel = () => {
    this.setState({ modalVisible: false });
  };

  onPressConfirm = () => {
    this.setState({ modalVisible: false });
    this.onChange();
  };

  onChangeIOS = (event, date) => {
    this.setState({ pickerDate: date });
  };

  onChangeAndroid = (event, date) => {
    if (date) {
      this.setState({ pickerDate: date, modalVisible: false });
      this.onChange(date);
    } else {
      this.setState({ modalVisible: false });
    }
  };

  onChange = date => {
    if (this.props.onChange) {
      const value = moment(date || this.state.pickerDate).format('YYYY-MM-DD');
      this.props.onChange(value, this.props.name);
    }
  };

  onChangeHtml = e => {
    console.log('onChangeHtml', e.target.value);
    this.onChange(e.target.value);
  };

  renderModal = () => {
    if (!this.state.modalVisible) {
      return null;
    }

    if (Platform.OS === 'android') {
      return (
        <DateTimePicker
          mode="date"
          display="spinner"
          value={this.state.pickerDate}
          onChange={this.onChangeAndroid}
        />
      );
    }

    return (
      <Modal
        transparent={true}
        animationType="slide"
        visible
        onRequestClose={this.hideModal}
      >
        <TouchableOpacity
          style={styles.modalViewTop}
          onPress={this.onPressCancel}
        />
        <View style={styles.modalViewMiddle}>
          <TouchableWithoutFeedback
            onPress={this.onPressCancel}
            hitSlop={{ top: 4, right: 4, bottom: 4, left: 4 }}
          >
            <Text style={styles.cancelText}>
              <I18n>inputCancel</I18n>
            </Text>
          </TouchableWithoutFeedback>
          <TouchableWithoutFeedback
            onPress={this.onPressConfirm}
            hitSlop={{ top: 4, right: 4, bottom: 4, left: 4 }}
          >
            <Text style={styles.confirmText}>
              <I18n>inputDone</I18n>
            </Text>
          </TouchableWithoutFeedback>
        </View>
        <View style={styles.modalViewBottom}>
          <DateTimePicker
            mode="date"
            value={this.state.pickerDate}
            onChange={this.onChangeIOS}
          />
        </View>
      </Modal>
    );
  };

  render() {
    const format =
      moment.locale() === 'en' ? 'MMM DD, YYYY' : 'DD [de] MMMM [de] YYYY';
    const momentValue = moment(this.props.value, 'YYYY-MM-DD');
    const value = momentValue.isValid() ? momentValue.format(format) : '';

    // onChange não pode vazar para o TextInput para não disparar o evento duas vezes e sem formatar os dados
    const inputProps = { ...this.props, onChange: undefined };

    const isNative = Platform.select({ native: true });

    if (!isNative) {
      // TODO [web migration] formatar o visual corretamente e deixar melhor integrado
      return (
        <View>
          <View pointerEvents="none">
            <TextInput {...this.props} value="" />
          </View>
          <input
            {...this.props}
            type="date"
            value={this.props.value || ''}
            onChange={this.onChangeHtml}
            style={{
              position: 'absolute',
              top: 11,
              left: 0,
              right: 0,
              bottom: 0,
              border: 0,
              background: 'none',
              width: '100%',
            }}
          />
        </View>
      );
    }

    return (
      <>
        <TouchableWithoutFeedback onPress={this.onPressInput}>
          <View pointerEvents="box-only">
            <TextInput {...inputProps} value={value} />
          </View>
        </TouchableWithoutFeedback>
        {this.renderModal()}
      </>
    );
  }
}

const styles = StyleSheet.create({
  modalViewTop: {
    ...defaultStyles.modalViewTop,
  },
  modalViewMiddle: {
    ...defaultStyles.modalViewMiddle,
  },
  cancelText: {
    color: Theme.color.gray,
  },
  confirmText: {
    ...defaultStyles.done,
    textTransform: 'uppercase',
  },
  modalViewBottom: {
    ...defaultStyles.modalViewBottom,
    height: 215,
  },
});
