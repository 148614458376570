import React from 'react';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import Theme, { opacity } from '../libs/Theme';
import LoadingIcon from '../icons/LoadingIcon';
import SuccessIcon from '../icons/SuccessIcon';
import I18n from './I18n';

const TEXT = 'text';
const LOADING = 'loading';
const FEEDBACK = 'feedback';

export default class ActionButton extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      status: TEXT,
    };
  }

  componentWillUnmount() {
    this.unmounted = true;
    clearTimeout(this.timeoutId);
  }

  onPress = async e => {
    if (!this.props.onPress) {
      return;
    }

    this.changeStatus(LOADING);

    try {
      const result = await this.props.onPress(e);
      if (this.props.useFeedbackIcon && result !== false) {
        this.changeStatus(FEEDBACK);
        this.timeoutId = setTimeout(() => this.changeStatus(TEXT), 2000);
      } else {
        this.changeStatus(TEXT);
      }
    } catch (error) {
      this.changeStatus(TEXT);
      throw error;
    }
  };

  changeStatus = status => {
    if (!this.unmounted) {
      this.setState({ status });
    }
  };

  render() {
    const {
      title,
      titleI18nParams,
      icon,
      type,
      size,
      theme,
      color,
    } = this.props;
    const { status } = this.state;

    const themeStyle = customThemes[theme] || {};
    const sizeStyle = customSizes[size] || {};
    const typeStyle = customTypes[type] || {};

    const IconComponent = icon;

    let _color;
    if (color) {
      _color = color;
    } else if (theme === 'white' || theme === 'whiteWithBorder') {
      _color = Theme.color.magenta;
    } else {
      _color = Theme.color.white;
    }

    return (
      <TouchableOpacity
        {...this.props}
        onPress={this.onPress}
        disabled={status !== TEXT}
      >
        <View
          style={[styles.box, themeStyle.box, sizeStyle.box, typeStyle.box]}
        >
          {IconComponent && (
            <View style={[styles.icon, status !== TEXT && styles.transparent]}>
              <IconComponent style={styles.icon} color={_color} height={16} />
            </View>
          )}
          <Text
            style={[
              styles.text,
              themeStyle.text,
              sizeStyle.text,
              typeStyle.text,
              { color: _color },
              status !== TEXT && styles.transparent,
            ]}
          >
            <I18n params={titleI18nParams}>{title}</I18n>
          </Text>
          {status === LOADING && (
            <LoadingIcon style={styles.loading} color={_color} />
          )}
          {status === FEEDBACK && (
            <SuccessIcon style={styles.saved} color={_color} />
          )}
        </View>
      </TouchableOpacity>
    );
  }
}

const styles = StyleSheet.create({
  box: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 6,
    paddingHorizontal: 23,
    backgroundColor: opacity('veryDarkGray', 0.2),
  },
  icon: {
    marginTop: -1,
    marginRight: 3,
  },
  text: {
    fontFamily: Theme.fonts.barlowBold,
    fontSize: 12,
    lineHeight: 12,
    textTransform: 'uppercase',
    textAlign: 'center',
    letterSpacing: 0.5,
    paddingVertical: 16,
  },
  transparent: {
    opacity: 0,
  },
  loading: {
    position: 'absolute',
  },
  saved: {
    position: 'absolute',
  },
});

const customThemes = {
  magenta: StyleSheet.create({
    box: {
      backgroundColor: Theme.color.magenta,
    },
  }),
  darkMagenta: StyleSheet.create({
    box: {
      backgroundColor: Theme.color.darkMagenta,
    },
  }),
  darkGray: StyleSheet.create({
    box: {
      backgroundColor: Theme.color.darkGray,
    },
  }),
  white: StyleSheet.create({
    box: {
      backgroundColor: Theme.color.white,
    },
  }),
  whiteWithBorder: StyleSheet.create({
    box: {
      backgroundColor: Theme.color.white,
      borderWidth: 2,
      borderColor: Theme.color.veryLightGray,
    },
    text: {
      paddingVertical: 14,
    },
  }),
  transparentWhite: StyleSheet.create({
    box: {
      backgroundColor: Theme.color.transparent,
    },
  }),
  transparentWhiteWithBorder: StyleSheet.create({
    box: {
      backgroundColor: opacity('black', 0.35),
      borderWidth: 2,
      borderColor: Theme.color.white,
    },
  }),
};

const customSizes = {
  xxSmall: StyleSheet.create({
    box: {
      paddingHorizontal: 6,
    },
    text: {
      paddingVertical: 6,
    },
  }),
  xSmall: StyleSheet.create({
    box: {
      paddingHorizontal: 16,
    },
    text: {
      paddingVertical: 10,
    },
  }),
  small: StyleSheet.create({
    box: {
      paddingHorizontal: 12,
    },
    text: {
      paddingVertical: 11,
    },
  }),
  tall: StyleSheet.create({
    box: {
      paddingHorizontal: 18,
    },
    text: {
      paddingVertical: 17,
    },
  }),
  tallWhiteWithBorder: StyleSheet.create({
    box: {
      paddingHorizontal: 18,
    },
    text: {
      paddingVertical: 16,
    },
  }),
  big: StyleSheet.create({
    box: {
      paddingHorizontal: 45,
    },
    text: {
      paddingVertical: 20,
    },
  }),
};

const customTypes = {
  lesson: StyleSheet.create({
    text: {
      fontFamily: Theme.fonts.barlowCondensedBold,
      fontSize: 16,
      lineHeight: 16,
      letterSpacing: 1,
    },
  }),
};
