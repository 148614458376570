import React from 'react';
import Svg, { Path } from 'react-native-svg';

export default function PlusIcon(props) {
  return (
    <Svg width={22} height={22} viewBox="0 0 22 22" {...props}>
      <Path fill={props.color} d="M12 2v8h8v2h-8v8h-2v-8H2v-2h8V2h2z" />
    </Svg>
  );
}
