export default {
  'Request is missing username parameter.':
    'É necessário preencher o endereço de e-mail',
  'Request is missing password parameter.':
    'É necessário preencher o campo senha',
  'Invalid credentials given.': 'Os campos usuário e senha não conferem',
  'Email not available':
    'Você já possui uma conta com esse e-mail. Faça login.',
  invalid_grant: 'Credenciais inválidas',
  invalid_request: 'Um ou mais campos não foram preenchidos corretamente',
  'invalid token': 'Token inválido ou expirado.',

  confirmPasswordRequired: 'O campo "Confirmar senha" precisa ser preenchido',
  differentPasswords: 'As senhas informadas não conferem',
  emailInvalid: 'Por favor, insira um e-mail válido',
  emailRequired: 'É necessário preencher um e-mail',
  fieldRequired: 'Este campo deve ser preenchido',
  invalidCpf: 'CPF inválido',
  'cpf already exists': 'CPF já utilizado por outra conta',
  invalidPassword: 'Senha incorreta',
  nicknameRequired: 'Por favor, digite um apelido válido',
  phoneInvalid: 'Por favor, digite um telefone válido',
  phoneRequired: 'É necessário preencher um telefone',
  uncheckedPolicy:
    'É necessário aceitar nossos termos de uso e política de privacidade para completar o cadastro',
  weakPassword:
    'A senha deve conter no mínimo 8 caracteres, com pelo menos uma letra e um número',

  emergencyContactError: 'Erro ao cadastrar contato de emergência',

  'Network Error': 'Ops. Parece que está offline.',
  networkErrorTitle: 'Ops. Ocorreu um erro de rede',
  networkErrorContent: `Pode ser que você esteja sem acesso a internet ou nossos servidores estejam sobrecarregados.

Por favor tente novamente ou entre em contato com nossa equipe.`,

  invalid_payment_hash:
    'Meio de pagamento inválido. Por favor cadastre um novo.',
  invalidCard: 'Cartão inválido',
  invalidDate: 'Data inválida',
  invalidCode: 'Código inválido',
  invalidName: 'Nome inválido',
  requiredField: 'Campo obrigatório',

  minimumPrice: 'Por razões de taxas, o valor mínimo é R$ 2,00.',
};
