import React from 'react';
import Svg, { Path } from 'react-native-svg';

export default function NotificationIcon(props) {
  return (
    <Svg width={22} height={22} viewBox="0 0 22 22" {...props}>
      <Path
        fill={props.color}
        d="M10 2H8V0h6v2h-2v1c3.9 0 7 3.1 7 7v7h2v2h-6.2c-.4 1.7-2 3-3.9 3S7.5 20.7 7 19H1v-2h2v-7c0-3.9 3.1-7 7-7V2zm2.7 17H9.2c.3.6 1 1 1.7 1s1.5-.4 1.8-1zM12 5h-2c-2.8 0-5 2.2-5 5v7h12v-7c0-2.8-2.2-5-5-5z"
      />
    </Svg>
  );
}
