import React from 'react';
import Svg, { Path } from 'react-native-svg';

export default function MenuIcon(props) {
  return (
    <Svg width={22} height={22} viewBox="0 0 22 22" {...props}>
      <Path
        fill={props.color}
        d="M13.2 11.2c0 1.2-1 2.2-2.2 2.2s-2.2-1-2.2-2.2S9.8 9 11 9s2.2 1 2.2 2.2zm0 8.5c0 1.2-1 2.2-2.2 2.2s-2.2-1-2.2-2.2c0-1.2 1-2.2 2.2-2.2s2.2.9 2.2 2.2zm0-17.4c0 1.2-1 2.2-2.2 2.2s-2.2-1-2.2-2.2S9.8.1 11 .1s2.2 1 2.2 2.2z"
      />
    </Svg>
  );
}
