import React from 'react';
import { Platform } from 'react-native';
import Svg, { Path } from 'react-native-svg';

export default function ShareIcon(props) {
  return (
    <Svg width={22} height={22} viewBox="0 0 14 16" {...props}>
      {Platform.OS === 'ios' ? (
        <Path
          fill={props.color}
          d="M4.2 6.5V8H1.9v6.2h10.3V7.9H10V6.5h1.6c1.1 0 2 .9 2 2v5.1c0 1.1-.9 2-2 2H2.5c-1.1 0-2-.9-2-2V8.5c0-1.1.9-2 2-2h1.7zM7.1.4l3.7 3.7-1 1-2-2v8.1H6.3V3.1l-1.9 2-1-1L7.1.4z"
        />
      ) : (
        <Path
          fill={props.color}
          d="M11.5 11.2c-.6 0-1.1.2-1.5.6L4.6 8.6c.1-.1.1-.4.1-.5 0-.1 0-.4-.1-.5L10 4.5c.4.4.9.6 1.5.6 1.3 0 2.2-1 2.2-2.2S12.8.5 11.5.5s-2.2 1-2.2 2.2c0 .1 0 .4.1.5L4 6.4c-.4-.4-.9-.6-1.5-.6C1.2 5.8.3 6.8.3 8s1 2.3 2.2 2.3c.6 0 1.1-.2 1.5-.6l5.4 3.2c-.1.1-.1.3-.1.4 0 1.2 1 2.2 2.2 2.2s2.2-1 2.2-2.2-.9-2.1-2.2-2.1z"
        />
      )}
    </Svg>
  );
}
