import React from 'react';
import Svg, { Path } from 'react-native-svg';
import Theme from '../libs/Theme';

export default class StarIcon extends React.PureComponent {
  static defaultProps = {
    color: Theme.color.magenta,
  };

  render() {
    return (
      <Svg width={22} height={22} viewBox="0 0 22 22" {...this.props}>
        <Path
          stroke={this.props.color}
          fill={this.props.filled ? this.props.color : Theme.color.transparent}
          strokeWidth="2"
          d="M11.4 1.9c-.1-.2-.4-.3-.6-.2-.1 0-.2.1-.2.2L7.8 7.5l-6.2.9c-.3.1-.5.3-.5.6 0 .1.1.2.1.3l4.5 4.4-1.1 6.2c0 .3.1.5.4.5h.3l5.6-2.9 5.6 2.9c.2.1.5 0 .6-.2 0-.1.1-.2 0-.3L16 13.7l4.5-4.4c.2-.2.2-.5 0-.7-.1-.1-.2-.1-.3-.1L14 7.6l-2.6-5.7z"
        />
      </Svg>
    );
  }
}
