import React, { useCallback } from 'react';
import { StyleSheet, Text, TouchableWithoutFeedback, View } from 'react-native';
import NavigationService from '../libs/NavigationService';
import Theme from '../libs/Theme';
import BackArrowIcon from '../icons/BackArrowIcon';
import ShareIcon from '../icons/ShareIcon';
import I18n from './I18n';

export default function Header({ title, style, onPressShare }) {
  const onPressBack = useCallback(() => {
    NavigationService.goBack();
  }, []);

  return (
    <View style={[styles.header, style]}>
      <View style={styles.side}>
        <TouchableWithoutFeedback onPress={onPressBack}>
          <View style={styles.button}>
            <BackArrowIcon ccolor={Theme.color.magenta} />
          </View>
        </TouchableWithoutFeedback>
      </View>
      <View style={styles.center}>
        {title && (
          <Text style={styles.title}>
            <I18n>{title}</I18n>
          </Text>
        )}
      </View>
      <View style={styles.side}>
        {onPressShare && (
          <TouchableWithoutFeedback onPress={onPressShare}>
            <View style={styles.button}>
              <ShareIcon color={Theme.color.magenta} />
            </View>
          </TouchableWithoutFeedback>
        )}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  header: {
    backgroundColor: Theme.color.white,
    paddingTop: Theme.dimensions.topSpace,
    flexDirection: 'row',
    alignItems: 'center',
  },
  center: {
    flexGrow: 1,
    flexShrink: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    color: Theme.color.veryDarkGray,
    fontFamily: Theme.fonts.barlowBold,
    fontSize: 16,
    textAlign: 'center',
  },
  side: {
    width: 50,
  },
  button: {
    height: Theme.dimensions.headerHeight,
    width: 50,
    alignItems: 'center',
    justifyContent: 'center',
  },
});
