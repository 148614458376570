import React from 'react';
import Api from '../libs/Api';
import AlertService from '../libs/AlertService';
import { validateEmail, resolveError } from '../libs/FormUtils';
import NavigationService from '../libs/NavigationService';
import ActionButton from './ActionButton';
import FormLayout from './FormLayout';
import TextInput from './TextInput';

export default class ForgotPasswordForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      error: '',
    };
  }

  onChangeInput = (value, name) => {
    this.setState({ [name]: value });
  };

  onSubmit = async () => {
    this.setState({ error: '' });

    if (!this.validate()) {
      return false;
    }

    try {
      await Api.recoverPassword(this.state.email);
      AlertService.show({
        title: 'done',
        message: 'forgotPasswordEmailSent',
        onConfirm: () => NavigationService.goBack(),
      });
    } catch (error) {
      this.setState({ error: resolveError(error, false, 'email') });
      return false;
    }
  };

  validate = () => {
    const email = this.state.email.trim();

    if (!email) {
      this.setState({ error: 'fieldRequired' });
      return false;
    }

    if (!validateEmail(email)) {
      this.setState({ error: 'emailInvalid' });
      return false;
    }

    return true;
  };

  render() {
    const fields = [
      <TextInput
        name="email"
        value={this.state.email}
        label="emailAddress"
        onChange={this.onChangeInput}
      />,
    ];

    const footer = (
      <ActionButton
        title="recoveryPassword"
        theme="magenta"
        onPress={this.onSubmit}
      />
    );

    return (
      <FormLayout
        header={this.props.header}
        content="weWillRecoverYourPassword"
        fields={fields}
        footer={footer}
        error={this.state.error}
      />
    );
  }
}
