import React from 'react';
import { View, StyleSheet } from 'react-native';
import Svg, { Path } from 'react-native-svg';
import Theme from '../libs/Theme';

export default class SettingsIcon extends React.PureComponent {
  static defaultProps = {
    color: Theme.color.magenta,
  };

  render() {
    return (
      <View style={this.props.withBorder && styles.withBorder}>
        <Svg
          width={this.props.withBorder ? 17 : 20}
          height={this.props.withBorder ? 17 : 20}
          viewBox="0 0 20 20"
          {...this.props}
        >
          <Path
            fill={this.props.color}
            d="M17.14 11c0-.3.1-.6.1-1s0-.7-.1-1l2.1-1.7c.2-.2.2-.4.1-.6l-2-3.5c-.1-.1-.3-.2-.6-.1l-2.5 1c-.5-.4-1.1-.7-1.7-1l-.4-2.6c.1-.3-.2-.5-.4-.5h-4c-.2 0-.5.2-.5.4l-.4 2.7c-.6.2-1.1.6-1.7 1l-2.4-1c-.3-.1-.5 0-.7.2l-2 3.5c-.1.1 0 .4.2.6L2.34 9c0 .3-.1.6-.1 1s0 .7.1 1l-2.1 1.7c-.2.2-.2.4-.1.6l2 3.5c.1.1.3.2.6.1l2.5-1c.5.4 1.1.7 1.7 1l.4 2.6c0 .2.2.4.5.4h4c.2 0 .5-.2.5-.4l.4-2.6c.6-.3 1.2-.6 1.7-1l2.5 1c.2.1.5 0 .6-.2l2-3.5c.1-.2.1-.5-.1-.6l-2.3-1.6zm-7.4 2.5c-1.9 0-3.5-1.6-3.5-3.5s1.6-3.5 3.5-3.5 3.5 1.6 3.5 3.5-1.6 3.5-3.5 3.5z"
          />
        </Svg>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  withBorder: {
    borderRadius: 34,
    backgroundColor: Theme.color.white,
    width: 34,
    height: 34,
    shadowColor: Theme.color.black,
    shadowOffset: { width: 0, height: 3 },
    shadowOpacity: 0.1,
    shadowRadius: 2,
    elevation: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
});
