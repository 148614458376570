import React, { useCallback } from 'react';
import { StyleSheet, Text, TouchableWithoutFeedback, View } from 'react-native';
import moment from 'moment-timezone';
import FastImage from 'react-native-fast-image';
import { formatDuration } from '../libs/DIYUtils';
import NavigationService from '../libs/NavigationService';
import { lessonStatus, isPaid, isDonation, isLive } from '../libs/Rules';
import Theme from '../libs/Theme';
import DateBox from './DateBox';
import TimeBox from './TimeBox';
import I18n from './I18n';
import DonationEntry from './DonationEntry';
import PlansSection from './PlansSection';
import ResizableImage from './ResizableImage';

export default function LessonMiniCard({
  status,
  lesson,
  lessonGroup,
  trainer,
  place,
  product,
  sponsor,
}) {
  const onPressDonation = useCallback(() => {
    NavigationService.navigate('LessonDonation', { id: lesson.id });
  }, [lesson]);

  const onPressTrainer = useCallback(() => {
    NavigationService.navigate('Trainer', { id: trainer.id });
  }, [trainer]);

  if (!lesson) {
    return null;
  }

  let statusColor;
  let statusText;
  let statusTextParams;

  switch (status) {
    case lessonStatus.CANCELED:
      statusColor = styles.blue;
      statusText = 'cancelled';
      break;

    case lessonStatus.LESSON_FULL:
    case lessonStatus.WARN_ME:
      statusColor = styles.magenta;
      statusText = 'lessonIsFull';
      break;

    case lessonStatus.CAN_SUBSCRIBE:
      statusColor = styles.yellow;
      statusText = lesson.available_vacancies < 10 ? 'lastVacancies' : '';
      statusTextParams = { count: lesson.available_vacancies };
      break;
  }

  const isTitleGray = [
    lessonStatus.CANCELED,
    lessonStatus.LESSON_MISSED,
    lessonStatus.ALREADY_HAPPENED,
  ].includes(status);

  const isTimeGray = [
    lessonStatus.CANCELED,
    lessonStatus.LESSON_MISSED,
    lessonStatus.EXCLUSIVE,
    lessonStatus.USER_LIMIT,
    lessonStatus.NOT_OPEN_YET,
    lessonStatus.ALREADY_HAPPENED,
    lessonStatus.LESSON_FULL,
    lessonStatus.WARN_ME,
  ].includes(status);

  const theme = isTimeGray ? 'gray' : 'magenta';

  const recurrenceDays = [
    { letter: 'sundayLetter', selected: lessonGroup?.recurrence_sunday },
    { letter: 'mondayLetter', selected: lessonGroup?.recurrence_monday },
    { letter: 'tuesdayLetter', selected: lessonGroup?.recurrence_tuesday },
    { letter: 'wednesdayLetter', selected: lessonGroup?.recurrence_wednesday },
    { letter: 'thursdayLetter', selected: lessonGroup?.recurrence_thursday },
    { letter: 'fridayLetter', selected: lessonGroup?.recurrence_friday },
    { letter: 'saturdayLetter', selected: lessonGroup?.recurrence_saturday },
  ];

  let uniqueClass;
  if (!lesson.lesson_group) {
    uniqueClass = true;
  } else if (lessonGroup) {
    uniqueClass = recurrenceDays.filter(day => day.selected).length === 0;
  }

  return (
    <View style={styles.container}>
      {!!statusText && (
        <Text style={[styles.status, statusColor]}>
          <I18n params={statusTextParams}>{statusText}</I18n>
        </Text>
      )}
      <View style={styles.instanceAndTrainer}>
        <View style={styles.instance}>
          <Text style={[styles.title, isTitleGray && styles.gray]}>
            {lesson.name}
          </Text>
          <Text style={styles.trainerName}>{trainer?.name}</Text>
          <View style={styles.dateTime}>
            <DateBox theme={theme} size="large" date={lesson.start_datetime} />
            <TimeBox
              theme={theme}
              size="large"
              date={lesson.start_datetime}
              style={styles.time}
            />
          </View>
          <Text style={styles.place}>{place?.name}</Text>
        </View>
        <TouchableWithoutFeedback onPress={onPressTrainer}>
          <FastImage
            style={styles.trainerPicture}
            source={{ uri: trainer?.picture || '' }}
          />
        </TouchableWithoutFeedback>
      </View>
      <View style={styles.vacanciesAndRecurrence}>
        <View>
          <Text style={styles.vacancies}>
            {isLive(lesson) ? (
              formatDuration(
                moment(lesson.end_datetime).diff(
                  moment(lesson.start_datetime),
                  'seconds',
                ),
              )
            ) : (
              <I18n params={{ count: lesson.total_vacancies }}>
                totalCapacity
              </I18n>
            )}
          </Text>
          <Text style={styles.label}>
            <I18n>{isLive(lesson) ? 'duration' : 'capacity'}</I18n>
          </Text>
        </View>
        {typeof uniqueClass !== 'undefined' && (
          <View style={styles.recurrence}>
            {uniqueClass ? (
              <Text style={styles.recurrenceText}>
                {moment(lesson.start_datetime).format('DD [de] MMMM [de] YYYY')}
              </Text>
            ) : (
              <View style={styles.recurrenceRow}>
                {recurrenceDays.map((item, index) => (
                  <View
                    key={index}
                    style={[
                      styles.recurrenceDay,
                      item.selected && styles.selectedDay,
                    ]}
                  >
                    <Text
                      style={[
                        styles.recurrenceDayText,
                        item.selected && styles.selectedDayText,
                      ]}
                    >
                      <I18n>{item.letter}</I18n>
                    </Text>
                  </View>
                ))}
              </View>
            )}
            <Text style={styles.label}>
              <I18n>{uniqueClass ? 'uniqueClass' : 'recurrence'}</I18n>
            </Text>
          </View>
        )}
      </View>
      {isPaid(lesson) ? (
        <PlansSection product={product} lessonId={lesson.id} />
      ) : isDonation(lesson) ? (
        <View style={styles.donation}>
          <DonationEntry
            text="classOfferedByDonations"
            onPress={onPressDonation}
          />
        </View>
      ) : sponsor ? (
        <View style={styles.sponsor}>
          <Text style={styles.sponsorText}>
            <I18n
              params={{ sponsor: sponsor.name }}
              i18nKey="classOfferedBySponsor"
            >
              <Text style={styles.sponsorName} />
            </I18n>
          </Text>
          <View
            style={[
              styles.logoContainer,
              { backgroundColor: sponsor.main_color },
            ]}
          >
            <ResizableImage
              style={styles.logo}
              source={{ uri: sponsor.transparency_logo || '' }}
              fitVertical
            />
          </View>
        </View>
      ) : null}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 20,
    marginTop: 30,
  },
  status: {
    color: Theme.color.veryDarkGray,
    fontFamily: Theme.fonts.barlowBold,
    fontSize: 12,
    lineHeight: 12,
    letterSpacing: 0.7,
    textTransform: 'uppercase',
    marginBottom: 15,
  },
  yellow: {
    color: Theme.color.yellow,
  },
  magenta: {
    color: Theme.color.magenta,
  },
  blue: {
    color: Theme.color.lightBlue,
  },
  gray: {
    color: Theme.color.gray,
  },
  instanceAndTrainer: {
    flexDirection: 'row',
    marginBottom: 20,
  },
  instance: {
    flexGrow: 1,
    flexShrink: 1,
    marginRight: 20,
  },
  title: {
    color: Theme.color.veryDarkGray,
    fontFamily: Theme.fonts.barlowBold,
    fontSize: 26,
    lineHeight: 26,
  },
  trainerName: {
    color: Theme.color.gray,
    fontFamily: Theme.fonts.barlowMedium,
    fontSize: 15,
    marginTop: 3,
  },
  dateTime: {
    flexDirection: 'row',
    marginTop: 20,
    alignItems: 'center',
  },
  time: {
    marginLeft: 10,
    position: 'relative',
    top: -7,
  },
  place: {
    color: Theme.color.gray,
    fontFamily: Theme.fonts.barlowSemiBold,
    fontSize: 14,
    marginTop: 5,
  },
  trainerPicture: {
    height: 203,
    width: 136,
    borderRadius: 136,
    backgroundColor: Theme.color.lowContrastGray_USE_WITH_WISDOM,
    overflow: 'hidden',
  },
  vacanciesAndRecurrence: {
    borderColor: Theme.color.veryLightGray,
    borderTopWidth: 1,
    borderBottomWidth: 1,
    height: 60,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  vacancies: {
    color: Theme.color.veryDarkGray,
    fontFamily: Theme.fonts.barlowSemiCondensed,
    fontSize: 14,
    lineHeight: 14,
  },
  label: {
    color: Theme.color.darkGray,
    fontFamily: Theme.fonts.barlowCondensed,
    fontSize: 12,
    textTransform: 'uppercase',
    marginTop: 4,
  },
  recurrence: {
    width: 136,
    marginLeft: 20,
  },
  recurrenceText: {
    color: Theme.color.veryDarkGray,
    fontFamily: Theme.fonts.barlowSemiCondensed,
    fontSize: 12,
    lineHeight: 12,
  },
  recurrenceRow: {
    flexDirection: 'row',
  },
  recurrenceDay: {
    borderColor: Theme.color.veryLightGray,
    borderBottomWidth: 1,
    flexGrow: 1,
    marginHorizontal: 1,
    paddingBottom: 3,
  },
  recurrenceDayText: {
    textAlign: 'center',
    color: Theme.color.gray,
    fontFamily: Theme.fonts.barlowCondensed,
    fontSize: 14,
    lineHeight: 14,
  },
  selectedDay: {
    borderColor: Theme.color.magenta,
  },
  selectedDayText: {
    color: Theme.color.magenta,
    fontFamily: Theme.fonts.barlowCondensedSemiBold,
  },
  donation: {
    paddingVertical: 20,
    borderBottomWidth: 1,
    borderColor: Theme.color.veryLightGray,
  },
  sponsor: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    paddingVertical: 20,
    borderBottomWidth: 1,
    borderColor: Theme.color.veryLightGray,
  },
  sponsorText: {
    color: Theme.color.darkGray,
    fontFamily: Theme.fonts.barlowSemiCondensed,
    fontSize: 14,
    flexGrow: 1,
    flexShrink: 1,
    marginRight: 15,
  },
  sponsorName: {
    fontFamily: Theme.fonts.barlowSemiCondensedSemiBold,
  },
  logoContainer: {
    width: 136,
    height: 40,
    borderRadius: 5,
    justifyContent: 'center',
    alignItems: 'center',
  },
  logo: {
    height: 35,
    width: 0,
  },
});
