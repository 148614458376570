import React from 'react';
import { Animated, View, Platform, StyleSheet } from 'react-native';
import Theme from '../libs/Theme';
import MinusIcon from '../icons/MinusIcon';
import PlusIcon from '../icons/PlusIcon';
import ShareIcon from '../icons/ShareIcon';
import LessonActionButton from './LessonActionButton';
import LessonActionHighlightButton from './LessonActionHighlightButton';

export default function LessonActionBar({
  theme,
  actionText,
  actionIcon,
  actionIconProps,
  price,
  rating,
  backStyle,
  onPressAction,
  onPressDetails,
  onPressBack,
  onPressShare,
}) {
  return (
    <View style={styles.container}>
      {Platform.select({
        native: (
          <>
            <View style={styles.narrow}>
              <LessonActionButton
                title="readMore"
                icon={PlusIcon}
                onPress={onPressDetails}
              />
              <Animated.View style={[StyleSheet.absoluteFill, backStyle]}>
                <LessonActionButton
                  title="back"
                  icon={MinusIcon}
                  onPress={onPressBack}
                  style={styles.back}
                />
              </Animated.View>
            </View>
            <View style={styles.narrow}>
              <LessonActionButton
                title="share"
                icon={ShareIcon}
                onPress={onPressShare}
              />
            </View>
          </>
        ),
      })}
      <View
        style={Platform.select({
          native: styles.wide,
          web: styles.full,
        })}
      >
        <LessonActionHighlightButton
          theme={theme}
          title={actionText}
          icon={actionIcon}
          iconProps={actionIconProps}
          price={price}
          rating={rating}
          onPress={onPressAction}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: Theme.color.white,
    flexDirection: 'row',
  },
  narrow: {
    width: '25%',
  },
  wide: {
    width: '50%',
  },
  full: {
    width: '100%',
  },
  back: {
    backgroundColor: Theme.color.lowContrastGray_USE_WITH_WISDOM,
  },
});
