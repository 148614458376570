import { Model } from '@nozbe/watermelondb';
import { field } from '@nozbe/watermelondb/decorators';

export default class CustomerSettings extends Model {
  static table = 'customer_settings';

  @field('timezone') timezone!: string;
  @field('main_payment_method') main_payment_method!: string;
  @field('main_credit_card') main_credit_card!: string;
  @field('new_highlight_lesson_push') new_highlight_lesson_push!: boolean;
  @field('new_highlight_lesson_email') new_highlight_lesson_email!: boolean;
  @field('new_event_push') new_event_push!: boolean;
  @field('new_event_email') new_event_email!: boolean;
  @field('canceled_lesson_push') canceled_lesson_push!: boolean;
  @field('canceled_lesson_email') canceled_lesson_email!: boolean;
  @field('checkin_available_push') checkin_available_push!: boolean;
  @field('checkin_available_email') checkin_available_email!: boolean;
  @field('review_available_push') review_available_push!: boolean;
  @field('review_available_email') review_available_email!: boolean;
  @field('open_vacancy_push') open_vacancy_push!: boolean;
  @field('open_vacancy_email') open_vacancy_email!: boolean;
  @field('payment_push') payment_push!: boolean;
  @field('custom_push') custom_push!: boolean;
}
