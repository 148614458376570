import React, { useCallback, useState } from 'react';
import {
  Platform,
  StyleSheet,
  Text,
  TouchableWithoutFeedback,
  View,
} from 'react-native';
import { groupBy, keyBy } from 'lodash';
import moment from 'moment-timezone';
import DataService, {
  withObservables,
  withMapProps,
} from '../libs/DataService';
import { resolveError } from '../libs/FormUtils';
import ModalService from '../libs/ModalService';
import NavigationService from '../libs/NavigationService';
import SentryService from '../libs/SentryService';
import Theme from '../libs/Theme';
import useSync from '../hooks/useSync';
import DiyIcon from '../icons/DiyIcon';
import LiveIcon from '../icons/LiveIcon';
import BookedIcon from '../icons/BookedIcon';
import MenuIcon from '../icons/MenuIcon';
import CouchMessage from './CouchMessage';
import ActivityEntry from './ActivityEntry';
import ActionButton from './ActionButton';

function JourneyTab_ItemOptions({ activity }) {
  const onPressSee = useCallback(() => {
    if (activity.type === 'lesson') {
      NavigationService.navigate('Lesson', { id: activity.id });
    } else if (activity.type === 'workout') {
      NavigationService.navigate('Workout', { id: activity.id });
    }
    ModalService.hide();
  }, [activity]);

  const onPressRemove = useCallback(async () => {
    try {
      if (activity.type === 'lesson') {
        await DataService.updateEnrollment({
          id: activity.enrollmentId,
          show_in_history: false,
        });
      } else if (activity.type === 'workout') {
        await DataService.updateWorkoutConsumption({
          id: activity.workoutConsumptionId,
          show_in_history: false,
        });
      }
    } catch (error) {
      SentryService.log(error);
      resolveError(error, true);
    }
    ModalService.hide();
  }, [activity]);

  const onPressCancel = useCallback(() => {
    ModalService.hide();
  }, []);

  return (
    <View style={styles.modal}>
      <View style={styles.section}>
        <ActionButton
          title="activityDetails"
          theme="magenta"
          onPress={onPressSee}
          style={styles.modalButton}
        />
        <ActionButton
          title="removeFromJourney"
          theme="whiteWithBorder"
          onPress={onPressRemove}
          style={styles.modalButton}
        />
        <ActionButton
          title="cancel"
          theme="whiteWithBorder"
          onPress={onPressCancel}
          style={styles.modalButton}
        />
      </View>
    </View>
  );
}

function JourneyTab_Item({ activity }) {
  const onPress = useCallback(() => {
    ModalService.show(<JourneyTab_ItemOptions activity={activity} />);
  }, [activity]);

  return (
    <View
      style={styles.activity}
      key={`${activity.type}_${activity.id}_${activity.date}`}
    >
      <View style={styles.timeline}>
        <View style={styles.icon}>
          {activity.type === 'lesson' ? (
            activity.is_virtual ? (
              <LiveIcon color={Theme.color.magenta} width={18} height={18} />
            ) : (
              <BookedIcon color={Theme.color.magenta} width={18} height={18} />
            )
          ) : (
            <DiyIcon color={Theme.color.magenta} width={18} height={18} />
          )}
        </View>
        <View style={styles.verticalLine} />
      </View>
      <View style={styles.entry}>
        <ActivityEntry item={activity} />
      </View>
      <TouchableWithoutFeedback onPress={onPress}>
        <View style={styles.menu}>
          <MenuIcon color={Theme.color.lightGray} />
        </View>
      </TouchableWithoutFeedback>
    </View>
  );
}

function JourneyTab({ lessons, enrollments, workoutConsumptions }) {
  const [visibleDays, setVisibleDays] = useState(5);

  const onPressEmptyCta = useCallback(() => {
    NavigationService.navigate('Lives');
  }, []);

  const onPressMoreItems = useCallback(() => {
    setVisibleDays(visibleDays + 20);
  }, [visibleDays]);

  const enrollmentsMap = keyBy(enrollments, 'lesson');
  const lessonActivities = lessons
    .filter(lesson => moment(lesson.end_datetime).diff(moment(), 'minutes') < 0)
    .map(lesson => ({
      type: 'lesson',
      is_virtual: lesson.is_virtual,
      id: lesson.id,
      date: lesson.start_datetime,
      enrollmentId: enrollmentsMap[lesson.id]?.id,
    }));

  const workoutActivities = workoutConsumptions
    .filter(workoutConsumption => workoutConsumption.end_time)
    .map(workoutConsumption => ({
      type: 'workout',
      id: workoutConsumption.workout,
      date: workoutConsumption.start_time,
      workoutConsumptionId: workoutConsumption.id,
    }));

  const isNative = Platform.select({ native: true });
  const activities = isNative
    ? [...lessonActivities, ...workoutActivities]
    : lessonActivities.filter(l => !l.is_virtual);

  if (!activities.length) {
    return (
      <CouchMessage
        title="emptyJourneyTitle"
        subtitle="emptyJourneySubtitle"
        cta="emptyJourneyCta"
        onPressCta={onPressEmptyCta}
      />
    );
  }

  const activitiesByDay = groupBy(activities, item =>
    moment(item.date).format('YYYY-MM-DD'),
  );

  const fullCalendar = Object.keys(activitiesByDay).sort().reverse();

  const calendar = fullCalendar.map(dayKey => {
    const momentDate = moment(dayKey);
    return {
      date: momentDate.format('DD [de] MMMM, YYYY'),
      weekday: momentDate.format('dddd'),
      activities: activitiesByDay[dayKey],
    };
  });

  return (
    <>
      {calendar.slice(0, visibleDays).map(day => (
        <React.Fragment key={day.date}>
          <View style={styles.day}>
            <Text style={styles.weekday}>{day.weekday}</Text>
            <Text style={styles.date}>{day.date}</Text>
          </View>
          {day.activities.map(activity => (
            <JourneyTab_Item
              key={`${activity.type}_${activity.id}_${activity.date}`}
              activity={activity}
            />
          ))}
          <View style={styles.endDayDots}>
            <View style={styles.dot} />
            <View style={styles.dot} />
            <View style={styles.dot} />
          </View>
        </React.Fragment>
      ))}
      {visibleDays < calendar.length && (
        <ActionButton
          theme="white"
          title="loadMore"
          onPress={onPressMoreItems}
          style={styles.loadMore}
        />
      )}
    </>
  );
}

function JourneyTab_Sync(props) {
  const { lessonIds, lessons } = props;

  const { syncing, error } = useSync(
    useCallback(async () => {
      await DataService.syncWorkoutConsumptions();
      await DataService.syncEnrollments();
      const existingLessons = lessons.map(l => l.id);
      const missingLessons = lessonIds.filter(
        id => !existingLessons.includes(id),
      );
      if (missingLessons.length) {
        await DataService.syncLessons({ ids: missingLessons.join(',') });
      }
    }, [lessonIds, lessons]),
  );

  return <JourneyTab {...props} loading={syncing} error={error} />;
}

const styles = StyleSheet.create({
  day: {
    margin: 20,
  },
  weekday: {
    color: Theme.color.darkGray,
    fontFamily: Theme.fonts.barlowCondensedSemiBold,
    fontSize: 14,
    textTransform: 'uppercase',
  },
  date: {
    color: Theme.color.gray,
    fontFamily: Theme.fonts.barlowCondensedSemiBold,
    fontSize: 14,
    textTransform: 'uppercase',
  },
  activity: {
    flexDirection: 'row',
    marginBottom: 10,
  },
  timeline: {
    marginHorizontal: 15,
    alignItems: 'center',
  },
  verticalLine: {
    backgroundColor: Theme.color.veryLightGray,
    width: 2,
    flexGrow: 1,
    flexShrink: 1,
  },
  icon: {
    padding: 5,
    alignItems: 'center',
    justifyContent: 'center',
  },
  entry: {
    flexGrow: 1,
    flexShrink: 1,
    marginTop: 15,
  },
  menu: {
    padding: 20,
    alignSelf: 'center',
    marginTop: 15,
  },
  endDayDots: {
    marginLeft: 27.5,
    marginTop: -7,
  },
  dot: {
    backgroundColor: Theme.color.veryLightGray,
    width: 3,
    height: 3,
    borderRadius: 3,
    marginTop: 3,
  },
  loadMore: {
    alignSelf: 'center',
    marginVertical: 20,
  },
  modal: {
    width: 320,
    marginTop: Theme.dimensions.topSpace + 20,
    marginBottom: Theme.dimensions.bottomSpace + 20,
    backgroundColor: Theme.color.white,
    borderRadius: 10,
    paddingHorizontal: 30,
    paddingVertical: 20,
  },
  modalButton: {
    marginVertical: 5,
  },
});

const enhance3 = withObservables(['lessonIds'], ({ lessonIds }) => ({
  lessons: DataService.observeLessonsByIds(lessonIds),
}));

const enhance2 = withMapProps(({ enrollments }) => ({
  lessonIds: enrollments.map(e => e.lesson),
}));

const enhance = withObservables([], () => ({
  enrollments: DataService.observeVisibleAndRegisteredEnrollments(),
  workoutConsumptions: DataService.observeVisibleWorkoutConsumptions(),
}));

export default enhance(enhance2(enhance3(JourneyTab_Sync)));
