import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { connect } from 'react-redux';
import isEqual from 'react-fast-compare';
import Theme from '../libs/Theme';
import Api from '../libs/Api';
import { validateEmail } from '../libs/FormUtils';
import FormLayout from './FormLayout';
import I18n from './I18n';
import TextInput from './TextInput';
import ActionButton from './ActionButton';

class EmergencyContactForm extends React.PureComponent {
  constructor(props) {
    super(props);

    const contact = this.props.contact || {};

    this.state = {
      name: contact.name || '',
      email: contact.email || '',
      phone_country_code: contact.phone_country_code || '',
      phone: contact.phone || '',
      relationship: contact.relationship || '',
      error: '',
      errors: {},
      showErrors: false,
    };
  }

  async componentDidMount() {
    try {
      await Api.getEmergencyContacts();
    } catch (error) {}

    // hack para só mostrar os erros depois do render terminar
    // como o componente foi criado num emaranhado de nós, dispara-se o evento onChange no fluxo
    // dos primeiros renders e faz uma primeira validação desnecessário, resultando no visual ruim
    // esse timer bloqueia isso temporariamente até arrumarmos o componente
    this.showErrorsTimeoutId = setTimeout(() => {
      this.setState({ showErrors: true });
    }, 1000);
  }

  componentWillUnmount() {
    clearTimeout(this.showErrorsTimeoutId);
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps, this.props)) {
      this.setState({ ...this.props.contact });
    }
  }

  setValidation(name, value) {
    const requiredFields = ['name', 'phone_country_code', 'phone'];

    let error = '';

    if (requiredFields.indexOf(name) > -1 && value.length === 0) {
      error = 'fieldRequired';
    }

    if (name === 'email' && value && !validateEmail(value)) {
      error = 'emailInvalid';
    }

    if (this.state.showErrors) {
      this.setState(previousState => ({
        error: error,
        errors: { ...previousState.errors, [name]: error },
      }));
    }

    return { error, name };
  }

  onChangeInput = (value, name) => {
    this.setState({ [name]: value });
    this.setValidation(name, value);
  };

  getContactData = () => ({
    name: this.state.name,
    email: this.state.email,
    phone: this.state.phone,
    phone_country_code: this.state.phone_country_code,
    relationship: this.state.relationship,
  });

  getFormStructure = () => [
    { label: 'name', name: 'name', required: true },
    { label: 'levelOfKinship', name: 'relationship' },
    {
      label: 'ddi',
      name: 'phone_country_code',
      type: 'tel',
      required: true,
    },
    {
      label: 'phone',
      name: 'phone',
      type: 'tel',
      mask: 'tel',
      required: true,
    },
    {
      label: 'email',
      name: 'email',
      type: 'email',
    },
  ];

  validateFields = contactInfo =>
    this.getFormStructure()
      .map((field, index, structure) =>
        this.setValidation(field.name, contactInfo[field.name], structure),
      )
      .filter(el => el.error);

  isValid(contact) {
    const errors = this.validateFields(contact);
    return Object.keys(errors).length === 0;
  }

  submitHandler = async () => {
    const contact = this.getContactData();

    if (!this.isValid(contact)) {
      return false;
    }

    try {
      const id = (this.props.contact || {}).id;
      if (id) {
        await Api.updateEmergencyContacts(id, contact);
      } else {
        await Api.createEmergencyContacts(contact);
      }
      this.setState({ error: '', errors: {} });
      if (this.props.onSuccess) {
        this.props.onSuccess();
      }
    } catch (error) {
      if (error.response && error.response.data) {
        this.setState({
          error: 'emergencyContactError',
          errors: {
            ...error.response.data,
            general: 'emergencyContactError',
          },
        });
      }
    }
  };

  getFields = () => {
    return [
      <TextInput
        name="name"
        value={this.state.name || ''}
        error={this.state.errors.name || ''}
        label="name"
        onChange={this.onChangeInput}
        required={true}
      />,
      <TextInput
        name="relationship"
        value={this.state.relationship || ''}
        label="levelOfKinship"
        onChange={this.onChangeInput}
        error={this.state.errors.relationship || ''}
      />,
      <View style={styles.row}>
        <View style={styles.countryCode}>
          <TextInput
            name="phone_country_code"
            value={this.state.phone_country_code || ''}
            label="ddi"
            required
            onChange={this.onChangeInput}
            error={this.state.errors.phone_country_code || ''}
          />
        </View>
        <View style={styles.phone}>
          <TextInput
            name="phone"
            value={this.state.phone || ''}
            label="phone"
            required
            onChange={this.onChangeInput}
            error={this.state.errors.phone || ''}
          />
        </View>
      </View>,
      <TextInput
        name="email"
        value={this.state.email || ''}
        label="email"
        onChange={this.onChangeInput}
        error={this.state.errors.email || ''}
      />,
    ];
  };

  getSubmitButton = () => (
    <ActionButton
      title={this.props.submitLabel || 'save'}
      theme="whiteWithBorder"
      onPress={this.submitHandler}
      useFeedbackIcon
    />
  );

  render() {
    const fields = this.getFields();
    const footer = this.getSubmitButton();
    const error = Object.values(this.state.errors).find(e => e);
    return (
      <View>
        <View style={styles.disclaimerArea}>
          <Text style={styles.disclaimerText}>
            <I18n>emergencyContactDisclaimer</I18n>
          </Text>
        </View>
        <FormLayout fields={fields} footer={footer} error={error} />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  disclaimerArea: {
    padding: 20,
  },
  disclaimerText: {
    lineHeight: 25,
    color: Theme.color.darkGray,
    fontFamily: Theme.fonts.barlow,
    fontSize: 16,
  },
  footer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  row: {
    flexDirection: 'row',
  },
  countryCode: {
    width: 50,
    marginRight: 10,
  },
  phone: {
    flexGrow: 1,
    flexShrink: 1,
  },
});

const mapStateToProps = ({ emergencyContacts }) => ({
  contact: Object.values(emergencyContacts)[0] || {},
});

export default connect(mapStateToProps)(EmergencyContactForm);
