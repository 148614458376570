export function isNetworkError(error) {
  const message = (error || {}).message;
  return (
    message === 'Network Error' || // axios
    message === 'The Internet connection appears to be offline.' // react-native-fs
  );
}

export function isTimeoutError(error) {
  return ((error || {}).message || '').toLowerCase().startsWith('timeout');
}

export function isRequestError(error) {
  return ((error || {}).message || '')
    .toLowerCase()
    .startsWith('request failed');
}

export function isResponseTimedOut(response) {
  return response === 'The request timed out.';
}

export function isResponseCanceled(response) {
  return (
    response === 'stream was reset: CANCEL' ||
    response === 'stream was reset: PROTOCOL_ERROR'
  );
}
