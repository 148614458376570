import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import Theme from '../libs/Theme';
import I18n from '../components/I18n';

export default class FormLayout extends React.PureComponent {
  renderHeader = () => {
    if (!this.props.header) {
      return null;
    }
    return (
      <Text style={styles.title}>
        <I18n>{this.props.header}</I18n>
      </Text>
    );
  };

  renderContent = () => {
    if (!this.props.content) {
      return null;
    }
    return (
      <Text style={styles.content}>
        <I18n>{this.props.content}</I18n>
      </Text>
    );
  };

  renderFields = () => {
    if (!this.props.fields) {
      return null;
    }
    return this.props.fields.map((field, index) =>
      field ? (
        <View
          key={index}
          style={[
            styles.row,
            index === this.props.fields.length - 1 ? styles.lastRow : null,
          ]}
        >
          {field}
        </View>
      ) : null,
    );
  };

  renderError = () => {
    if (!this.props.error) {
      return null;
    }
    return (
      <Text style={styles.error}>
        <I18n>{this.props.error}</I18n>
      </Text>
    );
  };

  renderFooter = () => {
    return <View style={styles.footer}>{this.props.footer}</View>;
  };

  render() {
    return (
      <View style={styles.container}>
        {this.renderHeader()}
        {this.renderContent()}
        {this.renderFields()}
        {this.renderError()}
        {this.renderFooter()}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 20,
    paddingBottom: 20,
  },
  title: {
    color: Theme.color.veryDarkGray,
    fontFamily: Theme.fonts.barlowBold,
    fontSize: 30,
    lineHeight: 30,
    letterSpacing: 0.4,
    maxWidth: '80%',
    marginBottom: 20,
  },
  content: {
    color: Theme.color.darkGray,
    fontFamily: Theme.fonts.barlow,
    fontSize: 15,
    lineHeight: 23,
    marginBottom: 25,
  },
  row: {
    marginBottom: 20,
    paddingTop: 10,
  },
  lastRow: {
    marginBottom: 20,
  },
  error: {
    color: Theme.color.magenta,
    fontFamily: Theme.fonts.barlow,
    fontSize: 13,
    lineHeight: 17,
    marginBottom: 30,
    paddingRight: 20,
  },
  footer: {
    alignSelf: 'flex-end',
  },
});
