import React, { useCallback } from 'react';
import { TouchableOpacity } from 'react-native';

export default function TouchableWithData(props) {
  const { onPress, data } = props;

  const _onPress = useCallback(
    e => {
      e.data = data;
      onPress?.(e);
    },
    [onPress, data],
  );

  return <TouchableOpacity {...props} onPress={_onPress} />;
}
