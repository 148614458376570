import React from 'react';
import Svg, { Path } from 'react-native-svg';
import Theme from '../libs/Theme';

export default class PersonIcon extends React.PureComponent {
  static defaultProps = {
    color: Theme.color.white,
  };

  render() {
    return (
      <Svg width={18} height={21} viewBox="0 0 18 21" {...this.props}>
        <Path
          fill={this.props.color}
          d="M16 0H2C.9 0 0 .9 0 2v14c0 1.1.9 2 2 2h4l3 3 3-3h4c1.1 0 2-.9 2-2V2c0-1.1-.9-2-2-2zM9 3.3c1.5 0 2.7 1.2 2.7 2.7S10.5 8.7 9 8.7 6.3 7.5 6.3 6 7.5 3.3 9 3.3zM15 14H3v-.9c0-2 4-3.1 6-3.1s6 1.1 6 3.1v.9z"
        />
      </Svg>
    );
  }
}
