import { __DEV__ } from './Env';

// TODO [web migration]
// import firebase from 'react-native-firebase';
const firebase = {
  analytics: () => ({
    setUserId: () => {},
    setUserProperty: () => {},
    setCurrentScreen: () => {},
    logEvent: () => {},
  }),
};

class AnalyticsService {
  constructor() {
    if (__DEV__) {
      // mock analytics para não enviar testes de desenvolvimento
      this.analytics = {
        setUserId: () => {},
        setUserProperty: () => {},
        setCurrentScreen: () => {},
        logEvent: () => {},
      };
    } else {
      this.analytics = firebase.analytics();
    }
  }

  setUser = (user = {}) => {
    if (user.id) {
      this.analytics.setUserId(String(user.id));
    }
    if (user.email) {
      this.analytics.setUserProperty('email', user.email);
    }
  };

  setCurrentScreen = (screen_name, screen_id) => {
    this.analytics.setCurrentScreen(screen_name);

    // Embora o Firebase Analytics tenha seu evento próprio ele
    // naturalmente é disparado com troca nativamente uma tela.
    // Como o React Native fica em uma só View, é mais seguro também
    // disparar um evento personalizado para garantir o tracking
    this.analytics.logEvent('screen_navigation', {
      screen_name,
      screen_id: String(screen_id),
    });
  };

  logEvent = (event, params) => {
    console.log('[Analytics]', event, params);
    this.analytics.logEvent(event, params);
  };
}

export default new AnalyticsService();
