import React from 'react';
import Svg, { Path } from 'react-native-svg';

export default function GroupClassIcon(props) {
  return (
    <Svg width={22} height={22} viewBox="0 0 22 22" {...props}>
      <Path
        fill={props.color}
        d="M11 8c1.8 0 3.5.9 4.5 2.4v.1c.6-.2 1.2-.4 1.8-.4 1.2 0 2.4.5 3.3 1.3.9.9 1.4 2 1.4 3.3 0 2.5-2.7 5.4-4.7 7.1-1.2-1-2.2-2.1-3.1-3.3-.2.2-.3.4-.5.6l-.1.1c-.8 1-1.6 1.8-2.6 2.6l-.4-.4c-1-.9-1.9-1.9-2.8-2.9-.9 1.2-1.9 2.3-3.1 3.3C2.8 20.1.1 17.3.1 14.7c0-1.2.5-2.4 1.4-3.3.9-.9 2.1-1.3 3.3-1.3.6 0 1.2.1 1.8.4v-.1C7.6 8.9 9.2 8 11 8zm0 1.8c-2 0-3.6 1.6-3.6 3.6 0 1.5 1.4 3.7 3.6 5.9 2.2-2.2 3.6-4.4 3.6-5.9 0-2-1.6-3.6-3.6-3.6zm7.9 2.6c-.8-.5-1.8-.6-2.6-.2.1.4.2.9.2 1.3s0 .8-.1 1.1c0 .2-.1.4-.2.6-.2.6-.5 1.2-.8 1.7l.4.5c.5.7 1 1.3 1.6 1.9 1.7-1.7 2.8-3.5 2.8-4.6-.1-.9-.6-1.8-1.3-2.3zm-14.2-.5c-1.5 0-2.8 1.3-2.8 2.8 0 1.1 1.1 2.9 2.8 4.6.6-.6 1.2-1.3 1.7-2.1l.3-.4c-.7-1-1-2.2-1.1-3.4 0-.4.1-.9.2-1.3-.4-.1-.7-.2-1.1-.2zM4 3c1.6 0 2.9 1.3 2.9 2.9 0 .8-.3 1.5-.9 2.1s-1.3.9-2 .9c-1.7 0-3-1.4-3-3S2.3 3 4 3zm13.8 0c1.6 0 2.9 1.3 2.9 2.9 0 .8-.3 1.5-.9 2.1-.6.5-1.3.9-2.1.9-1.6 0-2.9-1.3-2.9-2.9 0-1.7 1.3-3 3-3zM11 .2c1.9 0 3.5 1.6 3.5 3.5S12.9 7.2 11 7.2 7.5 5.6 7.5 3.7 9.1.2 11 .2zM4 4.8c-.6 0-1.1.5-1.1 1.1S3.3 7 4 7s1.1-.5 1.1-1.1S4.6 4.8 4 4.8zm13.7 0c-.6 0-1.1.5-1.1 1.1S17.1 7 17.7 7s1.1-.5 1.1-1.1c0-.3-.1-.6-.3-.8s-.5-.3-.8-.3zM11 2c-.9 0-1.7.7-1.7 1.7S10 5.4 11 5.4s1.7-.7 1.7-1.7S11.9 2 11 2z"
      />
    </Svg>
  );
}
