import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import Theme from '../libs/Theme';
import I18n from './I18n';
import ActionButton from './ActionButton';

export default function DonationEntry({ text, textI18nParams, onPress }) {
  return (
    <View style={styles.container}>
      <Text style={styles.text}>
        <I18n params={textI18nParams}>{text}</I18n>
      </Text>
      <ActionButton
        theme="whiteWithBorder"
        title="donateCta"
        onPress={onPress}
        style={styles.button}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  text: {
    color: Theme.color.darkGray,
    fontFamily: Theme.fonts.barlowSemiCondensed,
    fontSize: 14,
    flexGrow: 1,
    flexShrink: 1,
    marginRight: 15,
  },
  button: {
    width: 136,
  },
});
