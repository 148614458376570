// TODO [web migration]

class NotificationService {
  setup = async () => {};

  createChannels = () => {};

  getInitialURL = async () => {};

  destroy = () => {};

  requestPermissions = async () => {};

  setToken = token => {};

  saveUserToken = async () => {};

  scheduleNotification = async (data, fireDate) => {};

  cancelNotification = notificationId => {};

  // https://facebook.github.io/react-native/docs/headless-js-android
  onReceiveBackgroundMessage = async message => {};

  onReceiveMessage = async message => {};

  onReceiveNotification = async message => {};

  showNotification = async data => {};

  onNotificationOpened = async notificationOpen => {};

  createNotificationFromData = data => {};
}

export default new NotificationService();
