import StoreService from '../libs/StoreService';

const SET_CREDIT_CARDS = 'CREDIT_CARDS/SET_CREDIT_CARDS';
const ADD_CREDIT_CARD = 'CREDIT_CARDS/ADD_CREDIT_CARD';
const DELETE_CREDIT_CARD = 'CREDIT_CARDS/DELETE_CREDIT_CARD';
const DELETE_ALL_CREDIT_CARDS = 'CREDIT_CARDS/DELETE_ALL_CREDIT_CARDS';

export function setCreditCards(creditCards) {
  StoreService.dispatch({ type: SET_CREDIT_CARDS, creditCards });
}

export function addCreditCard(creditCard) {
  StoreService.dispatch({ type: ADD_CREDIT_CARD, creditCard });
}

export function deleteCreditCard(cardId) {
  StoreService.dispatch({ type: DELETE_CREDIT_CARD, cardId });
}

export function deleteAllCreditCards() {
  StoreService.dispatch({ type: DELETE_ALL_CREDIT_CARDS });
}

export default function reducer(state = [], action) {
  switch (action.type) {
    case SET_CREDIT_CARDS: {
      return [...action.creditCards];
    }

    case ADD_CREDIT_CARD: {
      return [...state, action.creditCard];
    }

    case DELETE_CREDIT_CARD: {
      return [...state.filter(c => c.id !== action.cardId)];
    }

    case DELETE_ALL_CREDIT_CARDS: {
      return [];
    }

    default:
      return state;
  }
}
