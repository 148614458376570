import React from 'react';
import { AppState } from 'react-native';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { setupStore } from './store';
import AnalyticsService from './libs/AnalyticsService';
import AuthService from './libs/AuthService';
import StorageService from './libs/StorageService';
import DataService from './libs/DataService';
import NetInfoService from './libs/NetInfoService';
import NotificationService from './libs/NotificationService';
import SentryService from './libs/SentryService';
import StoreService from './libs/StoreService';
import LanguageService from './languages/LanguageService';
import Routes from './components/Routes';
import SplashImage from './components/SplashImage';
import ModalLayer from './components/ModalLayer';

export default class App extends React.Component {
  constructor(props) {
    super(props);

    this.appState = AppState.currentState;

    this.state = {
      ready: false,
    };
  }

  async componentDidMount() {
    try {
      await AuthService.setup();
      await StorageService.setup();
      await LanguageService.setup();

      const { store, persistor } = setupStore();
      this.store = store;
      this.persistor = persistor;
      StoreService.setStore(this.store);
    } catch (error) {
      SentryService.log(error);
    }

    this.setState({ ready: true });
  }

  onBeforeLift = () => {
    const { settings, user } = StoreService.getState();

    LanguageService.setLanguage(settings.language);

    SentryService.setUser(user);
    AnalyticsService.setUser(user);

    NetInfoService.setup();
    NotificationService.setup();

    DataService.syncAllData(true);
    DataService.syncPolling();

    AppState.addEventListener('change', this.onAppStateChange);
  };

  componentWillUnmount() {
    AppState.removeEventListener('change', this.onAppStateChange);

    clearInterval(this.syncPollingId);

    NetInfoService.destroy();
    NotificationService.destroy();
  }

  onAppStateChange = nextAppState => {
    if (
      this.appState.match(/inactive|background/) &&
      nextAppState === 'active'
    ) {
      clearInterval(this.syncPollingId);
      this.syncPollingId = setInterval(DataService.syncPolling, 60 * 1000);
      DataService.syncAllData(true);
    } else {
      clearInterval(this.syncPollingId);
    }

    this.appState = nextAppState;
  };

  render() {
    if (!this.state.ready) {
      return null;
    }

    return (
      <Provider store={this.store}>
        <PersistGate
          persistor={this.persistor}
          loading={<SplashImage />}
          onBeforeLift={this.onBeforeLift}
        >
          <Routes />
          <ModalLayer />
        </PersistGate>
      </Provider>
    );
  }
}
