import { Model } from '@nozbe/watermelondb';
import { field } from '@nozbe/watermelondb/decorators';

export default class User extends Model {
  static table = 'users';

  @field('customer_id') customer_id!: string;
  @field('trainer_id') trainer_id!: string;
  @field('email') email!: string;
  @field('first_name') first_name!: string;
  @field('last_name') last_name!: string;
  @field('is_staff') is_staff!: boolean;
  @field('default_city') default_city!: string;
  @field('language') language!: string;
}
