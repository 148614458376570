import axios from 'axios';
import moment from 'moment-timezone';
import qs from 'qs';
import React from 'react';
import MudeLogoIcon from '../icons/MudeLogoIcon';
import { API_URL } from '../libs/Env';
import { resolveError as resolverErrorUtils } from '../libs/FormUtils';
import Theme from '../libs/Theme';
import styles from './KioskScreen.module.scss';

const CLIENT_ID = '6e2meC0BHufltzEJf2ojgciybDYYgJxirubrGsED';
const CLIENT_SECRET =
  'b6IuiBl8PI2tZUQyTxw7YGBWnWCmvkLBCqM9YIKpRjkMJxvR4b8lBvqFIx2x1zovNE27VnWwzvAZMqWBdTu7Km6JIbWpGGZgPQO4e9kq7fzmAml9RUHsZUckcXXJawtd';

const FLOW_CREATE_ACCOUNT = 'FLOW_CREATE_ACCOUNT';
const FLOW_LOGIN = 'FLOW_LOGIN';

function sliceName(name) {
  let first_name;
  let last_name;
  const spaceIndex = name.indexOf(' ');
  if (spaceIndex > -1) {
    first_name = name.substr(0, spaceIndex);
    last_name = name.substr(spaceIndex + 1);
  } else {
    first_name = name;
  }
  return { first_name, last_name };
}

export default class KioskScreen extends React.Component {
  constructor(props) {
    super(props);

    this.formRef = React.createRef();
    this.accessToken = '';

    this.state = {
      loading: true,
      error: '',
      lesson: {},
      flow: FLOW_CREATE_ACCOUNT,
      status: [],
    };
  }

  async componentDidMount() {
    try {
      const result = await axios.get(
        `${API_URL}/lesson/${this.props.route.params.id}/`,
      );
      this.setState({ lesson: result.data });
    } catch (error) {
      this.setState({ error: error.message });
    }

    this.setState({ loading: false });
  }

  onChangeFlow = e => {
    const flow = e.currentTarget.dataset.flow;
    this.setState({ flow });
  };

  onClickRestart = () => {
    window.location.reload();
  };

  onSubmit = async e => {
    e.preventDefault();

    this.accessToken = '';

    this.setState({ status: [] }, async () => {
      try {
        switch (this.state.flow) {
          case FLOW_CREATE_ACCOUNT:
            await this.createAccount();
            break;
          case FLOW_LOGIN:
            await this.login();
            break;
        }
      } catch (error) {
        this.resolveError(error);
      }
    });
  };

  resolveError = async error => {
    const errorMessage =
      error.response?.data?.errors ?? error.response?.data?.detail;

    switch (errorMessage) {
      // Email não disponível, tenta fazer login com o email e senha antes de prosseguir
      case 'Email not available':
        this.addStatus(
          '⚠️ Email não disponível. Tentando entrar com as mesmas credenciais.',
        );
        try {
          await this.login();
        } catch (error) {
          this.resolveError(error);
        }
        break;

      // Dados pessoais faltando, tenta atualizar os dados antes de prosseguir
      case 'cpf or passport not provided':
      case 'age limit':
        this.addStatus('⚠️ Dados pessoais incompletos.');
        try {
          await this.updatePersonalData();
        } catch (error) {
          this.resolveError(error);
        }
        break;

      // Erro ao fazer o checkin na aula, tenta fazer a inscrição para depois fazer o checkin
      case 'Sua matrícula é invalida':
        this.addStatus('⚠️ Inscrição ainda não realizada.');
        try {
          await this.subscribe();
        } catch (error) {
          this.resolveError(error);
        }
        break;

      default:
        this.addStatus(`❌ ${resolverErrorUtils(error, false, '', false)}`);
        break;
    }
  };

  addStatus = status => {
    this.setState(
      { status: [...this.state.status, status] },
      this.scrollToBottom,
    );
  };

  scrollToBottom = () => {
    window.scrollTo({ top: Number.MAX_SAFE_INTEGER, behavior: 'smooth' });
  };

  createAccount = async () => {
    const formData = new FormData(this.formRef.current);
    const { first_name } = sliceName(formData.get('name'));

    this.addStatus('⌛ Criando conta...');
    const result = await axios({
      method: 'post',
      url: `${API_URL}/signup/`,
      data: {
        nickname: first_name,
        email: formData.get('email'),
        phone: formData.get('phone'),
        phone_country_code: '+55',
        password: formData.get('password'),
      },
    });
    this.addStatus('✅ Conta criada.');

    this.accessToken = result.data.access_token;

    await this.updatePersonalData();
  };

  updatePersonalData = async () => {
    const formData = new FormData(this.formRef.current);
    const { first_name, last_name } = sliceName(formData.get('name'));

    this.addStatus('⌛ Atualizando dados pessoais...');
    await axios({
      method: 'patch',
      url: `${API_URL}/customer/me/`,
      data: {
        first_name,
        last_name,
        cpf: formData.get('cpf'),
        birth_date: moment(formData.get('birth_date'), 'DD/MM/YYYY').format(
          'YYYY-MM-DD',
        ),
      },
      headers: {
        Authorization: `Bearer ${this.accessToken}`,
      },
    });
    this.addStatus('✅ Dados pessoais atualizados.');

    await this.subscribe();
  };

  login = async () => {
    const formData = new FormData(this.formRef.current);

    this.addStatus('⌛ Verificando email e senha...');
    const result = await axios({
      method: 'post',
      url: `${API_URL}/token/`,
      data: qs.stringify({
        grant_type: 'password',
        username: formData.get('email'),
        password: formData.get('password'),
      }),
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      auth: {
        username: CLIENT_ID,
        password: CLIENT_SECRET,
      },
    });
    this.addStatus('✅ Email e senha válidos.');

    this.accessToken = result.data.access_token;

    await this.checkin();
  };

  subscribe = async () => {
    this.addStatus('⌛ Inscrevendo na aula...');
    await axios({
      method: 'post',
      url: `${API_URL}/lesson/${this.props.route.params.id}/subscribe/`,
      headers: {
        Authorization: `Bearer ${this.accessToken}`,
      },
    });
    this.addStatus('✅ Inscrição realizada.');

    await this.checkin();
  };

  checkin = async () => {
    this.addStatus('⌛ Fazendo checkin...');
    await axios({
      method: 'post',
      url: `${API_URL}/lesson/${this.props.route.params.id}/checkin/`,
      headers: {
        Authorization: `Bearer ${this.accessToken}`,
      },
    });
    this.addStatus('✅ Checkin feito.');
  };

  renderContent = () => {
    const { loading, error, lesson, flow, status } = this.state;

    if (loading) {
      return 'Carregando...';
    }

    if (error) {
      return error;
    }

    return (
      <div className={styles.wrapper}>
        <h1 className={styles.title}>{lesson.name}</h1>
        <h2 className={styles.subtitle}>
          {moment
            .parseZone(lesson.start_datetime)
            .format('dddd, DD/MM [às] HH:mm')}
        </h2>
        <p>Vagas disponíveis: {lesson.available_vacancies}</p>
        <div className={styles.flowSection}>
          <button
            className={`${styles.flowButton} ${
              flow === FLOW_CREATE_ACCOUNT ? styles.selected : ''
            }`}
            onClick={this.onChangeFlow}
            data-flow={FLOW_CREATE_ACCOUNT}
          >
            Criar conta
          </button>
          <button
            className={`${styles.flowButton} ${
              flow === FLOW_LOGIN ? styles.selected : ''
            }`}
            onClick={this.onChangeFlow}
            data-flow={FLOW_LOGIN}
          >
            Já possui conta
          </button>
        </div>
        <form
          ref={this.formRef}
          className={styles.form}
          onSubmit={this.onSubmit}
        >
          {flow === FLOW_CREATE_ACCOUNT && (
            <label className={styles.field}>
              <div className={styles.label}>Nome Completo*</div>
              <input
                className={styles.input}
                type="text"
                name="name"
                required
                pattern=".+ .+"
              />
            </label>
          )}
          <label className={styles.field}>
            <div className={styles.label}>Email*</div>
            <input
              className={styles.input}
              type="email"
              name="email"
              required
            />
          </label>
          {flow === FLOW_CREATE_ACCOUNT && (
            <label className={styles.field}>
              <div className={styles.label}>Telefone*</div>
              <input
                className={styles.input}
                type="tel"
                name="phone"
                required
                minLength="10"
                pattern="[0-9\(\)\s\+\-\.]*"
              />
              <div className={styles.caption}>Incluindo o DDD</div>
            </label>
          )}
          {flow === FLOW_CREATE_ACCOUNT && (
            <label className={styles.field}>
              <div className={styles.label}>CPF*</div>
              <input
                className={styles.input}
                type="text"
                name="cpf"
                required
                minLength="11"
                maxLength="14"
                pattern="[0-9\s\.\-]*"
              />
            </label>
          )}
          {flow === FLOW_CREATE_ACCOUNT && (
            <label className={styles.field}>
              <div className={styles.label}>Data de nascimento*</div>
              <input
                className={styles.input}
                type="text"
                name="birth_date"
                required
                minLength="10"
                maxLength="10"
                pattern="[0-9\s\.\-\/]*"
              />
              <div className={styles.caption}>Formato DD/MM/YYYY</div>
            </label>
          )}
          <label className={styles.field}>
            <div className={styles.label}>Senha*</div>
            <input
              className={styles.input}
              type="password"
              name="password"
              required
              minLength="8"
            />
            <div className={styles.caption}>
              Mínimo de 8 caracteres, com pelo menos uma letra e um número
            </div>
          </label>
          <div>
            <button className={`${styles.button} ${styles.submitButton}`}>
              Enviar
            </button>
          </div>
        </form>
        {status.length > 0 && (
          <>
            <div className={styles.status}>
              {status.map((item, index) => (
                <div key={`${index}-${item}`} className={styles.statusItem}>
                  {item}
                </div>
              ))}
            </div>
            <button
              className={`${styles.button} ${styles.restartButton}`}
              onClick={this.onClickRestart}
            >
              Reiniciar
            </button>
          </>
        )}
      </div>
    );
  };

  render() {
    return (
      <>
        <div className={styles.header}>
          <MudeLogoIcon color={Theme.color.magenta} />
        </div>
        <div className={styles.container}>{this.renderContent()}</div>
      </>
    );
  }
}
