import React, { useCallback } from 'react';
import {
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  TouchableWithoutFeedback,
  View,
} from 'react-native';
import FastImage from 'react-native-fast-image';
import moment from 'moment-timezone';
import AnalyticsService from '../libs/AnalyticsService';
import { addToCalendar } from '../libs/LessonActions';
import { getStaticUrl } from '../libs/MapUtils';
import NavigationService from '../libs/NavigationService';
import { isDonation, isLive } from '../libs/Rules';
import Theme from '../libs/Theme';
import { trainerLabel, genderContext } from '../libs/Utils';
import CalendarIcon from '../icons/CalendarIcon';
import PersonIcon from '../icons/PersonIcon';
import PinIcon from '../icons/PinIcon';
import I18n from './I18n';
import ActionButton from './ActionButton';
import DateBox from './DateBox';
import DonationCard from './DonationCard';
import SponsorCard from './SponsorCard';

const IMAGE_WIDTH = Theme.dimensions.windowWidth - 55 - 136;

export default function LessonDetails({
  lesson,
  lessonGroup,
  place,
  neighborhood,
  trainer,
  modality,
  sponsor,
}) {
  const openMap = useCallback(() => {
    NavigationService.openUrl(
      `https://maps.google.com?q=${place?.latitude},${place?.longitude}`,
    );
  }, [place]);

  const onPressTrainer = useCallback(() => {
    NavigationService.navigate('Trainer', { id: trainer?.id });
  }, [trainer]);

  const openPressCalendar = useCallback(() => {
    NavigationService.navigate('Search', {
      place: place?.id,
      modality: modality?.id,
      trainer: trainer?.id,
    });
  }, [place, modality, trainer]);

  const openPressAddToCalendar = useCallback(() => {
    if (lesson) {
      addToCalendar(lesson.id);
      AnalyticsService.logEvent('add_to_calendar', {
        lesson_id: String(lesson.id),
      });
    }
  }, [lesson]);

  const recurrenceInfo = [
    lessonGroup?.recurrence_sunday,
    lessonGroup?.recurrence_monday,
    lessonGroup?.recurrence_tuesday,
    lessonGroup?.recurrence_wednesday,
    lessonGroup?.recurrence_thursday,
    lessonGroup?.recurrence_friday,
  ];

  const recurrenceDays = [];
  for (let i = 0; i < 7; i++) {
    const day = moment().add(i + 1, 'days');
    const selected = recurrenceInfo[day.day()];
    recurrenceDays.push({ day, selected });
  }

  const uniqueClass = recurrenceDays.filter(day => day.selected).length === 0;

  const description = lessonGroup?.description || trainer?.description;
  const images = lessonGroup?.images || trainer?.images;

  const mapDimensions = StyleSheet.flatten(styles.map);

  const isNative = Platform.select({ native: true });

  return (
    <View style={styles.container}>
      {!!description && (
        <>
          <Text style={styles.description}>{description}</Text>
          {images?.length > 0 && (
            <ScrollView
              horizontal
              decelerationRate="fast"
              showsHorizontalScrollIndicator={false}
              contentContainerStyle={styles.images}
            >
              {images.map(image => (
                <FastImage
                  key={image}
                  style={styles.image}
                  source={{ uri: image || '' }}
                />
              ))}
            </ScrollView>
          )}
        </>
      )}
      {!!place && !isLive(lesson) && (
        <View style={styles.section}>
          <View style={styles.icon}>
            <PinIcon width={16} height={16} color={Theme.color.white} />
          </View>
          <View style={styles.info}>
            <Text style={styles.label}>
              <I18n>lessonLocation</I18n>
            </Text>
            <Text style={styles.placeName}>{place.name}</Text>
            <Text style={styles.address}>{place.address}</Text>
            <Text style={styles.neighborhood}>{neighborhood?.name}</Text>
          </View>
          <View>
            <FastImage
              style={styles.map}
              source={{
                uri: getStaticUrl(
                  place.latitude,
                  place.longitude,
                  mapDimensions.width,
                  mapDimensions.height,
                ),
              }}
            />
            <ActionButton
              title="howToGetThere"
              theme="whiteWithBorder"
              onPress={openMap}
              style={styles.overlayButton}
            />
          </View>
        </View>
      )}
      {!!trainer && (
        <View style={styles.section}>
          <View style={styles.icon}>
            <PersonIcon width={16} height={16} color={Theme.color.white} />
          </View>
          <View style={styles.info}>
            <Text style={styles.label}>
              <I18n params={{ context: genderContext(trainer) }}>
                {trainerLabel(modality)}
              </I18n>
            </Text>
            <Text style={styles.trainerName}>{trainer.name}</Text>
            {!!trainer.instagram && (
              <Text style={styles.instagram} selectable>
                {trainer.instagram}
              </Text>
            )}
            {!!trainer.institution && (
              <Text style={styles.institution}>{trainer.institution}</Text>
            )}
          </View>
          <View>
            <TouchableWithoutFeedback onPress={onPressTrainer}>
              <FastImage
                style={styles.trainerPicture}
                source={{ uri: trainer.picture || '' }}
              />
            </TouchableWithoutFeedback>
            <ActionButton
              title="readMore"
              theme="whiteWithBorder"
              onPress={onPressTrainer}
              style={styles.overlayButton}
            />
          </View>
        </View>
      )}
      {!uniqueClass && isNative && (
        <View style={styles.recurrenceSection}>
          <View style={styles.row}>
            <View style={styles.icon}>
              <CalendarIcon width={16} height={16} color={Theme.color.white} />
            </View>
            <View style={styles.info}>
              <Text style={styles.label}>
                <I18n>otherDates</I18n>
              </Text>
              <View style={styles.recurrenceRow}>
                {recurrenceDays.map((item, index) => (
                  <DateBox
                    key={index}
                    date={item.day}
                    theme={item.selected ? 'magenta' : 'gray'}
                  />
                ))}
              </View>
            </View>
          </View>
          <ActionButton
            title="seeAgenda"
            theme="whiteWithBorder"
            onPress={openPressCalendar}
            style={styles.recurrenceButton}
          />
        </View>
      )}
      {isNative && (
        <>
          <ActionButton
            icon={CalendarIcon}
            title="addToCalendar"
            theme="whiteWithBorder"
            onPress={openPressAddToCalendar}
            style={styles.addToCalendar}
          />
          {isDonation(lesson) && (
            <DonationCard trainer={trainer} lessonId={lesson.id} />
          )}
          <SponsorCard sponsor={sponsor} />
        </>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    paddingVertical: 30,
  },
  description: {
    color: Theme.color.darkGray,
    fontFamily: Theme.fonts.barlow,
    fontSize: 16,
    lineHeight: 22,
    marginHorizontal: 20,
    marginBottom: 10,
  },
  images: {
    flexDirection: 'row',
    paddingLeft: 20,
    paddingRight: 10,
    marginTop: 30,
    marginBottom: 10,
  },
  image: {
    backgroundColor: Theme.color.veryLightGray,
    width: IMAGE_WIDTH,
    height: IMAGE_WIDTH * (230 / 170),
    borderRadius: 6,
    marginRight: 15,
  },
  section: {
    paddingHorizontal: 20,
    marginTop: 30,
    flexDirection: 'row',
  },
  icon: {
    backgroundColor: Theme.color.magenta,
    borderRadius: 4,
    width: 32,
    height: 32,
    justifyContent: 'center',
    alignItems: 'center',
  },
  info: {
    flexGrow: 1,
    flexShrink: 1,
    marginLeft: 10,
  },
  label: {
    color: Theme.color.magenta,
    fontFamily: Theme.fonts.barlowSemiCondensedBold,
    fontSize: 10,
    letterSpacing: 0.3,
    textTransform: 'uppercase',
  },
  placeName: {
    color: Theme.color.veryDarkGray,
    fontFamily: Theme.fonts.barlowBold,
    fontSize: 14,
    marginTop: 5,
  },
  address: {
    color: Theme.color.darkGray,
    fontFamily: Theme.fonts.barlowCondensed,
    fontSize: 14,
    marginTop: 5,
  },
  neighborhood: {
    color: Theme.color.darkGray,
    fontFamily: Theme.fonts.barlowCondensedSemiBold,
    fontSize: 14,
  },
  map: {
    width: 136,
    height: 145,
    marginHorizontal: 20,
  },
  trainerName: {
    color: Theme.color.veryDarkGray,
    fontFamily: Theme.fonts.barlowBold,
    fontSize: 22,
    marginTop: 5,
  },
  instagram: {
    color: Theme.color.veryDarkGray,
    fontFamily: Theme.fonts.barlowCondensed,
    fontSize: 14,
    marginTop: 5,
  },
  institution: {
    color: Theme.color.darkGray,
    fontFamily: Theme.fonts.barlowSemiCondensedSemiBold,
    fontSize: 16,
    marginTop: 5,
  },
  trainerPicture: {
    height: 145,
    width: 97,
    borderRadius: 136,
    backgroundColor: Theme.color.lowContrastGray_USE_WITH_WISDOM,
    overflow: 'hidden',
    marginRight: 136 + 20 - 97,
  },
  overlayButton: {
    position: 'absolute',
    top: 85,
    right: 0,
    width: 136,
  },
  recurrenceSection: {
    paddingHorizontal: 20,
    marginTop: 30,
  },
  row: {
    flexDirection: 'row',
  },
  recurrenceRow: {
    marginTop: 20,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  recurrenceButton: {
    alignSelf: 'flex-end',
    width: 136,
    marginTop: 20,
  },
  addToCalendar: {
    margin: 20,
    marginTop: 40,
  },
});
