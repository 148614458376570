import { Model, Query } from '@nozbe/watermelondb';
import { children, field, json } from '@nozbe/watermelondb/decorators';
import { Associations } from '@nozbe/watermelondb/Model';
import Lesson from './Lesson';
import Workout from './Workout';

export default class Trainer extends Model {
  static table = 'trainers';

  static associations: Associations = {
    lessons: { type: 'has_many', foreignKey: 'trainer' },
    workouts: { type: 'has_many', foreignKey: 'trainer' },
  };

  @children('lessons') lessons!: Query<Lesson>;
  @children('workouts') workouts!: Query<Workout>;

  @field('name') name!: string;
  @field('slug') slug!: string;
  @field('description') description!: string;
  @field('cref') cref!: string;
  @field('picture') picture!: string;
  @field('instagram') instagram!: string;
  @field('institution') institution!: string;
  @field('approved') approved!: boolean;
  @field('gender') gender!: string;
  @json('expertise', _json => _json || []) expertise!: string[];
  @json('images', _json => _json || []) images!: string[];
  @field('product') product!: string;
}
