import React, { useCallback } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import Theme from '../libs/Theme';
import ModalService from '../libs/ModalService';
import NavigationService from '../libs/NavigationService';
import I18n from '../components/I18n';
import LoginButtons from '../components/LoginButtons';

export default function LoginToContinueModal() {
  const onSuccess = useCallback(() => {
    ModalService.hide();
  }, []);

  const onPressLogin = useCallback(() => {
    ModalService.hide();
    NavigationService.navigate('LoginModal');
  }, []);

  const onPressCreateAccount = useCallback(() => {
    ModalService.hide();
    NavigationService.navigate('CreateAccountModal');
  }, []);

  return (
    <View style={styles.container}>
      <Text style={styles.title}>
        <I18n>hello</I18n>
      </Text>
      <Text style={styles.message}>
        <I18n>youAreNotRegisteredYet</I18n>
      </Text>
      <View style={styles.buttons}>
        <LoginButtons
          onSuccess={onSuccess}
          onPressLogin={onPressLogin}
          onPressCreateAccount={onPressCreateAccount}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    width: 320,
    marginTop: Theme.dimensions.topSpace + 20,
    marginBottom: Theme.dimensions.bottomSpace + 20,
    backgroundColor: Theme.color.white,
    borderRadius: 10,
    paddingHorizontal: 25,
    paddingTop: 25,
    paddingBottom: 10,
  },
  title: {
    color: Theme.color.veryDarkGray,
    fontFamily: Theme.fonts.barlowCondensedBold,
    fontSize: 22,
  },
  message: {
    color: Theme.color.darkGray,
    fontFamily: Theme.fonts.barlow,
    fontSize: 16,
    marginVertical: 10,
  },
  buttons: {
    marginHorizontal: -20,
  },
});
