import { Model, Relation } from '@nozbe/watermelondb';
import { field, relation } from '@nozbe/watermelondb/decorators';
import { Associations } from '@nozbe/watermelondb/Model';
import Customer from './Customer';
import Lesson from './Lesson';

export default class Enrollment extends Model {
  static table = 'enrollments';

  static associations: Associations = {
    lessons: { type: 'belongs_to', key: 'lesson' },
    customers: { type: 'belongs_to', key: 'customer' },
  };

  @relation('lessons', 'lesson') lesson_obj!: Relation<Lesson>;
  @relation('customers', 'customer') customer_obj!: Relation<Customer>;

  @field('queued') queued!: boolean;
  @field('queue_enter_time') queue_enter_time!: string;
  @field('queue_leave_time') queue_leave_time!: string;
  @field('last_queue_notification') last_queue_notification!: string;
  @field('registered') registered!: boolean;
  @field('registered_time') registered_time!: string;
  @field('unregistered_time') unregistered_time!: string;
  @field('checked_in') checked_in!: boolean;
  @field('checkin_time') checkin_time!: string;
  @field('trainer_checked') trainer_checked!: boolean;
  @field('trainer_checkin_time') trainer_checkin_time!: string;
  @field('rating') rating!: number;
  @field('comment') comment!: string;
  @field('rating_time') rating_time!: string;
  @field('medical_certificate_check') medical_certificate_check!: boolean;
  @field('customer') customer!: string;
  @field('lesson') lesson!: string;
  @field('show_in_history') show_in_history!: boolean;
  @field('reserved_spot') reserved_spot!: string;
  @field('spot_reserve_time') spot_reserve_time!: string;
}
