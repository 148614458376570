import { Model, Query } from '@nozbe/watermelondb';
import { children, field } from '@nozbe/watermelondb/decorators';
import { Associations } from '@nozbe/watermelondb/Model';
import Event from './Event';
import Lesson from './Lesson';
import LessonGroup from './LessonGroup';
import SponsorGroup from './SponsorGroup';

export default class Sponsor extends Model {
  static table = 'sponsors';

  static associations: Associations = {
    lessons: { type: 'has_many', foreignKey: 'sponsor' },
    lesson_groups: { type: 'has_many', foreignKey: 'sponsor' },
    events: { type: 'has_many', foreignKey: 'sponsor' },
    sponsor_groups: { type: 'has_many', foreignKey: 'sponsor' },
  };

  @children('lessons') lessons!: Query<Lesson>;
  @children('lesson_groups') lesson_groups!: Query<LessonGroup>;
  @children('events') events!: Query<Event>;
  @children('sponsor_groups') sponsor_groups!: Query<SponsorGroup>;

  @field('name') name!: string;
  @field('main_color') main_color!: string;
  @field('text_color') text_color!: string;
  @field('transparency_logo') transparency_logo!: string;
  @field('ad_image') ad_image!: string;
  @field('ad_title') ad_title!: string;
  @field('ad_text') ad_text!: string;
  @field('page_image') page_image!: string;
  @field('page_text') page_text!: string;
  @field('main_url') main_url!: string;
  @field('calltoaction_url') calltoaction_url!: string;
  @field('calltoaction_text') calltoaction_text!: string;
  @field('order') order!: number;
  @field('gender') gender!: string;
  @field('published') published!: boolean;
}
