import React from 'react';
import qs from 'qs';
import { __DEV__ } from './Env';

export const AO_VIVO = __DEV__ ? 15 : 25; // id da cidade `Ao Vivo` no banco de dados
export const RIO_DE_JANEIRO = 1; // id da cidade do `Rio de Janeiro` no banco de dados
export const RECIFE = 8; // id da cidade de `Recife` no banco de dados

export const DEFAULT_LATITUDE = -22.94376;
export const DEFAULT_LONGITUDE = -43.224597;

export function capitalize(string = '') {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function removeAccents(string = '') {
  // https://stackoverflow.com/a/37511463/1288541
  return string.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}

export function removeWhitespace(string = '') {
  return string.replace(/\s/g, '');
}

export function formatPrice(priceInCents = '', prefix = 'R$ ') {
  if (priceInCents % 100) {
    return `${prefix} ${(priceInCents / 100).toFixed(2).replace('.', ',')}`;
  }
  return `${prefix} ${priceInCents / 100}`;
}

export function getCreditCardImage(brand) {
  return brand
    ? `https://assets.pagar.me/checkout/1.1.0/images/icon-bandeira-${brand}.png`
    : '';
}

export const filterObjectEmptyValues = obj => {
  const output = {};
  Object.entries(obj).forEach(([key, val]) => {
    if (val) {
      output[key] = val;
    }
  });
  return output;
};

export const isDevDevice = () => {
  return __DEV__ || window.location.search === '?dev';
};

export function genderContext(obj) {
  switch (obj?.gender) {
    case 'F':
      return 'female';
    case 'M':
      return 'male';
    default:
      return '';
  }
}

export function trainerLabel(modality) {
  switch (modality?.name) {
    case 'Yoga':
    case 'Yoga.':
    case 'Hatha Yoga':
      return 'instructor';
    case 'Dança':
      return 'teacher';
    case 'Meditação':
      return 'guide';
    default:
      return 'coach';
  }
}

export function workoutContext(modality) {
  switch (modality?.name) {
    case 'Alongamento':
    case 'Respiração':
    case 'Yoga':
      return 'practice';
    case 'Bodyweight':
    case 'Força':
    case 'Mobilidade':
      return 'workout';
    default:
      return '';
  }
}

export function haversineFormula(lat1, lon1, lat2, lon2) {
  function rad(x) {
    return (x * Math.PI) / 180;
  }
  const R = 6378.137;
  const dLat = rad(lat2 - lat1);
  const dLong = rad(lon2 - lon1);
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(rad(lat1)) *
      Math.cos(rad(lat2)) *
      Math.sin(dLong / 2) *
      Math.sin(dLong / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c;
  return d * 1000; // meters;
}

///// web migration /////

// forçamos o schema mudefit:// para tentar abrir o app da Mude, isso
// é importante pois nem sempre a url abre no browser padrão do sistema,
// pode ser aberta internamente por outros apps que possuem browser
// internos como o Gmail, Whataspp etc
export function openApp() {
  window.location = `mudefit://${window.location.href
    .replace('https://', '')
    .replace('http://', '')}`;
}

export function formatApiError(error, expectedKey = '') {
  const data = error?.response?.data;
  const feedback =
    data?.[expectedKey] ||
    data?.error_description ||
    data?.errors ||
    data?.error ||
    data?.detail ||
    data ||
    error?.message ||
    error ||
    'Erro desconhecido';

  if (Array.isArray(feedback)) {
    return feedback.map(s => <div key={s}>{s}</div>);
  }

  if (typeof feedback === 'object') {
    return Object.keys(feedback)
      .map(key => `${key}: ${feedback[key]}`)
      .map(s => <div key={s}>{s}</div>);
  }

  return feedback;
}

// se precisar alterar o estilo, olhar o projeto do app que tem todos os parâmetros de forma legível
const qsMapStyle =
  '&style=element:geometry|color:0xf5f5f5&style=element:labels|visibility:off&style=element:labels.icon|visibility:off&style=element:labels.text.fill|color:0x616161&style=element:labels.text.stroke|color:0xf5f5f5&style=feature:administrative.land_parcel|visibility:off&style=feature:administrative.land_parcel|element:labels.text.fill|color:0xbdbdbd&style=feature:administrative.neighborhood|visibility:off&style=feature:poi|element:geometry|color:0xeeeeee&style=feature:poi|element:labels.text|visibility:off&style=feature:poi|element:labels.text.fill|color:0x757575&style=feature:poi.park|element:geometry|color:0xe5e5e5&style=feature:poi.park|element:labels.text.fill|color:0x9e9e9e&style=feature:road|element:geometry|color:0xffffff&style=feature:road|element:labels|visibility:off&style=feature:road.arterial|element:labels.text.fill|color:0x757575&style=feature:road.highway|element:geometry|color:0xdadada&style=feature:road.highway|element:labels.text.fill|color:0x616161&style=feature:road.local|element:labels.text.fill|color:0x9e9e9e&style=feature:transit.line|element:geometry|color:0xe5e5e5&style=feature:transit.station|element:geometry|color:0xeeeeee&style=feature:water|element:geometry|color:0xc9c9c9&style=feature:water|element:labels.text|visibility:off&style=feature:water|element:labels.text.fill|color:0x9e9e9e';
export function getStaticUrl(latitude, longitude, width, height) {
  const params = qs.stringify(
    {
      center: `${latitude},${longitude}`,
      zoom: 12,
      size: `${Math.ceil(width)}x${Math.ceil(height)}`, // somente número inteiro
      key: 'AIzaSyCCyWIHxWbj5Zc-VqZVuKlxVPBtYBtX2zU',
    },
    { encode: false }, // encode converte a vírgula `,` para `%2C` e quebra no iOS
  );

  return `https://maps.googleapis.com/maps/api/staticmap?${params}${qsMapStyle}`;
}

export function trainerUrl(trainer) {
  return trainer?.slug ? `/${trainer.slug}` : `/professor/${trainer?.id}`;
}
