import {
  schemaMigrations,
  addColumns,
} from '@nozbe/watermelondb/Schema/migrations';

// As propriedades criadas com o prefixo `__` (dois underlines)
// são usadas apenas para referências internas do nosso app e
// estão fora das implementações padrões do WatermelonDB

export default schemaMigrations({
  migrations: [
    {
      toVersion: 27,
      steps: [
        addColumns({
          table: 'enrollments',
          columns: [
            { name: 'reserved_spot', type: 'string' },
            { name: 'spot_reserve_time', type: 'string' },
          ],
        }),
        addColumns({
          table: 'lessons',
          columns: [
            { name: 'has_reserved_spots', type: 'boolean' },
            { name: 'reservable_spots', type: 'string', __isJson: true },
            { name: 'available_spots', type: 'string', __isJson: true },
          ],
        }),
      ],
    },
  ],
});
