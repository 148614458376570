import React from 'react';
import Svg, { Path } from 'react-native-svg';

export default function SuccessIcon(props) {
  return (
    <Svg width={22} height={22} viewBox="0 0 22 22" {...props}>
      <Path
        fill={props.color}
        d="M11,22C4.93,22,0,17.08,0,11S4.93,0,11,0s11,4.93,11,11S17.08,22,11,22z M11,20c4.97,0,9-4.03,9-9s-4.03-9-9-9s-9,4.03-9,9S6.03,20,11,20z"
      />
      <line
        stroke={props.color || 'currentColor'}
        strokeWidth={2}
        x1="6.47"
        y1="10.01"
        x2="6.47"
        y2="10.01"
      >
        <animate
          attributeName="x2"
          from="6.47"
          to="10.56"
          values="6.47; 10.56; 10.56"
          keyTimes="0; 0.33; 1"
          dur="500ms"
          begin="150ms"
          fill="freeze"
        />
        <animate
          attributeName="y2"
          from="10.01"
          to="14.08"
          values="10.01; 14.08; 14.08"
          keyTimes="0; 0.33; 1"
          dur="500ms"
          begin="150ms"
          fill="freeze"
        />
      </line>
      <line
        stroke={props.color || 'currentColor'}
        strokeWidth={2}
        x1="9.84"
        y1="13.36"
        x2="9.84"
        y2="13.36"
      >
        <animate
          attributeName="x2"
          from="9.84"
          to="15.53"
          values="9.84; 9.84; 15.53"
          keyTimes="0; 0.33; 1"
          dur="500ms"
          begin="150ms"
          fill="freeze"
        />
        <animate
          attributeName="y2"
          from="13.36"
          to="7.92"
          values="13.36; 13.36; 7.92"
          keyTimes="0; 0.33; 1"
          dur="500ms"
          begin="150ms"
          fill="freeze"
        />
      </line>
    </Svg>
  );
}
