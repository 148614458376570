import React from 'react';
import { Platform, StyleSheet } from 'react-native';
import FastImage from 'react-native-fast-image';

export default class ResizableImage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      width: null,
      height: null,
    };
  }

  componentWillUnmount() {
    this.unmount = true;
  }

  onLoad = e => {
    if (this.unmount) {
      return;
    }
    const { width, height } = e.nativeEvent;
    this.setState({ width, height });
  };

  render() {
    const { width, height } = this.state;

    const style = { ...this.props.style };

    if (width && height) {
      if (this.props.fitVertical) {
        style.width = style.height * (width / height);
      } else {
        style.height = style.width * (height / width);
      }
    }

    const isWeb = Platform.OS === 'web';

    if (isWeb && (!width || !height)) {
      if (this.props.fitVertical) {
        style.width = '100%';
      } else {
        style.height = '100%';
      }
    }

    return (
      <FastImage
        {...this.props}
        style={style}
        onLoad={isWeb ? undefined : this.onLoad}
        resizeMode={FastImage.resizeMode.contain}
      />
    );
  }
}
