import React from 'react';
import Svg from 'react-native-svg';

export default function LoadingIcon(props) {
  return (
    <Svg width={30} height={8} viewBox="0 0 30 8" {...props}>
      <circle fill={props.color || 'currentColor'} cx={4} cy={4} r={4}>
        <animate
          attributeName="r"
          from="0"
          to="4"
          dur="500ms"
          repeatCount="indefinite"
        />
        <animate
          attributeType="CSS"
          attributeName="opacity"
          from="0"
          to="0.75"
          dur="500ms"
          repeatCount="indefinite"
        />
      </circle>
      <circle fill={props.color || 'currentColor'} cx={4} cy={4} r={4}>
        <animate
          attributeName="cx"
          from="4"
          to="15"
          dur="500ms"
          repeatCount="indefinite"
        />
        <animate
          attributeType="CSS"
          attributeName="opacity"
          from="0.75"
          to="1"
          dur="500ms"
          repeatCount="indefinite"
        />
      </circle>
      <circle fill={props.color || 'currentColor'} cx={4} cy={4} r={4}>
        <animate
          attributeName="cx"
          from="15"
          to="26"
          dur="500ms"
          repeatCount="indefinite"
        />
        <animate
          attributeType="CSS"
          attributeName="opacity"
          from="1"
          to="0.75"
          dur="500ms"
          repeatCount="indefinite"
        />
      </circle>
      <circle fill={props.color || 'currentColor'} cx={26} cy={4} r={4}>
        <animate
          attributeName="r"
          from="4"
          to="0"
          dur="500ms"
          repeatCount="indefinite"
        />
        <animate
          attributeType="CSS"
          attributeName="opacity"
          from="0.75"
          to="0"
          dur="500ms"
          repeatCount="indefinite"
        />
      </circle>
    </Svg>
  );
}
