import { Model } from '@nozbe/watermelondb';
import { json } from '@nozbe/watermelondb/decorators';
import Lesson from './Lesson';
import Workout from './Workout';

export default class Request extends Model {
  static table = 'requests';

  @json('response', _json => _json) response!: unknown;
}

export enum RequestId {
  ME = '/me',
  DIY_HOME = '/diy_home',
  // TODO adicionar os outros requests
}

export type Me = {
  id: number;
  customer: string;
  default_city: string;
};

export type DiyHomeHighlightCard = {
  id: number;
  highlight_type: 'collection';
};

export type DiyHomeHighlights = {
  id: number;
  type: 'highlights';
  cards: DiyHomeHighlightCard[];
};

export type DiyHomeWorkouts = {
  id: number;
  type: 'workouts';
  items: number[];
};

export type DiyHomeCollections = {
  id: number;
  type: 'collections';
  items: number[];
};

export type DiyHomeContent =
  | DiyHomeHighlights
  | DiyHomeWorkouts
  | DiyHomeCollections;

export type DiyHome = {
  id: number;
  content: DiyHomeContent[];
};

export type CommunityGroupSubscription = {
  id: string;
  group: number;
};

export type FeedItem =
  | (Lesson & {
      type: 'lesson';
    })
  | (Workout & {
      type: 'workout';
      start_datetime?: string;
      end_datetime?: string;
    });

export type Feed = FeedItem[];
