import { Model, Relation } from '@nozbe/watermelondb';
import { field, relation } from '@nozbe/watermelondb/decorators';
import { Associations } from '@nozbe/watermelondb/Model';
import Sponsor from './Sponsor';

export default class SponsorGroup extends Model {
  static table = 'sponsor_groups';

  static associations: Associations = {
    sponsors: { type: 'belongs_to', key: 'sponsor' },
  };

  @relation('sponsors', 'sponsor') sponsor_obj!: Relation<Sponsor>;

  @field('name') name!: string;
  @field('sponsor') sponsor!: string;
  @field('is_member') is_member!: boolean;
}
