import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { API_URL } from '../libs/Env';
import NavigationService from '../libs/NavigationService';
import withNavParams from '../components/withNavParams';
import LayoutWithHeader from '../components/LayoutWithHeader';

function SlugScreen({ slug }) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    async function setup() {
      try {
        // TODO [web migration] buscar o professor em cache para ir mais rápido
        const result = await axios.get(`${API_URL}/trainer/?limit=1000`);
        const trainer = result.data.results.find(t => t.slug === slug);
        if (trainer) {
          NavigationService.navigate('Trainer', { id: trainer.id });
          return;
        } else {
          setError('notFound');
        }
      } catch (error) {
        setError(error.message);
      }
      setLoading(false);
    }
    setup();
  }, [slug]);

  return <LayoutWithHeader loading={loading} error={error} />;
}

export default withNavParams(SlugScreen);
