import { Model, Query, Relation } from '@nozbe/watermelondb';
import { children, field, relation } from '@nozbe/watermelondb/decorators';
import { Associations } from '@nozbe/watermelondb/Model';
import City from './City';
import Place from './Place';

export default class Neighborhood extends Model {
  static table = 'neighborhoods';

  static associations: Associations = {
    places: { type: 'has_many', foreignKey: 'neighborhood' },
    cities: { type: 'belongs_to', key: 'city' },
  };

  @children('places') places!: Query<Place>;

  @relation('cities', 'city') city_obj!: Relation<City>;

  @field('name') name!: string;
  @field('latitude') latitude!: string;
  @field('longitude') longitude!: string;
  @field('city') city!: string;
}
