import _ from 'lodash';
import AnalyticsService from './AnalyticsService';
import NetInfoService, { Bandwidth } from './NetInfoService';
import StoreService from './StoreService';

export const TYPE_EXERCISE = 'exercise';
export const TYPE_BREAK = 'pause';

export function humanizeLevel(level) {
  switch (level) {
    case 10:
      return 'beginner';
    case 20:
      return 'intermediate';
    case 30:
      return 'advanced';
    default:
      return 'allLevels';
  }
}

export function getAverageLevel(list = []) {
  const levels = [];
  (list || []).forEach(item => {
    if (item && item.level && !levels.includes(item.level)) {
      levels.push(item.level);
    }
  });
  if (levels.length > 1) {
    return -1;
  }
  if (levels.length === 1) {
    return levels[0];
  }
  return 0;
}

export function formatDuration(durationAsSeconds) {
  const minutes = Math.round(durationAsSeconds / 60);
  if (minutes < 60) {
    return `${minutes || 0}min`;
  }
  const hour = Math.floor(minutes / 60);
  const minutesFormatted = minutes % 60;
  if (minutesFormatted) {
    return `${hour}h ${minutesFormatted}min`;
  }
  return `${hour}h`;
}

export function formatStopwatch(durationAsSeconds) {
  if (durationAsSeconds < 0) {
    return '0:00';
  }
  const seconds = Math.floor(durationAsSeconds);
  const minutes = Math.floor(seconds / 60);
  if (minutes < 60) {
    return `${minutes}:${fillZero(seconds % 60, 2)}`;
  }
  const hour = Math.floor(minutes / 60);
  return `${hour}:${fillZero(minutes % 60, 2)}:${fillZero(seconds % 60, 2)}`;
}

export function formatWeight(weightAsGrams = 0) {
  const kilo = Math.floor(weightAsGrams / 1000);
  const grams = weightAsGrams % 1000;
  if (!grams) {
    return `${kilo} kg`;
  }
  if (!kilo) {
    return `${grams} g`;
  }
  return `${kilo},${fillZero(grams, 3).replace(/0+$/g, '')} kg`;
}

export function getWorkoutSteps(workout = {}) {
  return _.flatten(workout?.parts?.map(p => p.items));
}

export function getWorkoutExercises(workout) {
  return getWorkoutSteps(workout)
    .filter(s => s.type === TYPE_EXERCISE)
    .map(s => s.exercise_id);
}

export function getCollectionWorkouts(collection) {
  return _.uniq(_.flatten(collection?.content?.map(item => item.workouts)));
}

export function getCollectionExercises(collection, workouts) {
  return _.uniq(
    _.flatten(
      getCollectionWorkouts(collection).map(w =>
        getWorkoutExercises(workouts?.[w]),
      ),
    ),
  );
}

export function getBestVideoUrl(exercise) {
  if (!exercise) {
    return '';
  }

  const videoSizes = exercise.video_sizes || [];

  // inicialmente tenta usar o vídeo na qualidade definida pelo usuário
  const userSize = StoreService.getState().localSettings.videoSize;
  let videoUrl = videoSizes[userSize];

  // depois tenta usar o video q já existe em cache
  if (!videoUrl) {
    videoUrl = exercise.video_cached;
  }

  // depois tenta usar o melhor video de acordo com a tela e banda da internet
  if (!videoUrl) {
    const bestSize = getBestVideoSize();
    videoUrl = videoSizes[bestSize];
  }

  // se achou uma url boa, salva ela em cache para ser usado depois
  if (videoUrl && videoUrl !== exercise.video_cached && exercise.id) {
    exercise.saveCachedVideo(videoUrl);
  }

  // retorna o video no tamanho correto, com fallback para o video original que fez o upload
  return videoUrl || exercise.video || '';
}

function getBestVideoSize() {
  const sizeOptions = [
    { width: 180, height: 320 },
    { width: 360, height: 640 },
    { width: 480, height: 854 },
    { width: 720, height: 1280 },
    { width: 1080, height: 1920 },
  ];

  // calcula o melhor tamanho com base no tamanho da tela
  let screenSizeIndex = 0;
  const screen = { width: window.innerWidth, height: window.innerHeight };
  const acceptableWidth = screen.width * screen.scale * 0.75; // tolerância de 25% de upscaling
  const acceptableHeight = screen.height * screen.scale * 0.75;
  for (let i = 0; i < sizeOptions.length; i++) {
    screenSizeIndex = i;
    const option = sizeOptions[screenSizeIndex];
    if (acceptableWidth < option.width && acceptableHeight < option.height) {
      break;
    }
  }

  // calcula o melhor tamanho com base na banda de internet disponível
  const bandwidthSizeIndex =
    {
      [Bandwidth.ZERO]: 0,
      [Bandwidth.LOW]: 0, // com internet ruim é melhor pegar o menor vídeo possível
      [Bandwidth.MEDIUM]: 1,
      [Bandwidth.HIGH]: 2,
      [Bandwidth.ULTRA]: 3,
    }[NetInfoService.getBandwidth()] || 0;

  // respeita o menor vídeo entre as opções disponíveis de tela e banda de internet
  const sizeIndex = Math.min(screenSizeIndex, bandwidthSizeIndex);

  const dimensions = sizeOptions[sizeIndex] || sizeOptions[0];
  const video_size = `${dimensions.width}x${dimensions.height}`;

  AnalyticsService.logEvent('best_video_size', { video_size });

  return video_size;
}

// ---------------------------------- Helpers ----------------------------------

const fillZero = (number, length) => {
  const diff = length - String(number).length;
  return `${new Array(diff > 0 ? diff : 0).fill(0).join('')}${number}`;
};
