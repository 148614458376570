import React from 'react';
import { Platform, StyleSheet } from 'react-native';
import NavigationService from '../libs/NavigationService';
import Theme from '../libs/Theme';
import withNavParams from '../components/withNavParams';
import AuthGate from '../components/AuthGate';
import ModalScrollView from '../components/ModalScrollView';
import PersonalDataForm from '../components/PersonalDataForm';
import EmergencyContactForm from '../components/EmergencyContactForm';
import DeficiencyForm from '../components/DeficiencyForm';
import Anamnesis from '../components/Anamnesis';

export const STEP_PERSONAL_DATA = 'STEP_PERSONAL_DATA';
export const STEP_EMERGENCY_CONTACT = 'STEP_EMERGENCY_CONTACT';
export const STEP_DEFICIENCY = 'STEP_DEFICIENCY';
export const STEP_COVID = 'STEP_COVID';
export const STEP_ANAMNESIS = 'STEP_ANAMNESIS';

class CompleteDataModalScreen extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      current: 0,
    };
  }

  isLastStep = () => {
    return this.state.current === this.props.flow.length - 1;
  };

  nextStep = () => {
    if (this.isLastStep()) {
      NavigationService.goBack();
    } else {
      this.setState({ current: this.state.current + 1 });
      if (Platform.OS === 'web') {
        window.scrollTo(0, 0);
      }
    }
  };

  renderContent = () => {
    const step = this.props.flow[this.state.current];
    const submitLabel = this.isLastStep() ? 'save' : 'next';

    switch (step) {
      case STEP_PERSONAL_DATA:
        return (
          <ModalScrollView
            title="completeRegistration"
            contentContainerStyle={styles.container}
          >
            <PersonalDataForm
              submitLabel={submitLabel}
              onSuccess={this.nextStep}
            />
          </ModalScrollView>
        );

      case STEP_EMERGENCY_CONTACT:
        return (
          <ModalScrollView
            title="emergencyContact"
            contentContainerStyle={styles.container}
          >
            <EmergencyContactForm
              submitLabel={submitLabel}
              onSuccess={this.nextStep}
            />
          </ModalScrollView>
        );

      case STEP_DEFICIENCY:
        return (
          <ModalScrollView
            title="deficiencyTitle"
            contentContainerStyle={styles.container}
          >
            <DeficiencyForm
              submitLabel={submitLabel}
              onSuccess={this.nextStep}
            />
          </ModalScrollView>
        );

      case STEP_COVID:
        return (
          <ModalScrollView
            key={STEP_COVID}
            title="covidTitle"
            contentContainerStyle={styles.container}
          >
            <Anamnesis
              submitLabel={submitLabel}
              onSuccess={this.nextStep}
              isCovid
            />
          </ModalScrollView>
        );

      case STEP_ANAMNESIS:
        return (
          <ModalScrollView
            key={STEP_ANAMNESIS}
            title="anamnesisTitle"
            contentContainerStyle={styles.container}
          >
            <Anamnesis />
          </ModalScrollView>
        );
    }

    return null;
  };

  render() {
    return <AuthGate>{this.renderContent()}</AuthGate>;
  }
}

const styles = StyleSheet.create({
  container: {
    paddingBottom: 20 + Theme.dimensions.bottomSpace,
  },
});

export default withNavParams(CompleteDataModalScreen);
