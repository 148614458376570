import React from 'react';
import { Platform } from 'react-native';
import * as AddCalendarEvent from 'react-native-add-calendar-event';
import Permissions from 'react-native-permissions';
import moment from 'moment-timezone';
import Api from './Api';
import AlertService from './AlertService';
import AnalyticsService from './AnalyticsService';
import AuthService from './AuthService';
import DataService from './DataService';
import { resolveError } from './FormUtils';
import GeolocationService from './GeolocationService';
import ModalService from './ModalService';
import NavigationService from './NavigationService';
import NotificationService from './NotificationService';
import {
  personalDataComplete,
  emergencyComplete,
  deficiencyComplete,
  needAnamnesis,
  anamnesisEmpty,
  anamnesisExpired,
  isSubscribed,
  isChecked,
  isLive,
  isDonation,
  nearOfLesson,
} from './Rules';
import SentryService from './SentryService';
import StoreService from './StoreService';
import {
  STEP_PERSONAL_DATA,
  STEP_EMERGENCY_CONTACT,
  STEP_DEFICIENCY,
  STEP_COVID,
  STEP_ANAMNESIS,
} from '../screens/CompleteDataModalScreen';
import { COVID_FORM_KEY } from '../components/Anamnesis';
import LoginToContinueModal from '../components/LoginToContinueModal';

export function pay(product, plan, lesson) {
  if (!AuthService.isLogged) {
    _showLoginAlert();
    return;
  }
  NavigationService.navigate('Checkout', {
    productId: product.id,
    planId: plan.id,
    lessonId: lesson.id,
  });
}

export async function subscribe(id, isWatchNowFlow) {
  const lesson = await DataService.findLesson(id);
  if (!lesson) {
    return false;
  }

  const { user } = StoreService.getState();
  if (!(await _validateUserToLesson(user, lesson))) {
    return false;
  }

  try {
    await Api.subscribe(id);
  } catch (error) {
    const message = resolveError(error);
    switch (message) {
      case 'lesson with no vacancies':
        AlertService.show({
          title: 'someoneWasFasterThanYou',
          message: 'vacanyWasFulfilled',
          confirmText: 'warnMeForNewVacancies',
          dismissText: 'IDontWantToBeWarned',
          onConfirm: () => enqueue(id),
        });
        break;

      case 'schedule conflict':
        AlertService.show({
          title: 'scheduleConflictWarningTitle',
          message: 'scheduleConflictWarningContent',
        });
        break;

      case 'schedule limit':
        AlertService.show({
          title: 'scheduleLimitReachedTitle',
          message: 'scheduleLimitReachedContent',
          messageI18nParams: { count: user?.subscriptions_limit },
        });
        break;

      case 'age limit':
        AlertService.show({
          title: 'ageLimitTitle',
          message: 'ageLimitContent',
        });
        break;

      default:
        if (message) {
          AlertService.show({
            title: 'genericErrorTitle',
            message,
          });
        }
        break;
    }
    return false;
  }

  if (isLive(lesson)) {
    AnalyticsService.logEvent('subscribe_live', { lesson_id: String(id) });
  } else {
    AnalyticsService.logEvent('subscribe_lesson', { lesson_id: String(id) });
  }

  NotificationService.requestPermissions();

  if (!isWatchNowFlow) {
    if (lesson.has_reserved_spots) {
      NavigationService.navigate('LessonSpot', { id: lesson.id });
    } else {
      // TODO [web migration]
      // const title = isLive(lesson)
      //   ? 'yourReminderWasCreated'
      //   : 'youAreScheduled';
      // if (isDonation(lesson)) {
      //   NavigationService.navigate('LessonDonation', { id: lesson.id, title });
      // } else if (lesson.sponsor) {
      //   NavigationService.navigate('SponsorAd', { id: lesson.sponsor, title });
      // }
    }
  }

  return true;
}

export async function unsubscribe(id) {
  const lesson = await DataService.findLesson(id);
  if (!lesson) {
    return;
  }

  AlertService.show({
    title: isLive(lesson) ? 'cancelLiveSubscription' : 'cancelSubscription',
    message: isLive(lesson)
      ? 'cancelLiveSubscriptionContent'
      : 'cancelSubscriptionContent',
    confirmText: isLive(lesson) ? 'yesRemoveMyReminder' : 'yesReleaseMyVacancy',
    dismissText: isLive(lesson) ? 'keepMyReminder' : 'keepMyDateScheduled',
    onConfirm: async () => {
      await Api.unsubscribe(id);
      if (isLive(lesson)) {
        AnalyticsService.logEvent('unsubscribe_live', {
          lesson_id: String(id),
        });
      } else {
        AnalyticsService.logEvent('unsubscribe_lesson', {
          lesson_id: String(id),
        });
      }
    },
  });
}

export async function enqueue(id) {
  if (!AuthService.isLogged) {
    _showLoginAlert();
    return;
  }

  try {
    await Api.enqueue(id);
  } catch (error) {
    resolveError(error, true);
    return false;
  }

  const lesson = await DataService.findLesson(id);
  if (isLive(lesson)) {
    AnalyticsService.logEvent('enqueue_live', {
      lesson_id: String(id),
    });
  } else {
    AnalyticsService.logEvent('enqueue_lesson', {
      lesson_id: String(id),
    });
  }
}

export async function dequeue(id) {
  AlertService.show({
    title: 'warnMeTitle',
    message: 'warnMeContent',
    confirmText: 'warnMeConfirm',
    dismissText: 'warnMeDismiss',
    onDismiss: async () => {
      Api.dequeue(id);
      const lesson = await DataService.findLesson(id);
      if (isLive(lesson)) {
        AnalyticsService.logEvent('dequeue_live', {
          lesson_id: String(id),
        });
      } else {
        AnalyticsService.logEvent('dequeue_lesson', {
          lesson_id: String(id),
        });
      }
    },
  });
}

export async function checkIn(id) {
  const lesson = await DataService.findLesson(id);
  if (!lesson) {
    return;
  }

  // TODO [web migration] só verificamos o GPS se tivermos acesso a ele nativamente
  const isNative = Platform.select({ native: true });

  if (!isLive(lesson) && isNative) {
    const hasPermission = await GeolocationService.hasPermission();
    if (!hasPermission) {
      AlertService.show({
        title: 'areYouCloseToYourClass',
        message: 'modalCheckinContent',
        confirmText: 'activateMyLocation',
        dismissText: 'IAmOnTheWay',
        onConfirm: async () => {
          const success = await GeolocationService.requestPermission();
          if (success) {
            checkIn(id);
          }
        },
      });
      return;
    }

    const position = await GeolocationService.getCurrentPosition();
    if (!position) {
      // se não retornar uma posição, não precisa dar feedback visual
      // pois o próprio GeolocationService.getCurrentPosition() já resolve
      return;
    }

    const place = await DataService.findPlace(lesson.place);
    if (!nearOfLesson(place, position)) {
      AlertService.show({
        title: 'areYouCloseToYourClass',
        message: 'modalCheckinContent',
        confirmText: 'IAmOnTheWay',
      });
      return;
    }
  }

  try {
    await Api.checkin(id);
  } catch (error) {
    resolveError(error, true);
    return false;
  }

  if (isLive(lesson)) {
    AnalyticsService.logEvent('checkin_live', {
      lesson_id: String(id),
    });
  } else {
    AnalyticsService.logEvent('checkin_lesson', {
      lesson_id: String(id),
    });
  }
}

export async function watch(id) {
  const lesson = await DataService.findLesson(id);
  if (!lesson) {
    return;
  }

  const { user } = StoreService.getState();
  const enrollment = await DataService.findEnrollmentByLessonAndUser(
    id,
    user?.id,
  );
  if (!isSubscribed(enrollment)) {
    const success = await subscribe(id, true);
    if (!success) {
      return;
    }
  }

  NavigationService.navigate('LiveLesson', { id: lesson.id });

  if (!isChecked(enrollment)) {
    await checkIn(lesson.id);
  }
}

export async function addToCalendar(id) {
  const lesson = await DataService.findLesson(id);
  if (!lesson) {
    return;
  }

  let location;
  const place = await DataService.findPlace(lesson.place);
  if (place) {
    if (lesson.is_virtual) {
      location = place.name;
    } else {
      const neighborhood = await DataService.findNeighborhood(
        place.neighborhood,
      );
      const city = await DataService.findCity(place.city);
      location = [place.name, place.address, neighborhood?.name, city?.name]
        .filter(i => i)
        .join(' - ');
    }
  }

  const formatDate = date => moment(date).toISOString();

  const url = `https://app.mude.fit/aula/${id}`;

  try {
    await AddCalendarEvent.presentEventCreatingDialog({
      title: lesson.name,
      startDate: formatDate(lesson.start_datetime),
      endDate: formatDate(lesson.end_datetime),
      location,
      url,
      notes: Platform.OS === 'ios' ? '' : url,
      navigationBarIOS: {
        tintColor: '#eb0045',
      },
    });
  } catch (error) {
    if (error === 'permissionNotGranted') {
      AlertService.show({
        title: 'calendarAccessTitle',
        message: 'calendarAccessText',
        confirmText: 'allow',
        dismissText: 'dontAllow',
        onConfirm: () => {
          Permissions.openSettings();
        },
        onDismiss: () => {
          AlertService.hide();
        },
      });
    } else {
      SentryService.log(error);
    }
  }
}

///// Helpers /////

function _showLoginAlert() {
  ModalService.show(<LoginToContinueModal />);
}

async function _validateUserToLesson(user, lesson) {
  if (!AuthService.isLogged) {
    _showLoginAlert();
    return false;
  }

  if (isLive(lesson)) {
    return true;
  }

  const { emergencyContacts } = StoreService.getState();

  const flow = [];
  if (!personalDataComplete(user)) {
    flow.push(STEP_PERSONAL_DATA);
  }
  if (!emergencyComplete(emergencyContacts)) {
    flow.push(STEP_EMERGENCY_CONTACT);
  }
  if (!deficiencyComplete(user)) {
    flow.push(STEP_DEFICIENCY);
  }
  const lastCovidSubmit = await DataService.getLocal(COVID_FORM_KEY);
  const covidExpired = lastCovidSubmit
    ? moment().diff(moment(lastCovidSubmit), 'minutes') > 10
    : true;
  if (covidExpired) {
    flow.push(STEP_COVID);
  }
  if (
    needAnamnesis(lesson) &&
    (anamnesisEmpty(user) || anamnesisExpired(user))
  ) {
    flow.push(STEP_ANAMNESIS);
  }

  if (flow.length > 0) {
    let alertProps = {
      title: 'completeRegistrationRequiredTitle',
      message: 'completeRegistrationContent',
      confirmText: 'completeNow',
      dismissText: 'doItLater',
      onConfirm: () =>
        NavigationService.navigate('CompleteDataModal', { flow }),
    };
    if (flow[0] === STEP_ANAMNESIS && anamnesisEmpty(user)) {
      alertProps = {
        ...alertProps,
        title: 'modalAnamnesisTitle',
        message: 'modalAnamnesisContent',
        confirmText: 'answerNow',
      };
    } else if (flow[0] === STEP_ANAMNESIS && anamnesisExpired(user)) {
      alertProps = {
        ...alertProps,
        title: 'modalAnamnesisExpiredTitle',
        message: 'modalAnamnesisExpiredContent',
        confirmText: 'answerNow',
      };
    }
    AlertService.show(alertProps);
    return false;
  }
  return true;
}
