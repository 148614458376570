import React from 'react';
import Svg, { Path } from 'react-native-svg';

export default function CheckInIcon(props) {
  return (
    <Svg width={15} height={19} viewBox="0 0 15 19" {...props}>
      <Path
        fill={props.color}
        d="M7.5.6c3.9 0 7 3.2 7 7 0 3.9-4.1 8.3-7 10.8-3-2.5-7-7-7-10.8s3.1-7 7-7zm2.9 4.6L6.6 9l-2-2-1.2 1.2L6 10.9c.2.2.4.3.6.3.2 0 .5-.1.6-.3l4.4-4.4-1.2-1.3z"
      />
    </Svg>
  );
}
