import React from 'react';
import { StyleSheet, Text, TouchableHighlight, View } from 'react-native';
import Theme from '../libs/Theme';
import I18n from './I18n';
import ForwardArrowIcon from '../icons/ForwardArrowIcon';

export default function LessonInfoButton({
  icon,
  title,
  titleContext,
  description,
  onPress,
}) {
  const IconComponent = icon;
  return (
    <TouchableHighlight
      onPress={onPress}
      underlayColor={Theme.color.veryLightGray}
      style={styles.container}
    >
      <>
        <View style={styles.icon}>
          <IconComponent color={Theme.color.white} width={16} height={16} />
        </View>
        <View style={styles.titleAndDescription}>
          <Text style={styles.title}>
            <I18n params={{ context: titleContext }}>{title}</I18n>
          </Text>
          <Text style={styles.description}>
            <I18n>{description}</I18n>
          </Text>
        </View>
        <ForwardArrowIcon color={Theme.color.magenta} width={16} height={16} />
      </>
    </TouchableHighlight>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: Theme.color.white,
    borderRadius: 10,
    padding: 20,
    flexDirection: 'row',
    alignItems: 'center',
  },
  icon: {
    backgroundColor: Theme.color.magenta,
    borderRadius: 4,
    width: 32,
    height: 32,
    justifyContent: 'center',
    alignItems: 'center',
  },
  titleAndDescription: {
    flexGrow: 1,
    flexShrink: 1,
    marginHorizontal: 10,
  },
  title: {
    color: Theme.color.magenta,
    fontFamily: Theme.fonts.barlowSemiCondensedBold,
    fontSize: 12,
    letterSpacing: 0.3,
    textTransform: 'uppercase',
  },
  description: {
    color: Theme.color.veryDarkGray,
    fontFamily: Theme.fonts.barlowSemiCondensed,
    fontSize: 14,
  },
});
