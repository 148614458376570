import moment from 'moment-timezone';
import _ from 'lodash';

export const MAX_VISIBLE_DAYS = 7;
export const MAX_VISIBLE_LESSONS = 100;

function notPassedYet(lesson) {
  const now = moment();
  const start = moment(lesson.end_datetime);
  const willStartAt = start.diff(now, 'minutes');
  return willStartAt > 0;
}

export function filterPastLessons(lessons) {
  return lessons.filter(notPassedYet);
}

export function sortByLessonDate(a, b) {
  return new Date(a.start_datetime) - new Date(b.start_datetime);
}

export function getCalendarLessons(lessons) {
  // TODO criar um cache/memo
  const calendar = [];

  const sortedLessons = lessons.filter(l => l?.id).sort(sortByLessonDate);
  const visibleLessons = sortedLessons.slice(0, MAX_VISIBLE_LESSONS - 1);
  const lessonsByDate = _.groupBy(visibleLessons, l =>
    moment(l.start_datetime).format('YYYY-MM-DD'),
  );

  Object.keys(lessonsByDate)
    .sort()
    .slice(0, MAX_VISIBLE_DAYS)
    .forEach(date => {
      calendar.push({
        date,
        lessons: lessonsByDate[date],
      });
    });

  return calendar;
}

export function getRatingEmoji(rating) {
  return (
    {
      1: '😭',
      2: '☹️',
      3: '😐',
      4: '🙂',
      5: '😁',
    }[rating] || ''
  );
}

export function getRatingLabel(rating) {
  return (
    {
      1: 'ratingVeryBad',
      2: 'ratingBad',
      3: 'ratingOk',
      4: 'ratingGood',
      5: 'ratingVeryGood',
    }[rating] || ''
  );
}
