import StoreService from '../libs/StoreService';

const SET_TRANSACTIONS = 'TRANSACTIONS/SET_TRANSACTIONS';

export function setTransactions(transactions) {
  StoreService.dispatch({ type: SET_TRANSACTIONS, transactions });
}

export default function reducer(state = [], action) {
  switch (action.type) {
    case SET_TRANSACTIONS: {
      return [...action.transactions];
    }

    default:
      return state;
  }
}
