import React, { useState, useCallback } from 'react';
import { StyleSheet, TouchableHighlight } from 'react-native';
import Theme, { opacity } from '../libs/Theme';
import useTimeout from '../hooks/useTimeout';
import LoadingIcon from '../icons/LoadingIcon';
import SuccessIcon from '../icons/SuccessIcon';

const DEFAULT = 'default';
const LOADING = 'loading';
const SUCCESS = 'success';

export default function AsyncButton({
  theme,
  style,
  onPress,
  showSuccessIcon,
  children,
}) {
  const [status, setStatus] = useState(DEFAULT);

  const timeout = useTimeout();

  const _onPress = useCallback(
    async e => {
      if (!onPress) {
        return;
      }

      setStatus(LOADING);

      try {
        await onPress(e);
        if (showSuccessIcon) {
          setStatus(SUCCESS);
          timeout(() => setStatus(DEFAULT), 2000);
        } else {
          setStatus(DEFAULT);
        }
      } catch (error) {
        setStatus(DEFAULT);
        throw error;
      }
    },
    [onPress, showSuccessIcon, timeout],
  );

  let backgroundColor, underlayColor, color;
  switch (theme) {
    case 'white':
      backgroundColor = Theme.color.white;
      underlayColor = Theme.color.veryLightGray;
      color = Theme.color.magenta;
      break;

    case 'shadow':
      backgroundColor = opacity('black', 0.4);
      underlayColor = opacity('black', 0.6);
      color = Theme.color.white;
      break;

    default:
      backgroundColor = Theme.color.magenta;
      underlayColor = Theme.color.darkMagenta;
      color = Theme.color.white;
      break;
  }

  return (
    <TouchableHighlight
      onPress={_onPress}
      disabled={!onPress || status !== DEFAULT}
      underlayColor={underlayColor}
      style={[styles.container, { backgroundColor }, style]}
    >
      {status === LOADING ? (
        <LoadingIcon color={color} />
      ) : status === SUCCESS ? (
        <SuccessIcon color={color} />
      ) : (
        children
      )}
    </TouchableHighlight>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: Theme.color.magenta,
    justifyContent: 'center',
    alignItems: 'center',
  },
});
