import React from 'react';
import Svg, { Path } from 'react-native-svg';

export default function CheckIcon(props) {
  return (
    <Svg width={22} height={22} viewBox="0 0 22 22" {...props}>
      <Path
        fill={props.color}
        d="M18.8 2.7L8.3 13.1 3 7.8l-3 3 8.5 8.5L22 5.6l-3.2-2.9z"
      />
    </Svg>
  );
}
