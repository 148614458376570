import StoreService from '../libs/StoreService';

const SET_ANAMNESIS = 'ANAMNESIS/SET_ANAMNESIS';
const DELETE_ANAMNESIS = 'ANAMNESIS/DELETE_ANAMNESIS';

export function setAnamnesis(anamnesis) {
  StoreService.dispatch({ type: SET_ANAMNESIS, anamnesis });
}

export function deleteAnamnesis() {
  StoreService.dispatch({ type: DELETE_ANAMNESIS });
}

export default function reducer(state = [], action) {
  switch (action.type) {
    case SET_ANAMNESIS: {
      return [...action.anamnesis];
    }

    case DELETE_ANAMNESIS: {
      return [];
    }

    default:
      return state;
  }
}
