import React from 'react';
import { StyleSheet } from 'react-native';
import Theme from '../libs/Theme';
import ModalScrollView from '../components/ModalScrollView';
import ForgotPasswordForm from '../components/ForgotPasswordForm';

export default function ForgotPasswordModalScreen() {
  return (
    <ModalScrollView
      title="forgotPasswordQuestion"
      contentContainerStyle={styles.container}
    >
      <ForgotPasswordForm />
    </ModalScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    paddingTop: 20,
    paddingBottom: 20 + Theme.dimensions.bottomSpace,
  },
});
