import React, { useCallback } from 'react';
import { StyleSheet, Text } from 'react-native';
import NavigationService from '../libs/NavigationService';
import Theme from '../libs/Theme';

export default function TextLink(props) {
  const { style, url, highlight, onPress } = props;
  const _onPress = useCallback(() => {
    NavigationService.openUrl(url);
  }, [url]);

  return (
    <Text
      {...props}
      style={[styles.text, highlight && styles.highlight, style]}
      onPress={onPress || _onPress}
    />
  );
}

const styles = StyleSheet.create({
  text: {
    textDecorationLine: 'underline',
  },
  highlight: {
    color: Theme.color.magenta,
  },
});
