import React from 'react';
import Svg, { Path } from 'react-native-svg';
import Theme from '../libs/Theme';

export default class ForwardArrowIcon extends React.PureComponent {
  static defaultProps = {
    color: Theme.color.white,
  };

  render() {
    return (
      <Svg width={10} height={17} viewBox="0 0 10 17" {...this.props}>
        <Path
          fill="none"
          stroke={this.props.color}
          strokeWidth={2}
          d="M0.9,1.1l7.3,7.4l-7.3,7.4"
        />
      </Svg>
    );
  }
}
