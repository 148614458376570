import React, { useCallback } from 'react';
import urlParse from 'url-parse';
import qs from 'qs';
import styles from './LivePlayerScreen.module.scss';

const NETSHOW = 'n';
const LIVESTREAM = 'l';
const VIMEO = 'v';

export default function LivePlayerScreen() {
  const urlObj = urlParse(window.location.href);
  const query = qs.parse(urlObj.query, { ignoreQueryPrefix: true });

  const id = query.id;
  const provider = query.provider;
  const name = query.name || 'Anônimo';
  const email = query.email || 'anonimo@example.com';
  const autoplay = query.autoplay !== 'false' && query.autoplay !== '0';

  const onLoad = useCallback(() => {
    if (!autoplay) {
      alert(
        'Modo Pouca Energia\n\nClique no ▶️ PLAY para começar\n\nSe tiver problemas na reprodução, desligue o Modo Pouca Energia',
      );
    }
  }, [autoplay]);

  if (!provider || !id) {
    return <div className={styles.error}>Não encontrado</div>;
  }

  const src =
    provider === NETSHOW
      ? `https://embed-videos.netshow.me/s/${id}/player?nsm_eb_email=${email}&nsm_eb_name=${name}`
      : provider === LIVESTREAM
      ? `https://livestream.com/${id}/player?width=640&height=360&enableInfoAndActivity=true&defaultDrawer=&autoPlay=true&mute=false`
      : provider === VIMEO
      ? `https://player.vimeo.com/video/${id}?autoplay=${autoplay}`
      : null;

  return (
    <>
      <div className={styles.fullscreen} />
      {provider === NETSHOW && (
        <script
          type="text/javascript"
          src="https://static.netshow.me/integration/embed.js"
        />
      )}
      <iframe
        id="live_player"
        title="Aula ao vivo"
        src={src}
        width="100%"
        height="100%"
        allow="autoplay"
        allowFullScreen="no"
        frameBorder="0"
        scrolling="no"
        className={styles.fullscreen}
        onLoad={onLoad}
      />
      {provider === LIVESTREAM && (
        <script
          type="text/javascript"
          data-embed_id="live_player"
          src="https://livestream.com/assets/plugins/referrer_tracking.js"
        />
      )}
    </>
  );
}
