import React, { useCallback } from 'react';
import {
  ScrollView,
  StyleSheet,
  Text,
  TouchableWithoutFeedback,
  View,
} from 'react-native';
import Theme from '../libs/Theme';
import I18n from './I18n';

function HorizontalMenu_Item({ item, selected, data, onPress }) {
  const _onPress = useCallback(() => {
    onPress?.(data);
  }, [data, onPress]);

  return (
    <TouchableWithoutFeedback onPress={_onPress}>
      <View style={[styles.button, selected && styles.selected]}>
        <Text style={[styles.text, selected && styles.selectedText]}>
          <I18n>{item}</I18n>
        </Text>
      </View>
    </TouchableWithoutFeedback>
  );
}

export default function HorizontalMenu({ items, selected, onChange }) {
  return (
    <ScrollView
      style={styles.container}
      contentContainerStyle={styles.contentContainer}
      horizontal
      showsHorizontalScrollIndicator={false}
      bounces={false}
    >
      {items?.map((item, i) => (
        <HorizontalMenu_Item
          key={i}
          item={item}
          selected={selected === i}
          data={i}
          onPress={onChange}
        />
      ))}
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    minHeight: 47,
    maxHeight: 47,
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderColor: Theme.color.veryLightGray,
  },
  contentContainer: {
    flexDirection: 'row',
    paddingHorizontal: 20,
  },
  button: {
    marginRight: 20,
  },
  text: {
    color: Theme.color.gray,
    fontFamily: Theme.fonts.barlowBold,
    fontSize: 12,
    textTransform: 'uppercase',
    lineHeight: 45,
    height: 45,
  },
  selected: {
    borderBottomWidth: 2,
    borderColor: Theme.color.magenta,
  },
  selectedText: {
    color: Theme.color.magenta,
  },
});
