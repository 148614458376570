import React from 'react';
import Svg, { Path } from 'react-native-svg';

export default function CouchIcon(props) {
  return (
    <Svg width={59} height={33} viewBox="0 0 59 33" {...props}>
      <Path
        fill={props.color}
        d="M55.8 9.1h-1.2V4.7c0-2.6-2.1-4.6-4.6-4.6H33.1c-1.6 0-2.9.8-3.8 2-.8-1.2-2.2-2-3.8-2H15c-.5 0-.9.4-.9.9s.4.9.9.9h10.6c1.6 0 2.9 1.3 2.9 2.9v8.6l-.1-.1c-1-.9-2.4-1.5-3.8-1.5H10.3c-.1-1.2-.9-2.2-1.9-2.6-.3-.1-.6-.2-1-.2H5.8V4.7c0-1.6 1.3-2.9 2.9-2.9h2.8c.5 0 .9-.4.9-.9s-.4-.8-.9-.8H8.7C6.1.1 4.1 2.2 4.1 4.7V9H2.9C1.3 9.1 0 10.4 0 12v9c0 3.3 2.7 6 6.1 6h.4l-.9 2.5c-.6 1.6.6 3.4 2.3 3.4h1.4c.8 0 1.5-.4 2-1L15 27h28.8l3.7 4.9c.5.6 1.2 1 2 1h1.4c1.7 0 2.9-1.8 2.3-3.4l-1-2.5h.4c3.3 0 6.1-2.7 6.1-6.1v-9c0-1.5-1.3-2.8-2.9-2.8zM30.2 4.7c0-1.6 1.3-2.9 2.9-2.9h16.8c1.6 0 2.9 1.3 2.9 2.9V9h-1.6c-1.1 0-2.2.7-2.7 1.8-.1.3-.2.6-.2.9H34c-1.4 0-2.8.6-3.8 1.5V4.7zm-19.9 8.8h14.3c2.1 0 3.8 1.7 3.8 3.8v1.2H10.3v-5zm-.4 17.4c-.1.2-.4.3-.6.3H7.9c-.5 0-.9-.5-.7-1L8.3 27h4.5l-2.9 3.9zm40.9.3h-1.4c-.2 0-.5-.1-.6-.3L45.9 27h4.5l1.2 3.1c.1.5-.3 1.1-.8 1.1zm1.8-5.9H6.1c-2.4 0-4.3-1.9-4.3-4.3v-9c0-.7.5-1.2 1.2-1.2h4.5c.7 0 1.2.5 1.2 1.2v7.5c0 .5.4.9.9.9H41c.5 0 .9-.4.9-.9s-.4-.9-.9-.9H30.2v-1.2c0-2.1 1.7-3.8 3.8-3.8h14.3v5.1h-3.9c-.5 0-.9.4-.9.9s.4.9.9.9h4.7c.5 0 .9-.4.9-.9V12c0-.7.5-1.2 1.2-1.2h4.5c.7 0 1.2.5 1.2 1.2v9c0 2.4-1.9 4.3-4.3 4.3z"
      />
    </Svg>
  );
}
