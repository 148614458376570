import React, { useCallback, useEffect } from 'react';
import { StyleSheet } from 'react-native';
import AlertService from '../libs/AlertService';
import Api from '../libs/Api';
import { resolveError } from '../libs/FormUtils';
import SentryService from '../libs/SentryService';
import Theme from '../libs/Theme';
import FacebookIcon from '../icons/FacebookIcon';
import ActionButton from './ActionButton';

export default function FacebookButton({ onSuccess }) {
  useEffect(() => {
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: '2269834249977491',
        cookie: true,
        xfbml: true,
        version: 'v8.0',
      });

      window.FB.AppEvents.logPageView();
    };

    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = 'https://connect.facebook.net/en_US/sdk.js';
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');
  }, []);

  const onPress = useCallback(async () => {
    return new Promise((resolve, reject) => {
      window.FB.login(response => {
        if (response.authResponse) {
          Api.facebookLogin(response.authResponse.accessToken)
            .then(() => {
              onSuccess();
              resolve();
            })
            .catch(error => {
              AlertService.show({ message: resolveError(error) });
              SentryService.log(error);
              reject();
            });
        } else {
          resolve();
        }
      });
    });
  }, [onSuccess]);

  return (
    <ActionButton
      icon={FacebookIcon}
      title="enter"
      theme="whiteWithBorder"
      onPress={onPress}
      style={styles.button}
      color={Theme.color.veryDarkGray}
    />
  );
}

const styles = StyleSheet.create({
  button: {
    margin: 5,
    flexShrink: 1,
    flexGrow: 1,
  },
});
