export default {
  exerciseLibraryEmpty: 'Exercícios ainda não cadastrados.',

  // Levels
  beginner: 'Iniciante',
  intermediate: 'Intermediário',
  advanced: 'Avançado',

  // Library
  library: 'Biblioteca',

  // Exercise
  exerciseNotAvailable: 'Exercício não disponível.',
  videoNotAvailable: 'Vídeo não disponível.',

  // ExerciseInstructionsScreen
  preparation: 'Preparação', // backend
  execution: 'Execução', // backend
  attention: 'Atenção', // backend

  // Collection
  collectionNotAvailable: 'Coleção não disponível.',
  aboutTheCollection: 'Sobre a coleção',
  chooseAWorkout: 'Escolha um treino',
  exercisesLibrary: 'Biblioteca completa',
  exercisesLibraryDetail: 'Veja tudo que preparamos pra você',
  exercisesLibraryOf: 'Biblioteca \n{{name}}',
  collectionsSponsor: 'Patrocinador desta coleção',
  exercise: 'exercício',
  exercise_plural: 'exercícios',
  workout: 'treino',
  workouts: 'treinos',
  workout_plural: 'treinos',
  totalWorkouts: '{{count}} treino',
  totalWorkouts_plural: '{{count}} treinos',
  collection: 'coleção',
  allLevels: 'Todos os níveis',
  by: 'por',

  // Workout
  start: 'Começar',
  start_practice: 'Começar prática',
  start_workout: 'Começar treino',
  equipments: 'Equipamentos',
  withoutEquipment: 'Sem equipamento',
  whereToDo: 'Onde fazer?',
  anyPlace: 'Qualquer local',
  goodFor: 'Bom para',
  level: 'Nível',
  createdBy: 'Criado por',
  createdBy_practice: 'Prática criada por',
  createdBy_workout: 'Treino criado por',
  seconds: 'segundos',
  repetitions: 'repetições',
  mediaDownloading: 'Carregando {{count}} de {{total}} exercícios',
  mediaDownloadReady:
    'Treino pronto! Aperte a \nseta quando estiver preparado.',
  getReady: 'Prepare-se!',
  go: 'Vai!',
  serieCounter: 'Série {{count}} de {{total}}',
  stepCounter: '{{count}} de {{total}}',
  crono: 'Crono',
  volume: 'Volume',
  loadWeight: 'Carga / Peso',
  instructions: 'Instruções',
  workoutPaused: 'Treino pausado.',
  continueWorkout: 'Continuar meu treino!',
  exitWorkout: 'Sair do treino',
  resetWorkout: 'Reiniciar o treino',
  takeARest: 'Descanse',
  regainYourEnergies: 'Recupere \nsuas energias',
  restingIsPartOfTraining: 'Descansar faz \nparte do treino',
  workoutDone: 'Treino feito!',
  playTime: 'Treino',
  pauseTime: 'Pausas',
  totalCrono: 'Crono Total',
  saveWorkout: 'Salvar treino?',
  chooseYourLoad: 'Escolha sua carga',
  loadTooltipTitle: 'Anote sua carga',
  loadTooltipText: 'Isto permite acompanhar sua evolução nos treinos.',
  loadTooltipButtonText: 'Ok, entendi',
  myList: 'Minha lista',

  // Profile
  continue: 'continuar',
  workoutInProgress: 'Treino em andamento',
  youHaventFinishedAnyWorkoutYet: 'Você não terminou nenhum treino :(',

  // Settings
  videoQuality: 'Qualidade do vídeo',
};
