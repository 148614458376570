class StoreService {
  setStore = store => {
    this.store = store;
  };

  getStore = () => {
    return this.store;
  };

  getState = () => {
    if (this.store && this.store.getState) {
      return this.store.getState();
    }
    return {};
  };

  dispatch = action => {
    if (this.store) {
      this.store.dispatch(action);
    }
  };
}

export default new StoreService();
