import React, { useCallback } from 'react';
import { StyleSheet, View } from 'react-native';
import NavigationService from '../libs/NavigationService';
import Theme from '../libs/Theme';
import SplashImage from '../components/SplashImage';
import LoginButtons from '../components/LoginButtons';

export default function FrontDoorScreen() {
  const onSuccess = useCallback(() => {
    NavigationService.reset('Tab');
  }, []);

  return (
    <View style={styles.container}>
      <SplashImage />
      <View style={styles.footer}>
        <LoginButtons onSuccess={onSuccess} />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  footer: {
    position: 'absolute',
    width: '100%',
    bottom: Theme.dimensions.bottomSpace,
  },
});
