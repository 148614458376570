import React from 'react';
import Svg, { Path } from 'react-native-svg';

export default function BookedIcon(props) {
  return (
    <Svg width={22} height={22} viewBox="0 0 22 22" {...props}>
      <Path
        fill={props.color}
        d="M11 0c1.3 0 2.4.8 2.8 2H18c1.1 0 2 .9 2 2v16c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V4c0-1.1.9-2 2-2h4.2C8.6.8 9.7 0 11 0zM6 4H4v16h14V4h-2v3H6V4zm9.1 5l1.5 1.4-6.5 6.6L6 12.9l1.5-1.5L10 14l5.1-5zM11 2c-.6 0-1 .4-1 1s.4 1 1 1 1-.4 1-1-.4-1-1-1z"
      />
    </Svg>
  );
}
