import { Model, Relation } from '@nozbe/watermelondb';
import { field, relation } from '@nozbe/watermelondb/decorators';
import { Associations } from '@nozbe/watermelondb/Model';
import Customer from './Customer';

export default class Address extends Model {
  static table = 'addresses';

  static associations: Associations = {
    customers: { type: 'belongs_to', key: 'customer' },
  };

  @relation('customers', 'customer') customer_obj!: Relation<Customer>;

  @field('name') name!: string;
  @field('country') country!: string;
  @field('postcode') postcode!: string;
  @field('street_name') street_name!: string;
  @field('street_number') street_number!: string;
  @field('street_complement') street_complement!: string;
  @field('state') state!: string;
  @field('city') city!: string;
  @field('neighborhood') neighborhood!: string;
  @field('customer') customer!: string;
}
