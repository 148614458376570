import React from 'react';
import {
  Platform,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import { connect } from 'react-redux';
import isEqual from 'react-fast-compare';
import i18n from 'i18next';
import Api from '../libs/Api';
import Theme from '../libs/Theme';
import { ufList, getCountryList } from '../libs/SelectInputData';
import { filterObjectEmptyValues, RECIFE } from '../libs/Utils';
import { validateCpf } from '../libs/FormUtils';
import AlertService from '../libs/AlertService';
import I18n from './I18n';
import FormLayout from './FormLayout';
import TextInput from './TextInput';
import DateInput from './DateInput';
import SelectInput from './SelectInput';
import ActionButton from './ActionButton';
import SwitchInput from './SwitchInput';

class PersonalDataForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      user: this.props.user,
      address: this.prepareAddress(this.props.address),
      showErrors: false,
      errors: {},
      error: '',
      disablepostcode: false,
    };
  }

  async componentDidMount() {
    if (!this.isBrazilian()) {
      const address = this.state.address;
      address.council = address.state;
      address.state = '';
      this.setState({ address });
    }

    try {
      await Api.getUserAddress();
      await Api.getMe();
    } catch (error) {}

    // hack para só mostrar os erros depois do render terminar
    // como o componente foi criado num emaranhado de nós, dispara-se o evento onChange no fluxo
    // dos primeiros renders e faz uma primeira validação desnecessário, resultando no visual ruim
    // esse timer bloqueia isso temporariamente até arrumarmos o componente
    this.showErrorsTimeoutId = setTimeout(() => {
      this.setState({ showErrors: true });
    }, 1000);
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps, this.props)) {
      this.setState({
        user: this.props.user,
        address: this.prepareAddress(this.props.address),
      });
    }
  }

  prepareAddress = address => {
    if (!address) {
      address = {};
    }
    if (!address.country) {
      address.country = 'BR';
    }
    return address;
  };

  showWhyWeNeedData = () => {
    AlertService.show({
      title: 'whyWeNeedData',
      children: (
        <Text style={styles.whyModalText}>
          <I18n i18nKey="modalWhyDataContent">
            <Text style={styles.whyModalTextBold} />
          </I18n>
        </Text>
      ),
    });
  };

  onChangeInput = (value, name) => {
    const user = { ...this.state.user };

    user[name] = value;

    this.setState({ user }, () => {
      this.validateFields(name);
    });
  };

  onChangeAddress = (value, name) => {
    const { address } = this.state;
    if (name === 'postcode' && value.length === 9) {
      this.autocomplete(value);
    }
    address[name] = value;
    this.setState({ address });
    this.validateFields(name);
  };

  togglepostcode = value => {
    this.setState({ disablepostcode: value });
  };

  autocomplete = async postcode => {
    const { address, errors } = this.state;
    await Api.getAddressByZip(postcode).then(data => {
      address.street_name = data.logradouro;
      address.city = data.localidade;
      address.state = data.uf;
      address.neighborhood = data.bairro;

      errors.street_name = '';
      errors.city = '';
      errors.state = '';
      errors.neighborhood = '';
    });
    this.setState({ address, errors });
  };

  getPhoneMask = () => {
    switch (this.state.user.phone_country_code) {
      case '+55':
      case '55':
        return 'tel';

      default:
        return '';
    }
  };

  isBrazilian = () =>
    !this.state.address ||
    !this.state.address.country ||
    this.state.address.country === 'BR';

  isRequiredField = field => this.getRequiredUserFields().includes(field);

  getRequiredUserFields = () => {
    const fields = ['nickname', 'phone_country_code', 'phone'];
    if (this.isBrazilian()) {
      fields.push('cpf');
    } else {
      fields.push('passport');
    }
    if (this.props.user.settings.default_city === RECIFE) {
      fields.push('birth_date', 'gender');
    }
    return fields;
  };

  getRequiredAddressFields = isBrazilian =>
    ['country', 'state', 'city']
      .concat(isBrazilian ? ['neighborhood'] : [])
      .concat(isBrazilian && !this.state.disablepostcode ? ['postcode'] : [])
      .concat(!isBrazilian ? ['council'] : []);

  validate = () => {
    const userFields = [
      'birth_date',
      'cpf',
      'passport',
      'first_name',
      'gender',
      'last_name',
      'nickname',
      'phone',
      'phone_country_code',
    ];
    const addressFields = ['country', 'neighborhood', 'postcode'];
    if (this.isBrazilian()) {
      addressFields.push('state');
      addressFields.push('city');
    } else {
      addressFields.push('council');
    }

    const userValid = userFields
      .map(el => this.validateFields(el))
      .filter(el => !el);
    const addressValid = addressFields
      .map(el => this.validateFields(el))
      .filter(el => !el);

    return userValid.length === 0 && addressValid.length === 0;
  };

  validateRequiredFields = field => {
    const requiredUserFields = this.getRequiredUserFields();
    const errorMessage = 'fieldRequired';
    if (requiredUserFields.includes(field) && !this.state.user[field]) {
      this.setError(field, errorMessage);
      return false;
    }

    return true;
  };

  validateFields = field => {
    const { user } = this.state;

    if (!this.validateRequiredFields(field)) {
      return false;
    }

    if (this.isBrazilian()) {
      if (field === 'cpf' && user.cpf && !validateCpf(user.cpf)) {
        this.setError(field, 'invalidCpf');
        return false;
      }
    }

    this.setError(field, null);
    this.clearError();
    return true;
  };

  validateHeight = height => {
    if (height) {
      const newHeight = String(height).replace(/,/g, '.');
      if (!isNaN(Number(newHeight)) && height) {
        return true;
      }
    }
    return false;
  };

  clearError = () => {
    this.setState({ error: '' });
  };

  setError = (field, error) => {
    if (this.state.showErrors) {
      this.setState(previousState => ({
        errors: { ...previousState.errors, [field]: error },
        error: 'invalid_request',
      }));
    }
  };

  save = async () => {
    const { user, address } = this.state;
    if (!this.validate()) {
      return false;
    }

    const filteredUserObj = filterObjectEmptyValues(user);
    const newUser = {
      height: null,
      weight: null,
      birth_date: null,
      ...filteredUserObj,
      first_name: user.first_name,
      last_name: user.last_name,
      is_foreigner: user.is_foreigner,
    };
    newUser.state = this.isBrazilian() ? newUser.state : newUser.council;

    // atualmente o sistema impede de atualizar um cadastro que o cpf já exista
    // é um bloqueio para evitar contas com cpf duplicados e a consequência é que
    // não podemos atualizar outro dado em função disso. Então caso o cpf não tenha
    // sido alterado, não enviamos ele para não ficar nesta trava
    if (newUser.cpf === this.props.user.cpf) {
      delete newUser.cpf;
    }

    // propriedade q não é alterada e quebra na api, por isso temos q remover
    delete newUser.picture;

    try {
      await Api.updateMe(newUser);
      await Api.updateUserAddress(address);
      if (this.props.onSuccess) {
        this.props.onSuccess();
      }
    } catch (error) {
      if (error.response && error.response.data) {
        this.setState({
          errors: { ...this.state.errors, ...error.response.data },
        });
      } else {
        AlertService.show(error);
      }
    }
  };

  getFields = () => {
    const { language } = this.props;
    const { user, address } = this.state;

    const isNative = Platform.select({ isNative: true });

    return [
      <TextInput
        name="first_name"
        value={user.first_name || ''}
        error={this.state.errors.first_name}
        label="name"
        onChange={this.onChangeInput}
        required={this.isRequiredField('first_name')}
      />,
      <TextInput
        name="last_name"
        value={user.last_name}
        label="surname"
        onChange={this.onChangeInput}
        error={this.state.errors.last_name}
        required={this.isRequiredField('last_name')}
      />,
      isNative && (
        <TextInput
          name="nickname"
          value={user.nickname}
          label="nickname"
          onChange={this.onChangeInput}
          error={this.state.errors.nickname}
          required={this.isRequiredField('nickname')}
        />
      ),
      isNative && (
        <DateInput
          name="birth_date"
          value={user.birth_date}
          label="birthDate"
          onChange={this.onChangeInput}
          error={this.state.errors.birth_date}
          required={this.isRequiredField('birth_date')}
        />
      ),
      isNative && (
        <SelectInput
          name="gender"
          label="genderYouIdentify"
          items={[
            { value: '', label: '' },
            { value: 'F', label: i18n.t('female') },
            { value: 'M', label: i18n.t('male') },
            { value: 'O', label: i18n.t('others') },
            { value: 'N', label: i18n.t('notSpecified') },
          ]}
          onChange={this.onChangeInput}
          error={this.state.errors.gender}
          required={this.isRequiredField('gender')}
          value={user.gender || ''}
        />
      ),
      <View style={styles.row}>
        <View style={styles.countryCode}>
          <TextInput
            name="phone_country_code"
            value={user.phone_country_code}
            label="ddi"
            required={this.isRequiredField('phone_country_code')}
            onChange={this.onChangeInput}
            error={this.state.errors.phone_country_code}
          />
        </View>
        <View style={styles.phone}>
          <TextInput
            name="phone"
            value={user.phone}
            label="phone"
            required={this.isRequiredField('phone')}
            international={!this.isBrazilian()}
            onChange={this.onChangeInput}
            error={this.state.errors.phone}
          />
        </View>
      </View>,
      isNative && (
        <TextInput
          name="email"
          value={user.email}
          label="email"
          onChange={this.onChangeInput}
          disabled
        />
      ),
      isNative && (
        <View style={styles.row}>
          <View style={styles.height}>
            <TextInput
              label="heightM"
              name="height"
              value={user.height}
              onChange={this.onChangeInput}
              error={this.state.errors.height}
              required={this.isRequiredField('height')}
            />
          </View>
          <View style={styles.weight}>
            <TextInput
              label="weightKg"
              name="weight"
              value={user.weight}
              onChange={this.onChangeInput}
              error={this.state.errors.weight}
              required={this.isRequiredField('weight')}
            />
          </View>
        </View>
      ),
      isNative && (
        <SelectInput
          label="country"
          name="country"
          onChange={this.onChangeAddress}
          value={address.country}
          error={this.state.errors.country}
          required={this.isRequiredField('country')}
          items={getCountryList(language)}
        />
      ),
      isNative && !this.isBrazilian() && (
        <TextInput
          label="passport"
          name="passport"
          value={user.passport || ''}
          onChange={this.onChangeInput}
          error={this.state.errors.passport}
          required={this.isRequiredField('passport')}
        />
      ),
      this.isBrazilian() && (
        <TextInput
          label="cpf"
          name="cpf"
          value={user.cpf || ''}
          mask="cpf"
          onChange={this.onChangeInput}
          error={this.state.errors.cpf}
          required={this.isRequiredField('cpf')}
        />
      ),
      this.isBrazilian() && (
        <View style={styles.row}>
          <View style={styles.zipCode}>
            <TextInput
              label="zipcode"
              name="postcode"
              value={address.postcode || ''}
              onChange={this.onChangeAddress}
              error={this.state.errors.postcode}
              required={this.isRequiredField('postcode')}
            />
          </View>
          {isNative && (
            <View style={styles.switchZipcode}>
              <Text style={styles.switchText}>
                <I18n>dontKnowZipcode</I18n>
              </Text>
              <SwitchInput
                value={this.state.disablepostcode}
                onChange={this.togglepostcode}
              />
            </View>
          )}
        </View>
      ),
      isNative && this.isBrazilian() && (
        <SelectInput
          label="state"
          name="state"
          onChange={this.onChangeAddress}
          value={address.state}
          items={ufList.BR}
        />
      ),
      isNative && !this.isBrazilian() && (
        <TextInput
          label="state_consul"
          name="council"
          onChange={this.onChangeAddress}
          value={address.council}
        />
      ),
      isNative && this.isBrazilian() && (
        <TextInput
          label="city"
          name="city"
          onChange={this.onChangeAddress}
          value={address.city}
        />
      ),
      isNative && this.isBrazilian() && (
        <TextInput
          label="neighborhood"
          name="neighborhood"
          onChange={this.onChangeAddress}
          value={address.neighborhood}
        />
      ),
    ];
  };

  getSubmitButton = () => (
    <ActionButton
      title={this.props.submitLabel || 'save'}
      theme="whiteWithBorder"
      onPress={this.save}
      useFeedbackIcon
    />
  );

  render() {
    const fields = this.getFields();
    const footer = this.getSubmitButton();

    const mapKeyError = {
      birth_date: 'birthDate',
      gender: 'genderYouIdentify',
      phone_country_code: 'ddi',
    };
    const error = Object.entries(this.state.errors)
      .map(([key, value]) =>
        value ? `[${i18n.t(mapKeyError[key] || key)}] ${i18n.t(value)}` : '',
      )
      .filter(e => e)
      .join('\n');

    return (
      <View style={styles.container}>
        <View style={styles.disclaimerArea}>
          <Text style={styles.disclaimerText}>
            <I18n>dataUseDisclaimer</I18n>
          </Text>
        </View>
        <TouchableOpacity activeOpacity={0.5} onPress={this.showWhyWeNeedData}>
          <Text style={styles.whyWeNeedData}>
            <I18n>whyWeNeedData</I18n>
          </Text>
        </TouchableOpacity>
        <FormLayout fields={fields} footer={footer} error={error} />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    paddingBottom: 60 + Theme.dimensions.bottomSpace,
  },
  disclaimerArea: {
    padding: 20,
    paddingBottom: 0,
  },
  disclaimerText: {
    lineHeight: 25,
    color: Theme.color.darkGray,
    fontFamily: Theme.fonts.barlow,
    fontSize: 16,
  },
  whyWeNeedData: {
    padding: 20,
    marginBottom: 10,
    color: Theme.color.magenta,
    fontFamily: Theme.fonts.barlowBold,
    fontSize: 12,
    lineHeight: 12,
    letterSpacing: 0.5,
    textTransform: 'uppercase',
  },
  whyModalText: {
    color: Theme.color.darkGray,
    fontFamily: Theme.fonts.barlow,
    fontSize: 16,
  },
  whyModalTextBold: {
    fontFamily: Theme.fonts.barlowBold,
  },
  row: {
    flexDirection: 'row',
  },
  footer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  height: {
    width: (Theme.dimensions.windowWidth - 60) / 2,
  },
  weight: {
    width: (Theme.dimensions.windowWidth - 60) / 2,
    marginLeft: 20,
  },
  switch: {
    transform: [{ scaleX: 0.8 }, { scaleY: 0.8 }],
    marginLeft: '15%',
  },
  zipCode: {
    width: (Theme.dimensions.windowWidth - 60) / 2,
  },
  switchZipcode: {
    width: (Theme.dimensions.windowWidth - 60) / 2,
    marginLeft: 20,
    flexDirection: 'row',
    alignItems: 'center',
  },
  countryCode: {
    width: 50,
    marginRight: 10,
  },
  phone: {
    flexGrow: 1,
    flexShrink: 1,
  },
  switchText: {
    color: Theme.color.darkGray,
    fontFamily: Theme.fonts.barlow,
    fontSize: 12,
    lineHeight: 24,
    textTransform: 'uppercase',
  },
});

const mapStateToProps = ({ user, address, settings }) => ({
  user,
  address,
  language: settings.language,
});

export default connect(mapStateToProps)(PersonalDataForm);
