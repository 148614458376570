import React, { useCallback, useEffect, useRef, useState } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { formatStopwatch } from '../libs/DIYUtils';
import ActionButton from '../components/ActionButton';
import withNavParams from '../components/withNavParams';
import HeaderLogoAndProfile from '../components/HeaderLogoAndProfile';

function setMetadata(data) {
  if ('mediaSession' in navigator) {
    navigator.mediaSession.metadata = new window.MediaMetadata(data);
  }
}

function QrWorkoutScreen_Counter({ id, audioRef, navigation }) {
  const [time, setTime] = useState(formatStopwatch(0));

  useEffect(() => {
    const title = `Treino QR`;
    navigation.setOptions({ title });
    setMetadata({ title });

    const intervalId = setInterval(() => {
      const time = formatStopwatch(audioRef.current.currentTime || 0);
      setTime(time);
      setMetadata({ artist: time });
    }, 1000);

    return () => clearInterval(intervalId);
  }, [id, audioRef, navigation]);

  return <Text>{time}</Text>;
}

function QrWorkoutScreen({ id, navigation }) {
  const [play, setPlay] = useState(false);

  const audioRef = useRef(
    new Audio('https://harrison.com.br/temp/mude-treino-1.mp3'),
  );

  const onPlay = useCallback(() => {
    audioRef.current?.play();
  }, []);

  const onPause = useCallback(() => {
    audioRef.current?.pause();
  }, []);

  useEffect(() => {
    audioRef.current.addEventListener('play', () => setPlay(true));
    audioRef.current.addEventListener('pause', () => setPlay(false));
  }, []);

  return (
    <>
      <HeaderLogoAndProfile />
      <View style={styles.container}>
        <Text>Treino QR: {id}</Text>
        <Text> </Text>
        <QrWorkoutScreen_Counter
          id={id}
          audioRef={audioRef}
          navigation={navigation}
        />
        <Text> </Text>
        <ActionButton
          title={play ? 'Pause' : 'Play'}
          theme="magenta"
          onPress={play ? onPause : onPlay}
        />
      </View>
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 20,
    paddingBottom: 100,
  },
  entry: {
    marginVertical: 10,
  },
});

export default withNavParams(QrWorkoutScreen);
