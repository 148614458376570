import { useState, useEffect } from 'react';
import { resolveError } from '../libs/FormUtils';

export default function useSync(callback) {
  const [syncing, setSyncing] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    async function sync() {
      setSyncing(true);
      try {
        await callback();
      } catch (err) {
        setError(resolveError(err, false, '', false));
      }
      // espera um pouco antes de terminar o sync para evitar piscar a mensagem de not found
      setTimeout(() => setSyncing(false), 1000);
    }
    sync();
  }, [callback]);

  return { syncing, error };
}
