import React from 'react';
import { View, StyleSheet } from 'react-native';
import Api from '../libs/Api';
import { resolveError } from '../libs/FormUtils';
import Theme from '../libs/Theme';
import ActionButton from './ActionButton';
import FormLayout from './FormLayout';
import TextInput from './TextInput';

export default class LoginForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      error: '',
    };
  }

  onChangeInput = (value, name) => {
    this.setState({ [name]: value });
  };

  onSubmit = async () => {
    const { email, password } = this.state;

    this.setState({ error: '' });

    const user = {
      email,
      password,
    };

    try {
      await Api.login(user);
      this.props.onSuccess();
    } catch (error) {
      this.setState({ error: resolveError(error, false, 'email') });
      return false;
    }
  };

  render() {
    const fields = [
      <TextInput
        name="email"
        value={this.state.email}
        label="emailAddress"
        onChange={this.onChangeInput}
      />,
      <TextInput
        name="password"
        label="password"
        value={this.state.password}
        onChange={this.onChangeInput}
      />,
    ];

    const footer = (
      <View style={styles.footer}>
        <ActionButton
          title="forgotPassword"
          theme="white"
          onPress={this.props.onForgotPassword}
          style={styles.forgotPassword}
        />
        <ActionButton title="enter" theme="magenta" onPress={this.onSubmit} />
      </View>
    );

    return (
      <FormLayout
        header={this.props.header}
        content={this.props.content}
        fields={fields}
        footer={footer}
        error={this.state.error}
      />
    );
  }
}

const styles = StyleSheet.create({
  footer: {
    width: Theme.dimensions.windowWidth - 40,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  forgotPassword: {
    marginLeft: -20,
  },
});
