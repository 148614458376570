import React, { useCallback, useEffect, useState } from 'react';
import styles from './LessonSpotScreen.module.scss';
import AlertService from '../libs/AlertService';
import Api from '../libs/Api';
import AuthService from '../libs/AuthService';
import DataService, { withObservables } from '../libs/DataService';
import { resolveError } from '../libs/FormUtils';
import NavigationService from '../libs/NavigationService';
import useSync from '../hooks/useSync';
import BookedIcon from '../icons/BookedIcon';
import withNavParams from '../components/withNavParams';
import LayoutWithHeader from '../components/LayoutWithHeader';
import LessonActionBar from '../components/LessonActionBar';

const CELL_WIDTH = 60;
const CELL_HEIGHT = 48;
const CELL_MARGIN = 16;

function LessonSpotScreen_Button({ spot, className, disabled, onSelect }) {
  const spotId = spot.id;
  const onPress = useCallback(async () => {
    onSelect(spotId);
  }, [spotId, onSelect]);

  return (
    <button
      className={`${styles.spot} ${className}`}
      style={{
        left: spot.x * (CELL_WIDTH + CELL_MARGIN) + 20,
        top: spot.y * (CELL_HEIGHT + CELL_MARGIN),
      }}
      onClick={onPress}
      disabled={disabled}
    >
      {spot.id}
    </button>
  );
}

function parseJSON(json, errorValue) {
  try {
    return JSON.parse(json);
  } catch (error) {
    return errorValue;
  }
}

function LessonSpotScreen({ lesson, enrollment }) {
  const reservableSpots =
    typeof lesson.reservable_spots === 'string'
      ? parseJSON(lesson.reservable_spots, [])
      : Array.isArray(lesson.reservable_spots)
      ? lesson.reservable_spots
      : [];

  const availableSpots = lesson.available_spots;

  const [selectedSpot, setSelectedSpot] = useState(
    enrollment.reserved_spot || availableSpots[0],
  );

  const onSelectSpot = useCallback(value => {
    setSelectedSpot(value);
  }, []);

  const enrollmentId = enrollment.id;
  const onSubmit = useCallback(async () => {
    try {
      await DataService.saveLessonSpot(enrollmentId, selectedSpot);
      AlertService.show({
        title: `Vaga ${selectedSpot}`,
        message: `Sua vaga ${selectedSpot} foi reservada com sucesso.`,
        onConfirm: () => NavigationService.goBack(),
      });
    } catch (error) {
      resolveError(error, true);
    }
  }, [enrollmentId, selectedSpot]);

  const columns = Math.max(...reservableSpots.map(s => s.x)) + 1;
  const width = columns * (CELL_WIDTH + CELL_MARGIN) - CELL_MARGIN + 40;

  const rows = Math.max(...reservableSpots.map(s => s.y)) + 1;
  const height = rows * (CELL_HEIGHT + CELL_MARGIN) - CELL_MARGIN;

  return (
    <LayoutWithHeader>
      <div className={styles.container}>
        <div className={styles.title}>{lesson.name}</div>
        <div className={styles.gridWindow}>
          <div className={styles.gridContent} style={{ width, height }}>
            {reservableSpots.map(spot => {
              const isSelected = spot.id === selectedSpot;
              const isMySpot = spot.id === enrollment.reserved_spot;
              const isAvailable = availableSpots.includes(spot.id);
              return (
                <LessonSpotScreen_Button
                  key={spot.id}
                  spot={spot}
                  className={
                    isSelected
                      ? styles.selected
                      : isMySpot || isAvailable
                      ? styles.available
                      : styles.unavailable
                  }
                  disabled={!isMySpot && !isAvailable}
                  onSelect={onSelectSpot}
                />
              );
            })}
          </div>
        </div>
      </div>
      <LessonActionBar
        theme="magenta"
        actionText={`Reservar vaga ${selectedSpot}`}
        actionIcon={BookedIcon}
        onPressAction={onSubmit}
      />
    </LayoutWithHeader>
  );
}

function LessonSpotScreen_Sync(props) {
  const { id, lesson, enrollment } = props;

  const { syncing, error } = useSync(
    useCallback(() => {
      return DataService.syncLesson(id);
    }, [id]),
  );

  // TODO o isLogged deveria ser reativo
  const isLogged = AuthService.isLogged;
  useEffect(() => {
    if (isLogged) {
      Api.getMe().catch(() => {});
      DataService.syncEnrollments().catch(() => {});
    }
  }, [isLogged]);

  if (!lesson || !enrollment) {
    return (
      <LayoutWithHeader loading={syncing} error={error || 'lessonsNotFound'} />
    );
  }

  return <LessonSpotScreen {...props} />;
}

const enhance = withObservables(['id'], ({ id }) => ({
  lesson: DataService.observeLesson(id),
  enrollment: DataService.observeEnrollmentByLessonId(id),
}));

export default withNavParams(enhance(LessonSpotScreen_Sync));
