import { Model, Q, Query } from '@nozbe/watermelondb';
import { children, field, json, lazy } from '@nozbe/watermelondb/decorators';
import { Associations } from '@nozbe/watermelondb/Model';
import Address from './Address';
import EmergencyContact from './EmergencyContact';
import Enrollment from './Enrollment';

export default class Customer extends Model {
  static table = 'customers';

  static associations: Associations = {
    addresses: { type: 'has_many', foreignKey: 'customer_id' },
    emergency_contacts: { type: 'has_many', foreignKey: 'customer_id' },
    enrollments: { type: 'has_many', foreignKey: 'customer' },
  };

  @lazy enrollmentsRegistered = this.collections
    .get<Enrollment>('enrollments')
    .query(Q.where('registered', true), Q.where('customer', this.id));

  @lazy enrollmentsCheckedIn = this.collections
    .get<Enrollment>('enrollments')
    .query(
      Q.or(Q.where('checked_in', true), Q.where('trainer_checked', true)),
      Q.where('customer', this.id),
    );

  @children('addresses') addresses!: Query<Address>;
  @children('emergency_contacts') emergency_contacts!: Query<EmergencyContact>;
  @children('enrollments') enrollments!: Query<Enrollment>;

  // vamos manter o user como propriedade json para facilitar o fluxo vigente
  // mas depois precisamos refletir sobre como estruturar isso junto com o back
  // pois temos uma tabela dedicada ao user que é usado pelo /me
  @json('user', _json => _json || {}) user!: {};
  @field('nickname') nickname!: string;
  @field('cpf') cpf!: string;
  @field('passport') passport!: string;
  @field('is_foreigner') is_foreigner!: boolean;
  @field('phone_country_code') phone_country_code!: string;
  @field('phone') phone!: string;
  @field('height') height!: string;
  @field('weight') weight!: string;
  @json('anamnesis_status', _json => _json || {}) anamnesis_status!: {
    details: string;
    valid: boolean;
  };
  @field('available_subscriptions') available_subscriptions!: number;
  @field('subscriptions_limit') subscriptions_limit!: number;
  @field('gender') gender!: string;
  @field('subscriptions_time_limit') subscriptions_time_limit!: number;
  @field('lessons_skipped') lessons_skipped!: number;
  @field('birth_date') birth_date!: string;
  @field('picture') picture!: string;
  @field('medical_certificate_check') medical_certificate_check!: string;
  @field('medical_certificate_trainer') medical_certificate_trainer!: string;
  @json('sponsors', _json => _json || []) sponsors!: string[];
  @field('comments') comments!: string;
  @field('settings') settings!: string;
  @field('training_sheet') training_sheet!: string;
  @json('sponsor_group_codes', _json => _json || []) sponsor_group_codes!: {
    code: string;
    sponsor_group_id: string;
  }[];
}
