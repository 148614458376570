import { Model, Query } from '@nozbe/watermelondb';
import { children, field, json } from '@nozbe/watermelondb/decorators';
import { Associations } from '@nozbe/watermelondb/Model';
import Lesson from './Lesson';

export default class Modality extends Model {
  static table = 'modalities';

  static associations: Associations = {
    lessons: { type: 'has_many', foreignKey: 'modality' },
  };

  @children('lessons') lessons!: Query<Lesson>;

  @json('about_images', _json => _json || []) about_images!: string[];
  @field('name') name!: string;
  @field('image') image!: string;
  @field('square_image') square_image!: string;
  @field('horizontal_image') horizontal_image!: string;
  @field('lesson_image') lesson_image!: string;
  @field('about_title') about_title!: string;
  @field('about_text') about_text!: string;
  @field('searchable') searchable!: boolean;
}
